import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import Image from '@viking-eng/image';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { navigateTo, prepareHtml } from '../../common/Utils';

import './HomeCarousel.scss';

const getSlidesPerRow = () => {
  const windowWidth = window.innerWidth;

  if (windowWidth >= 768 && windowWidth <= 1200) {
    return 2;
  }

  if (windowWidth < 768) {
    return 1;
  }

  return 3;
};

const isCardsScrollable = (carouselCards, slidesPerRow) => {
  const cardsLength = carouselCards?.length;
  const windowWidth = window.innerWidth;

  if (windowWidth > 1200 && cardsLength > slidesPerRow) {
    return true;
  }

  if (windowWidth >= 768 && windowWidth <= 1200 && cardsLength > slidesPerRow) {
    return true;
  }

  if (windowWidth < 768 && cardsLength > slidesPerRow) {
    return true;
  }

  return false;
};

const HomeCarousel = ({ cards, setQueryParams }) => {
  let sliderRef = useRef(null);
  const [carouselCards, setCarouselCards] = useState([]);
  const [carouselKey, setCarouselKey] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerRow, setSlidesPerRow] = useState(0);

  const next = () => {
    sliderRef.slickNext();
    setCurrentSlide(currentSlide + 1);
  };

  const previous = () => {
    sliderRef.slickPrev();
    setCurrentSlide(currentSlide - 1);
  };

  const handleReinitializeCarousel = (array) => {
    setCarouselCards(array);
    setCarouselKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const slidesPerRow = getSlidesPerRow();
    window.handleReinitializeCarousel = handleReinitializeCarousel;
    setCarouselCards(cards);
    setSlidesPerRow(slidesPerRow);
    return () => {
      delete window.handleReinitializeCarousel;
    };
  }, [cards]);

  const addClickEventsToCard = (card) => {
    if (card?.link?.urlPath?.startsWith('http')) {
      return window.open(card?.link?.urlPath);
    }
    const queryParams = {};
    const key = card?.link?.queryParam;
    const value = card?.link?.modalToOpen;
    queryParams[key] = value;
    if (key && value) {
      setQueryParams(queryParams);
    }
    return navigateTo(card?.link?.urlPath);
  };

  const settings = {
    arrows: false,
    centerMode: false,
    centerPadding: '0px',
    draggable: false,
    infinite: false,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: false,
    touchThreshold: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          draggable: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          swipe: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          draggable: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          swipe: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          draggable: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: false,
        },
      },
    ],
  };
  return (
    <>
      {carouselCards?.length > 0 && (
        <div className="carousel-wrapper">
          <div className="carousel-container">
            <div className="carousel">
              <div className="title">Discover More and Enhance Your Journey</div>
              <div className="cards">
                {isCardsScrollable(carouselCards, slidesPerRow) && currentSlide > 0 && (
                  <Button appearance="icon" className="carousel-arrow-left" onButtonClick={previous}>
                    <Icon name="carousel-arrow-left" />
                  </Button>
                )}
                <Slider
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                  key={carouselKey}
                  {...settings}
                >
                  {carouselCards.map((card) => (
                    <div
                      id={card.id}
                      className="cards-item"
                      role="group"
                      aria-roledescription="slide"
                      aria-label={card.title}
                      key={card.id}
                    >
                      <div className="cards-image">
                        <Image {...card.image} className="img-fluid" />
                      </div>
                      <div className="cards-caption">
                        <div className="caption-title">{card.title}</div>
                        <div className="caption-description" dangerouslySetInnerHTML={prepareHtml(card.description)} />
                        <div className="caption-button">
                          <Button appearance="primary" onButtonClick={() => addClickEventsToCard(card)}>
                            {card.buttonLabel}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                {isCardsScrollable(carouselCards, slidesPerRow) &&
                  Number(currentSlide + slidesPerRow) < carouselCards.length && (
                    <Button appearance="icon" className="carousel-arrow-right" onButtonClick={next}>
                      <Icon name="carousel-arrow-right" />
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeCarousel;

import FieldValidationError from '@viking-eng/field-validation-error';
import Icon from '@viking-eng/icon/lib/Icon';
import InputText from '@viking-eng/input-text';
import Select from '@viking-eng/select';
import Spinner from '@viking-eng/spinner';
import classNames from 'classnames';
import React from 'react';
import { Field, TokenEx } from '../..';
import { CREDIT_CARD } from '../../../common/Constants';
import { cvvMask } from '../../../common/forms/Masks';
import {
  ERROR_CODES,
  required,
  validCvvOnCard,
  validExpiryDate,
  validNameOnCard,
  validOnboardCreditCardDate,
} from '../../../common/forms/Validations';
import { getCreditCardExpiryValues } from '../../../common/Utils';
import PropTypes from '../../../PropTypes';
import Address from '../address/Address';
import './CreditCard.scss';

const ExpiryDateError = ({ expiryDateActive, meta: { error }, errorCodes, errorMessages }) => (
  <>
    {error && !expiryDateActive && (
      <div className="expiry-date-error">
        <FieldValidationError errorCode={error} errorCodes={errorCodes} errorMessages={errorMessages} />
      </div>
    )}
  </>
);

ExpiryDateError.propTypes = {
  expiryDateActive: PropTypes.bool,
  meta: PropTypes.reduxFormMeta.isRequired,
  errorCodes: PropTypes.errorCodes.isRequired,
  errorMessages: PropTypes.errorMessages.isRequired,
};

ExpiryDateError.defaultProps = {
  expiryDateActive: false,
};

class CreditCard extends React.Component {
  componentDidUpdate(prevProps) {
    const { cardTypeValue: prevCardType } = prevProps;
    const { cardTypeValue, updateCardType } = this.props;
    if (cardTypeValue !== prevCardType && cardTypeValue !== undefined) {
      updateCardType(cardTypeValue);
    }
  }

  render() {
    const {
      cardNumber,
      cardType,
      cardTypeValue,
      currentCardType,
      cvv,
      cvvToolTip,
      disabled,
      expiryDateActive,
      expiryMonth,
      expiryYear,
      forEdit,
      handleExpiryDateChange,
      iconName,
      invalidCardError,
      isOnboardForm,
      isSubmitting,
      isTokenExLoaded,
      maskedAccountNumber,
      name,
      nameOnCard,
      propCountry,
      resetFields,
      updateCardType,
      ...addressFields
    } = this.props;
    const { months, years } = getCreditCardExpiryValues();
    const rowClass = 'row no-gutters';
    const getReadOnly = () => {
      if (nameOnCard && nameOnCard.options && nameOnCard.options.length === 1) {
        return true;
      }
      return false;
    };
    // TODO: set this as env variable if we keep it
    // const blurCardField = true;
    return (
      <div className="form-credit-card">
        {!isTokenExLoaded && (
          <div className="loading-spinner">
            <Spinner color="black" />
          </div>
        )}
        <div>
          {isTokenExLoaded && (
            <div>
              {!isOnboardForm && name && nameOnCard ? (
                <div className={rowClass}>
                  <Field
                    component={InputText}
                    inputAttributes={{ disabled: disabled || isSubmitting, autoComplete: 'name' }}
                    name="name"
                    classes="text-uppercase"
                    className={classNames('card-container', {
                      'for-edit': forEdit,
                    })}
                    validate={[required, validNameOnCard]}
                    errorCodes={{
                      [ERROR_CODES.INVALID]: 'NameOnCardInvalid',
                      [ERROR_CODES.REQUIRED]: 'NameOnCardRequired',
                    }}
                    {...name}
                  />
                </div>
              ) : (
                <div className={rowClass}>
                  <Field
                    component={Select}
                    name="nameOnCard"
                    classes="text-uppercase"
                    validate={required}
                    errorCodes={{
                      [ERROR_CODES.REQUIRED]: 'NameOnCardRequired',
                    }}
                    disabled={disabled || isSubmitting}
                    readOnly={getReadOnly()}
                    options={nameOnCard.options || [{ label: 'N/A', value: null }]}
                    placeholder={nameOnCard.placeholder}
                  />
                </div>
              )}
            </div>
          )}
          <div className={`${rowClass} token-ex-row`}>
            {forEdit ? (
              <Field
                component={InputText}
                inputAttributes={{ disabled: true }}
                name="maskedAccountNumber"
                {...cardNumber}
              />
            ) : (
              <TokenEx
                paymentMethod={CREDIT_CARD}
                {...cardNumber}
                invalidCardError={invalidCardError}
                disabled={disabled || isSubmitting}
              />
            )}
            {/* {blurField && (<div className="blur-bar" />)} */}
            {iconName && isTokenExLoaded && (
              <div
                className={classNames('card-container', {
                  'for-edit': forEdit,
                })}
              >
                <Icon className="card-icon" name={iconName} />
              </div>
            )}
          </div>
          {(expiryMonth || expiryYear) && isTokenExLoaded && (
            <>
              {cvv && (
                <div className={rowClass}>
                  <div className="d-none d-md-inline half-width">
                    <Field
                      component={InputText}
                      name="cvv"
                      disabled={disabled || isSubmitting}
                      inputAttributes={{ disabled: disabled || isSubmitting }}
                      validate={[required, validCvvOnCard]}
                      errorCodes={{
                        [ERROR_CODES.REQUIRED]: ERROR_CODES.CVV_REQUIRED,
                        [ERROR_CODES.INVALID_CVV]: ERROR_CODES.INVALID_CVV,
                      }}
                      {...cvvMask(cardTypeValue)}
                      {...cvv}
                    />
                  </div>
                  <div className="d-md-none col-12">
                    <Field
                      component={InputText}
                      name="cvv"
                      disabled={disabled || isSubmitting}
                      validate={[required, validCvvOnCard]}
                      errorCodes={{
                        [ERROR_CODES.REQUIRED]: ERROR_CODES.CVV_REQUIRED,
                        [ERROR_CODES.INVALID_CVV]: ERROR_CODES.INVALID_CVV,
                      }}
                      {...cvvMask(cardTypeValue)}
                      {...cvv}
                    />
                  </div>
                  <div className="d-none d-md-inline half-width cvv-tip">{cvvToolTip}</div>
                  <div className="d-md-none col cvv-tip">{cvvToolTip}</div>
                </div>
              )}
              <div className={rowClass}>
                {expiryMonth && (
                  <div className="half-width">
                    <Field
                      component={Select}
                      name="expiryMonth"
                      options={months}
                      disabled={disabled || isSubmitting}
                      onChange={(event, newValue) => {
                        handleExpiryDateChange({ month: newValue });
                      }}
                      validate={required}
                      errorCodes={{
                        [ERROR_CODES.REQUIRED]: 'ExpMonthRequired',
                      }}
                      {...expiryMonth}
                    />
                  </div>
                )}
                {expiryYear && (
                  <div className="half-width">
                    <Field
                      component={Select}
                      name="expiryYear"
                      options={years}
                      disabled={disabled || isSubmitting}
                      onChange={(event, newValue) => {
                        handleExpiryDateChange({ year: newValue });
                      }}
                      validate={required}
                      errorCodes={{
                        [ERROR_CODES.REQUIRED]: 'ExpYearRequired',
                      }}
                      {...expiryYear}
                    />
                  </div>
                )}
              </div>
              <Field
                component={ExpiryDateError}
                name="expiryDate"
                validate={[validExpiryDate, validOnboardCreditCardDate]}
                errorCodes={{
                  [ERROR_CODES.INVALID]: 'ExpMonthYearInvalid',
                  [ERROR_CODES.INVALID_DISEMBARK_MONTH_YEAR]: 'ExpDisembarkMonthYearInvalid',
                }}
                expiryDateActive={expiryDateActive}
              />
            </>
          )}
          {isTokenExLoaded && (
            <Address {...addressFields} submitting={isSubmitting} propCountry={propCountry} resetFields={resetFields} />
          )}
        </div>
      </div>
    );
  }
}

CreditCard.propTypes = {
  ...PropTypes.creditCardFields,
};

export default CreditCard;

import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { formValueSelector, getFormInitialValues, submit } from 'redux-form';
import { getWaiter } from 'redux-waiter';
import commonStore from '../../common';
import { triggerLinkEvent, triggerPageView } from '../../common/Analytics';
import {
  AIR_CABIN_CLASS,
  AIR_FLOW_NAMES,
  AIR_LABELS,
  AIR_PAGE_ANALYTICS_BASE_DATA,
  AIR_PATHS,
  FORMS,
  MODALS,
  NOTIFICATION_TYPES,
  RESPONSE_STATUS,
  WAITER_NAMESPACES,
} from '../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../common/ModalStore';
import userStore from '../../common/UserStore';
import { getPageHasBeenRefreshed, goTo } from '../../common/Utils';
import airStore, {
  airCheckout,
  commitFlightSelection,
  getCurrentCabinClass,
  getSegmentNumber,
  getSelectedSeats,
  navigateBack,
  navigateTo,
  resetSearch,
  setFinalChosenSeats,
  updateSeatMapData,
  updateSeats,
} from '../../pages/newAir/AirStore';
import AirNav from './AirNav';

const { clearModal } = modalStore.creators;

const {
  selectors: {
    getAddCollectTotal,
    getAvailableFlights,
    getSearchComplete,
    getSeatmapPageNumber,
    isLastSeatmapPage,
    getAirBookingFlow,
    getCheckoutProcessing,
    getFlightSelectionKey,
    getFlightSelectionCabinClass,
    getItinerariesDiffer,
    getIsAirPendingInProgress,
    getChosenFlights,
    getPreAssignedSeats,
    getPreAssignedSeatsSplitPnr,
    getSearchGetPending,
    getAllSeatMapsUnavailable,
    getFlightSelectionValue,
    getCheckoutModalData,
    getExitSeatAccepted,
    getAirPassengersSkipPassengerSelection,
    getFilters,
    getOriginalTotalAvailableFlightCount,
    getIsAirViewOnly,
    getIsRepriced,
    checkForPaidSeats,
  },
  creators: {
    setSeatmapPageNumber,
    setResetPassengerTurn,
    setExitSeatAccepted,
    setSeatMapDone,
    setSeatTimeOutCallCount,
  },
} = airStore;
const {
  selectors: { getIsViewOnly, getBookingNumber },
} = userStore;
const {
  selectors: { getLabels },
} = commonStore;
const { CHANGE_SEATS, UPGRADE_CLASS, CHANGE_ITINERARIES } = AIR_FLOW_NAMES;
const { VALIDATE_CHECKOUT, POST_SAVE_SEATS, SEATMAP, GET_SEARCH_REQS, POST_SEARCH_FLIGHTS } = WAITER_NAMESPACES;
const {
  SUCCESS_200,
  SUCCESS_CREATED_201,
  SUCCESS_NO_CONTENT_204,
  SUCCESS_PARTIAL_CONTENT_206,
  FAILURE_TIMEOUT_408,
} = RESPONSE_STATUS;

const searchSelector = formValueSelector(FORMS.AIR_SEARCH);
const preName = 'preFlightSelector';
const postName = 'postFlightSelector';
const paymentSelector = formValueSelector(FORMS.PAYMENT_CHECKOUT);

const getProductInfo = (state, chosenFlights, selectedFlight) => {
  return {
    product_id: ['air'],
    product_name: ['air'],
    product_category: ['air'],
    current_class: [getCurrentCabinClass(state)],
    selected_class: [get(selectedFlight, 'airClass', '')],
    product_price: [get(selectedFlight, 'offer.addCollectFee.totalFee', 0)],
    product_quantity: [chosenFlights.passengers.length],
  };
};

const hasSeatChanges = (state, allFlightsRaw, chosenFlights) => {
  const allFlights = allFlightsRaw.filter((item) => item.type !== 'label');
  let passenger1Seats = [];
  let passenger2Seats = [];
  passenger1Seats = allFlights
    ? allFlights.map((flight) => ({
        segmentNumber: flight.segmentNumber,
        seat: get(flight, 'seat', ''),
      }))
    : [];
  if (chosenFlights.passengers.length > 1) {
    const chosenFlights2 = chosenFlights.passengers ? chosenFlights.passengers[1].flightSchedule : '';
    const allFlights2Raw = chosenFlights2 ? chosenFlights2.pre.concat(chosenFlights2.post) : '';
    const allFlights2 = allFlights2Raw.filter((item) => item.type !== 'label');
    passenger2Seats = allFlights2
      ? allFlights2.map((flight) => ({
          segmentNumber: flight.segmentNumber,
          seat: get(flight, 'seat', ''),
        }))
      : [];
  }
  const getSelectedSeat1 = (segmentId) => {
    const selectedSeat1Data = passenger1Seats.filter((item) => item.segmentNumber === segmentId);
    return get(selectedSeat1Data[0], 'seat', '');
  };
  const getSelectedSeat2 = (segmentId) => {
    const selectedSeat2Data = passenger2Seats.filter((item) => item.segmentNumber === segmentId);
    return get(selectedSeat2Data[0], 'seat', '');
  };

  const seatmapPage = getSeatmapPageNumber(state);
  const currentSegment = allFlights[seatmapPage].segmentNumber;
  const seatSelected1 = getSelectedSeat1(currentSegment);
  const seatSelected2 = getSelectedSeat2(currentSegment);
  if (!seatSelected1 && !seatSelected2) {
    return false;
  }
  return true;
};

const outboundDeviation = (values) => values?.preFlightSelector?.[values?.preFlightSelector?.length - 1] || {};
const returnDeviation = (values) => values?.postFlightSelector?.[0] || {};

const outboundHotelDeviation = (values) => {
  return values?.preFlightSelector?.[0]?.date;
};

const returnHotelDeviation = (values) => {
  return values?.postFlightSelector?.[1]?.date;
};
const hasDeviationChanges = (initialValues, formValues, hasOutboundHotel, hasReturnHotel) => {
  const outboundInit = outboundDeviation(initialValues);
  const outboundForm = outboundDeviation(formValues);
  const returnInit = returnDeviation(initialValues);
  const returnForm = returnDeviation(formValues);
  if (
    outboundInit.to !== outboundForm.to ||
    outboundInit.date !== outboundForm.date ||
    (hasOutboundHotel && outboundHotelDeviation(initialValues) !== outboundHotelDeviation(formValues)) ||
    returnInit.to !== returnForm.to ||
    returnInit.date !== returnForm.date ||
    (hasReturnHotel && returnHotelDeviation(initialValues) !== returnHotelDeviation(formValues))
  ) {
    return true;
  }
  return false;
};

const getSearchAndBookingDetails = (state, allFlightsRaw, chosenFlights) => {
  const labels = getLabels(state);
  const hasDeviation = get(chosenFlights, ['passengers', 0, 'hasDeviation'], false);
  const airLabels = allFlightsRaw.filter((x) => x.type === 'label');
  const stopoverLabel = get(labels, 'pages.newAir.labels.stopover') || AIR_LABELS.STOPOVER;
  const hotelLabel = get(labels, 'pages.newAir.labels.hotel') || AIR_LABELS.HOTEL;
  const hotelStopoverLabel = get(labels, 'pages.newAir.labels.hotelStopover') || AIR_LABELS.HOTEL_STOPOVER;
  const outboundLabel = get(labels, 'pages.newAir.labels.outbound') || AIR_LABELS.OUTBOUND;
  const returnLabel = get(labels, 'pages.newAir.labels.return') || AIR_LABELS.RETURN;
  const hasStopover =
    airLabels.filter((x) => x.text.indexOf(stopoverLabel) > 0 && x.text.indexOf(hotelLabel) < 0).length > 0;
  const hasHotel = airLabels.filter((x) => x.text.indexOf(hotelStopoverLabel) > 0).length > 0;
  const hasOutboundHotel =
    airLabels.filter((x) => x.text.includes(hotelStopoverLabel) > 0 && x.text.includes(outboundLabel) > 0).length > 0;
  const hasReturnHotel =
    airLabels.filter((x) => x.text.includes(hotelStopoverLabel) > 0 && x.text.includes(returnLabel) > 0).length > 0;
  const values = searchSelector(state, preName, postName);
  const initialValues = getFormInitialValues(FORMS.AIR_SEARCH)(state);
  const searchDetails = {};
  if (values) {
    if (values.preFlightSelector && values.preFlightSelector.length > 0) {
      searchDetails.departure_airport = values.preFlightSelector[0].from;
      searchDetails.departure_date = moment(values.preFlightSelector[0].date).format('YYYY-MM-DD');
    }
    if (values.postFlightSelector && values.postFlightSelector.length > 0) {
      const lastIndex = values.postFlightSelector.length - 1;
      searchDetails.arrival_airport = values.postFlightSelector[lastIndex].to;
      searchDetails.return_date = moment(values.postFlightSelector[lastIndex].date).format('YYYY-MM-DD');
    }
  }

  const hasStopoverChanges =
    ((values?.preFlightSelector?.length || 0) > 1 && !hasOutboundHotel) ||
    ((values?.postFlightSelector?.length || 0) > 1 && !hasReturnHotel);
  return {
    search_details: {
      stopover: hasStopoverChanges,
      hotel_stopover: hasHotel,
      deviation: hasDeviationChanges(initialValues, values, hasOutboundHotel, hasReturnHotel),
      ...searchDetails,
    },
    booking_details: {
      deviation: hasDeviation,
      stopover: hasStopover,
      hotel: hasHotel,
    },
  };
};

const mapStateToProps = (state, { location }) => {
  const path = location.pathname;
  const labels = getLabels(state);

  const { exitSeatSelectedModal } = getCheckoutModalData(state);

  const changeSeatFlow = getAirBookingFlow(state) === CHANGE_SEATS;
  const airBookingFlow = getAirBookingFlow(state);
  const isUpgradeClass = airBookingFlow === UPGRADE_CLASS;
  const isSearchPath = path === AIR_PATHS.SEARCH;
  const allSeatMapsUnavailable = getAllSeatMapsUnavailable(state);
  const lastSeatMap = path === AIR_PATHS.SEATS && isLastSeatmapPage(state);
  const isViewOnly = getIsViewOnly(state) || getIsAirViewOnly(state);
  const checkoutProcessing = getCheckoutProcessing(state);

  const hasPaidSeats = checkForPaidSeats(state);

  const nav = {
    primaryLabel: get(labels, 'pages.newAir.labels.buttons.next'),
    secondaryLabel: get(labels, 'pages.newAir.labels.buttons.previous'),
    primaryDisabled: false,
    secondaryDisabled: false,
  };

  const pageHasBeenRefreshed = getPageHasBeenRefreshed();

  if (isViewOnly || (pageHasBeenRefreshed && checkoutProcessing)) {
    nav.primaryDisabled = true;
    nav.secondaryDisabled = true;
  }

  if (path === AIR_PATHS.SEARCH) {
    const searchComplete = getSearchComplete(state);
    const [flightInfoUnchosen, availableFlightClasses] = getAvailableFlights(state);
    const flightSelection = getFlightSelectionKey(state);
    const flightSelectionCabinClass = getFlightSelectionCabinClass(state);
    const { ECONOMY, PREMIUM_ECONOMY } = AIR_CABIN_CLASS;
    const currentFlightClass = availableFlightClasses.includes(ECONOMY.text) ? ECONOMY.code : PREMIUM_ECONOMY.code;

    const originalFlightCount = getOriginalTotalAvailableFlightCount(state);
    const filters = getFilters(state);
    const { hasAdditionalOffers } = filters;
    const onlyCurrentNoAdditionals = !hasAdditionalOffers && originalFlightCount === 1;

    const searchSelector = formValueSelector(FORMS.AIR_SEARCH)(state, preName, postName);
    const searchReqWaiter = isUpgradeClass ? `${UPGRADE_CLASS}-${GET_SEARCH_REQS}` : GET_SEARCH_REQS;
    const searchReqs = getWaiter(state, searchReqWaiter);
    const searchReqsGateways = searchReqs?.response?.data?.gateways;
    const originalGateways = searchReqs?.response?.data?.travelDates;
    const handleOriginalGateways = (gateways, direction) => {
      const originalGatewaysPax1 = getOriginalGatewayCodes(gateways?.[0]?.[`${direction}OriginalAir`]);
      if (gateways?.length > 1) {
        const originalGatewaysPax2 = getOriginalGatewayCodes(gateways?.[1]?.[`${direction}OriginalAir`]);
        return originalGatewaysPax1?.concat(originalGatewaysPax2);
      }
      return originalGatewaysPax1;
    };
    const getOriginalGatewayCodes = (gateways) => {
      const airCodes = [gateways?.[0]?.departure?.code, gateways?.[0]?.arrival?.code];
      if (gateways?.alternateCities) {
        airCodes.forEach((city) => airCodes.push(city?.code));
      }
      return airCodes;
    };
    const originalPreGatewayCodes = handleOriginalGateways(originalGateways, 'pre');
    const originalPostGatewayCodes = handleOriginalGateways(originalGateways, 'post');
    const filterGateways = (gateways) => gateways?.filter((gateway) => gateway.active).map((gateway) => gateway.code);
    const preDeviationGateways = filterGateways(searchReqsGateways?.preDeviationGateways)?.concat(
      originalPreGatewayCodes
    );
    const preStopoverGateways = filterGateways(searchReqsGateways?.preStopoverGateways)?.concat(
      originalPreGatewayCodes
    );
    const postDeviationGateways = filterGateways(searchReqsGateways?.postDeviationGateways)?.concat(
      originalPostGatewayCodes
    );
    const postStopoverGateways = filterGateways(searchReqsGateways?.postStopoverGateways)?.concat(
      originalPostGatewayCodes
    );
    const homeGateways = searchReqs?.response?.data?.endpointGateways?.airports?.map((airport) => airport.airportCode);

    const preFlightSelector = searchSelector?.preFlightSelector;
    const postFlightSelector = searchSelector?.postFlightSelector;

    const getGatewayMatch = (deviationCodes, stopoverCodes, flightSelector, direction) => {
      if (flightSelector?.length === 0) {
        return true;
      }
      const firstLeg = flightSelector?.[0];
      const secondLeg = flightSelector?.length > 1 && flightSelector?.[1];
      let homeGateway = direction === 'pre' ? firstLeg?.from : firstLeg?.to;
      let deviationGateway = direction === 'pre' ? firstLeg?.to : firstLeg?.from;
      const stopoverGateway = secondLeg && direction === 'pre' ? secondLeg?.from : firstLeg?.to;
      const getHomeMatch = (gateway) => homeGateways?.find((code) => code === gateway);
      const getDeviationMatch = (gateway) => deviationCodes?.find((code) => code === gateway);
      const getStopoverMatch = (gateway) => stopoverCodes?.find((code) => code === gateway);

      if (secondLeg) {
        homeGateway = direction === 'pre' ? firstLeg?.from : secondLeg?.to;
        deviationGateway = direction === 'pre' ? firstLeg?.to : secondLeg?.from;
      }

      const homeMatch = getHomeMatch(homeGateway);
      const deviationMatch = secondLeg ? true : getDeviationMatch(deviationGateway);
      const stopoverMatch = secondLeg ? getStopoverMatch(stopoverGateway) : true;

      return homeMatch && deviationMatch && stopoverMatch;
    };

    const preGatewayMatch = getGatewayMatch(preDeviationGateways, preStopoverGateways, preFlightSelector, 'pre');
    const postGatewayMatch = getGatewayMatch(postDeviationGateways, postStopoverGateways, postFlightSelector, 'post');

    if (!preGatewayMatch || !postGatewayMatch) {
      nav.primaryDisabled = true;
    }

    if (!searchComplete && flightInfoUnchosen.length === 0) {
      nav.primaryLabel = get(labels, 'pages.newAir.labels.buttons.search');
    } else if (!flightSelection) {
      nav.primaryDisabled = true;
    }

    if (isUpgradeClass && flightSelectionCabinClass === currentFlightClass && flightInfoUnchosen.length === 1) {
      nav.primaryDisabled = true;
    }
    if (!isUpgradeClass && searchComplete && onlyCurrentNoAdditionals) {
      nav.primaryDisabled = true;
    }
  }

  if (path === AIR_PATHS.SEATS && !lastSeatMap) {
    nav.primaryLabel = get(labels, 'pages.newAir.labels.buttons.nextFlight');
  }

  if (lastSeatMap && changeSeatFlow) {
    nav.primaryProcessing = getWaiter(state, POST_SAVE_SEATS).isPending || getIsAirPendingInProgress(state) || false;
    if (allSeatMapsUnavailable) {
      nav.primaryLabel = get(labels, 'pages.newAir.labels.buttons.continue');
    } else if (hasPaidSeats) {
      nav.primaryLabel = get(labels, 'pages.newAir.labels.buttons.next');
    } else {
      nav.primaryLabel = get(labels, 'pages.newAir.labels.buttons.confirm');
    }
    nav.primaryDisabled = isViewOnly;
  }

  const seatMapResponse = getWaiter(state, SEATMAP);
  const seatMapPending = seatMapResponse.isPending;
  const searchReqWaiter = isUpgradeClass ? `${UPGRADE_CLASS}-${GET_SEARCH_REQS}` : GET_SEARCH_REQS;
  const searchReqs = getWaiter(state, searchReqWaiter);
  const postSearchPending = getWaiter(state, POST_SEARCH_FLIGHTS).isPending;
  const getSearchPending = getSearchGetPending(state);
  const searchReqPending = searchReqs.isPending;
  const searchCriteriaStatus = get(searchReqs, 'response.status', null);
  const seatMapStatus = get(seatMapResponse, 'response.status', null);
  const seatMapInternalCode = get(seatMapResponse, 'response.data.internalCode', '');

  if (
    changeSeatFlow &&
    seatMapStatus === RESPONSE_STATUS.FAILURE_NOT_FOUND_404 &&
    seatMapInternalCode === RESPONSE_STATUS.FAILURE_NO_PNR
  ) {
    nav.primaryDisabled = true;
  }

  if (seatMapPending || searchReqPending || postSearchPending || getSearchPending) {
    nav.primaryDisabled = true;
  }

  const isRepriced = getIsRepriced(state);

  if (path === AIR_PATHS.REVIEW) {
    if (getAddCollectTotal(state) === 0 && !isRepriced) {
      nav.primaryProcessing = getCheckoutProcessing(state) || false;
      nav.primaryLabel = get(labels, 'pages.newAir.labels.buttons.confirm');
    }

    if (getWaiter(state, VALIDATE_CHECKOUT).isPending) {
      nav.primaryDisabled = true;
    }
  }

  return {
    nav,
    searchReqPending,
    searchCriteriaStatus,
    isSearchPath,
    exitSeatSelectedModal,
  };
};

const hasLayoverWarning = (flight) => {
  const departureWarning = flight?.departureLegs.some(
    (leg) => leg.segment?.layoverDurationWarning !== null && leg.segment?.layoverDurationWarning
  );
  const arrivalWarning = flight?.arrivalLegs.some(
    (leg) => leg.segment?.layoverDurationWarning !== null && leg.segment?.layoverDurationWarning
  );
  return departureWarning || arrivalWarning;
};

const mapDispatchToProps = (dispatch, { location }) => {
  const path = location.pathname;
  return {
    acceptExitSeat: () => {
      dispatch(setExitSeatAccepted(true));
      dispatch(clearModal());
    },
    clearModal: () => dispatch(clearModal()),
    primaryClick: () => {
      return dispatch(async (_, getState) => {
        dispatch(setSeatTimeOutCallCount(0));
        const state = getState();
        const bookingNumber = getBookingNumber(state) || '';
        const currAirFlow = getAirBookingFlow(state);
        const searchComplete = getSearchComplete(state);
        const lastPage = isLastSeatmapPage(state);
        const chosenFlights = getChosenFlights(state);
        const flightSchedules = get(chosenFlights, ['passengers', 0, 'flightSchedule'], {
          pre: [],
          post: [],
        });
        const allFlightsRaw = flightSchedules.pre.concat(flightSchedules.post);
        const isRepriced = getIsRepriced(state);
        const pageAnalytics = AIR_PAGE_ANALYTICS_BASE_DATA[currAirFlow];
        const linkEvent = {
          button: 'next_flight',
          event_name: 'air_flow_nav',
          total_steps: (pageAnalytics && pageAnalytics.total_steps) || 0,
          ...((pageAnalytics && pageAnalytics.pages[path]) || {}),
          ...getSearchAndBookingDetails(getState(), allFlightsRaw, chosenFlights),
          air_mod_type: currAirFlow,
          booking_id: bookingNumber,
        };
        if (path === AIR_PATHS.SEARCH) {
          const flightSelection = getFlightSelectionKey(getState());
          if (searchComplete) {
            linkEvent.air_step = (Number.parseInt(linkEvent.air_step || 0, 10) + 1).toString();
          }
          if (searchComplete && !flightSelection) {
            triggerLinkEvent({ ...linkEvent, event_name: 'no_air_selection' });
            return dispatch(setViewAndShowModal(MODALS.NO_AIR_SELECTION));
          }
          const [flightInfoUnchosen] = getAvailableFlights(getState());
          if (searchComplete && flightInfoUnchosen.length > 0) {
            dispatch(commitFlightSelection());
            const preSelectedChosen = get(
              getChosenFlights(getState()),
              'passengers[0].flightSchedule.offer.current',
              false
            );
            if (preSelectedChosen) {
              triggerLinkEvent({ ...linkEvent, event_name: 'pre-selected_chosen' });
              dispatch(setViewAndShowModal(MODALS.PRE_SELECTED_CHOSEN_MODAL));
              return null;
            }
            const [flightId] = flightSelection.split(',');
            const selectedFlight = flightInfoUnchosen[parseInt(flightId, 10)];
            if (hasLayoverWarning(selectedFlight)) {
              dispatch(setViewAndShowModal(MODALS.AIR_CONNECTION_WARNING));
              return null;
            }

            triggerLinkEvent({
              ...linkEvent,
              acdl_event: 'add_to_cart',
              event_name: currAirFlow === UPGRADE_CLASS ? 'same_flight_alteration' : 'submit',
              ...getProductInfo(state, chosenFlights, getFlightSelectionValue(getState())),
            });
            return dispatch(navigateTo(AIR_PATHS.SEATS));
          }

          triggerLinkEvent({ ...linkEvent, event_name: 'submit' });
          return dispatch(submit(FORMS.AIR_SEARCH));
        }

        if (path === AIR_PATHS.SEATS) {
          const seatmapPage = getSeatmapPageNumber(state);
          const isExitSeatAccepted = getExitSeatAccepted(getState());
          const isSplitPnr = getItinerariesDiffer(state);
          const preAssignedSeats = isSplitPnr ? getPreAssignedSeatsSplitPnr(state) : getPreAssignedSeats(state);
          const flights = get(getChosenFlights(state), 'passengers', []);
          const seatMapStatus = get(getWaiter(state, SEATMAP), 'response.status', '');
          const internalCode = get(getWaiter(state, SEATMAP), 'response.data.internalCode', '');
          const seatMapData = get(getWaiter(state, SEATMAP), 'response.data', '');
          const seatMapCabins = get(seatMapData, 'cabins', []);
          const oneOrMoreSeatsAvailable = seatMapCabins.some((c) => c.availableSeats > 0);
          const seatMapNotAvailable =
            seatMapStatus === RESPONSE_STATUS.FAILURE_ERROR_CODE ||
            seatMapStatus === RESPONSE_STATUS.FAILURE_TIMEOUT_408 ||
            seatMapStatus === RESPONSE_STATUS.FAILURE_ERROR_CODE_500 ||
            internalCode === RESPONSE_STATUS.FAILURE_NOT_FOUND ||
            !oneOrMoreSeatsAvailable;

          const hasPaidSeats = checkForPaidSeats(state);

          const reducedCabins = seatMapCabins.length
            ? seatMapCabins
                .reduce((acc, curr) => {
                  return acc.concat(curr.seatRows);
                }, [])
                .flat()
            : [];

          const isExitSeat = (seat) => {
            const number = seat.substr(0, seat.length - 1);
            const seatRowArr = reducedCabins.length ? reducedCabins.filter((item) => item.number === +number) : [];
            const seatArr = seatRowArr.length ? seatRowArr[0]?.seats.filter((item) => item.displayNumber === seat) : [];
            return seatArr.length ? seatArr[0].exitRow : false;
          };

          let exitSeatSelected = false;
          let showSeatsSelectedModal = false;
          const pax1Seats = getSelectedSeats(flights[0]);
          const pax1PreAssignedSeats = isSplitPnr ? preAssignedSeats : preAssignedSeats.pax1AssignedSeats;
          if (
            pax1Seats[seatmapPage]?.seat === '' &&
            pax1PreAssignedSeats[seatmapPage]?.seat !== '' &&
            currAirFlow === CHANGE_SEATS
          ) {
            showSeatsSelectedModal = true;
          }
          if (flights.length === 1) {
            exitSeatSelected = isExitSeat(pax1Seats[seatmapPage]?.seat);
          } else if (flights.length > 1) {
            const pax2Seats = getSelectedSeats(flights[1]);
            if (
              (pax1Seats[seatmapPage]?.seat !== '' && pax2Seats[seatmapPage]?.seat === '') ||
              (pax1Seats[seatmapPage]?.seat === '' && pax2Seats[seatmapPage]?.seat !== '')
            ) {
              showSeatsSelectedModal = true;
            }
            exitSeatSelected = isExitSeat(pax1Seats[seatmapPage]?.seat) || isExitSeat(pax2Seats[seatmapPage]?.seat);
          }
          if (showSeatsSelectedModal && !seatMapNotAvailable) {
            dispatch(setViewAndShowModal(MODALS.NO_SEAT_SELECTED_MODAL));
            return null;
          }
          if (!isExitSeatAccepted && exitSeatSelected && !seatMapNotAvailable) {
            dispatch(setViewAndShowModal(MODALS.EXIT_SEAT_SELECTED_MODAL));
            return null;
          }

          if (!hasSeatChanges(state, allFlightsRaw, chosenFlights)) {
            linkEvent.event_name = 'seats_not_selected';
          }

          if (!lastPage) {
            window.scrollTo(0, 0);
            const newSeatmapPage = seatmapPage + 1;
            const segmentNumber = dispatch(getSegmentNumber(newSeatmapPage));
            dispatch(setSeatmapPageNumber(newSeatmapPage));
            dispatch(setResetPassengerTurn(true));
            triggerLinkEvent({ ...linkEvent, button: 'next_flight' });
            return dispatch(updateSeatMapData(segmentNumber));
          }

          if (lastPage && currAirFlow === CHANGE_SEATS) {
            const flightChosen = get(chosenFlights, ['passengers'], {});
            dispatch(setSeatMapDone(true));
            await dispatch(setFinalChosenSeats(flightChosen));
            if (hasPaidSeats) {
              return dispatch(navigateTo(AIR_PATHS.REVIEW));
            }
            const updateSeat = await dispatch(updateSeats());
            const updateSeatsResponseError = get(updateSeat, 'data.errorCode', null);
            if (updateSeatsResponseError) {
              dispatch(setViewAndShowModal(MODALS.FAILED_BOOKING_MODAL));
              triggerLinkEvent({ ...linkEvent, event_name: 'booking_failed' });
              return null;
            }

            triggerPageView(path, 'AIR', {
              acdl_event: 'purchase',
              payment_method: paymentSelector(state, 'paymentMethod'),
              event_name: 'submit',
              ...getProductInfo(state, chosenFlights, getFlightSelectionValue(state)),
              booking_id: bookingNumber,
            });
            triggerLinkEvent({ ...linkEvent, acdl_event: 'purchase', event_name: 'submit' });
            return dispatch(navigateTo(AIR_PATHS.DONE));
          }

          triggerLinkEvent({ ...linkEvent, event_name: 'submit' });
          return dispatch(navigateTo(AIR_PATHS.REVIEW));
        }

        if (path === AIR_PATHS.REVIEW) {
          if (getAddCollectTotal(state) === 0 && !isRepriced) {
            const checkout = await dispatch(airCheckout());
            if (!checkout) {
              dispatch(setViewAndShowModal(MODALS.FAILED_BOOKING_MODAL));
              triggerLinkEvent(linkEvent);
              return null;
            }
            if (checkout.isLocked) {
              dispatch(setViewAndShowModal(MODALS.VIEW_ONLY));
              triggerLinkEvent(linkEvent);
              return null;
            }
            if (
              checkout.status !== SUCCESS_200 &&
              checkout.status !== SUCCESS_CREATED_201 &&
              checkout.status !== SUCCESS_NO_CONTENT_204 &&
              checkout.status !== SUCCESS_PARTIAL_CONTENT_206 &&
              checkout.status !== FAILURE_TIMEOUT_408 &&
              checkout !== NOTIFICATION_TYPES.UPDATE_FLIGHT_IN_PROGRESS
            ) {
              dispatch(setViewAndShowModal(MODALS.FAILED_BOOKING_MODAL));
              triggerLinkEvent(linkEvent);
              return null;
            }

            triggerPageView(path, 'AIR', {
              acdl_event: 'purchase',
              payment_method: paymentSelector(state, 'paymentMethod'),
              event_name: 'submit',
              ...getProductInfo(state, chosenFlights, getFlightSelectionValue(state)),
              booking_id: bookingNumber,
            });

            triggerLinkEvent({ ...linkEvent, acdl_event: 'purchase', event_name: 'submit' });
            dispatch(goTo(AIR_PATHS.DONE));
            return null;
          }

          triggerLinkEvent({ ...linkEvent, event_name: 'submit' });
          dispatch(navigateTo(AIR_PATHS.CHECKOUT));
          return null;
        }

        triggerLinkEvent(linkEvent);
        return null;
      });
    },
    secondaryClick: () =>
      dispatch((_, getState) => {
        window.scrollTo(0, 0);
        const state = getState();
        const bookingNumber = getBookingNumber(state);
        dispatch(setSeatTimeOutCallCount(0));
        const currAirFlow = getAirBookingFlow(state);
        const searchComplete = getSearchComplete(state);
        const airPassengers = getAirPassengersSkipPassengerSelection(state);
        const pageAnalytics = AIR_PAGE_ANALYTICS_BASE_DATA[currAirFlow];
        triggerLinkEvent({
          button: 'previous',
          event_name: 'air_flow_nav',
          total_steps: (pageAnalytics && pageAnalytics.total_steps) || 0,
          ...((pageAnalytics && pageAnalytics.pages[path]) || {}),
          air_mod_type: currAirFlow,
          booking_id: bookingNumber,
        });

        const itinerariesDiffer = getItinerariesDiffer(state);
        if (path === AIR_PATHS.SEATS) {
          const seatmapPage = getSeatmapPageNumber(state);
          if (seatmapPage !== 0) {
            dispatch(setResetPassengerTurn(true));
            window.scrollTo(0, 0);
            const newSegmentPage = seatmapPage - 1;
            const segmentNumber = dispatch(getSegmentNumber(newSegmentPage));
            dispatch(setSeatmapPageNumber(newSegmentPage));
            return dispatch(updateSeatMapData(segmentNumber));
          }
          if (currAirFlow === CHANGE_SEATS) {
            if (!itinerariesDiffer) {
              return dispatch(navigateTo(AIR_PATHS.HOME));
            }
            return dispatch(navigateTo(airPassengers.length === 1 ? AIR_PATHS.HOME : AIR_PATHS.SELECT_PASSENGERS));
          }
          return dispatch(navigateTo(AIR_PATHS.SEARCH));
        }

        if (path === AIR_PATHS.SEARCH) {
          if (!searchComplete) {
            if ((currAirFlow === CHANGE_ITINERARIES || currAirFlow === UPGRADE_CLASS) && airPassengers.length === 1) {
              return dispatch(navigateTo(AIR_PATHS.HOME));
            }
          }
          if (searchComplete) {
            if (currAirFlow === UPGRADE_CLASS) {
              return dispatch(navigateTo(airPassengers.length === 1 ? AIR_PATHS.HOME : AIR_PATHS.SELECT_PASSENGERS));
            }
            return dispatch(resetSearch(true));
          }
        }

        if (path === AIR_PATHS.REVIEW) {
          return dispatch(navigateTo(AIR_PATHS.SEATS));
        }
        if (path === AIR_PATHS.SEARCH) {
          return dispatch(navigateTo(AIR_PATHS.SELECT_PASSENGERS));
        }

        return dispatch(navigateBack());
      }),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AirNav);

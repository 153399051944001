import BookingSummary from '@viking-eng/booking-summary';
import Button from '@viking-eng/button';
import HomeCardSection from '@viking-eng/home-card-section';
import Notifications from '@viking-eng/notifications';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import PropTypes from '../../PropTypes';
import { APP_PATHS } from '../../common/Constants';
import { goTo } from '../../common/Utils';
import { HomeCarousel } from '../../components';
import { DialogModal, StartGuideModal } from '../../modals';
import './Home.scss';

export const gridLayout = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
};

export const cardSizes = {
  xs: 4,
  sm: 12,
  md: 12,
  lg: 12,
};

const removeFixedStyleOnBody = () => {
  if (window.innerWidth <= 576) {
    const selector = document.querySelector('body.modal-scroll-not-allowed');
    if (selector?.style?.position && selector?.style?.bottom) {
      selector.style.position = '';
      selector.style.bottom = '';
    }
  }
};

const scrollIntoViewMobile = (xCoord, yCoord) => {
  const selector = document.querySelector('body.modal-scroll-not-allowed');

  selector.style.position = 'relative';
  selector.style.bottom = 'none';
  window.scroll(xCoord, yCoord);
  selector.style.position = 'fixed';
  selector.style.bottom = `${yCoord}px`;
};

const Home = ({
  cards,
  pageNotifications,
  travelDetails,
  isLoading,
  homeFAQsText,
  homeFAQsButton,
  openGuideModal,
  guideModalsArr,
  clearModal,
  homeStartGuideWelcomeModalData,
  homeStartGuideFinishModalData,
  openStartGuideValue,
  setOpenStartGuide,
  firstTimeLogIn,
  setFirstTimeLogIn,
  displayGettingStarted,
  carouselCards,
  setQueryParams,
  isCloseToDeparture,
}) => {
  useEffect(() => {
    if (document.querySelector('body.modal-scroll-not-allowed')) {
      const items = document.querySelectorAll('.home-card a');

      if (items) {
        items.forEach((link) => {
          link.addEventListener('click', () => {
            removeFixedStyleOnBody();
          });
        });
      }
    }
  });

  if (displayGettingStarted && openStartGuideValue) {
    openGuideModal('mvjStartGuideWelcomeToMVJ');
  }
  return (
    <div id="homePage" className="home-page">
      {pageNotifications.length > 0 && (
        <Notifications icon="notification" notifications={pageNotifications} variant="alert" />
      )}
      {!isEmpty(travelDetails) && <BookingSummary data={travelDetails} />}
      {!isCloseToDeparture && <HomeCarousel cards={carouselCards} setQueryParams={setQueryParams} />}
      <HomeCardSection isLoading={isLoading} cards={cards} cardGridLayout={gridLayout} cardSizes={cardSizes} />
      {(firstTimeLogIn || openStartGuideValue) && (
        <>
          <DialogModal
            {...homeStartGuideWelcomeModalData}
            modalClassName="home-guide-modal-start"
            onConfirm={() => {
              if (window.innerWidth > 820) {
                window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
              }
              if (window.innerWidth <= 820) {
                window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 200);
              }
              if (window.innerWidth <= 768) {
                window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
              }
              if (window.innerWidth <= 576) {
                scrollIntoViewMobile(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
              }
              openGuideModal(1);
              document.querySelector('.card-grid :nth-child(1)').style.zIndex = '98';
            }}
            onCancel={() => {
              clearModal();
              setOpenStartGuide(false);
              setFirstTimeLogIn(false);
            }}
          />
          <DialogModal
            {...homeStartGuideFinishModalData}
            onConfirm={() => {
              clearModal();
              setFirstTimeLogIn(false);
              setOpenStartGuide(false);
              removeFixedStyleOnBody();
            }}
            onCancel={() => {
              setOpenStartGuide(false);
              removeFixedStyleOnBody();
            }}
            modalClassName="home-guide-modal-start home-guide-modal-end"
          />
          {guideModalsArr.map((modal, i) => {
            let modalTopPosition;
            if (i === 0) {
              modalTopPosition =
                document.querySelector('.card-grid .card-item:nth-child(1)')?.getBoundingClientRect().top +
                document.querySelector('.card-grid .card-item:nth-child(1)')?.offsetHeight +
                20;
            }
            if (i === 1) {
              modalTopPosition =
                document.querySelector('.card-grid .card-item:nth-child(5)')?.getBoundingClientRect().top +
                document.querySelector('.card-grid .card-item:nth-child(1)')?.offsetHeight +
                20;
            }
            if (i === 2) {
              modalTopPosition =
                document.querySelector('.card-grid .card-item:nth-child(2)')?.getBoundingClientRect().top +
                document.querySelector('.card-grid .card-item:nth-child(1)')?.offsetHeight +
                20;
            }
            if (i === 3) {
              modalTopPosition =
                document.querySelector('.card-grid .card-item:nth-child(7)')?.getBoundingClientRect().top +
                document.querySelector('.card-grid .card-item:nth-child(1)')?.offsetHeight +
                20;
            }
            return (
              <StartGuideModal
                id={modal.id}
                title={modal.title}
                message={modal.message}
                buttons={modal.buttons}
                forceCloseModal
                arrow
                modalPageNumber={`${i + 1} of ${guideModalsArr.length}`}
                modalContainerClassName={`home-guide-modal guide-modal-${modal.id} container`}
                marginTop={modalTopPosition}
                guideModalsFlowClassName="guide-modals-flow"
                onClose={() => {
                  setFirstTimeLogIn(false);
                  clearModal();
                  setOpenStartGuide(false);
                  document.querySelector(`.card-grid .card-item:nth-child(${modal.id})`).style.zIndex = '1';
                  window.scroll(0, 0);
                  removeFixedStyleOnBody();
                }}
                onConfirm={() => {
                  if (i === 2) {
                    if (window.innerWidth > 820) {
                      window.scroll(0, 750);
                    }
                    if (window.innerWidth <= 820) {
                      window.scroll(0, 300);
                    }
                    if (window.innerWidth <= 768) {
                      window.scroll(0, 460);
                    }
                    if (window.innerWidth <= 576) {
                      scrollIntoViewMobile(0, 900);
                    }
                  }
                  if (i === 0) {
                    if (window.innerWidth > 820) {
                      window.scroll(0, 560);
                    }
                    if (window.innerWidth <= 820) {
                      window.scroll(0, 300);
                    }
                    if (window.innerWidth <= 768) {
                      window.scroll(0, 460);
                    }
                    if (window.innerWidth <= 576) {
                      scrollIntoViewMobile(0, 800);
                    }
                  }
                  if (i === 1) {
                    if (window.innerWidth > 820) {
                      window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
                    }
                    if (window.innerWidth <= 820) {
                      window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 200);
                    }
                    if (window.innerWidth <= 768) {
                      window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
                    }
                    if (window.innerWidth <= 576) {
                      scrollIntoViewMobile(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
                    }
                  }
                  if (i + 1 !== guideModalsArr?.length) {
                    openGuideModal(modal.next);
                  } else {
                    openGuideModal(homeStartGuideFinishModalData.id);
                  }
                  document.querySelector(`.card-grid .card-item:nth-child(${modal.id})`).style.zIndex = '1';
                  if (i + 1 !== guideModalsArr?.length) {
                    document.querySelector(`.card-grid .card-item:nth-child(${modal.next})`).style.zIndex = '98';
                  } else {
                    window.scroll(0, 0);
                  }
                }}
                onCancel={() => {
                  if (i === 2) {
                    if (window.innerWidth > 820) {
                      window.scroll(0, 560);
                    }
                    if (window.innerWidth <= 820) {
                      window.scroll(0, 300);
                    }
                    if (window.innerWidth <= 768) {
                      window.scroll(0, 460);
                    }
                    if (window.innerWidth <= 576) {
                      scrollIntoViewMobile(0, 800);
                    }
                  }
                  if (i === 3 || i === 1) {
                    if (window.innerWidth > 820) {
                      window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
                    }
                    if (window.innerWidth <= 820) {
                      window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 200);
                    }
                    if (window.innerWidth <= 768) {
                      window.scroll(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
                    }
                    if (window.innerWidth <= 576) {
                      scrollIntoViewMobile(0, document.querySelector('.card-grid :nth-child(1)').offsetTop - 70);
                    }
                  }
                  openGuideModal(modal.previous);
                  document.querySelector(`.card-grid .card-item:nth-child(${modal.id})`).style.zIndex = '1';
                  document.querySelector(`.card-grid .card-item:nth-child(${modal.previous})`).style.zIndex = '98';
                }}
              />
            );
          })}
        </>
      )}
      <div className="faq-container">
        <div className="d-flex justify-content-center faq-text">{homeFAQsText}</div>
        <div className="d-flex justify-content-center">
          <Button
            attributes={{
              tabIndex: 0,
              role: 'link',
            }}
            onButtonClick={goTo(APP_PATHS.HELP)}
            appearance="primary"
          >
            {homeFAQsButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    })
  ).isRequired,
  pageNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      button: PropTypes.shape({
        onButtonClick: PropTypes.func,
        text: PropTypes.string,
      }),
    })
  ),
  travelDetails: PropTypes.shape(PropTypes.homeBookingSummary),
  isLoading: PropTypes.bool.isRequired,
  isCloseToDeparture: PropTypes.bool.isRequired,
};

Home.defaultProps = {
  pageNotifications: [],
  travelDetails: {},
};

export default Home;

import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { clearSubmitErrors, initialize, isSubmitting, reduxForm, SubmissionError } from 'redux-form';
import commonStore, { fetchCommonContent } from '../../common/CommonStore';
import {
  ADD_BOOKING_STEPS,
  COUNTRIES,
  FORMS,
  MODALS,
  PAGE_NAMES,
  VALID_BOOKING_STATUS,
  VOYAGE_STATUSES,
} from '../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../common/ModalStore';
import userStore, {
  addBookingGetPassengerNumber,
  fetchBookings,
  handleAutoAcceptPtc,
  handlePtcAcceptance,
  validateBooking,
} from '../../common/UserStore';
import { getPassengerFullName } from '../../common/Utils';
import { changeBookingFunction } from '../../common/Utils/storeHelpers';
import AddBookingModal from './AddBooking';

const {
  creators: { updateAddBookingStep },
  selectors: { getMvjStrings, getAddBookingStep, getErrors, getTermsUrl, getPageTabLabels, getViewOnlyContent },
} = commonStore;

const {
  creators: { updateAddBookingData },
  selectors: { getAddBookingData, getBookingDetails, getUserBookings, getPassengerNumber, getSplashPageLink },
} = userStore;

const { clearModal } = modalStore.creators;

const daysToDeparture = (departureDate) => Math.ceil(moment.duration(moment(departureDate).diff(moment())).asDays());

const isValidBooking = (status) => VALID_BOOKING_STATUS.includes(status);

const mapStateToProps = (state) => {
  const ptcLabels = getPageTabLabels(state)(PAGE_NAMES.PTC) || {};
  const { buttons, fields, labels } = get(getMvjStrings(state), 'labels.modals.addBooking', {});
  const modalId = MODALS.ADD_BOOKING;
  const addBookingData = getAddBookingData(state);
  const guests = get(addBookingData, 'passengers', []);
  const splashPageLink = getSplashPageLink(state);
  const formStep = getAddBookingStep(state) || ADD_BOOKING_STEPS.ADD;

  return {
    buttons,
    fields,
    labels: {
      ...labels,
      ...ptcLabels.labels,
    },
    initialValues: {
      bookingNumber: null,
      acceptTcs: false,
    },
    isSubmitting: isSubmitting(FORMS.ADD_BOOKING)(state),
    formStep,
    id: modalId,
    errors: getErrors(state),
    termsUrl: getTermsUrl(state),
    ptcData: get(addBookingData, 'ptcData', {}),
    hasPtcField: get(addBookingData, 'office', '') !== 'UK',
    passengerOptions: guests.map((passenger) => ({
      label: getPassengerFullName(passenger),
      value: `${passenger.passengerNumber}`,
      id: `passenger${passenger.passengerNumber}`,
    })),
    passengerNumber: getPassengerNumber(state)(guests),
    bookings: getUserBookings(state),
    isViewOnly: false,
    viewOnlyMessage: get(getViewOnlyContent(state), 'bannerMessage', ''),
    currentBookingNumber: get(getBookingDetails(state), 'bookingNumber'),
    hideClose: splashPageLink === VOYAGE_STATUSES.NO_BOOKINGS && formStep === ADD_BOOKING_STEPS.SUCCESS,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(clearModal()),
  onClose: () => {
    dispatch(
      initialize(FORMS.ADD_BOOKING, {
        bookingNumber: null,
        acceptTcs: false,
      })
    );
    dispatch(updateAddBookingStep(ADD_BOOKING_STEPS.ADD));
    dispatch(updateAddBookingData(null));
  },
  handleContactUs: () => {
    dispatch(setViewAndShowModal(MODALS.CONTACT_US));
  },
});

const onSubmit = (
  { ...values },
  dispatch,
  { errors, formStep, ptcData, bookings, hasPtcField, currentBookingNumber, onClose }
) =>
  new Promise((resolve, reject) => {
    dispatch(clearSubmitErrors(FORMS.ADD_BOOKING));

    if (formStep === ADD_BOOKING_STEPS.ADD) {
      if (bookings.find((booking) => booking.bookingId === values.bookingNumber)) {
        return reject(new SubmissionError({ _error: errors.DuplicateBookingNumber }));
      }
      const validateFirstName = bookings?.length > 0;
      return dispatch(validateBooking(values.bookingNumber, validateFirstName))
        .then((res) => {
          if (res) {
            if (!res.bookingID) {
              const REJECTION_MESSAGES = {
                404: errors.InvalidBooking,
                405: res.data.errorCode,
                409: errors.AddCanceledBookingFailed,
                424: errors.BookingNotAvailable,
              };
              const rejectionMessage =
                REJECTION_MESSAGES[res.data?.errorCode] ||
                REJECTION_MESSAGES[res.data?.status] ||
                errors.AddBookingFailed;
              return reject(new SubmissionError({ _error: rejectionMessage }));
            }
            if (!res.cmsDataLoaded) {
              // @todo: Add in check here for alreadyBooked flag?
              return reject(new SubmissionError({ _error: errors.BookingNotAvailable }));
            }
            const valid = isValidBooking(res.bookingStatus, res.departureDate);
            const daysToGo = daysToDeparture(res.departureDate);
            if (res.data || !valid) {
              return reject(new SubmissionError({ _error: errors.InvalidBooking }));
            }
            if (daysToGo <= 0) {
              return reject(new SubmissionError({ _error: errors.CurrentlySailingBooking }));
            }
            dispatch(updateAddBookingData(res));
            if ([COUNTRIES.UNITED_KINGDOM, COUNTRIES.UNITED_KINGDOM_GB].includes(res.office)) {
              return dispatch(addBookingGetPassengerNumber(res.passengers, true)).then((passengerId) => {
                if (passengerId) {
                  return dispatch(
                    handleAutoAcceptPtc({
                      country: res.office,
                      passengerNumber: passengerId,
                      addBooking: true,
                    })
                  ).then(() => {
                    dispatch(updateAddBookingStep(ADD_BOOKING_STEPS.SUCCESS));
                    // Success! - Rejecting so that the form's submitted state goes back to false,
                    // so we can submit again after next step
                    return reject();
                  });
                }
                dispatch(updateAddBookingStep(ADD_BOOKING_STEPS.PTC));
                // Success! - Rejecting so that the form's submitted state goes back to false,
                // so we can submit again after next step
                return reject();
              });
            }
            dispatch(updateAddBookingStep(ADD_BOOKING_STEPS.PTC));
            // Success! - Rejecting so that the form's submitted state goes back to false,
            // so we can submit again after next step
            return reject();
          }
          return reject(new SubmissionError({ _error: errors.AddBookingFailed }));
        })
        .catch(() => {
          reject(new SubmissionError({ _error: errors.AddBookingFailed }));
        });
    }
    if (formStep === ADD_BOOKING_STEPS.PTC) {
      // Update user data if not already determined
      return dispatch(addBookingGetPassengerNumber()).then((paxNum) => {
        const passengerId = values.passengerId || paxNum;
        const promises = [
          dispatch(
            handlePtcAcceptance({
              versionNumber: hasPtcField ? ptcData.reference : 'NA',
              passengerId: Number(passengerId),
              addBooking: true,
            })
          ),
          dispatch(fetchBookings(values.bookingNumber, false, true, false, Number(passengerId))).then(() => {
            dispatch(fetchCommonContent()).then(() => {
              const cards = window?.target_MVJ_Carousel?.queue;
              if ((cards || []).length > 0) {
                window.handleReinitializeCarousel(cards);
              }
            });
          }),
        ];
        return Promise.allSettled(promises)
          .then(() => {
            // if added booking is the only booking on account, clear modal as user will be auto redirected to that booking
            if (!bookings.length) {
              dispatch(changeBookingFunction({
                newBookingNumber: values.bookingNumber,
              }));
              dispatch(clearModal());
              dispatch(onClose());
            }

            dispatch(updateAddBookingStep(ADD_BOOKING_STEPS.SUCCESS));
            // Success! - Rejecting so that the form's submitted state goes back to false,
            // so we can submit again after next step
            return reject();
          })
          .catch(() => reject(new SubmissionError({ _error: errors.AddBookingFailed })));
      });
    }
    if (formStep === ADD_BOOKING_STEPS.SUCCESS) {
      return dispatch(
        changeBookingFunction({
          currentBookingNumber,
          newBookingNumber: values.bookingNumber,
        })
      );
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return reject('Unknown Add Booking Step');
  });

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORMS.ADD_BOOKING,
    enableReinitialize: true,
    onSubmit,
  })
)(AddBookingModal);

export default enhance;

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { PATH_DIRECTORY } from '../Constants';

const MAINTENANCE_MODE_KEY = 'maintenance-mode';
const MaintenanceCheck = ({ children }) => {
  const { pathname } = window.location;

  useEffect(() => {
    const checkForMaintenanceMode = async () => {
      const setMaintenance = (val) => window.sessionStorage.setItem(MAINTENANCE_MODE_KEY, !!val);

      const maintStatusPath = `${window.location.origin}${PATH_DIRECTORY}/maintenance/status`;
      const maintenanceResponse = await fetch(maintStatusPath);
      let response = { isMaintenanceOrigin: false };
      try {
        response = JSON.parse(await maintenanceResponse.text());
      } catch {
        // continue
      }
      if ([true, 'true'].includes(response?.isMaintenanceOrigin)) {
        setMaintenance(true);
        window.location.reload();
      } else {
        setMaintenance(false);
      }
    };

    checkForMaintenanceMode();
  }, [pathname]);

  return children;
};

MaintenanceCheck.propTypes = {
  children: PropTypes.node,
};

export default MaintenanceCheck;
export { MAINTENANCE_MODE_KEY };

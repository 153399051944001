import { appInsightsHelper } from '@viking-eng/telemetry';
import axios from 'axios';
import cryptoRandomString from 'crypto-random-string';
import get from 'lodash/get';
import { callWaiter } from 'redux-waiter';
import commonStore from './CommonStore';
import { API_HOST, VOYAGE_TYPE } from './Constants';
import userStore from './UserStore';
import { base64EncodeString, debugLog } from './Utils/index';
import { handleAdvisoryCode, handleErrorResponse } from './Utils/apiUtils';

const { getSessionInfo, handleTelemetry } = appInsightsHelper;

export const getTraceparent = () => {
  const version = '00';
  const traceId = cryptoRandomString({ length: 16 });
  const id = cryptoRandomString({ length: 8 });
  const flags = '00';

  return `${version}-${traceId}-${id}-${flags}`;
};

const mapUrlVoyageType = (url, { useBrandInUrl }) => {
  let remappedUrl = url;
  const { MISSISSIPPI, RIVER } = VOYAGE_TYPE;
  const replacementValues = [{ target: MISSISSIPPI, value: RIVER }];
  if (!useBrandInUrl) {
    replacementValues.forEach(({ target, value }) => {
      remappedUrl = remappedUrl
        .replace(new RegExp(`/${target}/`), `/${value}/`)
        .replace(new RegExp(`/${target}?`), `/${value}`)
        .replace(new RegExp(`/${target}$`), `/${value}`);
    });
  }
  return remappedUrl;
};

const getFullUrl = (url, state) => {
  const { getMvjPropertiesFeatureFlags } = commonStore.selectors;
  const flags = getMvjPropertiesFeatureFlags(state);
  return `${API_HOST}${mapUrlVoyageType(url, flags)}`;
};

export const getRequestConfig = ({ state, config = {}, timestamp, url }) => {
  const { getHeaders, getLoggedInUser, getSessionId } = userStore.selectors;
  const headers = getHeaders(state);
  let { passengerNumber = 1 } = getLoggedInUser(state) || {};
  const sessionId = getSessionId(state) || '';

  const traceparent = config.traceparent || getTraceparent();
  const requestGroupId = traceparent;
  const appInsightsContextSessionId = getSessionInfo()?.session?.id;
  const appinsights = base64EncodeString(
    JSON.stringify({ requestGroupId, sessionId: appInsightsContextSessionId, url })
  );

  if (config?.params && window.location.hostname === 'localhost') {
    debugLog('Please consider using the buildUrl function for adding query params to the url for consistency.');
  }

  const params = config.params || {};
  if (timestamp) {
    // TODO: figure out if there's a better way to bust the cache
    params.timestamp = Date.now();
  }

  if (['undefined', undefined].includes(passengerNumber)) {
    passengerNumber = 1;
  }

  const ret = {
    ...config,
    headers: {
      ...headers,
      // 'Content-Type': 'application/json; charset=utf-8, text/plain, */*',
      passengerNumber,
      appinsights,
      traceparent,
      tracestate: config.tracestate,
      lockSessionId: sessionId,
      ...(config.headers || {}),
    },
    params,
  };
  return ret;
};

axios.interceptors.response.use(
  (responseData) => {
    const { config, data, headers, request, ...rest } = responseData;
    const startDate = new Date();
    const appInsightsContextSessionId = getSessionInfo()?.session?.id;

    const traceparent = config?.headers?.traceparent;

    try {
      const url = config.url.includes('http') ? config.url : `${config.baseURL}${config.url}`;
      handleTelemetry({
        duration: Math.abs(startDate - new Date()),
        id: traceparent,
        options: { method: config.method },
        requestGroupId: traceparent,
        resource: url,
        response: {
          data,
          headers,
          json: () => Promise.resolve(data),
          ok: request.status === 200,
          status: request.status,
          statusText: request.statusText,
          url,
        },
        sessionId: appInsightsContextSessionId,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return { config, data, headers, request, ...rest };
  },
  (catchData) => {
    const { config, response, request, ...rest } = catchData;
    const startDate = new Date();

    const appInsightsContextSessionId = getSessionInfo()?.session?.id;
    const url = config.url.includes('http') ? config.url : `${config.baseURL}${config.url}`;

    try {
      handleTelemetry({
        duration: Math.abs(startDate - new Date()),
        id: config?.headers?.traceparent,
        options: { method: config.method },
        requestGroupId: config?.headers?.traceparent,
        resource: url,
        response: {
          data: response?.data,
          headers: response?.headers,
          json: () => Promise.resolve(response?.data),
          ok: request?.status === 200,
          status: request?.status,
          statusText: request?.statusText,
          url,
        },
        sessionId: appInsightsContextSessionId,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // eslint-disable-next-line no-throw-literal
    throw { isError: true, config, response, request, data: response?.data, status: response?.status, ...rest };
  }
);

export const getData = ({
  config,
  creator,
  refreshData = false,
  node,
  store: { creators, store },
  tab,
  url,
  updateImmediately = true,
}) => (dispatch, getState) => {
  const state = getState();
  const { loaded, ...savedData } = get(state, `${store}.${node}`, false);

  if (!loaded || refreshData) {
    const { endRequest, startRequest } = creators;
    dispatch(startRequest(node));

    const fullUrl = getFullUrl(url);
    const requestConfig = getRequestConfig({ state, config, timestamp: true, url: fullUrl });

    return axios
      .get(fullUrl, requestConfig)
      .then((res) => {
        const { data, status } = res || {};
        if (data?.advisoryCode) {
          handleAdvisoryCode({ advisoryCode: data.advisoryCode, url, requestConfig });
        }
        if (creator && updateImmediately) {
          dispatch(creator(data, tab));
        } else if (creators.receiveHoldingContent) {
          dispatch(creators.receiveHoldingContent(node, data));
        }
        dispatch(endRequest(node));
        return {
          ...data,
          status,
          isSuccessful: true
        };
      })
      .catch((err) => {
        const { response } = err || {};
        if (requestConfig.suppressError && creator && updateImmediately) {
          dispatch(creator({}, tab));
        }
        handleErrorResponse(response, state, dispatch, url, err, requestConfig);
        dispatch(endRequest(node));
        return response;
      });
  }

  return new Promise((resolve) => resolve(savedData));
};

export const postWaiterData = ({ name, config, waiterConfig, url, values }) => (dispatch, getState) => {
  const fullUrl = getFullUrl(url);
  const requestConfig = getRequestConfig({ state: getState(), config, timestamp: false, url: fullUrl });

  return dispatch(
    callWaiter(name, {
      // prettier-ignore
      requestCreator: () => axios.post(fullUrl, values, requestConfig)
        .catch((res) => {
          handleErrorResponse(res.response, getState(), dispatch, url, res, requestConfig);
          return res.response;
        }),
      ...(waiterConfig || {}),
    })
  );
};

export const putWaiterData = ({ name, config, waiterConfig, url, values }) => (dispatch, getState) => {
  const fullUrl = getFullUrl(url);
  const requestConfig = getRequestConfig({ state: getState(), config, timestamp: false, url: fullUrl });
  return dispatch(
    callWaiter(name, {
      // prettier-ignore
      requestCreator: () => axios.put(fullUrl, values, requestConfig)
        .catch((res) => {
          handleErrorResponse(res.response, getState(), dispatch, url, res, requestConfig);
          return res.response;
        }),
      ...(waiterConfig || {}),
    })
  );
};

export const getWaiterData = ({ name, config, waiterConfig, url }) => (dispatch, getState) => {
  const fullUrl = getFullUrl(url);
  const requestConfig = getRequestConfig({ state: getState(), config, timestamp: true, url: fullUrl });
  return dispatch(
    callWaiter(name, {
      requestCreator: () =>
        axios.get(fullUrl, requestConfig).catch((res) => {
          handleErrorResponse(res.response, getState(), dispatch, url, res, requestConfig);
          return res.response;
        }),
      ...(waiterConfig || {}),
    })
  );
};

export const postData = ({ config, creator, isHandleErrorResponse = true, url, values }) => (dispatch, getState) => {
  const fullUrl = getFullUrl(url);
  const requestConfig = getRequestConfig({ state: getState(), config, timestamp: false, url: fullUrl });
  return axios
    .post(fullUrl, values, requestConfig)
    .then((response) => {
      const { data } = response?.response || response;

      if (data?.advisoryCode) {
        handleAdvisoryCode({ advisoryCode: data.advisoryCode, url, requestConfig });
      }
      if (creator && !data?.errorCode) {
        dispatch(creator(true, data));
      }
      return {
        isSuccessful: !data?.errorCode,
        data,
      };
    })
    .catch((data) => {
      const { response } = data;
      if (isHandleErrorResponse) {
        handleErrorResponse(response, getState(), dispatch, fullUrl, data, requestConfig);
      }
      // Fallback for cases when the response comes back as undefined
      const errorData = response && response.data ? response.data : '';
      if (creator) {
        dispatch(creator(false, errorData));
      }
      return {
        isSuccessful: false,
        data: errorData,
        status: response && response.status ? response.status : '',
      };
    });
};

export const putData = ({ config, creator, url, values }) => (dispatch, getState) => {
  const fullUrl = getFullUrl(url);
  const requestConfig = getRequestConfig({ state: getState(), config, timestamp: false, url: fullUrl });

  return axios
    .put(fullUrl, values, requestConfig)
    .then((res) => {
      const { data } = res || {};
      if (data?.advisoryCode) {
        handleAdvisoryCode({ advisoryCode: data.advisoryCode, url, requestConfig });
      }
      if (creator) {
        dispatch(creator(true, data));
      }
      return {
        isSuccessful: true,
        data,
      };
    })
    .catch((err) => {
      const { response } = err || {};
      handleErrorResponse(response, getState(), dispatch, url, err, requestConfig);
      const errorData = response && response.data ? response.data : '';
      if (creator) {
        dispatch(creator(false, errorData));
      }
      return {
        isSuccessful: false,
        errorData,
      };
    });
};

export const deleteData = ({ config, creator, url, data }) => (dispatch, getState) => {
  const fullUrl = getFullUrl(url);
  const requestConfig = getRequestConfig({ state: getState(), config, timestamp: false, url: fullUrl });

  return axios
    .delete(fullUrl, { ...requestConfig, data })
    .then((res) => {
      if (res?.data?.advisoryCode) {
        handleAdvisoryCode({ advisoryCode: res.data.advisoryCode, url, requestConfig });
      }
      if (creator) {
        dispatch(creator());
      }
      return {
        isSuccessful: true,
        data: res.data || null,
      };
    })
    .catch((res) => {
      handleErrorResponse(res.response, getState(), dispatch, url, res, requestConfig);
      return {
        isSuccessful: false,
        data: res?.response?.data || '',
        status: res?.response?.status || '',
      };
    });
};

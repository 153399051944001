import Container from '@viking-eng/container';
import ResponsiveImage from '@viking-eng/responsive-image';
import React from 'react';
import { Route } from 'react-router-dom';
import { Hero } from '..';
import { VALID_PAGE_ROUTES } from '../../common/Constants';
import PropTypes from '../../PropTypes';
import './HeaderFooterWrapper.scss';

const HeaderFooterWrapper = ({
  children,
  footer,
  header,
  heroImages,
  heroTitle,
  cruiseInfoError,
  splashPageLink,
  defaultHeroImages,
}) => {
  const { HERO } = VALID_PAGE_ROUTES;
  const renderComponent = (Component, otherProps) => (props) => <Component {...props} {...otherProps} />;
  return (
    <div className="rel-container">
      {header}
      {heroImages && (
        <Route
          exact
          path={HERO}
          render={renderComponent(Hero, {
            images: heroImages,
            title: heroTitle,
            heroImageMissing: cruiseInfoError || heroImages.every((img) => img.className === 'img-error'),
          })}
        />
      )}
      {splashPageLink && (
        <div className="hero-image">
          <ResponsiveImage images={defaultHeroImages} />
        </div>
      )}
      <div id="content" className="app-content">
        <Container>{children}</Container>
      </div>
      {footer}
    </div>
  );
};

HeaderFooterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  heroImages: PropTypes.heroImages,
  heroTitle: PropTypes.string,
  cruiseInfoError: PropTypes.bool,
  splashPageLink: PropTypes.bool,
  defaultHeroImages: PropTypes.array,
};

HeaderFooterWrapper.defaultProps = {
  heroImages: null,
  heroTitle: '',
  cruiseInfoError: false,
  splashPageLink: null,
  defaultHeroImages: [],
};

export default HeaderFooterWrapper;

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsightsHelper } from '@viking-eng/telemetry';
import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import merge from 'lodash/merge';
import moment from 'moment';
import { clearSubmitErrors, formValueSelector, SubmissionError } from 'redux-form';
import { createSelector } from 'reselect';
import { deleteData, getData, postData } from '../../common/Api';
import { getFriendlyError, updateBooking } from '../../common/CommonStore';
import {
  ADDONS_CHECKOUT,
  ALERT_TYPES,
  APP_INSIGHTS_TRACK_TYPE,
  APP_PATHS,
  CHINA_SHIP_CODE,
  DAYS_TO_SPA_RESERVATION,
  DEFAULT_DATE_FORMAT,
  DINING_DETAILS,
  EVO_ADVISORY_CODES,
  FEATURE_RESTRICTED,
  FORMS,
  INCLUDED_STATEROOM,
  LOCK_TYPES,
  MODALS,
  PAGE_NAMES,
  REGIONAL_SHORT_DATES,
  RESERVATION_STATE_KEYS,
  RESTAURANT_NAMES,
  SERVICE_CODES,
  SHIP_RESTAURANT_CODES,
  TAB_NAMES,
  TIME_ZONE_ABBREVIATIONS,
  TWO_BY_ONE,
  VOYAGE_TYPE,
} from '../../common/Constants';
import { ERROR_CODES } from '../../common/forms/Validations';
import commonStore, { modalStore, userStore } from '../../common/index';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import { fetchBookings, verifyLockUnlockStatus } from '../../common/UserStore';
import {
  buildUrl,
  convertIsoTime,
  convertStringToStartCase,
  findCardSectionByModalId,
  formatMoney,
  getCarouselImageArray,
  getCmsLabel,
  getEvoErrorMessage,
  getImageAttributes,
  getIsoDate,
  getPageTabUrl,
  getPassengerAbbrevName,
  getSimpleReservationModalState,
  getTabReference,
  mapModalSections,
  replaceCMSTokenWithValue,
} from '../../common/Utils';
import calendarStore, { fetchCalendarItems } from '../calendar/CalendarStore';
import shorexStore from '../shorex/ShorexStore';

const { logger } = appInsightsHelper;
const { MANFREDIS, CHEFS_TABLE, THE_RESTAURANT } = SHIP_RESTAURANT_CODES;
const { ONBOARD_EXPERIENCE } = PAGE_NAMES;
const {
  DINING_BEVERAGE,
  SPA,
  SHIP_CREW,
  STATEROOM,
  STATEROOM_PREFERENCES,
  SPECIAL_OCCASIONS,
  FOOD_ALLERGIES,
  ONBOARD_PREFERENCES,
  ENRICHMENT,
} = TAB_NAMES;

const {
  selectors: {
    getLabels,
    getErrors,
    getEvolutionErrors,
    getReservationModalInfo,
    getMvjStrings,
    getPageTabLabels,
    getModalLabels,
    getMvjFlags,
    getIsCurrentCountryPaymentBlocked,
    getViewOnlyContent,
    getIsUKAUNZ,
    getIsSsbpIncludedByAmenityCode,
    getSSBPPricing,
    getPaymentsAllEnabled,
    getPaymentsCheckoutEnabled,
  },
  creators: { updateReservationModalState },
  types: { CART_UPDATED, CLEAR_DINING_DATA },
} = commonStore;

const { getCalendarItems } = calendarStore.selectors;
const { getModalData } = modalStore.selectors;
const {
  getBookingDetails,
  getComboBookings,
  getPassengers,
  getPassengerNames,
  getCountryCodeFromCurrency,
  getIsCloseToDepartureDate,
  getItinerary,
  getItineraryDate,
  getItineraryLabels,
  getUpdateUserData,
  isAllGifCompleted,
  isGifOverride,
  getVoyageType,
  getIsApprovedForPurchases,
  getFeatureRestricted,
  getIsDirect,
  getIsBalancePastDue,
} = userStore.selectors;

const { clearExcursionsDay } = shorexStore.creators;

const MESSAGE_KEYS = {
  SPA_WITHIN_7_DAYS: 'messageSpaWithin7DaysSailing',
  OUTSIDE_RESERVATION_WINDOW: 'messageOutsideSpaReservationWindow',
  SPA_NOT_OPEN_YET_TZ: 'spaNotOpenYetTz',
  DINING_NOT_OPEN: 'diningNotOpenYet',
  DINING_NOT_OPEN_TZ: 'diningNotOpenYetTz',
  DINING_CLOSED: 'diningNowClosed',
  SPA_DURATION: 'durationLabel',
  SPA_NOT_AVAILABLE: 'spaTreatmentsNotAvailable',
  VIEW_ONLY: 'viewOnly',
  CHINA_PRICE_LIST: 'chinaSpaPriceList',
};

export const SPA_MODAL_STEPS = {
  BOOKING: 'booking',
  CONFIRMATION: 'confirmation',
  GUEST_SELECTION: 'guestSelection',
};

const getDiningMessage = (messages = [], key) => getCmsLabel(messages, key, 'text');

const getDiningAvailableDaysOptions = (options = [], itinerary = [], menus, reference, messages) =>
  options
    .map((day) => {
      const diningDate = moment(day.value, DEFAULT_DATE_FORMAT.YEAR_FIRST).format('YYYY-MM-DD');
      const label = [
        get(
          itinerary.find(({ date }) => date === diningDate),
          'value'
        ),
      ];
      let menuLabel;
      if (reference === CHEFS_TABLE) {
        const menu = menus.mealItems.find((m) => m.mealDate === diningDate);
        if (menu && menu.menuFoodName) {
          const message = getDiningMessage(messages, 'diningMenuNameLabel');
          menuLabel = replaceCMSTokenWithValue(message, [{ key: 'MENU_NAME', value: menu.menuFoodName }]);
        }
      }
      return {
        ...day,
        label,
        menuLabel,
      };
    })
    .filter((a) => a.label.length);

const getRestaurantCardByCodeAndShip = ({ section, restaurantCode, shipId, voyageType }) =>
  section?.cards.find(
    (card) => card.reference === restaurantCode && (shipId ? section.shipId === shipId : card.voyageType === voyageType)
  );

const getDiningSectionByCodeAndShip = ({ diningContent, restaurantCode, shipId, voyageType }) =>
  diningContent?.sections?.find((section) =>
    getRestaurantCardByCodeAndShip({ section, restaurantCode, shipId, voyageType })
  );

const mapDiningContent = (diningContent, restaurantDetails) => {
  const { restaurantCode, shipId, voyageType } = restaurantDetails;
  const restaurantSection = getDiningSectionByCodeAndShip({ diningContent, restaurantCode, shipId, voyageType });
  if (restaurantSection) {
    const {
      availability,
      sharedAvailability,
      restaurantCode,
      availabilityLoading,
      numberAvailable,
    } = restaurantDetails;
    const emptyAvailability = { options: [] };
    const remappedDiningCards = restaurantSection.cards.map((restaurant) => {
      if (!restaurant) {
        return restaurant;
      }
      if (restaurant?.reference !== restaurantCode) {
        return restaurant;
      }
      const updateData = {};
      if (availabilityLoading) {
        updateData.availabilityLoaded = false;
        updateData.availabilityLoading = availabilityLoading;
      }
      if (availability?.options?.length > 0 || sharedAvailability?.options?.length > 0) {
        updateData.availability = numberAvailable === 0 ? emptyAvailability : availability;
        updateData.sharedAvailability = numberAvailable === 0 ? emptyAvailability : sharedAvailability;
        updateData.availabilityLoaded = true;
        updateData.availabilityLoading = false;
        updateData.lockedDown = restaurantDetails.lockedDown;
      }
      if (availability?.options?.length === 0 && sharedAvailability?.options?.length === 0) {
        updateData.availabilityLoaded = true;
        updateData.availabilityLoading = false;
        updateData.lockedDown = restaurantDetails.lockedDown;
      }
      return {
        ...restaurant,
        modal: {
          ...restaurant?.modal,
          ...updateData,
        },
      };
    });
    const remappedDiningSections = diningContent?.sections.map((section) => {
      if (getRestaurantCardByCodeAndShip({ section, restaurantCode, shipId, voyageType })) {
        return {
          ...section,
          cards: remappedDiningCards,
        };
      }
      return section;
    });
    return {
      ...diningContent,
      sections: remappedDiningSections,
    };
  }

  return diningContent;
};

const mapDiningSections = (diningSections) => {
  diningSections?.map(({ section }) => {
    if (section?.reference === SERVICE_CODES.SSBP) {
      return section;
    }
    return {
      ...section,
      cards: section?.cards.map(({ card }) => {
        if (!card?.modal || !card?.modal?.availability) {
          return card;
        }
        return {
          ...card,
          modal: {
            ...card.modal,
            availability: { options: [] },
            sharedAvailability: { options: [] },
            availabilityLoaded: false,
          },
        };
      }),
    };
  });
};

const onboardStore = createPageTabsDuck('onboard').extend({
  types: [
    'RECEIVE_SPA_AVAILABILITY',
    'SET_LOADING_FLAG',
    'CLEAR_SPA_AVAILABILITY',
    'RECEIVE_RESTAURANT_MENU',
    'CLEAR_ONBOARD_DATA',
    'RECEIVE_DINING_AVAILABILITY',
    'CLEAR_DINING_AVAILABILITY',
    'UPDATE_DINING_AVAILABILITY_LOADING',
  ],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.CLEAR_SPA_AVAILABILITY:
        return {
          ...state,
          spa: {
            ...state.spa,
            availability: [],
          },
        };
      case types.RECEIVE_SPA_AVAILABILITY:
        return {
          ...state,
          spa: {
            ...state.spa,
            // if availability is falsey, we want to force an empty array
            availability: action.availability || [],
            content: {
              ...state.spa.content,
              loading: action.loading,
            },
          },
        };
      case types.RECEIVE_RESTAURANT_MENU:
        return {
          ...state,
          restaurantMenus: {
            ...state.restaurantMenus,
            content: action.menuData,
          },
        };
      case types.SET_LOADING_FLAG:
        return {
          ...state,
          spa: {
            ...state.spa,
            content: {
              ...state.spa.content,
              loading: action.loading,
            },
          },
        };
      case types.UPDATE_DINING_AVAILABILITY_LOADING:
        const { code, status, shipId, voyageType } = action.payload;
        const remappedDiningContent = mapDiningContent(state.diningBeverage?.content, {
          restaurantCode: code,
          availabilityLoading: status,
          shipId,
          voyageType,
        });
        return {
          ...state,
          diningBeverage: {
            ...state.diningBeverage,
            content: remappedDiningContent,
          },
        };
      case types.RECEIVE_DINING_AVAILABILITY:
        const remappedContent = mapDiningContent(state.diningBeverage?.content, action.payload);
        return {
          ...state,
          diningBeverage: {
            ...state.diningBeverage,
            content: remappedContent,
          },
        };
      case CLEAR_DINING_DATA:
      case CART_UPDATED:
      case types.CLEAR_DINING_AVAILABILITY:
        const remappedSections = mapDiningSections(state.diningBeverage?.content?.sections);
        return {
          ...state,
          diningBeverage: {
            ...state.diningBeverage,
            content: {
              ...state.diningBeverage?.content,
              loaded: false,
              sections: remappedSections,
            },
          },
        };
      case types.CLEAR_ONBOARD_DATA:
        return {};
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    receiveSpaAvailability: (availability) => ({
      type: types.RECEIVE_SPA_AVAILABILITY,
      availability,
      loading: false,
    }),
    clearSpaAvailability: () => ({
      type: types.CLEAR_SPA_AVAILABILITY,
    }),
    receiveRestaurantMenus: (menuData) => ({
      type: types.RECEIVE_RESTAURANT_MENU,
      menuData: [menuData],
    }),
    setLoadingFlag: (flag) => ({
      type: types.SET_LOADING_FLAG,
      loading: flag,
    }),
    clearOnboardData: () => ({
      type: types.CLEAR_ONBOARD_DATA,
    }),
    receiveDiningAvailability: (payload) => ({
      type: types.RECEIVE_DINING_AVAILABILITY,
      payload,
    }),
    updateAvailabilityLoading: (status, code, shipId, voyageType) => ({
      type: types.UPDATE_DINING_AVAILABILITY_LOADING,
      payload: {
        status,
        code,
        shipId,
        voyageType,
      },
    }),
    clearAvailabilityData: () => ({
      type: types.CLEAR_DINING_AVAILABILITY,
    }),
  }),
  selectors: {
    getLoadingFlag: (state) => get(state, 'onboard.spa.content.loading', false),
    getReservationResponse: (state) => get(state, 'onboard.receiveUpdateReservationResponse.data', null),
    getRestrictedStateroom: new Selector(() =>
      createSelector([getBookingDetails], ({ ship, voyage }) => {
        const { stateroomCategory } = ship;
        const { type } = voyage;
        const isOcean = type === 'ocean';
        const isExpedition = type === 'expedition';
        const restrictedStateroom =
          (['V1', 'V2'].includes(stateroomCategory) && isOcean) ||
          (['N1', 'N2'].includes(stateroomCategory) && isExpedition);

        return restrictedStateroom;
      })
    ),
    getShipCrewModalData: new Selector(({ getTabContentSections }) =>
      createSelector(
        [getTabContentSections, getLabels, getModalData],
        (getSections, { buttons: { print } = {} }, { id: modalId }) => {
          const pageSections = getSections(SHIP_CREW) || [];
          if (!pageSections.length) {
            return {};
          }
          const { cards: pageCards = [] } = pageSections[0];

          const findCard = (ref) => pageCards.find(({ id }) => id === ref);
          // TODO: [MR-1784] Use reference to find card
          const mappedCards = {
            deckPlan: MODALS.DECK_PLANS,
            ship: MODALS.SHIP_FEATURES,
            additionalInfo: MODALS.ADDITIONAL_INFO,
          };

          const modals = {};

          Object.entries(mappedCards).forEach(([modalKey, modalType]) => {
            const pageCard = findCard(modalId);
            if (!pageCard) {
              return;
            }
            if (pageCard?.primaryButtonUrl.includes(modalType)) {
              const {
                modal: { images, subtitle, title, sections = [] },
              } = pageCard;
              modals[modalKey] = {
                images:
                  images &&
                  images[0]
                    .map(({ url, ...imageAttributes }) => ({
                      ...imageAttributes,
                      mediaUrl: url,
                    }))
                    .map((image) => getImageAttributes({ image })),
                sections: sections.map(({ cards, ...section }) => ({
                  ...section,
                  cards: cards?.map(({ detailedDescription, description, ...card }) => ({
                    ...card,
                    images: card?.images
                      ?.reduce((acc, image) => {
                        if (!image.url) {
                          return acc;
                        }
                        const { url, ...imageAttributes } = image;
                        acc.push({
                          src: url,
                          tier: 'xs',
                          ...imageAttributes,
                        });
                        return acc;
                      }, [])
                      .map((image) => getImageAttributes({ image })),
                    noBorder: true,
                    description: detailedDescription || description,
                  })),
                })),
                displayCardGroups: true,
                printLabel: print,
                subtitle,
                title,
              };
            }
          });

          return modals;
        }
      )
    ),
    getStateroomModalData: new Selector(({ getTabContentSections }) =>
      createSelector(
        [(state) => getTabContentSections(state)(STATEROOM), getLabels, getModalData],
        (tabContentSections, { buttons: { print } = {} }, { id: modalId, shipId }) => {
          const { card, cards } = findCardSectionByModalId(tabContentSections, modalId, shipId, 'cards');
          if (!cards?.length || !card) {
            return {};
          }
          const {
            modal: { images, subtitle, title, sections = [] },
            primaryButtonUrl,
          } = card;
          return {
            images:
              images &&
              images[0].map(({ url, ...imageAttributes }) => ({
                ...imageAttributes,
                mediaUrl: url,
              })),
            sections: sections.map(({ cards, ...section }) => ({
              ...section,
              cards: cards?.map(({ detailedDescription, description, ...card }) => ({
                ...card,
                images: card?.images
                  ?.reduce((acc, image) => {
                    if (!image.url) {
                      return acc;
                    }
                    const { url, ...imageAttributes } = image;
                    acc.push({
                      src: url,
                      tier: 'xs',
                      ...imageAttributes,
                    });
                    return acc;
                  }, [])
                  .map((image) => getImageAttributes({ image })),
                noBorder: true,
                description: detailedDescription || description,
              })),
            })),
            displayCardGroups: true,
            printLabel: print,
            subtitle,
            title,
            modalType: primaryButtonUrl.replace('#', ''),
          };
        }
      )
    ),
    getIsDiningOpen: new Selector(() =>
      createSelector([getBookingDetails], ({ diningOpenDateTz }) => {
        const localDiningOpenDate = moment(diningOpenDateTz, 'YYYY-MM-DD HH:mm:ss');
        const isDiningOpen = moment().isSameOrAfter(localDiningOpenDate);
        return isDiningOpen;
      })
    ),
    getDiningBeverageModalCards: new Selector((selectors) => {
      const { getTabContentSections, getRestrictedStateroom, getRestaurantMenu } = selectors;
      return createSelector(
        [
          getPassengerNames,
          getModalData,
          getReservationModalInfo,
          getCountryCodeFromCurrency,
          (state) => getTabContentSections(state)(DINING_BEVERAGE),
          getItinerary,
          isAllGifCompleted,
          getIsBalancePastDue,
          getRestrictedStateroom,
          getVoyageType,
          getRestaurantMenu,
          getIsApprovedForPurchases,
          isGifOverride,
          (state) => getPageTabLabels(state)(ONBOARD_EXPERIENCE, DINING_BEVERAGE),
          getFeatureRestricted,
          getViewOnlyContent,
          getBookingDetails,
          (state) => getIsCurrentCountryPaymentBlocked(state)(ADDONS_CHECKOUT),
          getIsDirect,
          getIsSsbpIncludedByAmenityCode,
          getComboBookings,
          (state) => getMvjFlags(state),
          getMvjStrings,
          getBookingDetails,
          getSSBPPricing,
          (state) => getPaymentsAllEnabled(state),
          (state) => getPaymentsCheckoutEnabled(state),
        ],
        (
          passengerNames,
          { id: modalId, shipId },
          reservationModalInfo,
          countryCode,
          diningBeverage,
          itinerary,
          allGifCompleted,
          isBalancePastDue,
          restrictedStateroom,
          voyageType,
          restaurantMenus,
          isApprovedForPurchases,
          gifOverride,
          {
            buttons: {
              requestRefund,
              cancelConfirm,
              cancelReject,
              refundYes,
              refundNo,
              print,
              cancelReservation,
              remove,
              close,
              continueLabel,
              back,
              tableForTwo,
              sharedTables,
              tableFor2,
              tableFor1,
              reserve,
              inviteGuests,
              inviteMoreGuests,
            },
            labels: {
              perPerson,
              included,
              reservationConfirmed,
              selectDayLabel,
              availableTimesLabel,
              bookingNumberLabel,
              lastNameLabel,
              purchaseConfirmed,
              packagePurchasedByStateroom,
              menuComingSoon,
              diningTotalPartySizePluralLabel,
              diningTotalPartySizeOneLabel,
              diningZeroReservationsRemainingLabel,
              cancellationSuccessful,
              SsbpIncluded,
              specDiningErrorMessage,
              ssbpNotAvailableCombos,
            },
          },
          featureRestricted,
          { bannerMessage: viewOnlyMessage },
          { ship: { stateroomCategory } },
          isPaymentBlocked,
          isDirect,
          isSsbpIncludedByAmenityCode,
          comboBookings,
          mvjFlags,
          mvjStrings,
          { diningOpenDateTz },
          ssbpPricing,
          isPaymentsAllEnabled,
          isPaymentsCheckoutEnabled
        ) => {
          const defaultValues = {
            title: '',
            subtitle: '',
            sections: [],
          };
          if (!modalId) {
            return defaultValues;
          }

          const { card, cards, messages } = findCardSectionByModalId(
            diningBeverage,
            modalId,
            shipId,
            'cards',
            'messages'
          );
          if (!cards || !card) {
            return defaultValues;
          }

          const isSsbpIncluded = stateroomCategory === INCLUDED_STATEROOM.EXPLORER_SUITE;
          const {
            id,
            primaryButtonUrl: modalType,
            detailedDescription,
            details: { comboData, extensionType, inventoryCode, isSoldOut, singlePrice, reservationStatus },
            images,
            reference,
            modal: {
              title,
              states = {},
              callToActionTitle,
              sections,
              lockedDown,
              numberAvailable = 0,
              currentReservations,
              errorCode,
              availabilityLoaded,
              availabilityLoading,
            },
          } = card;
          let {
            modal: { availability, sharedAvailability },
          } = card;

          if (!title) {
            return defaultValues;
          }

          const cardVoyageType = card?.voyageType || '';

          let { subtitle } = card.modal;
          const isSsbp = reference === SERVICE_CODES.SSBP;
          const price = comboData ? comboData.reduce((acc, leg) => acc + leg.singlePrice, 0) : singlePrice;
          const cost = formatMoney(price, 0, countryCode);
          const {
            CANCELED,
            CANCELING,
            RESERVED,
            EDITING,
            OPEN,
            RESERVING,
            RESERVING_SHARED,
            INVITING,
            IN_CART,
          } = RESERVATION_STATE_KEYS;
          let priceLabel = price ? replaceCMSTokenWithValue(perPerson, [{ key: 'PRICE', value: cost }]) : included;
          let { state, subText } = getSimpleReservationModalState(reservationStatus, priceLabel);
          state = reservationModalInfo.state || state;

          const availabilityArraysEmpty =
            availability?.options?.length === 0 || sharedAvailability?.options?.length === 0;
          const noReservationsAvailForPartySize =
            errorCode === EVO_ADVISORY_CODES.PARTY_SIZE_RES_UNAVAIL || availabilityArraysEmpty;
          let locked = lockedDown || (numberAvailable === 0 && ![CANCELING, EDITING].includes(state));

          const localDiningOpenDate = moment(diningOpenDateTz, 'YYYY-MM-DD HH:mm:ss');
          const isDiningOpen = moment().isSameOrAfter(localDiningOpenDate);

          if ((state === RESERVED || noReservationsAvailForPartySize) && isDiningOpen) {
            locked = false;
          }

          if (priceLabel === included) {
            priceLabel = '';
          }
          let modalAlertIsUrgent = true;
          let lockdownMessage;
          const defaultLabels = {
            callToActionTitle,
          };
          const stateLabels = states[state] || defaultLabels;
          const { primaryButton = stateLabels, secondaryButton = {} } = stateLabels;
          if (isSsbp) {
            subtitle =
              voyageType === VOYAGE_TYPE.RIVER
                ? getCmsLabel(sections, 'western-europe', 'subtitle')
                : getCmsLabel(sections, 'ssbpSampleMenuOcean', 'subtitle');
          } else if (reference === SHIP_RESTAURANT_CODES.CHEFS_TABLE) {
            const menus = restaurantMenus(RESTAURANT_NAMES[reference]);
            if (menus.mealItems.length) {
              subtitle = subtitle.replace('#', `${APP_PATHS.MENU}/${reference}`);
            } else {
              subtitle = menuComingSoon;
            }
          }
          const submitting = get(reservationModalInfo, 'metadata.submitting', false);
          const invitees = get(reservationModalInfo, 'metadata.invitees', {});
          const remainingMessage =
            numberAvailable === 1
              ? getDiningMessage(messages, 'diningReservationsRemaining1')
              : getDiningMessage(messages, 'diningReservationsRemainingDynamic');

          let modalAlert;
          if (!isSsbp) {
            modalAlert = {
              text: replaceCMSTokenWithValue(remainingMessage, [{ key: 'RESERVATION_COUNT', value: numberAvailable }]),
              type: numberAvailable > 0 ? ALERT_TYPES.INFO : ALERT_TYPES.ERROR,
            };
          }

          let alert = primaryButton.label || modalAlert;
          const inviteeNames = Object.values(invitees).flatMap((invitee) => invitee.passengers);
          let guests = passengerNames.concat(inviteeNames);
          let diningMessage = null;

          const gifIncompleteLockdown = isSsbp && !allGifCompleted && !gifOverride && state === OPEN;
          const isSsbpLockedDown = featureRestricted || !isApprovedForPurchases || isSsbpIncluded;

          let isSsbpSoldout = false;
          if (isSsbp) {
            isSsbpSoldout = comboData ? comboData.some((data) => data.isSoldout === true) : isSoldOut;
          }

          let isReservationAllowed = !locked && !gifIncompleteLockdown && isApprovedForPurchases && !featureRestricted;

          // Set isReservationAllowed to false if stateroomCategory matches
          if (!isSsbp && restrictedStateroom) {
            isReservationAllowed = false;
          }

          const isReservationNotAvailable =
            locked ||
            (!availabilityLoaded && availabilityArraysEmpty && numberAvailable === 0) ||
            !isReservationAllowed ||
            !isDiningOpen;

          switch (state) {
            case CANCELED:
              subText = null;
              alert = cancellationSuccessful;
              if (isSsbp) {
                primaryButton.text = primaryButton.callToActionTitle;
              } else {
                primaryButton.text = close;
              }
              break;
            case CANCELING:
              if (isSsbp) {
                subText = priceLabel;
                alert = null;
                primaryButton.text = refundYes;
                secondaryButton.text = refundNo;
              } else {
                primaryButton.text = cancelConfirm;
                secondaryButton.text = cancelReject;
              }
              break;
            case INVITING: {
              alert = null;
              primaryButton.text = continueLabel;
              secondaryButton.text = back;
              if (guests.length > 1) {
                diningMessage = replaceCMSTokenWithValue(diningTotalPartySizePluralLabel, [
                  { key: 'PARTY_SIZE', value: guests.length },
                ]);
              } else {
                diningMessage = diningTotalPartySizeOneLabel;
              }
              break;
            }
            case RESERVING: {
              primaryButton.text = reserve;
              secondaryButton.text = back;
              alert = null;
              if (guests.length > 1) {
                diningMessage = replaceCMSTokenWithValue(diningTotalPartySizePluralLabel, [
                  { key: 'PARTY_SIZE', value: guests.length },
                ]);
              } else {
                diningMessage = diningTotalPartySizeOneLabel;
              }
              guests = [];
              break;
            }
            case RESERVING_SHARED: {
              primaryButton.text = reserve;
              secondaryButton.text = back;
              alert = null;
              guests = [];
              break;
            }
            case RESERVED:
              if (isSsbp) {
                alert = purchaseConfirmed;
                subText = priceLabel;
                primaryButton.text = requestRefund;
                primaryButton.disabled = isSsbpLockedDown;
              } else {
                primaryButton.text = close;
              }
              break;
            case EDITING:
              if (isSsbp) {
                subText = null;
                alert = null;
                primaryButton.text = primaryButton.callToActionTitle;
                secondaryButton.text = secondaryButton.callToActionTitle;
              } else {
                primaryButton.text = cancelReservation;
              }
              break;
            default:
              if (!isSsbp) {
                primaryButton.text = tableForTwo;
                secondaryButton.text = inviteGuests;
                if (locked || !availabilityLoaded) {
                  primaryButton.disabled = true;
                  secondaryButton.disabled = true;
                }
              } else {
                if (state === OPEN && isSsbpIncluded) {
                  alert = SsbpIncluded;
                } else if (state === OPEN && isSsbpSoldout) {
                  alert = replaceCMSTokenWithValue(ssbpNotAvailableCombos, [
                    { key: 'REGION_PHONE', value: getCmsLabel(sections, 'PHONE', 'longText') },
                  ]);
                  modalAlertIsUrgent = false;
                } else if (state === IN_CART && isSsbpSoldout) {
                  isSsbpSoldout = false;
                } else if (state === OPEN) {
                  alert = packagePurchasedByStateroom;
                  primaryButton.disabled = isSsbpLockedDown || gifIncompleteLockdown || isBalancePastDue;
                } else if (state === IN_CART) {
                  primaryButton.disabled = false;
                }
                primaryButton.text = stateLabels.callToActionTitle;
              }
              break;
          }
          let ukOlbMessage;
          if (isPaymentBlocked && state !== RESERVED && state !== CANCELING && state !== CANCELED && isSsbp) {
            primaryButton.text = '';
          }
          if (isPaymentBlocked) {
            ukOlbMessage = replaceCMSTokenWithValue(getCmsLabel(sections, 'euUkNotAvailable', 'title'), [
              { key: 'PHONE', value: getCmsLabel(sections, 'PHONE', 'longText') },
            ]);
          }
          if (featureRestricted === FEATURE_RESTRICTED.CLOSE_TO_SAILING) {
            lockdownMessage = getDiningMessage(messages, 'bookingCustomizationsClosed');
          } else if (!isSsbp && isReservationNotAvailable && restrictedStateroom) {
            lockdownMessage = getDiningMessage(messages, 'diningReservationsUnavailable');
          } else if (!isSsbp && isReservationNotAvailable) {
            lockdownMessage = getDiningMessage(messages, 'diningSharedTablesUnavailable');
          } else if (!isSsbp && noReservationsAvailForPartySize && numberAvailable !== 0) {
            lockdownMessage = getDiningMessage(messages, 'reservationsUnavailableForPartySize');
          } else if (card?.modal?.errorCode === EVO_ADVISORY_CODES.NO_NON_SHARE_TABLES_AVAIL) {
            lockdownMessage = get(mvjStrings.errors, 'OnlineDiningReservationNotPermitted[0].message', '');
          } else if (!isSsbp && numberAvailable === 0 && ![CANCELING, EDITING].includes(state)) {
            lockdownMessage = diningZeroReservationsRemainingLabel;
          } else if (featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY) {
            lockdownMessage = viewOnlyMessage;
          } else if (!isApprovedForPurchases) {
            lockdownMessage = isDirect
              ? getCmsLabel(sections, 'ssbpInvalidStatus', 'title')
              : getCmsLabel(sections, 'invalidStatusTA', 'title');
          } else if (isBalancePastDue && isSsbp) {
            lockdownMessage = isDirect
              ? getCmsLabel(sections, 'finalPaymentRequired', 'title')
              : getCmsLabel(sections, 'finalPaymentRequiredTA', 'title');
          } else if (reservationModalInfo?.metadata?.item?.specDine) {
            lockdownMessage = specDiningErrorMessage;
          }

          let showReservationSidebar = true;
          if (mvjFlags?.diningDisabledRestaurants?.[card?.voyageType]?.includes(reference)) {
            showReservationSidebar = false;
          }

          const adjustAvailability = (avail) => {
            if (!avail || !avail.options || !availabilityLoaded) {
              return avail;
            }
            const result = { ...avail };
            if (!isReservationAllowed) {
              result.options = [];
            } else {
              result.dayPlaceholder = selectDayLabel;
              result.timePlaceholder = availableTimesLabel;
              result.options = getDiningAvailableDaysOptions(
                result.options,
                itinerary,
                restaurantMenus(RESTAURANT_NAMES[reference]),
                reference,
                messages
              );
            }

            return result;
          };

          if (isSsbp && (!isPaymentsAllEnabled || !isPaymentsCheckoutEnabled)) {
            primaryButton.disabled = true;
          }
          availability = adjustAvailability(availability);
          sharedAvailability = adjustAvailability(sharedAvailability);

          if (isSsbp && isSsbpIncludedByAmenityCode) {
            if (comboBookings.length) {
              const { longText: regionalPhoneNumber } =
                sections.find((section) => section.reference && section.reference.match('PHONE')) || {};
              alert = getCmsLabel(sections, 'SSBPIncludedWithCSPhoneNumber', 'title');
              alert = alert.replace('PHONE', 'REGION_PHONE');
              alert = replaceCMSTokenWithValue(alert, [{ key: 'REGION_PHONE', value: regionalPhoneNumber }]);
            } else {
              alert = getCmsLabel(sections, 'SSBPIncludedForTrip', 'title');
            }

            modalAlertIsUrgent = false;
            primaryButton.text = null;
          }
          return {
            guests,
            id,
            title,
            alert,
            alertIsUrgent: modalAlertIsUrgent,
            modalAlert,
            subtitle,
            modalType,
            price: singlePrice,
            reference,
            reservationState: state,
            primaryButton: {
              ...primaryButton,
              subText,
              loading: submitting,
            },
            subText: isSsbp && isSsbpIncluded ? included : priceLabel,
            diningMessage,
            secondaryButton: {
              ...secondaryButton,
              disabled: submitting,
            },
            availability,
            sharedAvailability,
            printLabel: print,
            longDescription: detailedDescription,
            images: getCarouselImageArray({ images: [images], imageRatioPriorities: [TWO_BY_ONE] }),
            sections: sections
              ? mapModalSections(
                  sections.map(({ longText, ...section }) => ({
                    ...section,
                    longText: replaceCMSTokenWithValue(longText, [{ key: 'ssbppricing', value: ssbpPricing }]),
                  })),
                  true
                )
              : [],
            reservationStatus: reservationStatus || [],
            extensionType,
            inventoryCode,
            isReservationAllowed,
            labels: {
              overMaxTableSize: getDiningMessage(messages, 'diningMaximumTableSizeLabel'),
              remove,
              close,
              cancelReservation,
              yesCancel: cancelConfirm,
              noDoNotCancel: cancelReject,
              confirmed: reservationConfirmed,
              inviteGuests,
              sharedTables,
              tableFor2,
              tableFor1,
              tableForTwo,
              bookingNumber: bookingNumberLabel,
              lastName: lastNameLabel,
              inviteMoreGuests,
              reservationUnsuccessful: getDiningMessage(messages, 'diningReservationFailedLabel'),
              cancellationSuccessful,
              cancellationFailed: getDiningMessage(messages, 'diningCancelFailedLabel'),
              continue: continueLabel,
              inviteGuestLabel: getDiningMessage(messages, 'diningInviteGuestInfoLabel'),
              inviteGuestMessage: getDiningMessage(messages, 'diningAdditionalGuestsMessage'),
              diningMakeAReservation: getDiningMessage(messages, 'diningMakeAReservation'),
              diningSharedTablesHelp: getDiningMessage(messages, 'diningSharedTablesHelp'),
              diningSharedTablesUnavailable: getDiningMessage(messages, 'diningSharedTablesUnavailable'),
              inviteGuestsFetchMessage: getDiningMessage(messages, 'diningAdditionalGuestFetchMessage'),
              addGuests: getDiningMessage(messages, 'diningAddGuestsLabel'),
              inviteGuestSubText: getDiningMessage(messages, 'diningInviteWithBookingName'),
              tableForTwoSubText: getDiningMessage(messages, 'diningReserveTableSharing'),
              shareTablesSubText: getDiningMessage(messages, 'diningYouWillShare'),
            },
            lockdownMessage,
            currentReservations,
            isSsbp,
            isSsbpIncluded,
            gifLockdown: {
              gifCallToActionUrl: getCmsLabel(sections, 'guestInformationFormButton', 'callToActionUrl'),
              gifCallToActionTitle: getCmsLabel(sections, 'guestInformationFormButton', 'title'),
              gifValidationErrorBody: getCmsLabel(sections, 'ssbpGuestInformationFormTxt', 'title'),
              gifIncompleteLockdown,
            },
            ukOlbMessage,
            showReservationSidebar,
            isSsbpSoldout,
            numberAvailable,
            availabilityLoaded,
            availabilityLoading,
            shipId,
            cardVoyageType,
          };
        }
      );
    }),
    getPreferenceServiceCodes: new Selector(({ getTabContentSections }) =>
      createSelector(getTabContentSections, (getSections) =>
        memoize((tab) => {
          const sections = getSections(tab);
          return sections
            .map(({ items }) => items.filter(({ reference }) => reference).map(({ reference }) => reference))
            .flat();
        })
      )
    ),
    getSpaOpenDate: new Selector(() =>
      createSelector([getBookingDetails], ({ diningOpenDateTz, voyage: { embarkDate } }) => {
        const spaOpenDate = moment(diningOpenDateTz, 'YYYY-MM-DD HH:mm:ss');
        // if the the spaOpenDate is the same as embarkDate, we need to add 60 days
        if (moment(embarkDate).diff(spaOpenDate, 'days') === 0) {
          spaOpenDate.subtract(DAYS_TO_SPA_RESERVATION, 'days');
        }
        spaOpenDate.todayIsBeforeSpaOpenDate = moment().isBefore(spaOpenDate);
        return spaOpenDate;
      })
    ),
    getSpaModalContent: new Selector(({ getTabContentSections, getStoreState, getSpaOpenDate }) =>
      createSelector(
        [
          getModalData,
          getPassengerNames,
          (state) => getTabContentSections(state)(SPA),
          getCountryCodeFromCurrency,
          getItinerary,
          getStoreState,
          (state) => formValueSelector(FORMS.BOOKING)(state, 'formStep', 'day', 'service', 'time'),
          getErrors,
          getReservationModalInfo,
          getSpaOpenDate,
          (state) => getModalLabels(state)(MODALS.RESERVATION_MODAL),
          getBookingDetails,
          getFeatureRestricted,
          getIsCloseToDepartureDate,
          getIsUKAUNZ,
        ],
        (
          { id: modalId },
          passengers,
          spaSections,
          countryCode,
          itinerary,
          storeState,
          formValues,
          errors,
          reservationModalInfo,
          spaOpenDate,
          {
            buttons: { cancelReservation, cancelConfirm, cancelReject, close, reserve },
            labels: { reservationConfirmed, selectDuration, selectDayLabel, selectTime, cancellationSuccessful },
          },
          { voyage, ship },
          featureRestricted,
          getIsCloseToDepartureDate,
          isUKAUNZ
        ) => {
          const defaultData = {
            mainContent: {
              title: null,
            },
            sideContent: {},
          };
          if (!modalId) {
            return defaultData;
          }

          const { shipCode } = ship;
          const findCard = (element) => element.id === modalId;
          const findSection = (section) => section.cards.find(findCard);
          const spaSection = spaSections.find(findSection);
          const cards = get(spaSection, 'cards', []);
          const messages = get(spaSection, 'messages', []);
          const {
            primaryButtonSubText,
            modal: { title, sections, serviceDurations = [], subtitle, callToActionTitle },
          } = cards.find(findCard) || { modal: {} };

          if (!title) {
            return defaultData;
          }

          const startingPrice = serviceDurations.reduce(
            (min, service) => Math.min(min, service.servicePrice),
            get(serviceDurations, '0.servicePrice')
          );
          const { embarkDate, disembarkDate } = voyage;
          const embarkMoment = moment(embarkDate).format('YYYY-MM-DD');
          const disembarkMoment = moment(disembarkDate).format('YYYY-MM-DD');
          const itineraryOptions = itinerary.reduce((acc, date) => {
            if (
              !date.isExtension &&
              !date.isIncludedExtension &&
              ![embarkMoment, disembarkMoment].includes(date.date) &&
              date.voyageType !== VOYAGE_TYPE.RIVER
            ) {
              acc.push({
                value: date,
                label: [`${date.dayValue} — ${date.description}`],
              });
            }
            return acc;
          }, []);
          const availableTimes = get(storeState, 'spa.availability', []);
          const availableTimeOptions = availableTimes.map((value) => ({
            value,
            label: moment(value.startTime).format('LT'),
          }));

          const durationLabel = get(
            messages.find((m) => m.reference === MESSAGE_KEYS.SPA_DURATION),
            'text',
            ''
          );
          const serviceDurationOptions = serviceDurations.map((service) => {
            const { cmsDuration, servicePrice } = service;
            const duration = replaceCMSTokenWithValue(durationLabel, [{ key: 'DURATION', value: cmsDuration }]);
            return {
              value: service,
              label: shipCode === CHINA_SHIP_CODE ? duration : [duration,
                formatMoney(servicePrice, 0, countryCode),
              ].join(' \u2013 '),
            };
          });

          const { service = {}, time = {}, day = {}, formStep } = formValues;

          let primaryButtonText;
          const secondaryButton = {};
          let disableButton = false;
          let alert;

          let subText = replaceCMSTokenWithValue(primaryButtonSubText, [
            { key: 'PRICE', value: formatMoney(startingPrice, 0, countryCode) },
          ]);

          const state = reservationModalInfo.state || formStep;
          const submitting = get(reservationModalInfo, 'metadata.submitting', false);
          switch (state) {
            case SPA_MODAL_STEPS.GUEST_SELECTION:
              primaryButtonText = callToActionTitle;
              break;
            case SPA_MODAL_STEPS.BOOKING:
              primaryButtonText = reserve;
              disableButton = !day || !availableTimes.length;
              break;
            case SPA_MODAL_STEPS.CONFIRMATION:
              alert = reservationConfirmed;
              primaryButtonText = close;
              subText = null;
              break;
            case RESERVATION_STATE_KEYS.EDITING:
              primaryButtonText = cancelReservation;
              disableButton = getIsCloseToDepartureDate;
              break;
            case RESERVATION_STATE_KEYS.CANCELING:
              primaryButtonText = cancelConfirm;
              secondaryButton.text = cancelReject;
              break;
            case RESERVATION_STATE_KEYS.CANCELED:
              primaryButtonText = close;
              subText = null;
              break;
            default:
          }

          const selectedStartTime = moment(time.startTime).format('LT');
          const selectedEndTime = moment(time.endTime).format('LT');

          const { todayIsBeforeSpaOpenDate } = spaOpenDate;

          return {
            mainContent: {
              title,
              subtitle,
              sections,
              serviceDurations,
            },
            sideContent: {
              labels: {
                selectDuration,
                selectDay: selectDayLabel,
                selectTime,
                reservationConfirmed,
                cancellationFailed: getCmsLabel(messages, 'cancellationFailed', 'text'),
                cancellationSuccessful,
              },
              lockedDown: todayIsBeforeSpaOpenDate || featureRestricted,
              itinerary: itineraryOptions,
              serviceDurations: serviceDurationOptions,
              errors,
              alert,
              availableTimes: availableTimeOptions,
              reservationDetails: {
                title: [day.dayValue, day.description].join(' \u2013 '),
                date: moment(day.date, moment.HTML5_FMT.DATE).format(
                  isUKAUNZ ? `dddd, ${REGIONAL_SHORT_DATES.EU}` : `dddd, ${REGIONAL_SHORT_DATES.NA}`
                ),
                time: `${selectedStartTime} \u2013 ${selectedEndTime}`,
                duration: replaceCMSTokenWithValue(durationLabel, [{ key: 'DURATION', value: service.cmsDuration }]),
              },
              primaryButton: {
                text: primaryButtonText,
                loading: submitting,
                disabled: disableButton,
              },
              secondaryButton: {
                ...secondaryButton,
                disabled: submitting,
              },
              passengers: passengers.map((passenger) => ({
                label: passenger,
                isCheckable: true,
              })),
              subText,
              isSpaAvailable: !todayIsBeforeSpaOpenDate,
              disableButton,
            },
          };
        }
      )
    ),
    getSpaModalCards: new Selector(({ getTabContentSections }) =>
      createSelector(
        [getLabels, getModalData, getCountryCodeFromCurrency, (state) => getTabContentSections(state)(SPA), getBookingDetails],
        (labels, { id: modalId }, countryCode, spaSections, { ship }) => {
          const defaultValues = {
            title: '',
            subtitle: '',
            sections: [],
          };

          if (!modalId) {
            return defaultValues;
          }

          const { shipCode } = ship;
          const findCard = (element) => element.id === modalId;
          const findSection = (section) => section.cards.find(findCard);
          const cards = get(spaSections.find(findSection), 'cards', null);
          const priceList = spaSections[1]?.messages?.find((message) => message.reference === MESSAGE_KEYS.CHINA_PRICE_LIST);
          const priceListURL = priceList?.text;
          if (!cards) {
            return defaultValues;
          }

          const { detailedDescription, images, modal } = cards.find(findCard);
          const { title, alert, subtitle, callToActionTitle, sections, serviceDurations = [] } = modal;
          if (!title) {
            return defaultValues;
          }

          const startingPrice = serviceDurations.reduce(
            (min, service) => Math.min(min, service.servicePrice),
            get(serviceDurations, '0.servicePrice')
          );

          const { print, generic } = labels;
          const subText = shipCode === CHINA_SHIP_CODE ? priceListURL : replaceCMSTokenWithValue(generic.priceFrom, [
            { key: 'PRICE', value: formatMoney(startingPrice, 0, countryCode) },
          ]);
          return {
            images: getCarouselImageArray({ images: [images], imageRatioPriorities: [TWO_BY_ONE] }),
            title,
            alert,
            subtitle,
            subText,
            printLabel: print,
            primaryButton: {
              text: callToActionTitle,
            },
            longDescription: detailedDescription,
            sections: sections ? mapModalSections(sections, true) : [],
          };
        }
      )
    ),
    getSpaTabContent: new Selector(({ getTabContent, getSpaOpenDate }) =>
      createSelector(
        [
          (state) => getTabContent(state)(SPA),
          (state) => getPageTabLabels(state)(ONBOARD_EXPERIENCE, SPA),
          getBookingDetails,
          getCountryCodeFromCurrency,
          getSpaOpenDate,
        ],
        (content, { labels }, { diningOpenDateTz }, countryCode, spaOpenDate) => {
          const { perPerson } = labels;
          const spaStartDate = spaOpenDate.format('LL');
          const spaOpenTime = moment(diningOpenDateTz, 'YYYY-MM-DD HH:mm:ss').format('h:mm a').toUpperCase();
          const { todayIsBeforeSpaOpenDate } = spaOpenDate;
          const getBannerNotificationData = (messages) => {
            let key;
            if (todayIsBeforeSpaOpenDate) {
              key = MESSAGE_KEYS.SPA_NOT_OPEN_YET_TZ;
            }

            const message = get(
              messages.find((m) => m.reference === key),
              'text',
              null
            );
            let alertText;
            switch (key) {
              case MESSAGE_KEYS.SPA_WITHIN_7_DAYS:
                alertText = message;
                break;
              case MESSAGE_KEYS.SPA_NOT_OPEN_YET_TZ:
                if (todayIsBeforeSpaOpenDate) {
                  alertText = replaceCMSTokenWithValue(message, [
                    { key: 'SPA_START', value: spaStartDate },
                    { key: 'TIME', value: `${spaOpenTime} (${TIME_ZONE_ABBREVIATIONS[countryCode]})` },
                  ]);
                } else {
                  alertText = get(
                    messages.find((m) => m.reference === MESSAGE_KEYS.SPA_NOT_AVAILABLE),
                    'text',
                    null
                  );
                }
                break;
              default:
                return {};
            }

            return alertText
              ? {
                  bannerNotification: {
                    alertText,
                    id: 'spa-alert',
                  },
                }
              : {};
          };
          const isSpaTreatments = (title) => title === 'Spa Treatments';
          const treatmentsUnavailableNotification = {
            alertText: labels.treatmentsUnavailable,
            id: 'spa-unavailable',
          };

          return {
            ...content,
            sections: content.sections.map(({ cards, title, messages, ...otherAttr }) => ({
              ...otherAttr,
              title,
              messages,
              bannerNotification: isSpaTreatments(title) && !cards.length ? treatmentsUnavailableNotification : null,
              cards: cards.map((card) => ({
                ...card,
                subTitle: card.subtitle,
                price: card.details.servicePrice,
                primaryButton: {
                  ...card.primaryButton,
                  subText: card.details.servicePrice
                    ? replaceCMSTokenWithValue(perPerson, [
                        {
                          key: 'PRICE',
                          value: formatMoney(card.details.servicePrice, 0, countryCode),
                        },
                      ])
                    : null,
                },
              })),
              // TODO use some kind of ID here instead of title
              ...(isSpaTreatments(title) ? getBannerNotificationData(messages) : {}),
            })),
          };
        }
      )
    ),
    getDiningTabContent: new Selector(({ getTabContent, getRestrictedStateroom }) =>
      createSelector(
        [
          (state) => getTabContent(state)(DINING_BEVERAGE),
          getBookingDetails,
          getVoyageType,
          getMvjStrings,
          getRestrictedStateroom,
          (state) => getPageTabLabels(state)(ONBOARD_EXPERIENCE, DINING_BEVERAGE),
          getCountryCodeFromCurrency,
          (state) => getMvjFlags(state),
        ],
        (
          content,
          { diningOpenDateTz },
          voyageType,
          mvjStrings,
          restrictedStateroom,
          { buttons: { viewMenu }, labels: { diningReservationsUnavailable } },
          country,
          mvjFlags
        ) => {
          const localDiningOpenDate = moment(diningOpenDateTz, 'YYYY-MM-DD HH:mm:ss');
          const localDiningOpenTime = localDiningOpenDate.format('h:mm a').toUpperCase();
          const diningStartDate = localDiningOpenDate.format('LL').replace(' ', '\u00a0');
          const isBeforeDiningStartDate = moment().isBefore(localDiningOpenDate);
          const getBannerNotificationData = (messages) => {
            // no messages if river cruise
            if (voyageType === VOYAGE_TYPE.RIVER) {
              return {};
            }

            // TODO this section will be removed since MT will prepare message for us
            let alertText;

            // Remove the banner notification if stateroomCategory matches
            if (restrictedStateroom) {
              return {};
            }

            if (isBeforeDiningStartDate) {
              alertText = replaceCMSTokenWithValue(getDiningMessage(messages, MESSAGE_KEYS.DINING_NOT_OPEN_TZ), [
                { key: 'DINING_START', value: diningStartDate },
                { key: 'TIME', value: `${localDiningOpenTime} (${TIME_ZONE_ABBREVIATIONS[country]})` },
              ]);
            } else {
              return {};
            }

            return alertText
              ? {
                  bannerNotification: {
                    alertText,
                    id: 'dining-alert',
                  },
                }
              : {};
          };
          const diningSections = content?.sections.filter((section) => section.voyageType);
          const remappedSections = content?.sections.map(({ title, messages, cards, ...otherAttr }, index) => {
            const remappedCards = cards?.map((card) => {
              let { alert } = card;
              let alertType = ALERT_TYPES.ERROR;
              const { modal, primaryButton } = card;
              const isPremiumRestaurant = [CHEFS_TABLE, MANFREDIS, THE_RESTAURANT].includes(card.reference);
              const reservationAvailableOnShipMessage = get(
                mvjStrings.errors,
                'OnlineDiningReservationNotPermitted[0].message',
                ''
              );

              card.subTitle = card.subtitle; // CMSv2 - UI Kit card requires subTitle
              if (
                card?.modal?.errorCode === EVO_ADVISORY_CODES.DINING_NOT_OPEN &&
                card?.modal?.callToActionTitle === DINING_DETAILS.RESERVATIONS_BUTTON
              ) {
                primaryButton.disabled = true;
              }
              // Lock modal and display message on card if stateroomCategory matches
              if (card.reference !== SERVICE_CODES.SSBP && restrictedStateroom) {
                modal.lockedDown = true;
                alert = isPremiumRestaurant ? reservationAvailableOnShipMessage : '';
              }

              if (card.voyageType === VOYAGE_TYPE.RIVER && card.reference !== SERVICE_CODES.SSBP) {
                primaryButton.text = viewMenu;
              }

              const numberAvailable = get(card, 'modal.numberAvailable', null);

              switch (numberAvailable) {
                case null:
                  if (isPremiumRestaurant) {
                    if (modal.errorCode === EVO_ADVISORY_CODES.NO_NON_SHARE_TABLES_AVAIL) {
                      alert = reservationAvailableOnShipMessage;
                    } else {
                      alert = diningReservationsUnavailable;
                    }
                  }
                  if (modal.errorCode === EVO_ADVISORY_CODES.PARTY_SIZE_RES_UNAVAIL) {
                    alert = getDiningMessage(messages, 'reservationsUnavailableForPartySizeMainPage');
                  }
                  break;
                case 1:
                  alert = getDiningMessage(messages, `diningReservationsRemaining${numberAvailable}`);
                  alertType = ALERT_TYPES.INFO;
                  break;
                default: {
                  const templateString = getDiningMessage(messages, 'diningReservationsRemainingDynamic');
                  alert = replaceCMSTokenWithValue(templateString, [
                    {
                      key: 'RESERVATION_COUNT',
                      value: numberAvailable,
                    },
                  ]);
                  alertType = numberAvailable > 0 ? ALERT_TYPES.INFO : ALERT_TYPES.ERROR;
                }
              }

              if (mvjFlags?.diningDisabledRestaurants?.[card.voyageType]?.includes(card.reference)) {
                alert = '';
              }

              return {
                ...card,
                alert,
                alertType,
              };
            });

            return {
              ...otherAttr,
              title: otherAttr?.voyageType ? `${convertStringToStartCase(otherAttr?.voyageType)} ${title}` : title,
              messages,
              cards: remappedCards,
              ...(index >= content.sections.length - diningSections.length &&
              otherAttr?.voyageType !== VOYAGE_TYPE.RIVER
                ? getBannerNotificationData(messages)
                : {}),
            };
          });
          return {
            ...content,
            sections: remappedSections,
          };
        }
      )
    ),
    getEnrichmentTabContent: new Selector(({ getTabContent }) =>
      createSelector(
        [
          (state) => getTabContent(state)(ENRICHMENT),
          (state) => getPageTabLabels(state)(ONBOARD_EXPERIENCE, ENRICHMENT),
          getVoyageType,
        ],
        (content, { labels: { onboardEnrichmentRiver, onboardEnrichmentOcean } }, voyageType) => {
          const updateCardSubTitle = (card) => ({
            ...card,
            subTitle: card.subtitle,
          });
          const cardSections = ((content && content.sections[0] && content.sections[0].cards) || []).map((card) =>
            updateCardSubTitle(card)
          );

          const { sections, pageDescription } = content;
          let remappedSections = [];
          // split river and ocean cards into different sections
          if (cardSections && voyageType === VOYAGE_TYPE.MIXED) {
            remappedSections[0] = {
              cards: cardSections.filter((c) => c.voyageType === VOYAGE_TYPE.OCEAN),
              title: onboardEnrichmentOcean,
            };

            remappedSections[1] = {
              cards: cardSections.filter((c) => c.voyageType === VOYAGE_TYPE.RIVER),
              title: onboardEnrichmentRiver,
            };
          } else {
            remappedSections = sections;
          }
          return {
            pageDescription,
            remappedSections,
          };
        }
      )
    ),
    getSpecialOccasionsContent: new Selector(({ getTabContent }) =>
      createSelector(
        [
          (state) => getTabContent(state)(SPECIAL_OCCASIONS),
          getPassengers,
          getItinerary,
          getItineraryLabels,
          getLabels,
        ],
        (
          { title, subtitle, loaded, sections },
          passengers,
          itinerary,
          { day },
          { buttons: { cancel, submit, back } = {} }
        ) => {
          /*
            Service codes of Birthday and Retirement that are selected per guest as opposed to
            the entire booking
          */
          const perGuestServiceCodes = ['BDAY', 'RTRM'];

          const items = get(sections, '[0].items', []);
          const occasions = items.filter((item) => item.title.trim());
          const occasionServiceCodes = occasions.map(({ reference }) => reference);
          const success = getCmsLabel(items, 'onboardPreferencesSavedLabel', 'longText');
          const initialValues = {};
          passengers.forEach(({ passengerPreferences }, index) => {
            passengerPreferences.forEach(({ requestDate, serviceCode }) => {
              if (occasionServiceCodes.includes(serviceCode)) {
                const date = getIsoDate(requestDate);

                const dateArray = [];
                dateArray[index] = date;

                let values = {
                  [serviceCode]: dateArray,
                };

                if (perGuestServiceCodes.includes(serviceCode)) {
                  const checkboxArray = [];
                  checkboxArray[index] = true;

                  values = {
                    ...values,
                    checkboxes: {
                      [serviceCode]: checkboxArray,
                    },
                  };
                }

                merge(initialValues, values);
              }
            });
          });

          return {
            dayOptions: [
              {
                label: 'Celebration Date',
                value: null,
              },
              ...itinerary.map(({ date }, index) => {
                const dayNumber = index + 1;

                const displayDate = moment(date).format('ddd, MMM D, Y');

                return {
                  label: `${day} ${dayNumber} \u2013 ${displayDate}`,
                  value: date,
                };
              }),
            ],
            initialValues,
            labels: {
              cancel,
              submit,
              success,
              backButton: back,
            },
            loaded,
            occasions: occasions.map(({ title: occasionTitle, reference }) => ({
              occasionTitle,
              perGuest: perGuestServiceCodes.includes(reference),
              serviceCode: reference,
            })),
            passengers: passengers.map((passenger) => getPassengerAbbrevName(passenger)),
            subtitle,
            title,
          };
        }
      )
    ),
    getPreferencesContent: new Selector(({ getTabContent }) =>
      createSelector(
        [(state) => getTabContent(state)(ONBOARD_PREFERENCES), getFeatureRestricted],
        (content, featureRestricted) => {
          const preferencesContent = { ...content };
          if (content.sections.length) {
            if (featureRestricted) {
              preferencesContent.sections[0].title = content.sections[0].title.trim();
              preferencesContent.pageDescription = null;
            } else {
              preferencesContent.sections[0].bannerNotification = null;
            }
          }
          return preferencesContent;
        }
      )
    ),
    getStateroomPreferencesContent: new Selector(({ getTabContent }) =>
      createSelector(
        [
          (state) => getTabContent(state)(STATEROOM_PREFERENCES),
          getPassengers,
          getPassengerNames,
          (state) => getPageTabLabels(state)(ONBOARD_EXPERIENCE, STATEROOM_PREFERENCES),
          getFeatureRestricted,
        ],
        (
          { title, subtitle, loaded, sections },
          passengers,
          passengerNames,
          { buttons: { cancel, submit, back }, labels: { yourBeds } },
          featureRestricted
        ) => {
          const fields = get(sections, '[0].items', []).slice(1);
          // TODO: remove this item from cms and remove slice below
          const success = getCmsLabel(fields, 'onboardPreferencesSavedLabel', 'longText');
          const beds = fields.filter((bed) => bed.title && bed.title.trim());
          const preferences = get(sections, '[1].items', []).filter((pref) => pref.title && pref.title.trim());
          const preferenceReferences = preferences.map(({ reference }) => reference);
          const bedsReferences = beds.map(({ reference }) => reference);
          const initialValues = {};

          passengers.forEach(({ passengerPreferences }, index) => {
            const componentPassengers = [];
            passengerPreferences.forEach(({ serviceCode }) => {
              if (preferenceReferences.includes(serviceCode)) {
                componentPassengers[index] = {
                  [serviceCode]: true,
                };
                merge(initialValues, { passengers: componentPassengers });
              }
              if (bedsReferences.includes(serviceCode) && index === 0) {
                merge(initialValues, { bedsList: serviceCode });
              }
            });
          });
          // if nothing was selected for beds preference - set to first preference: BDTG
          if (!initialValues.bedsList && bedsReferences.length) {
            const [BDTG] = bedsReferences;
            initialValues.bedsList = BDTG;
          }
          return {
            lockedDown: !!featureRestricted,
            initialValues,
            loaded,
            beds: beds.map(({ title: bedTitle, reference }) => ({
              bedTitle,
              reference,
            })),
            labels: {
              cancel,
              submit,
              yourBeds,
              success,
              backButton: back,
            },
            preferences: preferences.map(({ title: preferenceTitle, reference }) => ({
              preferenceTitle,
              reference,
            })),
            passengers: passengerNames,
            title,
            subtitle,
          };
        }
      )
    ),
    getFoodAllergiesContent: new Selector(({ getTabContent }) =>
      createSelector(
        [
          (state) => getTabContent(state)(FOOD_ALLERGIES),
          getPassengerNames,
          getPassengers,
          getLabels,
          getFeatureRestricted,
        ],
        (
          { title, subtitle, loaded, sections },
          passengerNames,
          passengers,
          { buttons: { cancel, submit, back } = {} },
          featureRestricted
        ) => ({
          lockedDown: !!featureRestricted,
          loaded,
          labels: {
            cancel,
            submit,
            backButton: back,
            success: getCmsLabel(get(sections, '0.items'), 'onboardPreferencesSavedLabel', 'longText'),
          },
          passengerNames,
          subtitle,
          title,
          preferences: sections.map((pref) => ({
            ...pref,
            items: pref.items.filter((item) => item.title.trim()),
            groupName: pref.items[0].title,
          })),
          initialValues: {
            passengers: passengers.map((passenger) => {
              const result = {};
              passenger.passengerFoodPreferences.forEach(({ serviceCode }) => {
                result[serviceCode] = true;
              });
              return result;
            }),
          },
        })
      )
    ),
    getRestaurantMenu: new Selector(() =>
      createSelector(
        (state) => get(state, 'onboard.restaurantMenus.content', []),
        (restaurantMenus) =>
          memoize((restaurantName) => {
            const restaurantMenu = restaurantMenus.find((menu) => menu?.restaurantName === restaurantName);
            if (!get(restaurantMenu, 'mealItems.length')) {
              return {
                mealItems: [],
              };
            }

            return {
              ...restaurantMenu,
              mealItems: restaurantMenu.mealItems.map((item) => ({
                ...item,
                menuFoodName: item.menuFoodName.split(':').reverse()[0].trim(),
              })),
            };
          })
      )
    ),
  },
});

const {
  creators: {
    receiveContent,
    receiveTabContent,
    receiveSpaAvailability,
    clearSpaAvailability,
    receiveDiningAvailability,
    clearAvailabilityData,
  },
  selectors: { getPreferenceServiceCodes },
} = onboardStore;

export const fetchOnboardPageContent = () => (dispatch, getState) => {
  const voyageType = getVoyageType(getState());
  const url = buildUrl('/pages/onboardExperience', ['voyageType'], { voyageType });
  dispatch(
    getData({
      url,
      store: onboardStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export const fetchSpaAvailability = (serviceCode, passengerId, date, shipCode) => (dispatch, getState) => {
  dispatch(clearSpaAvailability());
  const bookingDetails = getBookingDetails(getState());
  const url = buildUrl('/spa', ['office', 'currency', 'shipCode', 'serviceCode', 'date', 'passengerId', 'calendarId'], {
    ...bookingDetails,
    shipCode,
    serviceCode,
    date,
    passengerId,
  });

  // :facepalm: getData runs the spread operator on the response body. In this
  // case, the response body is an array. So, we get an array transformed to an object
  // instead of the original array:
  // { 0: { foo: 'bar'} , 1: { foo: 'baz' }, loading: false, status: 200 }
  // we need to remove `loading` and `status` keys from the object, and see what's
  // left. The remaining keys are assumed to be the available times.
  return dispatch(
    getData({
      url,
      store: onboardStore,
      node: `${SPA}.availability`,
      creator: receiveSpaAvailability,
      tab: SPA,
      refreshData: true,
    })
  ).then((originalData) => {
    if (originalData?.status !== 200) {
      return {
        status: originalData?.status,
        loading: originalData?.loading,
        availableTimes: [],
      };
    }
    const responseKeys = Object.keys(originalData).filter((k) => !['loading', 'status'].includes(k));
    const availableTimes = responseKeys.map((k) => originalData[k]);
    return {
      status: originalData.status,
      loading: originalData.loading,
      availableTimes,
    };
  });
};

export const updateSpaReservation = (values) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const {
    service,
    time,
    passengerNumber,
    day: { date, shipCode },
  } = values;
  let {
    voyage: { type },
  } = bookingDetails;
  const {
    voyage: { embarkDate },
    comboBookings,
    ship,
    calendarId,
  } = bookingDetails;
  let { stateroomNumber, embarkDate: shipEmbarkDate } = ship;
  let targetComboBooking;
  let comboBookingNumbers;

  const isComboBooking = comboBookings.length > 1;
  if (isComboBooking) {
    comboBookingNumbers = comboBookings.map((combo) => combo.invoice);
    const itineraryDate = getItineraryDate(state)(date);
    if (itineraryDate) {
      const comboBooking = comboBookings.find((s) => s.voyageId === itineraryDate.voyageId);
      if (comboBooking) {
        targetComboBooking = comboBooking.invoice;
        stateroomNumber = comboBooking.stateroomNumber;
        shipEmbarkDate = comboBooking.embarkDate;
        type = comboBooking.voyageType;
      }
    }
  }

  const url = buildUrl('/spa', ['office', 'currency', 'mxpLiteral', 'bookingNumber'], {
    ...bookingDetails,
    mxpLiteral: 'mxp',
  });

  return new Promise((resolve, reject) =>
    dispatch(
      postData({
        url,
        values: {
          calendar: {
            calendarId,
            serviceCode: service.serviceCode,
            description: service.serviceName,
            pricePerPassenger: service.servicePrice,
            startTime: time.startTime,
            endTime: time.endTime,
          },
          targetComboBooking,
          comboBookingNumbers: comboBookingNumbers?.join('|'),
          stateroomNumber,
          embarkDate: shipEmbarkDate || embarkDate,
          passengerId: passengerNumber,
          vacancyId: time.vacancyId,
          shipCode,
          voyageType: type,
        },
      })
    ).then(({ isSuccessful, data }) => {
      if (isSuccessful && !data.spaData.errorMessage) {
        dispatch(clearExcursionsDay(moment(date).format('YYYY-MM-DD')));
        // Clear data for the excursion of same day to force reload of data getting updated conflicts
        // Clear Dining data to force refetch of data on next visit to dining tab
        // since dining tab call receives all available times, available times need to be refreshed
        // after spa is added or removed from booking
        dispatch(clearAvailabilityData());
        return resolve(values);
      }
      return reject(data);
    })
  );
};

export const fetchTabContent = (tab, refreshData, ignoreErrors = false, isCalendar = false) => (dispatch, getState) => {
  const state = getState();
  const { comboBookings, ...bookingDetails } = getBookingDetails(state);
  const tabUrl = getPageTabUrl(ONBOARD_EXPERIENCE, tab);
  const tabName = getTabReference(tabUrl);

  const voyageType = getVoyageType(state);

  const comboShipId = comboBookings.map(({ shipCode }) => shipCode).join('|');
  const comboIds = comboBookings.map(({ shipCode, invoice }) => `${shipCode}${invoice}`).join('|');
  const voyageTypes = comboBookings.map(({ voyageType }) => `${voyageType}`).join('|');
  const oceanShip = comboBookings.find((s) => s.voyageType === VOYAGE_TYPE.OCEAN);
  let oceanId;
  let {
    ship: { shipCode },
  } = bookingDetails;
  const { diningOpenDateTz } = bookingDetails;
  if (oceanShip) {
    oceanId = `${oceanShip.shipCode}${oceanShip.invoice}`;
    // Using ocean code on diningBeverage tab currently fails due to soap call
    // Only using ocean shipCode for spa tab while MT investigates with core if soap call is needed
    if (tab === TAB_NAMES.SPA) {
      ({ shipCode } = oceanShip);
    }
  }

  let params = {};
  if (comboShipId || comboIds) {
    if (tab === SPA || tab === SHIP_CREW) {
      params = {
        comboShipId,
      };
    }
    if (tab === DINING_BEVERAGE) {
      params = {
        comboIds,
        voyageTypes,
      };
    }
  }

  const queryParams = {
    oceanId,
    ignoreErrors,
    ...params,
  };

  if (tab === DINING_BEVERAGE) {
    const localDiningOpenDate = moment(diningOpenDateTz, 'YYYY-MM-DD HH:mm:ss');
    queryParams.isDiningOpen = moment().isSameOrAfter(localDiningOpenDate);

    if (!queryParams.isDiningOpen && isCalendar) {
      const calendarItems = getCalendarItems(state)
        .map((item) => item.items)
        .flat();
      const calendarItemExtensionTypes = calendarItems.filter((item) => item.extensionType === 'dine');
      const isInvitee = calendarItemExtensionTypes.length > 0;
      queryParams.isDiningOpen = isInvitee;
    }
  }

  const url = buildUrl(
    '/onboardExperience',
    ['office', 'currency', 'tabName', 'voyageType', 'shipCode', 'bookingNumber', 'calendarId'],
    {
      ...bookingDetails,
      tabName,
      shipCode,
      voyageType,
    },
    {
      ...queryParams,
    }
  );
  return dispatch(
    getData({
      url,
      store: onboardStore,
      node: `${tab}.content`,
      creator: receiveTabContent,
      tab,
      refreshData: refreshData || false,
    })
  );
};

export const fetchPreferencesTabContent = (tab) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const { brand } = bookingDetails;

  const queryParams = {
    brand: (brand || '').toLowerCase(),
  };

  const url = buildUrl(
    `/pages/${tab}`,
    ['voyage.type', 'ship.shipCode', 'ship.stateroomCategory'],
    getBookingDetails(getState()),
    {
      ...queryParams,
    }
  );
  dispatch(
    getData({
      url,
      store: onboardStore,
      node: `${tab}.content`,
      creator: receiveTabContent,
      tab,
    })
  );
};

const makeUpdatePreferenceCall = (preferences) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const errors = getErrors(getState());
    return dispatch(
      updateBooking({
        payload: {
          newPreferences: preferences,
        },
        shouldReloadBookings: false,
      })
    ).then(({ isSuccessful }) => {
      if (!isSuccessful) {
        reject(
          new SubmissionError({
            _error: errors.GeneralUpdateFailed,
          })
        );
      } else {
        resolve();
      }
    });
  });

export const handlePreferencesFormChange = (formName) => (values, dispatch, props) => {
  if (props.error) {
    dispatch(clearSubmitErrors(formName));
  }
};

export const updatePassengerPreferences = (tab, selectedPreferences) => (dispatch, getState) => {
  const state = getState();

  const passengers = getPassengers(state);
  const serviceCodes = getPreferenceServiceCodes(state)(tab);

  const otherPreferences = passengers.map((passenger) => {
    const preferences = passenger.passengerPreferences;
    return preferences.filter(({ serviceCode }) => !serviceCodes.includes(serviceCode));
  });

  const allPreferences = otherPreferences.map((passengerPreferences, index) => ({
    passengerPreferences: [...passengerPreferences, ...selectedPreferences[index]],
  }));

  return dispatch(makeUpdatePreferenceCall(allPreferences));
};

export const updatePassengerFoodPreferences = (selectedPreferences) => (dispatch, getState) => {
  const state = getState();
  const passengers = getPassengers(state);

  const currentPreferences = passengers.map(({ passengerFoodPreferences }) => passengerFoodPreferences);

  const findPreference = (element, serviceCode) => element.serviceCode === serviceCode;
  const allPreferences = currentPreferences.map((passengerFoodPreferences, index) => ({
    passengerFoodPreferences: [
      ...passengerFoodPreferences
        .map(({ serviceCode }) => {
          const item = selectedPreferences[index].find((el) => findPreference(el, serviceCode));
          if (!item) {
            return {
              serviceCode,
              removeFoodPreference: true,
            };
          }
          return null;
        })
        .filter((notUndefined) => notUndefined),
      ...selectedPreferences[index],
    ],
  }));

  return dispatch(makeUpdatePreferenceCall(allPreferences));
};

/* eslint-disable-next-line max-len */
const getInviteePassengerCount = (invitees = {}) =>
  Object.values(invitees).reduce((acc, { numberOfPassengers }) => {
    const val = acc + numberOfPassengers;
    return val;
  }, 0);

export const updateReservation = (formValues, reference, availability, willingToShare = false) => (
  dispatch,
  getState
) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const updateUserInfo = getUpdateUserData(state);
  let {
    ship: { shipCode },
  } = bookingDetails;
  const {
    bookingNumber: bookingID,
    passengers,
    voyage: { id: voyageId },
    ship: { stateroomNumber },
    calendarId,
    comboBookings,
  } = bookingDetails;
  const { reservationDate, reservationTime } = formValues;

  const isComboBooking = comboBookings.length > 1;
  if (isComboBooking) {
    const formattedDate = moment(reservationDate, DEFAULT_DATE_FORMAT.YEAR_FIRST).format('YYYY-MM-DD');
    const itineraryDate = getItineraryDate(state)(formattedDate);
    if (itineraryDate) {
      shipCode = itineraryDate.shipCode;
    }
  }

  const passengerNames = passengers.map((passenger) => getPassengerAbbrevName(passenger));
  const restaurantCode = `${shipCode}${reference}`;
  const modalData = onboardStore.selectors.getDiningBeverageModalCards(state);

  return new Promise((_, reject) => {
    const reservationModalInfo = getReservationModalInfo(state);
    const invitees = get(reservationModalInfo, 'metadata.invitees', {});
    const partySize = passengerNames.length + getInviteePassengerCount(invitees);
    const isUKAUNZ = getIsUKAUNZ(state);
    const url = buildUrl(
      '/dining',
      ['office', 'currency', 'booking', 'bookingNumber', 'restaurant', 'restaurantCode', 'partySize'],
      {
        ...bookingDetails,
        restaurant: 'restaurant',
        restaurantCode,
        partySize,
        booking: 'booking',
      },
      {
        willingToShare,
      }
    );

    dispatch(
      postData({
        url,
        values: {
          cabin: stateroomNumber,
          calendarId,
          voyageId,
          bookingID,
          passengers,
          updateUserInfo,
          invitees: Object.values(invitees).map((invitee) => ({
            last: invitee.lastName,
            first: invitee.firstName,
            bookingID: invitee.bookingId,
            numberOfPassengers: invitee.numberOfPassengers,
            partySize,
          })),
          reservation: {
            restaurantName: modalData.title,
            reservationTime,
            reservationDate,
          },
        },
      })
    ).then(({ isSuccessful, data, status }) => {
      if (isSuccessful) {
        dispatch(clearExcursionsDay(moment(reservationDate).format('YYYY-MM-DD')));
        // Clear excursion data for reservation date to force fresh load with updated conflicts
        // on next visit to shoreEx day
        const { reservationPost } = data;
        const reservationDateOption = availability?.find((day) => day.value === formValues.reservationDate);
        const { reservation } = reservationPost;

        const diningGuests = passengerNames.concat(
          Object.values(invitees).flatMap((invitee) =>
            invitee.passengers.map((passenger) => getPassengerAbbrevName(passenger))
          )
        );

        const formatedStartTime = moment(reservation?.reservationTime, 'HH:mm').add(12, 'hours').format('HH:mm');

        const confirmation = {
          passengers: diningGuests,
          details: [
            reservationDateOption?.label,
            reservationDateOption?.menuLabel,
            moment(reservation.reservationDate, DEFAULT_DATE_FORMAT.YEAR_FIRST).format(
              isUKAUNZ ? `dddd, ${REGIONAL_SHORT_DATES.EU}` : `dddd, ${REGIONAL_SHORT_DATES.NA}`
            ),
            convertIsoTime(formatedStartTime, 'LT'),
          ],
        };
        // update diningBeverage tab to get new # of reservations remaining
        return dispatch(fetchTabContent(DINING_BEVERAGE, true)).then(() => {
          dispatch(updateReservationModalState(RESERVATION_STATE_KEYS.RESERVED, confirmation));
        });
      }

      const error = {};
      switch (status) {
        case 404: {
          if (data.errors) {
            const badBookingNumbers = JSON.parse(data.errors);
            const guestErrors = formValues.guests.reduce((acc, { bookingNumber }, index) => {
              if (badBookingNumbers.includes(parseInt(bookingNumber, 10))) {
                acc[index] = { lastName: data.errorDescription };
              }
              return acc;
            }, []);
            error.guests = guestErrors;
          }
          break;
        }
        case 400: {
          // eslint-disable-next-line no-underscore-dangle
          error._error = getFriendlyError(state, data);
          break;
        }
        default:
          // eslint-disable-next-line no-underscore-dangle
          error._error = modalData.labels.reservationUnsuccessful;
      }

      return reject(new SubmissionError(error));
    });
  });
};

export const cancelReservation = ({ item, reference, labels, shipId, ...reservationModalInfo }) => (
  dispatch,
  getState
) => {
  const { description, itemId, startTime } = item;
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const updateUserInfo = getUpdateUserData(state);
  const {
    bookingNumber: bookingID,
    passengers,
    voyage: { id: voyageId },
    ship: { shipCode, stateroomNumber },
    calendarId,
  } = bookingDetails;
  const restaurantCode = `${shipId || shipCode}${reference}`;

  const url = buildUrl(
    '/dining/delete',
    ['office', 'currency', 'booking', 'bookingNumber', 'restaurant', 'restaurantCode', 'totalGuests'],
    {
      ...bookingDetails,
      restaurant: 'restaurant',
      booking: 'booking',
      restaurantCode,
      totalGuests: passengers.length,
    }
  );
  // clear any error messages
  dispatch(
    updateReservationModalState(RESERVATION_STATE_KEYS.CANCELING, {
      ...reservationModalInfo,
      statusMessage: null,
      submitting: true,
    })
  );

  // submit DELETE request
  // todo add invitees to cancel flow
  dispatch(
    deleteData({
      url,
      data: {
        calendarItemID: itemId,
        passengers,
        updateUserInfo,
        calendarID: calendarId,
        invitees: [],
        reservation: {
          restaurantName: description,
          reservationTime: moment(startTime).subtract(12, 'hours').format('HH:mm'),
          reservationDate: moment(startTime).format(DEFAULT_DATE_FORMAT.YEAR_FIRST),
        },
        cabin: stateroomNumber,
        voyageId,
        bookingID,
      },
    })
  ).then(({ isSuccessful, data, status }) => {
    if (isSuccessful) {
      dispatch(clearExcursionsDay(moment(startTime).format('YYYY-MM-DD')));
      // refresh booking (to rebuild calendar object)
      dispatch(fetchBookings()).then(() => {
        const calendarItems = dispatch(fetchCalendarItems());
        const diningBeverageContent = dispatch(fetchTabContent(DINING_BEVERAGE, true));
        // wait for dining tab content & calendarItems
        // to come back before telling them it was successful
        // dining tab content has new # reservations remaining,
        // new calendarItems will remove item from calendar in the UI
        Promise.all([diningBeverageContent, calendarItems]).then(() => {
          dispatch(
            updateReservationModalState(RESERVATION_STATE_KEYS.CANCELED, {
              ...reservationModalInfo,
              statusMessage: labels.cancellationSuccessful,
              submitting: false,
            })
          );
        });
      });
    } else {
      let error = labels.cancellationFailed;
      if (status === 400) {
        error = getFriendlyError(state, data);
      }
      dispatch(
        updateReservationModalState(RESERVATION_STATE_KEYS.CANCELING, {
          ...reservationModalInfo,
          item,
          reference,
          statusMessage: null,
          errorMessage: error,
          submitting: false,
        })
      );
    }
  });
};

export const cancelSpaReservation = ({ item, reference, labels, ...reservationModalInfo }) => (dispatch, getState) => {
  const state = getState();
  const { comboBookings, ship, ...bookingDetails } = getBookingDetails(state);
  const { shipCode } = comboBookings.find((s) => s.voyageType === VOYAGE_TYPE.OCEAN) || ship;

  const url = buildUrl('/spa/reservations', ['office', 'currency', 'shipCode', 'reservationId', 'calendarId'], {
    ...bookingDetails,
    shipCode,
    reservationId: item.reservationID,
  });

  dispatch(
    updateReservationModalState(RESERVATION_STATE_KEYS.CANCELING, {
      ...reservationModalInfo,
      statusMessage: null,
      submitting: true,
    })
  );

  // submit DELETE request
  dispatch(deleteData({ url })).then(({ isSuccessful }) => {
    if (isSuccessful) {
      dispatch(clearExcursionsDay(moment(item.startTime).format('YYYY-MM-DD')));
      // Clear data for the excursion of same day to force reload of data getting updated conflicts
      // Clear Dining data to force refetch of data on next visit to dining tab
      // since dining tab call receives all available times, available times need to be refreshed
      // after spa is added or removed from booking
      dispatch(clearAvailabilityData());
      dispatch(fetchBookings()).then(() => {
        dispatch(fetchCalendarItems()).then(() => {
          dispatch(
            updateReservationModalState(RESERVATION_STATE_KEYS.CANCELED, {
              ...reservationModalInfo,
              statusMessage: labels.cancellationSuccessful,
              submitting: false,
            })
          );
        });
      });
    } else {
      dispatch(
        updateReservationModalState(RESERVATION_STATE_KEYS.CANCELING, {
          ...reservationModalInfo,
          item,
          reference,
          statusMessage: labels.cancellationFailed,
          submitting: false,
        })
      );
    }
  });
};

export const addGuestValidation = ({ addGuest }) => (dispatch, getState) => {
  const state = getState();
  const errors = getErrors(state);
  const evolutionErrors = getEvolutionErrors(state);
  const { bookingNumber: bookingId, passengers } = getBookingDetails(state);

  const isDuplicateBooking = (index, bookingNumber) => {
    for (let i = 0; i < index; i += 1) {
      if (addGuest[i].bookingNumber === bookingNumber) {
        return true;
      }
    }
    return false;
  };

  let hasError;
  const fieldErrors = {
    addGuest: [],
  };
  const valid = {
    guests: [],
    passengerCount: passengers.length,
  };

  addGuest.forEach((entry, index) => {
    const { bookingNumber, lastName } = entry;
    if (bookingNumber || lastName) {
      if (!hasError) {
        fieldErrors.addGuest.push({});
        if (!bookingNumber) {
          hasError = true;
          fieldErrors.addGuest[index].bookingNumber = get(
            errors,
            ERROR_CODES.BOOKING_NUMBER_REQUIRED,
            'Booking Number is required.'
          );
          fieldErrors.error = get(errors, ERROR_CODES.BOOKING_NUMBER_REQUIRED, 'Booking Number is required.');
        } else if (bookingNumber && isDuplicateBooking(index, bookingNumber)) {
          hasError = true;
          fieldErrors.addGuest[index].bookingNumber = get(
            errors,
            ERROR_CODES.DUPLICATE_INVITEE,
            'Cannot invite the same guest twice.'
          );
          fieldErrors.error = get(errors, ERROR_CODES.DUPLICATE_INVITEE, 'Cannot invite the same guest twice.');
        } else if (bookingNumber && bookingNumber === bookingId) {
          hasError = true;
          fieldErrors.addGuest[index].bookingNumber = get(evolutionErrors, 2309, 'Cannot invite yourself.');
          fieldErrors.error = get(evolutionErrors, 2309, 'Cannot invite yourself.');
        } else if (!lastName) {
          hasError = true;
          fieldErrors.addGuest[index].lastName = get(errors, ERROR_CODES.LAST_NAME_REQUIRED, 'Last Name is required.');
          fieldErrors.error = get(errors, ERROR_CODES.LAST_NAME_REQUIRED, 'Last Name is required.');
        } else {
          valid.guests.push(entry);
        }
      }
    }
  });
  if (hasError) {
    return Promise.resolve({ fieldErrors });
  }
  return Promise.resolve({ valid });
};

export const validateDiningInvitee = (values) => (dispatch, getState) => {
  const state = getState();
  const evolutionErrors = getEvolutionErrors(state);

  const { inviteeLocked } = get(getPageTabLabels(state)(ONBOARD_EXPERIENCE, DINING_BEVERAGE), 'labels', {});
  const { bookingNumber, voyage } = getBookingDetails(state);

  const EVO_ERROR_CODES = {
    SHIP_MISMATCH: 2311,
    CANNOT_INVITE_SELF: 2309,
    NO_RESERVATIONS_REMAINING: 2305,
    BOOKING_LOCKED: 9006,
  };
  const { lastName } = values;
  const htmlEncodedLastName = encodeURIComponent(lastName);
  const url = buildUrl('/auth/validatebooking', ['lastName', 'bookingNumber'], {
    ...values,
    lastName: htmlEncodedLastName,
  });
  return dispatch(
    getData({
      url,
      store: onboardStore,
    })
  ).then(async ({ status, data: errorData, ...responseData }) => {
    const response = {};
    switch (status) {
      case 404: {
        response.error = errorData;
        break;
      }
      case 200: {
        if (responseData?.errorCode) {
          return {
            number: values.number,
            error: {
              errorDescription: responseData.errorDescription,
              errorCode: responseData.errorCode,
            },
          };
        }
        const isInviteeLocked = await dispatch(
          verifyLockUnlockStatus({
            type: LOCK_TYPES.UNLOCK,
            bookingId: responseData.bookingID,
            inviteeLastName: lastName,
          })
        );
        if (isInviteeLocked.data.errorCode) {
          const evoError = getEvoErrorMessage(isInviteeLocked.data, evolutionErrors);
          if (evoError.errorCode === '9006' || isInviteeLocked.data.errorDescription === 'Unlock Failed') {
            evoError.errorCode = EVO_ERROR_CODES.BOOKING_LOCKED;
            evoError.errorDescription = inviteeLocked;
          }
          if (isInviteeLocked.data.errorDescription.includes('does not match ship')) {
            evoError.errorDescription = evolutionErrors[EVO_ERROR_CODES.SHIP_MISMATCH];
            evoError.errorCode = EVO_ERROR_CODES.SHIP_MISMATCH;
          }
          response.error = evoError;
        }
        if (!response.error) {
          if (bookingNumber === `${responseData.bookingID}`) {
            response.error = {
              errorDescription: evolutionErrors[EVO_ERROR_CODES.CANNOT_INVITE_SELF],
              errorCode: EVO_ERROR_CODES.CANNOT_INVITE_SELF,
            };
          } else if (responseData.voyageID !== voyage.id) {
            response.error = {
              errorDescription: evolutionErrors[EVO_ERROR_CODES.SHIP_MISMATCH],
              errorCode: EVO_ERROR_CODES.SHIP_MISMATCH,
            };
          } else if (responseData.numberLeft === 0) {
            response.error = {
              errorDescription: evolutionErrors[EVO_ERROR_CODES.NO_RESERVATIONS_REMAINING],
              errorCode: EVO_ERROR_CODES.NO_RESERVATIONS_REMAINING,
            };
          } else {
            response.data = {
              ...responseData,
              passengers: responseData.passengers.slice(0, responseData.numberOfPassengers),
            };
          }
        }
        break;
      }
      default:
        response.data = responseData;
    }
    if (response.error) {
      return {
        number: values.number,
        error: response.error,
      };
    }
    return response.data;
  });
};

export const getDiningAvailsForInvitedPartySize = (guests, restaurantCode, partySize) => async (dispatch, getState) => {
  const state = getState();
  const { bookingNumber, ship, office, currency } = getBookingDetails(state);
  const { shipCode } = ship;
  const inviteeBookingIds = guests.map((guest) => guest.bookingNumber).join(',');
  const errors = getErrors(state);
  const availabilityUrl = buildUrl(
    '/dining',
    ['office', 'currency', 'bookingID', 'restaurant', 'restaurantCode', 'partySize'],
    {
      office,
      currency,
      restaurant: 'restaurant',
      restaurantCode: `${shipCode}${restaurantCode}`,
      partySize,
      bookingID: bookingNumber,
    },
    {
      inviteeBookingIds,
    }
  );

  const res = await dispatch(
    getData({
      url: availabilityUrl,
      store: onboardStore,
    })
  );
  if (res?.data?.errorCode) {
    return { error: errors.FindAvailabilityFailedPartySize };
  } else {
    const formattedDates = [];
    res.dates.forEach((d) => {
      const option = {
        value: d.date,
        availableTimes: [],
      };
      d.availability.times.forEach((t) =>
        option.availableTimes.push({
          value: t.seatingTime || '00:00',
          label: t.startTime || '00:00',
        })
      );
      formattedDates.push(option);
    });
    return formattedDates;
  }
};

export const fetchRestaurantData = (referenceCode, partySize, shipCode) => (dispatch, getState) => {
  const state = getState();
  const { ...bookingDetails } = getBookingDetails(state);
  const restaurantCode = `${shipCode}${referenceCode}`;

  const url = buildUrl(
    '/dining',
    ['office', 'currency', 'bookingNumber', 'restaurant', 'restaurantCode', 'availability', 'partySize', 'calendarId'],
    {
      ...bookingDetails,
      restaurant: 'restaurant',
      restaurantCode,
      availability: 'availability',
      partySize,
    }
  );

  return dispatch(
    getData({
      url,
      store: onboardStore,
      creator: receiveDiningAvailability,
    })
  );
};

export const fetchInviteeAvailability = (options, invitees, reference, partySize) => (dispatch, getState) => {
  const state = getState();
  const {
    ship: { shipCode },
    ...bookingDetails
  } = getBookingDetails(state);
  const itinerary = getItinerary(state);
  const reservationModalInfo = getReservationModalInfo(state);
  const errors = getErrors(state);
  const restaurantCode = `${shipCode}${reference}`;
  const tabContent = onboardStore.selectors.getTabContentSections(state)(DINING_BEVERAGE);
  const messages = tabContent?.['1']?.messages || [];
  const remappedInvitees = invitees.reduce((acc, guest) => {
    if (guest) {
      acc[guest.bookingID] = guest;
    }
    return acc;
  }, {});
  const url = buildUrl('/dining/noconflicts', ['office', 'currency', 'bookingNumber', 'restaurantCode', 'partySize'], {
    ...bookingDetails,
    restaurantCode,
    partySize,
  });

  return dispatch(
    postData({
      url,
      values: {
        options: options.map((o) => o.value),
        invitees,
      },
    })
  ).then(({ isSuccessful, data }) => {
    if (!isSuccessful) {
      const evolutionErrors = getEvolutionErrors(state);
      const evoError = getEvoErrorMessage(data, evolutionErrors);
      logger({
        type: APP_INSIGHTS_TRACK_TYPE.TRACE,
        name: 'Dining Invitee Error',
        message: 'fetchInviteeAvailability() failed',
        severity: SeverityLevel.Warning,
        logData: {
          invitees: JSON.stringify(invitees),
          errorCode: evoError?.errorCode || '(N/A)',
          errorDescription: evoError?.errorDescription || '(N/A)',
        },
      });
      return evoError;
    }

    if (isSuccessful) {
      if (data.length === 0) {
        return { error: errors.FindAvailabilityFailedInvitee };
      }
      const menus = onboardStore.selectors.getRestaurantMenu(state)(RESTAURANT_NAMES[reference]);
      return dispatch(
        updateReservationModalState(RESERVATION_STATE_KEYS.RESERVING, {
          ...reservationModalInfo.metadata,
          availability: getDiningAvailableDaysOptions(data, itinerary, menus, reference, messages),
          invitees: remappedInvitees,
        })
      );
    }
    return { error: errors.FindAvailabilityFailed };
  });
};

export const fetchRestaurantMenu = () => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const { startDate, endDate, type } = bookingDetails.voyage;
  const url = buildUrl('/dining/menu', ['office', 'currency', 'ship.shipCode', 'startDate', 'endDate'], {
    ...bookingDetails,
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
  });

  if (type === VOYAGE_TYPE.RIVER) {
    return null;
  }
  return dispatch(
    getData({
      url,
      store: onboardStore,
      node: 'restaurantMenus',
      creator: onboardStore.creators.receiveRestaurantMenus,
    })
  );
};

export const clearAvailabilityDiningData = () => (dispatch) => {
  dispatch(clearAvailabilityData());
};

export default onboardStore;

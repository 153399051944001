import { Selector } from 'extensible-duck';
import moment from 'moment';
import { createSelector } from 'reselect';
import { userStore } from '../../common';
import { getData } from '../../common/Api';
import commonStore from '../../common/CommonStore';
import {
  ACH_TYPES,
  ADD_NEW_ACCOUNT,
  NOTIFICATION_TYPES,
  PAGE_NAMES,
  REGIONAL_LONG_DATES
} from '../../common/Constants';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import { buildUrl, decodeCountryCodeFromCurrency, formatMoney, getCmsLabel } from '../../common/Utils';

import paymentStore, { calculateACHSavings, getCruisePaymentObject } from '../payments/PaymentsStore';

const { getPageTabLabels } = commonStore.selectors;

const { getBookingDetails, getCountryCodeFromCurrency } = userStore.selectors;

const { getPaymentAmountForOption, getPaymentMethods, getTabContent, isPaymentMethodsLoaded } = paymentStore.selectors;

const { SCHEDULE_PAYMENT } = PAGE_NAMES;

const schedulePaymentStore = createPageTitleDuck('schedulePayment').extend({
  types: [],
  reducer: (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
  creators: () => ({}),
  selectors: {
    getSchedulePaymentSummarySection: new Selector(() =>
      createSelector(
        [
          (state) => getTabContent(state)('checkoutBooking'),
          (state) => getPageTabLabels(state)(SCHEDULE_PAYMENT),
          (state) => getPaymentAmountForOption(state)('fullPayment'),
          getCountryCodeFromCurrency
        ],
        (paymentContent = [], labelData, paymentAmount, countryCode) => {
          const completePaymentDisclaimer = getCmsLabel(paymentContent.sections, 'schedPaymentDisclaimer', 'longText');
          const {
            buttons: { scheduleAPayment },
            labels: { achSavings, paymentAmountLabel, secureCheckout, cardTotal },
          } = labelData;

          const lineItems = [
            {
              label: paymentAmountLabel,
              value: paymentAmount,
            },
            {
              label: achSavings || 'ACH Savings',
              value: calculateACHSavings(paymentAmount),
              markDiscount: true,
            },
          ];
          const balanceItems = [{ label: cardTotal, value: paymentAmount + calculateACHSavings(paymentAmount) }];

          return {
            button: {
              text: scheduleAPayment,
            },

            lineItems: getCruisePaymentObject({
              lineItems,
              countryCode,
            }).lineItems,
            balanceItems: getCruisePaymentObject({
              lineItems: balanceItems,
              countryCode,
            }).lineItems,
            secureCheckout,
            completePaymentDisclaimer
          };
        }
      )
    ),
    getWarningModalContent: new Selector(({ getPageContent }) =>
      createSelector(
        [
          getPageContent,
          (state) => getPageTabLabels(state)(SCHEDULE_PAYMENT),
          getBookingDetails,
          (state) => getPaymentAmountForOption(state)('fullPayment'),
          getCountryCodeFromCurrency,
        ],
        (content, { labels: { ok, no, areYouSure } }, { payments: { balanceDueDate } }, amount, countryCode) => {
          const messages = content?.sections?.[0]?.items;
          const totalDue = amount + calculateACHSavings(amount);
          return {
            title: areYouSure,
            warningMessage: getCmsLabel(messages, 'addNewAccountWarning'),
            date: moment(balanceDueDate).format(REGIONAL_LONG_DATES.NA),
            amount: formatMoney(totalDue, 2, countryCode),
            confirmText: ok,
            cancelText: no,
          };
        }
      )
    ),
    getSuccessModalContent: new Selector(({ getPageContent }) =>
      createSelector(
        [getPageContent, (state) => getPageTabLabels(state)(SCHEDULE_PAYMENT)],
        (content, { buttons: { continueLabel } }) => {
          const messages = content?.sections?.[0]?.items;
          return {
            title: getCmsLabel(messages, 'scheduledSuccess'),
            buttonText: continueLabel,
            buttonAppearance: 'secondary-blue',
            type: NOTIFICATION_TYPES.SUCCESS,
          };
        }
      )
    ),
    getIsPageLoading: new Selector(() =>
      createSelector(
        [
          (state) => state?.schedulePayment?.content,
          isPaymentMethodsLoaded,
          (state) => getTabContent(state)('checkoutBooking'),
        ],
        (content, paymentMethodsLoaded, cruiseCheckout) => {
          const loading = !content?.loaded || !paymentMethodsLoaded || !cruiseCheckout?.loaded;
          return loading;
        }
      )
    ),
    getSchedulePaymentContent: new Selector(({ getPageContent }) =>
      createSelector(
        [
          (state) => getPageContent(state),
          (state) => getPageTabLabels(state)(SCHEDULE_PAYMENT),
          (state) => getPaymentAmountForOption(state)('fullPayment'),
          getBookingDetails,
          getCountryCodeFromCurrency,
          getPaymentMethods,
        ],
        (
          content,
          {
            labels: {
              fullPayment,
              schedulePayment,
              paymentDate,
              paymentAmount,
              paymentMethod,
              achAccount,
              bankDraft,
              draftDiscount,
              achCheckingLabel,
              achSavingsLabel,
              addNewAccount,
              selectAccount,
            },
          },
          balanceDue,
          { payments: { balanceDueDate } },
          country,
          paymentMethods
        ) => {
          const dueDate = moment(balanceDueDate).format(REGIONAL_LONG_DATES.NA);
          const totalDue = balanceDue + calculateACHSavings(balanceDue);
          const paymentAmountDue = `${fullPayment} ${formatMoney(totalDue, 2, country)}`;

          // If there is a saved payment method for current user, assign that data to `account` property in return object

          const defaultAch = {
            value: ADD_NEW_ACCOUNT,
            label: addNewAccount,
          };

          const savedAccounts = paymentMethods.reduce(
            (acc, { maskedAccountNumber, id, accountHolderName, isCreditCard, achAccountType }) => {
              let label = [accountHolderName];
              if (!isCreditCard) {
                const accountType = achAccountType === ACH_TYPES.SAVINGS ? achSavingsLabel : achCheckingLabel;
                label = label.concat([accountType, maskedAccountNumber]);
              }
              const paymentMethod = {
                value: id,
                label: label.join(', '),
              };
              acc.options.unshift(paymentMethod);

              return acc;
            },
            { helperLabel: selectAccount, options: [defaultAch] }
          );

          return {
            title: content.title,
            subtitle: content.subtitle,
            labels: {
              schedulePayment,
              paymentDate,
              paymentAmount,
              paymentMethod,
              achAccount,
              bankDraft,
              draftDiscount,
            },
            dueDate,
            country,
            paymentAmountDue,
            savedAccounts,
          };
        }
      )
    ),
  },
});

const { receiveContent } = schedulePaymentStore.creators;

export const fetchSchedulePaymentContent = () => (dispatch, getState) => {
  const bookings = getBookingDetails(getState());
  const country = decodeCountryCodeFromCurrency(bookings.currency);
  const url = buildUrl('/pages/schedulePayment', ['voyage.type'], { ...bookings }, { country });

  return dispatch(
    getData({
      url,
      store: schedulePaymentStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export default schedulePaymentStore;

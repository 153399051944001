import fallbackImage from '@viking-eng/css/lib/assets/viking-logo-gray.svg';
import MultiStepForm from '@viking-eng/multi-step-form';
import Spinner from '@viking-eng/spinner';
import React, { Suspense } from 'react';
import { APP_PATHS, MODALS } from '../../../../common/Constants';
import { navigateTo } from '../../../../common/Utils';
import lazyWithReload from '../../../../common/Utils/lazyWithReload';
import PropTypes from '../../../../PropTypes';
import './GuestCheckIn.scss';
import OnBoardCreditCardStep from './onboardCreditCardStep/OnBoardCreditCardStep';

const PhotoUploadModalContainer = lazyWithReload(() =>
  import('../../../../modals/photoUploadModal/PhotoUploadModalContainer')
);

const photoUploadModal = MODALS.PHOTO_UPLOAD_MODAL;

const GuestCheckIn = ({
  formTitle,
  formSteps,
  onCompleteText,
  onComplete,
  guestName,
  isLoading,
  openModal,
  buttons,
  paymentMethods,
  passengerNumber,
  removalModalBody,
  handleCancelOnboardCCRemove,
  handleRemoveOnboardCreditCard,
  handleAddCreditCard,
  submitting,
  handlePhotoValidation,
  content,
  handleShowModal,
  submitFailed,
  handleEditGif,
  handleBack,
  handleCciStepChange,
  stepNumber,
}) => {
  const { unableToCompleteAny, customerService, toCompleteCheckIn } = content;

  const comboSubmissionError = {
    alertButton: {
      text: customerService,
      onButtonClick: () => handleShowModal(),
    },
    alertEndText: toCompleteCheckIn,
    message: unableToCompleteAny,
  };

  const mappedCardSections = (sections) =>
    sections.map((section) => {
      const mappedSection = {
        ...section,
      };
      if (section.card) {
        mappedSection.card = {
          ...section.card,
          className: `${section.card.photoUrl ? '' : 'guest-card-no-image'}${
            section.card.className ? ` ${section.card.className}` : ''
          }`,
          primaryButton: {
            onButtonClick: () => openModal(photoUploadModal, 1),
            text: buttons.uploadPhoto,
          },
          guidelineButton: {
            onButtonClick: () => openModal(photoUploadModal, 1),
            alertButtonText: buttons.photoGuideline,
          },
          photoUrl: section.card.photoUrl || fallbackImage,
        };
      }
      if (section.subtitle && section.subtitle.text) {
        mappedSection.subtitle.onButtonClick = () => handleEditGif(passengerNumber);
      }
      return mappedSection;
    });

  const mapFormStepCardAndChildren = () => {
    const mappedFormSteps = formSteps.map((step, index) => {
      const { primaryButton } = step;
      primaryButton.loading = submitting;

      if (index === 0) {
        step.secondaryButton.onClick = handleBack;
      }

      if (step.sections && step.sections.some((section) => section.card)) {
        // eslint-disable-next-line no-param-reassign
        step.validation = () => handlePhotoValidation();
      }

      if (step.onboardCreditCardSection) {
        return {
          ...step,
          child: (
            <OnBoardCreditCardStep
              title={step.stepSubtitle}
              stepDescription={step.stepDescription}
              onBoardCreditCardData={step.onboardCreditCardSection}
              bannerMessage={step.bannerMessage}
              paymentMethods={paymentMethods}
              openModal={openModal}
              removalModalBody={removalModalBody}
              handleCancelOnboardCCRemove={handleCancelOnboardCCRemove}
              handleRemoveOnboardCreditCard={handleRemoveOnboardCreditCard}
              guestName={guestName}
              addCreditCard={buttons.addCreditCard}
              handleAddCreditCard={handleAddCreditCard}
            />
          ),
        };
      }
      return {
        ...step,
        sections: mappedCardSections(step.sections),
      };
    });
    return mappedFormSteps;
  };

  if (!guestName) {
    navigateTo(APP_PATHS.COMFORT_CHECK_IN);
  }

  return (
    <div className="comfort-check-in-page-wrapper">
      {isLoading && (
        <div className="loading-spinner">
          <Spinner color="black" />
        </div>
      )}
      {!isLoading && (
        <div>
          <MultiStepForm
            formTitle={formTitle}
            formSteps={mapFormStepCardAndChildren(formSteps)}
            guestName={guestName}
            initialStep={stepNumber}
            onComplete={{
              text: onCompleteText,
              onClick: () => onComplete(passengerNumber),
            }}
            onStepChange={handleCciStepChange}
            submitting={submitting}
            submissionError={submitFailed ? comboSubmissionError : ''}
          />
          <Suspense fallback={null}>
            <PhotoUploadModalContainer id={photoUploadModal} passengerNumber={passengerNumber} />
          </Suspense>
        </div>
      )}
    </div>
  );
};

GuestCheckIn.propTypes = {
  formTitle: PropTypes.string.isRequired,
  formSteps: PropTypes.shape({}).isRequired,
  onComplete: PropTypes.func.isRequired,
  onCompleteText: PropTypes.string.isRequired,
  guestName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  buttons: PropTypes.shape({}).isRequired,
  paymentMethods: PropTypes.shape({}),
  passengerNumber: PropTypes.number.isRequired,
  stepNumber: PropTypes.number.isRequired,
  removalModalBody: PropTypes.shape({}).isRequired,
  handleCancelOnboardCCRemove: PropTypes.func.isRequired,
  handleRemoveOnboardCreditCard: PropTypes.func.isRequired,
  handleAddCreditCard: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handlePhotoValidation: PropTypes.func.isRequired,
  content: PropTypes.shape({}).isRequired,
  handleShowModal: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  handleEditGif: PropTypes.func.isRequired,
  handleCciStepChange: PropTypes.func.isRequired,
};

GuestCheckIn.defaultProps = {
  paymentMethods: [],
};

export default GuestCheckIn;

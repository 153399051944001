import { fetchNotifications } from '../../pages/notifications/NotificationsStore';
import { initializeBookingData } from '../Analytics';
import commonStore, { fetchCommonContent } from '../CommonStore';
import { LOCK_TYPES } from '../Constants';
import modalStore from '../ModalStore';
import userStore, { fetchCart, handleUpdateLoggedInUser, reloadBookings, verifyLockUnlockStatus } from '../UserStore';

const {
  creators: { clearState },
  // selectors: {},
} = commonStore;

const {
  creators: { updateChangeBookingStatus },
  selectors: { getAuthData },
} = userStore;

const {
  creators: { clearModal },
} = modalStore;

export const changeBookingFunction = ({ currentBookingNumber, newBookingNumber }) => (dispatch, getState) => {
  const state = getState();
  const authData = getAuthData(state);
  if (currentBookingNumber) {
    dispatch(
      verifyLockUnlockStatus({
        type: LOCK_TYPES.UNLOCK,
        bookingId: currentBookingNumber,
      })
    );
  }
  dispatch(
    verifyLockUnlockStatus({
      type: LOCK_TYPES.LOCK,
      bookingId: newBookingNumber,
    })
  );
  // set loading state
  dispatch(updateChangeBookingStatus(true));
  // update logged in user to correct bookingId
  dispatch(
    handleUpdateLoggedInUser({
      authData,
      bookingId: newBookingNumber,
    })
  )
    // fetch common content, cart, and booking details
    .then(() => {
      dispatch(clearState());
      dispatch(clearModal());
      return dispatch(reloadBookings(newBookingNumber, true)).then((newBookingData) => {
        dispatch(fetchCommonContent());
        dispatch(fetchNotifications(true));
        dispatch(fetchCart(true));
        // end loading state
        dispatch(updateChangeBookingStatus(false));

        const pathname = window.location.pathname;
        initializeBookingData(
          { event: 'spa_page_view', event_name: 'spa_page_view', ...newBookingData?.booking },
          pathname
        );
      });
    });
};

import CardSections from '@viking-eng/card-sections';
import get from 'lodash/get';
import React from 'react';
import { APP_PATHS, RESERVATION_STATE_KEYS, RESERVATION_TYPES, TAB_NAMES } from '../../common/Constants';
import remapCardDataBasedOnModalType from '../../common/ModalUtils';
import { getModalType, hideAllModalsAndNavigate, lowercaseFirstLetter } from '../../common/Utils';
import { PageHeader, TravelProtectionModalSideContent } from '../../components';
import { InformationModal, MobileAppModal, ReservationModal } from '../../modals';
import PropTypes from '../../PropTypes';
import './BeforeYouGo.scss';
import ComfortCheckIn from './comfortCheckIn/ComfortCheckInContainer';
import GuestCheckIn from './comfortCheckIn/guestCheckIn/GuestCheckInContainer';

const BeforeYouGo = ({
  addToCart,
  closeModal,
  content: { backButton, pageDescription, sections },
  displayCruiseInfo,
  handleCardDetailsClicked,
  isFetchingCommonContent,
  isLoading,
  isSubmitting,
  location: { pathname },
  match: { url },
  mobileAppModalData,
  modalData,
  modalToOpen,
  requestRefund,
  showTPCard,
  subtitle,
  tabLabels,
  title,
  updateReservationModalState,
  isPaymentsDisabled,
}) => {
  const paths = [
    {
      component: ComfortCheckIn,
      componentProps: {
        backButton,
      },
      path: get(tabLabels, `${TAB_NAMES.COMFORT_CHECK_IN}.url`, ''),
      subPaths: [
        {
          component: GuestCheckIn,
          componentProps: { isLoading },
          path: '/check-in',
        },
      ],
    },
  ];
  const filterCards = ({ reference }) => {
    let enableCard = true;
    switch (reference) {
      case 'travelProtection':
        enableCard = showTPCard;
        break;
      case 'informationForYourCruiseCard':
        enableCard = displayCruiseInfo;
        break;
      default:
        break;
    }
    return enableCard;
  };
  const sectionData = sections.map((section) => ({
    ...section,
    cards: section.cards
      .filter((card) => filterCards(card))
      .map((card) => {
        const openModal = () => {
          const modalType = getModalType(card.primaryButtonUrl);
          handleCardDetailsClicked(modalType, card.id);
        };
        const onClick = card.primaryButton.onButtonClick || openModal;
        return remapCardDataBasedOnModalType(card, {
          onPrimaryButtonClick: onClick,
          onSecondaryButtonClick: onClick,
          defaultClick: onClick,
        });
      }),
  }));

  const modalId = 'addToCartModal';
  const modal = { ...modalData };
  const {
    extensionType,
    inventoryCode,
    primaryButton,
    reservationState,
    sideContentData,
    isReservationAllowed,
    lockedDownModal,
  } = modal;

  const resetModalState = () => updateReservationModalState();
  if (modal.title) {
    if (sideContentData) {
      modal.sideContent = <TravelProtectionModalSideContent {...modal} />;
    }
    if (primaryButton.callToActionUrl) {
      primaryButton.onButtonClick = () => {
        closeModal();
        hideAllModalsAndNavigate(primaryButton.callToActionUrl);
      };
    } else {
      const { OPEN, RESERVED, CANCELING, CANCELED, IN_CART } = RESERVATION_STATE_KEYS;

      let primaryButtonClickFunction;
      switch (reservationState) {
        case OPEN:
          primaryButton.attributes = {
            ...primaryButton.attributes,
            type: 'button',
            disabled: isPaymentsDisabled || lockedDownModal,
          };
          primaryButtonClickFunction = addToCart;
          break;
        case IN_CART:
          primaryButton.attributes = {
            ...primaryButton.attributes,
            type: 'button',
            disabled: isPaymentsDisabled,
          };
          primaryButtonClickFunction = () => {
            closeModal();
            hideAllModalsAndNavigate(APP_PATHS.CART);
          };
          break;
        case RESERVED:
          primaryButtonClickFunction = () => {
            updateReservationModalState(CANCELING);
          };
          modal.primaryButton.attributes = {
            ...primaryButton.attributes,
            type: 'button',
            disabled: isPaymentsDisabled || !isReservationAllowed,
          };
          break;
        case CANCELING:
          primaryButtonClickFunction = () => requestRefund(extensionType, inventoryCode);
          modal.primaryButton.attributes = {
            type: 'button',
          };
          modal.secondaryButton.onButtonClick = resetModalState;
          break;
        case CANCELED:
          primaryButtonClickFunction = () => {
            resetModalState();
            closeModal();
          };
          break;
        default:
          break;
      }

      modal.primaryButton.onButtonClick = primaryButtonClickFunction;
    }
  }

  if (!isFetchingCommonContent && modalData && modalToOpen && modalToOpen.modalType && modalToOpen.modalId) {
    handleCardDetailsClicked(lowercaseFirstLetter(modalToOpen.modalType), modalToOpen.modalId);
  }

  return (
    <div id="beforeYouGoPage" className="before-you-go-page">
      <PageHeader
        hasLine
        isLoading={isLoading}
        paths={paths}
        pageHeaderPathDepth={3}
        pathname={pathname}
        url={url}
        title={title}
        subtitle={subtitle}
      />
      {pathname === url && <CardSections sections={sectionData} headerText={pageDescription} />}
      {modal && (
        <>
          <ReservationModal
            {...modal}
            id={modalId}
            onClose={resetModalState}
            reservationType={RESERVATION_TYPES.PPG}
            forceAction={isSubmitting}
          />
          <MobileAppModal {...mobileAppModalData} id="mobileAppModal" />
          {showTPCard && <ReservationModal {...modal} id="travelProtection" onClose={resetModalState} />}
          {displayCruiseInfo && (
            <InformationModal {...modal} id="informationForYourCruiseModal" onClose={resetModalState} />
          )}
        </>
      )}
    </div>
  );
};

BeforeYouGo.propTypes = {
  addToCart: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  content: PropTypes.shape({
    pageDescription: PropTypes.string,
    sections: PropTypes.cardSections,
  }),
  displayCruiseInfo: PropTypes.bool,
  handleCardDetailsClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  match: PropTypes.routerMatch.isRequired,
  mobileAppModalData: PropTypes.shape(PropTypes.modalData),
  modalData: PropTypes.shape(PropTypes.modalData),
  modalToOpen: PropTypes.shape({ modalType: PropTypes.string, id: PropTypes.string }),
  requestRefund: PropTypes.func.isRequired,
  showTPCard: PropTypes.bool,
  subtitle: PropTypes.string,
  tabLabels: PropTypes.shape({}),
  tabs: PropTypes.tabs,
  title: PropTypes.string,
  updateReservationModalState: PropTypes.func.isRequired,
  voyageId: PropTypes.string,
  isFetchingCommonContent: PropTypes.bool,
};

BeforeYouGo.defaultProps = {
  content: {
    pageDescription: null,
    sections: [],
  },
  displayCruiseInfo: false,
  isSubmitting: false,
  mobileAppModalData: {},
  modalData: {},
  modalToOpen: null,
  showTPCard: false,
  subtitle: '',
  tabLabels: {},
  tabs: [],
  title: '',
  isFetchingCommonContent: false,
};

export default BeforeYouGo;

import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { createSelector } from 'reselect';
import commonStore, { userStore } from '../../common';
import { getData } from '../../common/Api';
// eslint-disable-next-line no-restricted-imports
import {
  APP_PATHS,
  CALENDAR_ITEM_PIXELS_PER_HOUR,
  CALENDAR_MODAL_STATES,
  CALENDAR_PORT_LABELS,
  DEFAULT_DATE_FORMAT,
  EXTENSION_TYPES,
  OOPS_PAGE_ERRORS,
  PAGE_NAMES,
  RESERVATION_TYPES,
  TAB_NAMES,
  TWO_BY_ONE,
  UPSELL_BAR_TILE,
  VOYAGE_TYPE,
} from '../../common/Constants';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import { buildUrl, findImageAlt, findImageSrc, getCardThumbnailObject, goTo, navigateTo } from '../../common/Utils';

const { CALENDAR } = PAGE_NAMES;
const { LIST } = TAB_NAMES;
const { ERROR, ERROR_RESERVATION, LOADING } = CALENDAR_MODAL_STATES;
const { getItinerary, getBookingDetails, getPassengers, getVoyageType } = userStore.selectors;
const { setOopsPageError } = userStore.creators;
const { getLabels, getErrors, getPageTabLabels, getIsUKAUNZ } = commonStore.selectors;

const HOUR_POSITION_OFFSET_PX = 3;

const PIXELS_PER_MINUTE = CALENDAR_ITEM_PIXELS_PER_HOUR / 60;

const CALENDAR_SETTINGS = {
  VISIBLE_HOURS_START: 7,
  VISIBLE_HOURS_END: 23,
  RENDERED_HOURS_END: 22,
};

export const ITINERARY_PAGES = {
  ITINERARY: 'itinerary',
  ITINERARY_SUMMARY: 'itinerarySummary',
};

const eventEntryHasState = (modalStatus = {}, desiredState, serviceCode, index) => {
  if (modalStatus.state === desiredState) {
    return modalStatus.serviceCode === serviceCode && modalStatus.passengerIndex === index;
  }
  return false;
};

const getCalendarMessage = (items, reference, fallback = {}) =>
  items.find((item) => item.reference === reference) || fallback;

const calendarStore = createPageTabsDuck('calendar').extend({
  types: ['RECEIVE_CALENDAR_ITEMS', 'CLEAR_CALENDAR_ITEMS', 'UPDATE_CALENDAR_MODAL_STATUS', 'UPDATE_MODAL_TO_OPEN'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.RECEIVE_CALENDAR_ITEMS:
        return {
          ...state,
          calendarItems: { ...action.payload, loaded: true },
        };
      case types.CLEAR_CALENDAR_ITEMS:
        return {
          ...state,
          calendarItems: {
            loaded: false,
          },
        };
      case types.UPDATE_CALENDAR_MODAL_STATUS: {
        return {
          ...state,
          calendarModalStatus: {
            serviceCode: action.serviceCode,
            passengerIndex: action.passengerIndex,
            state: action.state,
          },
        };
      }
      case types.UPDATE_MODAL_TO_OPEN:
        return {
          ...state,
          modalToOpen: action.payload,
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    receiveCalendarItems: (payload) => ({
      type: types.RECEIVE_CALENDAR_ITEMS,
      payload,
    }),
    clearCalendarItems: () => ({
      type: types.CLEAR_CALENDAR_ITEMS,
    }),
    updateCalendarModalStatus: (serviceCode, passengerIndex, state) => ({
      type: types.UPDATE_CALENDAR_MODAL_STATUS,
      serviceCode,
      passengerIndex,
      state,
    }),
    updateModalToOpen: (item, passengers, itineraryDate, passengerIndex) => ({
      type: types.UPDATE_MODAL_TO_OPEN,
      payload: {
        item,
        passengers,
        itineraryDate,
        passengerIndex,
      },
    }),
  }),
  selectors: {
    isLoadingCalendar: new Selector((selectors) => {
      const { getStoreState, isLoadingPage, isLoadingTabs } = selectors;
      return createSelector(
        [getStoreState, isLoadingPage, isLoadingTabs, (state) => get(state, 'calendar.calendarItems.loaded')],
        (storeState, loadingPageStatus, loadingTabsStatus, isPreviouslyLoaded) => {
          if (isPreviouslyLoaded) {
            return false;
          }

          const isLoadingCalendarItems = get(storeState, 'calendarItems.loading');

          return loadingPageStatus || loadingTabsStatus || isLoadingCalendarItems;
        }
      );
    }),
    getCalendarItems: (state) => get(state, 'calendar.calendarItems.itinerary', []),
    getCalendarModalStatus: (state) => get(state, 'calendar.calendarModalStatus', {}),
    getUpsellItems: new Selector(({ getTabContent }) =>
      createSelector(getTabContent, (getContent) => get(getContent('itinerary'), 'sections[0].items', []))
    ),
    getCustomPageLink: new Selector(() =>
      createSelector([(state) => getPageTabLabels(state)(CALENDAR, LIST)], ({ buttons: { calendarSummary }, url }) =>
        memoize((currentRoute) => {
          const regEx = /^\/myjourney\/calendar\/+(\d+)|\/myjourney\/calendar$/g;
          const lockPageLinkToRoute = currentRoute.match(regEx);

          if (lockPageLinkToRoute) {
            return {
              title: calendarSummary,
              url,
            };
          }
          return null;
        })
      )
    ),
    getBackButton: new Selector(() =>
      createSelector([(state) => getPageTabLabels(state)(CALENDAR)], ({ buttons: { dayView }, url }) =>
        memoize((page) => {
          if (page !== ITINERARY_PAGES.ITINERARY_SUMMARY || !dayView) {
            return undefined;
          }
          const label = dayView;
          return { label, previousPagePath: url };
        })
      )
    ),
    getDayViewCalendarEvents: new Selector((selectors) => {
      const { getCalendarItems, getCalendarModalStatus } = selectors;
      return createSelector(
        [getCalendarItems, getLabels, getBookingDetails, getErrors, getCalendarModalStatus],
        (calendarItems, labels, bookingDetails, errors, calendarModalStatus) =>
          memoize((date) => {
            const { itinerary = {}, voyage } = bookingDetails;
            const { journeyDays = [] } = itinerary;

            const filteredItinerary = journeyDays.filter((item) => item.date === date);
            const isExpeditionLanding =
              voyage?.type === VOYAGE_TYPE.EXPEDITION && filteredItinerary[0]?.isExpeditionLanding;

            /*
            This mapping is needed to properly display the correct calendar items
            for the selected date.
          */
            const mappedCalendarItems = Object.values(filteredItinerary).map((day) => {
              const matchedCalendarItem = calendarItems.find((item) => item.date === day.date);
              if (matchedCalendarItem) {
                if (voyage?.type === VOYAGE_TYPE.OCEAN) {
                  if (day.arrivalTime) {
                    const arrivalItem = matchedCalendarItem.items.find((item) => item.id === 'A');
                    if (arrivalItem) {
                      arrivalItem.startTime = day.arrivalTime;
                    } else if (!day?.arrivalTime.match('00:00:00')) {
                      matchedCalendarItem.items.unshift({
                        id: 'A',
                        startTime: day.arrivalTime,
                        description: get(labels, 'calendar.portArrival', CALENDAR_PORT_LABELS.ARRIVAL),
                      });
                    }
                  }
                  if (day.departure && !day.departure.match('23:59')) {
                    const departureItem = matchedCalendarItem.items.find((item) => item.id === 'D');
                    if (departureItem) {
                      departureItem.startTime = day.departure;
                    } else {
                      matchedCalendarItem.items.push({
                        id: 'D',
                        startTime: day.departure,
                        description: get(labels, 'calendar.portDeparture', CALENDAR_PORT_LABELS.DEPARTURE),
                      });
                    }
                  }
                }

                matchedCalendarItem.items = matchedCalendarItem.items.map(
                  ({ reservationID, inventoryCode, ...item }) => {
                    let startDate = moment(item.startTime).format('YYYY-MM-DD');
                    const reservationIdMoment = moment(reservationID, DEFAULT_DATE_FORMAT.YEAR_FIRST);
                    if (reservationID > 0 && reservationIdMoment.isValid()) {
                      startDate = reservationIdMoment.format('YYYY-MM-DD');
                    }
                    return {
                      ...item,
                      reservationID,
                      inventoryCode,
                      startDate,
                    };
                  }
                );
                return {
                  date: day.date,
                  id: matchedCalendarItem.id || null,
                  ...matchedCalendarItem,
                };
              }

              return {
                date: day.date,
                id: null,
                items: [],
              };
            });

            const dayItinerary = mappedCalendarItems.find((item) => item.date === date) || mappedCalendarItems[0];

            if (!dayItinerary) {
              return {
                date,
              };
            }

            const { VISIBLE_HOURS_START, VISIBLE_HOURS_END } = CALENDAR_SETTINGS;

            const getStartTime = (items = []) => {
              let startTime = VISIBLE_HOURS_START;
              const portArrival = items.find((item) => item.id === 'A');
              const endTimes = items.reduce((acc, item) => {
                if (moment(item?.endTime).format('YYYY-MM-DD') === date) {
                  acc.push(moment(item?.endTime).format('HH'));
                }
                return acc;
              }, []);

              if (portArrival?.startTime || endTimes.length) {
                const portArrivalTime = moment(portArrival?.startTime).format('HH');
                // const portArrivalHour = !portArrival?.startTime?.match('00:00:00') ? Number(moment(portArrival?.startTime).format('h')) : VISIBLE_HOURS_START;
                // const excursionHour = Number(firstEventEndTimeMoment.format('h') - 1);
                startTime = Math.min(portArrivalTime, ...endTimes);
              }

              return startTime < VISIBLE_HOURS_START ? startTime : VISIBLE_HOURS_START;
            };

            const getEndTime = (items = []) => {
              const visibleHoursEndTime = moment(date).hour(VISIBLE_HOURS_END).minutes(0);
              const endTimes = items.map((item) => (item?.endTime ? new Date(item?.endTime).getTime() : 0));
              const lastEventEndTime = Math.max(...endTimes);
              const lastEventEndTimeMoment = moment(lastEventEndTime);

              if (!lastEventEndTimeMoment.isSame(moment(date))) {
                return VISIBLE_HOURS_END;
              }
              if (lastEventEndTimeMoment?.hour() > 0) {
                if (lastEventEndTimeMoment && lastEventEndTimeMoment?.isAfter(visibleHoursEndTime.subtract(1, 'h'))) {
                  const paddingTime = lastEventEndTimeMoment?.minute() ? 2 : 1;
                  return lastEventEndTimeMoment?.hour() + paddingTime;
                }
              }

              const portDeparture = items.find((item) => item.id === 'D');
              const portDepartureTimeMoment = moment(portDeparture?.startTime);
              if (portDepartureTimeMoment?.isAfter(visibleHoursEndTime)) {
                return portDepartureTimeMoment;
              }

              return VISIBLE_HOURS_END;
            };

            const visibleHoursStart = getStartTime(dayItinerary.items);
            const visibleHoursEnd = getEndTime(dayItinerary.items);

            const initialCalendarHours = () => {
              const visibleHourEnd = moment.isMoment(visibleHoursEnd) ? visibleHoursEnd?.hour() + 1 : visibleHoursEnd;
              const visibleTimes = range(visibleHoursStart, visibleHourEnd);
              return visibleTimes.map((hour) => ({
                hour,
                min:
                  moment.isMoment(visibleHoursEnd) && hour === visibleHoursEnd?.hour()
                    ? visibleHoursEnd?.minute()
                    : '00',
              }));
            };

            const initialHours = initialCalendarHours();

            // separate the events and add ports to lines
            const items = dayItinerary.items.reduce(
              (acc, item) => {
                const momentActualStart = moment(item.startTime);
                const momentActualEnd = moment(item.endTime);
                const actualStartHour = momentActualStart.get('hour');
                const actualEndHour = momentActualEnd.get('hour');

                // add port events to lines
                if (['A', 'D'].includes(item.id)) {
                  const min = momentActualStart.get('minute');
                  const time = acc.lines.find((hourLine) => hourLine.hour === actualStartHour);
                  if (time) {
                    time.text = item.description;
                    return acc;
                  }
                  // Assumption - Viking won't start events at midnight on cruises
                  if (actualStartHour) {
                    acc.lines.push({
                      actualStartHour,
                      min,
                      text: item.description,
                    });
                  }
                  return acc;
                }

                // map events to correct calendarItem structure
                let momentDisplayStart = momentActualStart.clone();
                let momentDisplayEnd = momentActualEnd.clone();
                const momentDayDate = moment(date);

                // if the start or end time of the event is outside of the visible lines on the page,
                // adjust the display start/end position so it stays within the visible window.
                if (actualEndHour > visibleHoursEnd || !momentDisplayEnd.isSame(momentDayDate, 'date')) {
                  momentDisplayEnd = moment(date).hour(visibleHoursEnd - 1).minutes(0);
                }

                if (actualStartHour < visibleHoursStart || !momentDisplayStart.isSame(momentDayDate, 'date')) {
                  momentDisplayStart = moment(date).hour(visibleHoursStart).minutes(0);
                }

                const displayDurationMinutes = momentDisplayEnd.diff(momentDisplayStart, 'minutes');
                const displayStartHour = momentDisplayStart.get('hour');
                const displayStartMinutes = momentDisplayStart.get('minutes');
                const displayEndMinutes = momentDisplayEnd.get('minutes');

                // calculate the pixel location of the starting hour
                const hour = (displayStartHour - visibleHoursStart) * CALENDAR_ITEM_PIXELS_PER_HOUR;
                const minutes = (displayStartMinutes / 60) * CALENDAR_ITEM_PIXELS_PER_HOUR;

                let START_OFFSET = 0;
                let END_OFFSET = 0;
                // if it starts or ends at the top of the hour,
                // we offset by a little bit to it's not directly on the line
                if (!displayStartMinutes) {
                  START_OFFSET = HOUR_POSITION_OFFSET_PX;
                }
                if (!displayEndMinutes) {
                  END_OFFSET = HOUR_POSITION_OFFSET_PX;
                }

                const height = displayDurationMinutes * PIXELS_PER_MINUTE - START_OFFSET - END_OFFSET;
                const top = hour + minutes + START_OFFSET;
                const isInCart = item.inventoryCode && (item.isInCartPax1 || item.isInCartPax2);

                let button = null;
                if (isInCart) {
                  button = {
                    text: get(labels, 'buttons.checkout', ''),
                  };
                }

                let displayTime = `${momentActualStart.format('LT')}`;
                if (momentActualEnd.isValid()) {
                  if (momentActualEnd.isSame(momentActualStart, 'date')) {
                    if (momentActualEnd.isValid()) {
                      displayTime = `${momentActualStart.format('LT')} - ${momentActualEnd.format('LT')}`;
                    }
                  } else {
                    displayTime = `${momentActualStart.format('MMMM D, h:mm a')} - ${momentActualEnd.format('MMMM D, h:mm a')}`;
                  }
                }

                const calendarItem = {
                  style: {
                    height,
                    top,
                  },
                  displayTime,
                  button,
                  data: item,
                  isLoading: (i) => eventEntryHasState(calendarModalStatus, LOADING, item.serviceCode, i),
                  hasError: (i) =>
                    (eventEntryHasState(calendarModalStatus, ERROR, item.serviceCode, i) &&
                      errors.SomethingWentWrong) ||
                    (eventEntryHasState(calendarModalStatus, ERROR_RESERVATION, item.serviceCode, i) &&
                      errors.ReservationBeingUpdated),
                };
                acc.events.push(calendarItem);
                return acc;
              },
              {
                events: [],
                lines: initialHours,
              }
            );

            let { events } = items;
            const { lines } = items;

            if (isExpeditionLanding) {
              events = events.filter((item) => item?.data?.extensionType !== EXTENSION_TYPES.SHOREX);
            }

            return {
              events,
              lines,
              date: dayItinerary.date,
            };
          })
      );
    }),
    getAllCards: new Selector(({ getCalendarItems }) =>
      createSelector(
        [getItinerary, getLabels, getCalendarItems, getPassengers, getIsUKAUNZ],
        (itineraries, labels, calendarItems, passengers, isUKAUNZ) => {
          const getMatchingSpaItem = (spaItems, serviceCode, startTime) => {
            return spaItems.find(
              (a) =>
                a.extensionType === RESERVATION_TYPES.SPA && a.serviceCode === serviceCode && a.startTime === startTime
            );
          };
          const findAndMapCalendarItems = (date, isExpeditionLanding) => {
            const items = get(
              calendarItems.find(({ date: itineraryDate }) => itineraryDate === date),
              'items',
              []
            ).reduce((acc, item) => {
              const matchingSpaItem = getMatchingSpaItem(acc, item.serviceCode, item.startTime);

              if (matchingSpaItem) {
                matchingSpaItem.forPassenger1 = true;
                matchingSpaItem.forPassenger2 = true;
              } else {
                acc.push(item);
              }

              return acc;
            }, []);
            const mappedItems = items.map((item) => {
              const passengerNames = [];
              [0, 1].forEach((index) => {
                const forPassenger = get(item, `forPassenger${index + 1}`, false);
                if (forPassenger) {
                  const name = get(passengers, `[${index}].firstName`, '').toUpperCase();
                  passengerNames.push(name);
                }
              });
              const names = passengerNames.length ? ` (${passengerNames.join(', ')})` : '';
              let itemButtonProps;
              const listing = {
                startTime: item.startTime,
                time: moment(item.startTime).format('LT'),
              };
              // filter for dining reservations
              if (item.extensionType === EXTENSION_TYPES.DINE) {
                return {
                  ...listing,
                  description: `${item.description}`,
                };
              }
              if (isExpeditionLanding && item.extensionType === EXTENSION_TYPES.SHOREX) {
                return {};
              }

              const isInCart = item.inventoryCode && (item.isInCartPax1 || item.isInCartPax2);
              if (isInCart) {
                itemButtonProps = {
                  text: get(labels, 'buttons.checkout', ''),
                  onButtonClick: goTo(APP_PATHS.CART),
                };
              }
              return {
                ...listing,
                description: `${item.description}${names}`,
                itemButtonProps,
              };
            });
            return sortBy(mappedItems, ['startTime']);
          };
          return itineraries.map(({ dayValue, description, date, images = [], isExpeditionLanding }) => ({
            date,
            id: date,
            day: dayValue,
            title: description,
            displayDate: moment(date).format(isUKAUNZ ? 'dddd, DD MMMM' : 'dddd, MMMM DD'),
            items: findAndMapCalendarItems(date, isExpeditionLanding),
            images: getCardThumbnailObject(images),
          }));
        }
      )
    ),
    getUpsellItemsData: new Selector(({ getUpsellItems }) =>
      createSelector([getUpsellItems, getVoyageType], (upsellItems, voyageType) =>
        memoize((date) => {
          const filteredUpsellItems = upsellItems.filter(
            (item) =>
              !!item.images && (voyageType !== VOYAGE_TYPE.RIVER || item.reference !== UPSELL_BAR_TILE.ADD_DINING)
          );
          const items = filteredUpsellItems.map(
            ({ callToActionTitle, callToActionUrl, images, reference, voyageType }) => {
              let ctaUrl = callToActionUrl;
              if (reference === 'upsellBarAddShoreExcursionsTile') {
                ctaUrl = `${callToActionUrl}/${date}`;
              }
              return {
                alt: findImageAlt(images, TWO_BY_ONE),
                text: callToActionTitle,
                url: ctaUrl,
                image: findImageSrc(images, TWO_BY_ONE),
                reference,
                voyageType,
              };
            }
          );
          return items;
        })
      )
    ),
    getModalToOpen: (state) => get(state, 'calendar.modalToOpen'),
    getExpeditionsLandingDayNotification: new Selector(({ getTabContent }) =>
      createSelector([getItinerary, getTabContent, getVoyageType], (itineraries, tabContent, voyageType) =>
        memoize((date) => {
          let bannerNotification = null;
          const filteredItinerary = itineraries.filter((item) => item.date === date);
          if (voyageType === VOYAGE_TYPE.EXPEDITION && filteredItinerary[0]?.isExpeditionLanding) {
            const items = get(tabContent('itinerary'), 'sections[0].items', []);
            const disclaimerMessage = getCalendarMessage(items, 'vxpShorexDisclaimer');
            bannerNotification = {
              alertText: disclaimerMessage.title,
              id: disclaimerMessage.reference,
            };
          }

          return {
            bannerNotification,
          };
        })
      )
    ),
  },
});

const { getStoreState } = calendarStore.selectors;
const { receiveTabContent, receiveCalendarItems } = calendarStore.creators;

export const fetchCalendarItems = (refreshData = false, updateImmediately = true, fromHolding) => (
  dispatch,
  getState
) => {
  const state = getState();
  if (fromHolding) {
    const heldData = get(getStoreState(state), 'holding.calendarItems');
    return dispatch(receiveCalendarItems(heldData));
  }
  const bookingDetails = getBookingDetails(state);
  const { comboBookings, voyage, cruise } = bookingDetails;
  const params = {};
  const voyages =
    comboBookings.length > 0
      ? comboBookings.map(({ voyageId, cruiseName, voyageType }) => ({
          id: voyageId,
          name: cruiseName,
          type: voyageType,
        }))
      : [{ id: voyage?.id, name: cruise?.name, type: voyage?.type }];
  if (voyages.length) {
    params.voyages = window.btoa(JSON.stringify(voyages));
  }

  const voyageType = getVoyageType(state);
  const extensionDataUrl = `${buildUrl(
    '/calendar',
    ['office', 'currency', 'bookingLiteral', 'bookingNumber', 'voyage.id', 'voyageType'],
    {
      ...bookingDetails,
      voyageType,
      bookingLiteral: 'booking',
    },
    params
  )}`;
  return dispatch(
    getData({
      url: extensionDataUrl,
      store: calendarStore,
      node: 'calendarItems',
      creator: receiveCalendarItems,
      refreshData,
      updateImmediately,
    })
  ).then((calendarResponse) => {
    const { advisoryCode } = calendarResponse?.data ? calendarResponse?.data : {};
    if (['50559', '50560'].includes(advisoryCode)) {
      dispatch(setOopsPageError(OOPS_PAGE_ERRORS.CALENDAR_REBUILDING));
      navigateTo(APP_PATHS.OOPS_PAGE);
    }
  });
};

export const fetchItineraryContent = (page, refreshData = false) => (dispatch, getState) => {
  const voyageType = getVoyageType(getState());
  const url = buildUrl(`/pages/${page}`, ['voyageType'], { voyageType });
  dispatch(
    getData({
      url,
      store: calendarStore,
      node: `${page}.content`,
      creator: receiveTabContent,
      tab: page,
      refreshData,
    })
  );
};

export default calendarStore;

import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting } from 'redux-form';
import { userStore } from '../../common';
import commonStore, {
  getCardModalByPageName,
  handleDeepLinkModal,
  modalAddToCart,
  requestRefund,
} from '../../common/CommonStore';
import { FLAGS, FLAG_NAMES, FORMS, MODAL_URL_VARIABLE_NAMES, PAGE_NAMES } from '../../common/Constants';
import modalStore from '../../common/ModalStore';
import { withContent } from '../../components';
import BeforeYouGo from './BeforeYouGo';
import beforeYouGoStore, { fetchBeforeYouGoPageContent } from './BeforeYouGoStore';

const {
  getTitle,
  getSubtitle,
  isLoadingPage,
  getBeforeYouGoModalCards,
  getCustomizableSections,
  getMobileAppModalData,
} = beforeYouGoStore.selectors;
const { updateReservationModalState } = commonStore.creators;
const {
  getFetchingCommonContent,
  getLabels,
  getPaymentsAllEnabled,
  getPaymentsCheckoutEnabled,
} = commonStore.selectors;
const { getBookingDetails, getLinkQueryParams, showTPCard } = userStore.selectors;
const { clearModal } = modalStore.creators;

const mapStateToProps = (state) => {
  const content = getCustomizableSections(state);
  const modalPageName = get(getLinkQueryParams(state), MODAL_URL_VARIABLE_NAMES.BYG_CODE, '');
  const modalTyperFunction = (card) => card.primaryButtonUrl.replace('#', '');
  return {
    content,
    displayCruiseInfo: FLAGS.find(({ name }) => name === FLAG_NAMES.CRUISE_INFO).isActive,
    isLoading: isLoadingPage(state),
    isSubmitting: isSubmitting(FORMS.RESERVATION)(state),
    mobileAppModalData: getMobileAppModalData(state),
    modalData: getBeforeYouGoModalCards(state),
    modalToOpen: getCardModalByPageName(content?.sections, modalPageName, modalTyperFunction),
    showTPCard: showTPCard(state),
    subtitle: getSubtitle(state),
    title: getTitle(state),
    voyageId: getBookingDetails(state)?.voyage.id,
    isFetchingCommonContent: getFetchingCommonContent(state),
    isPaymentsDisabled: !getPaymentsAllEnabled(state) || !getPaymentsCheckoutEnabled(state),
    tabLabels: get(getLabels(state), `pages.${PAGE_NAMES.GUEST_INFORMATION}.tabs`, {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchBeforeYouGoPageContent()),
  handleCardDetailsClicked: (type, id) => dispatch(handleDeepLinkModal(type, id, MODAL_URL_VARIABLE_NAMES.BYG_CODE)),
  addToCart: () => dispatch(modalAddToCart(beforeYouGoStore, '', fetchBeforeYouGoPageContent)),
  requestRefund: (extensionType, inventoryCode) =>
    dispatch(
      requestRefund({
        extensionType,
        inventoryCode,
        refreshFunction: fetchBeforeYouGoPageContent,
        updateModalStateFunction: updateReservationModalState,
      })
    ),
  updateReservationModalState: (state) => dispatch(updateReservationModalState(state)),
  closeModal: () => dispatch(clearModal()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(BeforeYouGo);

import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from '..';
import { SSBP_PRICING } from '../../common/Constants';
import { prepareHtml, replaceCMSTokenWithValue } from '../../common/Utils';
import PropTypes from '../../PropTypes';

const InformationModal = ({
  id,
  title,
  printLabel,
  sections,
  longDescription,
  onClose,
  onPrint,
  modalClassName,
  currency,
}) => {
  const ssbpPrice = SSBP_PRICING[currency] || SSBP_PRICING.USD;
  const remappedSections = sections?.map((section) => ({
    ...section,
    description: replaceCMSTokenWithValue(section.description, [{ key: 'ssbppricing', value: ssbpPrice }], '{', '}'),
  }));

  return (
    <Modal id={id} printLabel={printLabel} onClose={onClose} onPrint={onPrint}>
      <ModalBody className={modalClassName}>
        <>
          <ModalHeader description={longDescription} title={title} />
          <br />
          {remappedSections?.map(({ description, heading, id, subhead }, index) => (
            <Fragment key={id || index}>
              {heading && <h3 dangerouslySetInnerHTML={prepareHtml(heading)} />}
              {subhead && <h4 dangerouslySetInnerHTML={prepareHtml(subhead)} />}
              {description && (
                <>
                  <div className="mt-2" dangerouslySetInnerHTML={prepareHtml(description)} />
                  <hr />
                </>
              )}
            </Fragment>
          ))}
        </>
      </ModalBody>
    </Modal>
  );
};

InformationModal.propTypes = {
  printLabel: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      longText: PropTypes.string,
    })
  ),
  ...PropTypes.modalData,
};

InformationModal.defaultProps = {
  printLabel: '',
  sections: [],
};

export default InformationModal;

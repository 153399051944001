import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import commonStore from '../../common/CommonStore';
import {
  FEATURE_RESTRICTED,
  MODAL_BUTTONS,
  START_GUIDE_BUTTONS,
  START_GUIDE_REFERENCES,
  USER_TYPES,
} from '../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../common/ModalStore';
import userStore from '../../common/UserStore';
import { sortByField } from '../../common/Utils';
import { withContent } from '../../components';
import Home from './Home';
import homeStore, { fetchHomePageContent } from './HomeStore';

const {
  getHomeCards,
  getHomePageNotifications,
  getHomePageTravelDetails,
  isLoadingPage,
  getHomeFAQsText,
  getHomeFAQsButton,
  getHomeStartGuideData,
  getPageContent,
} = homeStore.selectors;
const { setQueryParams } = userStore.creators;
const { getFeatureRestricted, getIsCloseToDepartureDate, getUserType } = userStore.selectors;
const { getOpenStartGuideValue, getFirstTimeLogIn, getIsBookingsMadeViaTA, getMvjStrings } = commonStore.selectors;
const { setOpenStartGuide, setFirstTimeLogIn } = commonStore.creators;

const {
  creators: { clearModal },
  selectors: { getModalView },
} = modalStore;

const mapStateToProps = (state) => {
  const isBookingsMadeViaTA = getIsBookingsMadeViaTA(state);
  const homeStartGuideData = getHomeStartGuideData(state);
  const firstTimeLogIn = getFirstTimeLogIn(state);
  const homeStartGuideWelcomeModal = homeStartGuideData.find(
    (item) => item?.reference === START_GUIDE_REFERENCES.WELCOME_TO_MVJ
  );
  const openStartGuideValue = getOpenStartGuideValue(state);
  const featureRestricted = getFeatureRestricted(state);

  const homeStartGuideWelcomeModalData = {
    id: homeStartGuideWelcomeModal?.reference,
    title: homeStartGuideWelcomeModal?.title,
    message: homeStartGuideWelcomeModal?.subtitle,
    buttons: [
      {
        id: MODAL_BUTTONS.CANCEL,
        text: START_GUIDE_BUTTONS.SKIP,
      },
      {
        id: MODAL_BUTTONS.CONFIRM,
        text: START_GUIDE_BUTTONS.START,
      },
    ],
  };

  const homeStartGuideFinishModal = homeStartGuideData.find(
    (item) => item?.reference === START_GUIDE_REFERENCES.THANK_YOU
  );
  const homeStartGuideFinishModalData = {
    id: homeStartGuideFinishModal?.reference,
    title: homeStartGuideFinishModal?.title,
    copy: homeStartGuideFinishModal?.subtitle,
    icon: 'hamburger-menu',
    buttons: [
      {
        id: MODAL_BUTTONS.CONFIRM,
        text: START_GUIDE_BUTTONS.CONTINUE,
      },
    ],
  };

  const homeStartGuideWidgetRaw = homeStartGuideData.filter((item) =>
    [
      START_GUIDE_REFERENCES.MAKE_PAYMENTS,
      START_GUIDE_REFERENCES.MAKE_PAYMENTS_TA,
      START_GUIDE_REFERENCES.BOOK_EXCURSIONS,
      START_GUIDE_REFERENCES.PRE_CRUISE_DOCUMENTS,
      START_GUIDE_REFERENCES.MANAGE_AIR_ITINERARY,
    ].includes(item.reference)
  );
  const homeStartGuideWidget = isBookingsMadeViaTA
    ? homeStartGuideWidgetRaw.filter((item) => item.reference !== START_GUIDE_REFERENCES.MAKE_PAYMENTS)
    : homeStartGuideWidgetRaw.filter((item) => item.reference !== START_GUIDE_REFERENCES.MAKE_PAYMENTS_TA);

  const homeStartGuideWidgetSorted = homeStartGuideWidget.map((item, i) => {
    const buttons =
      i !== 0
        ? [
            {
              id: MODAL_BUTTONS.CANCEL,
              text: START_GUIDE_BUTTONS.PREVIOUS,
            },
            {
              id: MODAL_BUTTONS.CONFIRM,
              text: START_GUIDE_BUTTONS.NEXT,
            },
          ]
        : [
            {
              id: MODAL_BUTTONS.CONFIRM,
              text: START_GUIDE_BUTTONS.NEXT,
            },
          ];
    let next = '';
    let previous = '';
    let id = '';
    if (
      item.reference === START_GUIDE_REFERENCES.MAKE_PAYMENTS ||
      item.reference === START_GUIDE_REFERENCES.MAKE_PAYMENTS_TA
    ) {
      id = 1;
      next = 2;
    }
    if (item.reference === START_GUIDE_REFERENCES.BOOK_EXCURSIONS) {
      previous = 1;
      next = 3;
      id = 2;
    }
    if (item.reference === START_GUIDE_REFERENCES.MANAGE_AIR_ITINERARY) {
      previous = 2;
      next = 4;
      id = 3;
    }
    if (item.reference === START_GUIDE_REFERENCES.PRE_CRUISE_DOCUMENTS) {
      previous = 3;
      id = 4;
    }
    return {
      id,
      title: item.title,
      message: item.subtitle,
      buttons,
      next,
      previous,
    };
  });

  const homeContent = getPageContent(state);
  let displayGettingStarted = false;
  const userType = getUserType(state);
  const viewOnly = featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY;
  const currentModal = getModalView(state);
  if (!viewOnly && userType === USER_TYPES.CONSUMER && homeContent?.loaded && firstTimeLogIn && !currentModal) {
    displayGettingStarted = true;
  }
  sortByField(homeStartGuideWidgetSorted, 'id');
  const queueCards = window?.target_MVJ_Carousel?.queue || [];
  const carouselCards =
    queueCards.length > 0 ? queueCards : get(getMvjStrings(state), 'labels.pages.home.carouselCards', {});

  return {
    cards: getHomeCards(state),
    pageNotifications: getHomePageNotifications(state),
    homeFAQsText: getHomeFAQsText(state),
    homeFAQsButton: getHomeFAQsButton(state),
    travelDetails: getHomePageTravelDetails(state),
    isLoading: isLoadingPage(state),
    guideModalsArr: homeStartGuideWidgetSorted,
    homeStartGuideFinishModalData,
    homeStartGuideWelcomeModalData,
    openStartGuideValue,
    firstTimeLogIn,
    displayGettingStarted,
    carouselCards,
    isCloseToDeparture: getIsCloseToDepartureDate(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  openGuideModal: (id) => dispatch(setViewAndShowModal(id)),
  setFirstTimeLogIn: (firstTimeLogInValue) => dispatch(setFirstTimeLogIn(firstTimeLogInValue)),
  setOpenStartGuide: (openStartGuideValue) => dispatch(setOpenStartGuide(openStartGuideValue)),
  fetchContent: () => {
    dispatch((_, getState) => {
      const state = getState();
      const userType = getUserType(state);
      const featureRestricted = getFeatureRestricted(state);
      dispatch(fetchHomePageContent());
      if (userType === USER_TYPES.CONSUMER && !featureRestricted) {
        dispatch(setViewAndShowModal(START_GUIDE_REFERENCES.WELCOME_TO_MVJ));
      }
    });
  },
  setQueryParams: (queryParams) => dispatch(setQueryParams(queryParams)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Home);

import { logAdvisoryCode } from './index';
import { APP_PATHS, OOPS_PAGE_ADVISORY_CODES, OOPS_PAGE_ERRORS } from '../Constants';
import commonStore from '../CommonStore';
import userStore, { signOut } from '../UserStore';
import history from '../history';

export const handleAdvisoryCode = ({ advisoryCode, url, message, requestConfig = {} }) => {
  const { headers: { traceparent, tracestate } = {} } = requestConfig;
  logAdvisoryCode({
    advisoryCode,
    url,
    message,
    logData: {
      traceparent,
      tracestate,
    },
  });
};

export const handleErrorResponse = (response, state, dispatch, url, error, requestConfig) => {
  const { isModalOpen } = commonStore.selectors;
  const { handleSuccessfulCartUpdate } = commonStore.creators;
  const { getLoggedInUser } = userStore.selectors;
  const { setOopsPageError } = userStore.creators;
  const clearCartAndRedirect = (status) => {
    // Handle resetting the cart when an error occurs to ensure new cart data is fetched
    // after error and navigating back.
    let redirectPage = APP_PATHS.OOPS_PAGE;
    if (status === 503) {
      redirectPage = APP_PATHS.MAINTENANCE;
    }
    dispatch(handleSuccessfulCartUpdate());
    history.push(redirectPage);
  };

  const { passengerNumber } = getLoggedInUser(state);
  const { status, advisoryCode, message } = response?.data || {};

  if (advisoryCode) {
    handleAdvisoryCode({ advisoryCode, url, message, requestConfig });
  }

  const isPageError = OOPS_PAGE_ADVISORY_CODES.includes(advisoryCode) && !url.includes('payment/checkoutCart');

  if (!requestConfig.suppressError && !isModalOpen(state)) {
    if (!response || !response?.data) {
      if (!passengerNumber) {
        dispatch(signOut());
        history.push(APP_PATHS.SYSTEM_MAINTENANCE);
      } else {
        clearCartAndRedirect();
      }
    } else if (isPageError) {
      if (!window.location.href.includes(APP_PATHS.OOPS_PAGE)) {
        dispatch(setOopsPageError(OOPS_PAGE_ERRORS.AEM_ENDPOINT_DOWN));
        clearCartAndRedirect();
      }
    } else {
      switch (status) {
        case 205: {
          return {
            isSuccessful: false,
            status,
            data: response?.data
          };
        }
        case 401:
        case 503: {
          if (!window.location.href.includes(APP_PATHS.MAINTENANCE)) {
            clearCartAndRedirect(status);
          }
          break;
        }
        case 504: {
          if (!window.location.href.includes(APP_PATHS.OOPS_PAGE)) {
            clearCartAndRedirect();
          }
          break;
        }
        default:
      }
    }
  }
  return null;
};

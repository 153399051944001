/* eslint-disable no-param-reassign */
import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import memoize from 'lodash/memoize';
import moment from 'moment';
import { change, formValueSelector, reset } from 'redux-form';
import { createSelector } from 'reselect';
import commonStore, { history } from '../../common';
import { initializeBookingData } from '../../common/Analytics';
import { deleteData, getData, postData, putData } from '../../common/Api';
import {
  ACH,
  ACH_TYPES,
  ACH_UNAVAILABLE_DAYS,
  ADDONS_CHECKOUT,
  ADD_NEW_ACCOUNT,
  AIR_CHECKOUT,
  API_HOST,
  APP_PATHS,
  BOOKING_STATUSES,
  BOOKING_TYPE,
  CART_ITEMS,
  COUNTRIES,
  CREDIT_CARD,
  CREDIT_CARD_TYPE,
  CRUISE_CHECKOUT,
  DAYS_TO_GO,
  DEBIT_CARD,
  DEFAULT_DATE_FORMAT,
  ENVIRONMENT_CODE,
  EVO_USER_TYPES,
  EXTENSION_TYPES,
  FEATURE_RESTRICTED,
  FIND_COUNTRY_MAPPING,
  FIVE_BY_FOUR,
  FORMS,
  FORM_FIELD_NAMES,
  MODALS,
  MVJ_FLAG_VARIABLES,
  NOTIFICATION_TYPES,
  PAGE_NAMES,
  PAYMENT_ACTION_TYPE,
  PAYMENT_FORMS,
  PAYMENT_METHOD_CHANGE_TYPE,
  PMT_REC_COUNT,
  PSD2_PAYMENT_COUNTRIES,
  REGIONAL_LONG_DATES,
  SCHEDULED_PAYMENT_STATE,
  SCHEDULE_PAYMENT,
  TAB_NAMES,
  TIMER_STATUS,
  TWO_BY_ONE,
  USER_TYPES
} from '../../common/Constants';
import { institutionTransitMask, postalCodeMask } from '../../common/forms/Masks';
import { numbersOnly } from '../../common/forms/Normalizations';
import { normalizeCountryCode } from '../../common/forms/Validations';
import modalStore, { setViewAndShowModal } from '../../common/ModalStore';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import userStore, { fetchBookings, fetchCart, reloadBookings } from '../../common/UserStore';
import {
  buildUrl,
  decodeCountryCodeFromCurrency,
  findImageSrc,
  formatMoney,
  getCardThumbnailObject,
  getCmsLabel,
  getCmsLabelLink,
  getEvoErrorMessage,
  getFormFieldValue,
  getPageTabUrl,
  getPassengerAbbrevName,
  getPaymentBrowserDetails,
  getTabReference,
  getWasTimeout,
  goTo,
  isCreditCardExpired,
  isFloatZero,
  isNumeric,
  mapStatesToOptions,
  navigateTo,
  replaceCMSTokenWithValue
} from '../../common/Utils';
import beforeYouGoStore from '../beforeYouGo/BeforeYouGoStore';
import { fetchNotifications } from '../notifications/NotificationsStore';
import onboardStore from '../onboard/OnboardStore';
import { deleteSinglePaxShoreExFromCart } from '../shorex/ShorexStore';
import travelBookingStore from '../travelBooking/TravelBookingStore';

const { CHECKOUT_CART, PAYMENTS_CART } = PAGE_NAMES;
const { ADDONS, CRUISE_PAYMENTS, PAYMENT_METHODS, ONBOARD_CREDIT_CARD } = TAB_NAMES;

const { getBookingDetailsContent } = travelBookingStore.selectors;
const {
  creators: { handleSuccessfulCartUpdate, setSubmitFlag, trackCallCount },
  selectors: {
    getCountryStates,
    getErrors,
    getEvolutionErrors,
    getFlagValue,
    getHasOnboardCreditCard,
    getLabels,
    getPageTabLabels,
    getProperty,
    getSubmitting,
    getUpdateBookingData,
    getPaymentsAllEnabled,
    getPaymentsCheckoutEnabled,
    getMvjProperties,
    getMvjStrings,
    getTabUrl,
    getIsCurrentCountryPaymentBlocked,
    getIsUKAUNZ,
    getTrackedCalls,
    getAllowScheduledPayment,
    getCountriesPopularFirst,
  },
} = commonStore;
const {
  selectors: { getModalData, getModalIsActive },
  creators: { clearModal },
} = modalStore;
const {
  getBalanceDue,
  getBookingDetails,
  getCartId,
  getPassengers,
  getPassenger,
  getCountryCodeFromCurrency,
  getIdToken,
  getUpdateUserData,
  getEmail,
  getDaysToGo,
  getFeatureRestricted,
  getLoggedInUser,
  getProtectionAmount,
  getSessionId,
  getIsTaAccount,
  getIsViewOnly,
  getBookingStatus,
  getIsCloseToDepartureDate,
  getBalanceDueDate,
  getUserType,
} = userStore.selectors;

const { getBeforeYouGoModalCards } = beforeYouGoStore.selectors;
const { getDiningBeverageModalCards } = onboardStore.selectors;

export const getTabNameFromCheckoutType = (type) => (type === ADDONS_CHECKOUT ? 'checkoutCart' : 'checkoutBooking');

export const getCruisePaymentObject = ({
  headerLabel,
  headerValue,
  date,
  lineItems = [],
  wrap = true,
  countryCode,
}) => ({
  header: {
    label: `${headerLabel} ${date ? moment(date).format(DEFAULT_DATE_FORMAT.NA_SLASH) : ''}`.trim(),
    value: isNumeric(headerValue) ? formatMoney(headerValue, 2, countryCode) : '',
    wrap,
  },
  lineItems: lineItems.map((item) => {
    const { value } = item;
    return {
      ...item,
      value: isNumeric(value) ? formatMoney(value, 2, countryCode) : '',
    };
  }),
});

export const getCartItemDescription = (item, passengers, itinerary) => {
  const { forPassenger, subtitle: extraText, extensionType } = item;
  const forPassengerDict = _keyBy(forPassenger, 'id');
  // `forPassenger` is an array of `{ id: 1, inCart: true/false }` objects
  // Only return passengers that are both "in the cart" _and_ have a matching id in this array
  const resultingPassengerNames = passengers.reduce((acc, passenger) => {
    const { passengerNumber } = passenger;
    const forPassDictEntry = forPassengerDict[passengerNumber];
    if (forPassengerDict && forPassDictEntry.inCart) {
      acc.push(getPassengerAbbrevName(passenger));
    }
    return acc;
  }, []);

  if (extraText) {
    resultingPassengerNames.push(extraText);
  }
  if ([EXTENSION_TYPES.SHOREX, EXTENSION_TYPES.DINE, EXTENSION_TYPES.SPA].includes(extensionType)) {
    const date = itinerary.journeyDays.find(
      (day) => day.date === moment(item.startTime, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
    );
    const formattedCruiseDay = `${date?.dayValue} \u2013 ${date?.description}`;
    resultingPassengerNames.push(formattedCruiseDay);
  }

  return resultingPassengerNames;
};

export const getJourneyAddonsArray = ({
  items = [],
  passengers = [],
  removeText = '',
  countryCode,
  itinerary,
  lockedDownMessage,
  removingId,
  viewOnly,
}) => {
  const condensedItems = items.reduce((acc, item) => {
    const comboItem = acc.find(
      ({ inventoryCode }) =>
        item.inventoryCode === inventoryCode && item.extensionType === EXTENSION_TYPES.GRATITUDE_OR_SSBP
    );
    if (comboItem) {
      if (!comboItem.comboData) {
        comboItem.comboData = [
          {
            itemID: comboItem.itemID,
            pricePerPassenger: comboItem.pricePerPassenger,
            totalItemPrice: comboItem.totalItemPrice,
            voyageId: comboItem.voyageId,
          },
        ];
      }
      comboItem.comboData.push({
        itemID: item.itemID,
        pricePerPassenger: item.pricePerPassenger,
        totalItemPrice: item.totalItemPrice,
        voyageId: item.voyageId,
      });
      delete comboItem.itemID;
      comboItem.pricePerPassenger += item.pricePerPassenger;
    } else {
      acc.push({ ...item });
    }
    return acc;
  }, []);
  return {
    lockedDownMessage,
    items: condensedItems.map((item) => {
      const totalCost = item.comboData
        ? item.comboData.reduce((acc, comboItem) => acc + comboItem.totalItemPrice, 0)
        : item.totalItemPrice;
      const removing = item.comboData
        ? !!item.comboData.filter((i) => removingId.includes(i.itemID)).length
        : removingId.includes(item.itemID);
      const imageSrc = item?.thumbnail?.length ? findImageSrc(item?.thumbnail, FIVE_BY_FOUR) : '';
      return {
        ...item,
        imageSrc,
        price: formatMoney(totalCost, 2, countryCode),
        removeButton: {
          text: removeText,
          disabled: !!removingId.length,
        },
        description: getCartItemDescription(item, passengers, itinerary),
        removing,
      };
    }),
    viewOnly,
  };
};

export const removeItemButtons = (item) => ({
  ...item,
  removeButton: {},
});

export const getJourneyPassengersItemTotal = (item) => {
  const { forPassenger, pricePerPassenger } = item;
  if (forPassenger instanceof Array && forPassenger.length === 2) {
    return (forPassenger[0].inCart + forPassenger[1].inCart) * pricePerPassenger;
  }
  return 0;
};

export const calculateACHSavings = (paymentAmount) => {
  const discount = 3.3;
  return (-1 * paymentAmount * discount) / 100;
};

export const getSectionText = (sections, reference) =>
  get(
    sections.find((section) => section.reference === reference),
    'text'
  );

export const addFieldPlaceholders = (htmlNames, fields, initialValues) => {
  const allFieldValues = Object.entries(htmlNames).reduce((acc, [key, fieldName]) => {
    const { label } = getFormFieldValue(fields, fieldName) || {};

    acc[key] = {
      ...acc[key],
      placeholder: label,
    };

    return acc;
  }, initialValues);
  return allFieldValues;
};

export const getPaymentSubmitDisabled = ({
  cardTypeValid,
  isTokenExValid,
  noPaymentDescription,
  paymentMethod,
  savedAccount,
  ukPaymentsMessage,
  updateUserInfo,
}) => {
  if (updateUserInfo?.updateUserType === EVO_USER_TYPES[USER_TYPES.CSA]) {
    return true;
  }
  if (!isTokenExValid) {
    if (paymentMethod === ACH && savedAccount !== ADD_NEW_ACCOUNT) {
      return false;
    } else if (!noPaymentDescription && !ukPaymentsMessage) {
      return true;
    }
  }
  if (!cardTypeValid && [CREDIT_CARD, DEBIT_CARD].includes(paymentMethod) && !noPaymentDescription) {
    return true;
  }
  return !paymentMethod && !noPaymentDescription && !ukPaymentsMessage;
};

const getIsNewAccountInvalid = ({ isTokenExValid, noPaymentDescription, savedAccount }) =>
  !isTokenExValid && !noPaymentDescription && savedAccount === ADD_NEW_ACCOUNT;
const getIsCreditCardInvalid = ({ cardType, isTokenExLoaded, paymentMethod }) =>
  paymentMethod === CREDIT_CARD && (!isTokenExLoaded || !cardType);

export const getPaymentSummarySubmitDisabled = ({
  button,
  cardType,
  isTokenExLoaded,
  isTokenExValid,
  noPaymentDescription,
  paymentAmountSelected,
  paymentMethod,
  savedAccount,
}) =>
  !paymentAmountSelected ||
  button.isViewOnly ||
  getIsNewAccountInvalid({ isTokenExValid, noPaymentDescription, savedAccount }) ||
  getIsCreditCardInvalid({ cardType, isTokenExLoaded, paymentMethod }); // TODO: This could use the getIsCreditCardAvailable

const paymentsStore = createPageTabsDuck('payments').extend({
  types: [
    'RECEIVE_TOKENEX_CONFIGURATION',
    'RECEIVE_TOKENEX_EVENT',
    'RECEIVE_TOKENEX_SESSION',
    'RESET_TOKENEX_METADATA',
    'RESET_TOKENEX_SESSION',
    'TOKENEX_REFRESHED',
    'RECEIVE_PAYMENT_METHODS',
    'RECEIVE_PAYMENT_CHALLENGE',
    'RECEIVE_CHALLENGE_RESPONSE',
    'RECEIVE_ONBOARD_CREDIT_CARD',
    'UPDATE_SELECTED_GUEST',
    'SET_ONBOARD_CC_VALIDATION_FLAG',
    'SET_ONBOARD_CC_SAVE_FAILED',
    'SET_SHARE_ONBOARD_CC',
    'SET_PAYMENT_METHOD_CHANGE_TYPE',
    'UPDATE_REMOVING_FLAG',
    'SET_CHECKOUT_ERROR',
    'UPDATE_CARD_TYPE_ERROR',
    'UPDATE_ADD_CARD_FLAG',
    'UPDATE_NEXT_LOCATION',
    'SET_PAYMENT_PROCESS_TIMER',
  ],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.RECEIVE_TOKENEX_CONFIGURATION:
        return {
          ...state,
          tokenEx: {
            ...state.tokenEx,
            [action.method]: {
              ...action.payload,
              loaded: true,
            },
          },
        };
      case types.RECEIVE_TOKENEX_EVENT:
        return {
          ...state,
          tokenEx: {
            ...state.tokenEx,
            meta: {
              ...state.tokenEx.meta,
              ...action.event,
            },
          },
        };
      case types.RECEIVE_TOKENEX_SESSION:
        return {
          ...state,
          tokenEx: {
            ...state.tokenEx,
            tokenExLoaded: true,
          },
        };
      case types.RESET_TOKENEX_SESSION:
        return {
          ...state,
          tokenEx: {
            ...state.tokenEx,
            tokenExLoaded: false,
            meta: null,
          },
        };
      case types.RESET_TOKENEX_METADATA:
        return {
          ...state,
          tokenEx: {
            ...state.tokenEx,
            meta: null,
          },
        };
      case types.TOKENEX_REFRESHED:
        return {
          ...state,
          tokenEx: {
            ...state.tokenEx,
            refreshed: action.refreshed,
          },
        };
      case types.RECEIVE_PAYMENT_METHODS:
        return {
          ...state,
          paymentMethods: {
            ...state.paymentMethods,
            ...action.payload,
            loaded: true,
          },
        };
      case types.RECEIVE_ONBOARD_CREDIT_CARD:
        return {
          ...state,
          onboardCreditCard: {
            ...state.onboardCreditCard,
            gifDetails: {
              ...state.onboardCreditCard.gifDetails,
              guests: action.payload,
            },
          },
        };
      case types.SET_ONBOARD_CC_VALIDATION_FLAG:
        return {
          ...state,
          onboardCreditCard: {
            ...state.onboardCreditCard,
            onboardCCValidationFlag: action.payload,
          },
        };
      case types.SET_ONBOARD_CC_SAVE_FAILED:
        return {
          ...state,
          onboardCreditCard: {
            ...state.onboardCreditCard,
            failedToSaveOnboardCCFlag: action.payload,
          },
        };
      case types.SET_SHARE_ONBOARD_CC:
        return {
          ...state,
          onboardCreditCard: {
            ...state.onboardCreditCard,
            gifDetails: {
              ...state.onboardCreditCard.gifDetails,
              shareCC: action.shareCC,
            },
            loaded: true,
          },
        };
      case types.SET_PAYMENT_METHOD_CHANGE_TYPE:
        return {
          ...state,
          paymentMethods: {
            ...state.paymentMethods,
            changeType: action.payload,
          },
        };
      case types.UPDATE_REMOVING_FLAG:
        return {
          ...state,
          addons: {
            ...state.addons,
            content: {
              ...state.addons.content,
              removing: action.itemID,
            },
          },
        };
      case types.SET_CHECKOUT_ERROR:
        return {
          ...state,
          checkoutError: action.error,
        };
      case types.SET_PAYMENT_PROCESS_TIMER:
        return {
          ...state,
          paymentProcessTimer: action.payload,
        };
      case types.UPDATE_CARD_TYPE_ERROR:
        return {
          ...state,
          cardTypeHasError: action.flag,
        };
      case types.UPDATE_ADD_CARD_FLAG:
        return {
          ...state,
          onboardCreditCard: {
            ...state.onboardCreditCard,
            addingCard: action.payload,
          },
        };
      case types.UPDATE_NEXT_LOCATION:
        return {
          ...state,
          onboardCreditCard: {
            ...state.onboardCreditCard,
            nextLocation: action.nextLocation,
          },
        };
      case types.RECEIVE_PAYMENT_CHALLENGE:
        return {
          ...state,
          paymentChallenge: {
            ...action.payload,
          },
        };
      case types.RECEIVE_CHALLENGE_RESPONSE:
        return {
          ...state,
          paymentChallenge: {
            ...state.paymentChallenge,
            challengeResponse: {
              ...action.payload,
              loaded: true,
            },
          },
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    receiveTokenExConfiguration: (method, payload) => ({
      type: types.RECEIVE_TOKENEX_CONFIGURATION,
      method,
      payload,
    }),
    receiveTokenExEvent: (event) => ({
      type: types.RECEIVE_TOKENEX_EVENT,
      event,
    }),
    resetTokenExMetadata: () => ({
      type: types.RESET_TOKENEX_METADATA,
    }),
    refreshedTokenEx: (refreshed) => ({
      type: types.TOKENEX_REFRESHED,
      refreshed,
    }),
    receivePaymentMethods: (payload) => ({
      type: types.RECEIVE_PAYMENT_METHODS,
      payload,
    }),
    receiveOnboardCC: (payload) => ({
      type: types.RECEIVE_ONBOARD_CREDIT_CARD,
      payload,
    }),
    setOnboardCCValidationFlag: (payload) => ({
      type: types.SET_ONBOARD_CC_VALIDATION_FLAG,
      payload,
    }),
    setOnboardCCSaveFailed: (payload) => ({
      type: types.SET_ONBOARD_CC_SAVE_FAILED,
      payload,
    }),
    setShareOnboardCC: (shareCC) => ({
      type: types.SET_SHARE_ONBOARD_CC,
      shareCC,
    }),
    setPaymentMethodChangeType: (payload) => ({
      type: types.SET_PAYMENT_METHOD_CHANGE_TYPE,
      payload,
    }),
    updateNextLocation: (nextLocation) => ({
      type: types.UPDATE_NEXT_LOCATION,
      nextLocation,
    }),
    updateRemovingFlag: (itemID) => ({
      type: types.UPDATE_REMOVING_FLAG,
      itemID,
    }),
    setCheckoutError: (error) => ({
      type: types.SET_CHECKOUT_ERROR,
      error,
    }),
    setPaymentProcessingTimer: (payload) => ({
      type: types.SET_PAYMENT_PROCESS_TIMER,
      payload,
    }),
    updateCardTypeError: (flag) => ({
      type: types.UPDATE_CARD_TYPE_ERROR,
      flag,
    }),
    updateAddCardFlag: (payload) => ({
      type: types.UPDATE_ADD_CARD_FLAG,
      payload,
    }),
    updateTokenExSession: () => ({
      type: types.RECEIVE_TOKENEX_SESSION,
    }),
    resetTokenExSession: () => ({
      type: types.RESET_TOKENEX_SESSION,
    }),
    receivePaymentChallenge: (payload) => ({
      type: types.RECEIVE_PAYMENT_CHALLENGE,
      payload,
    }),
    receiveChallengeResponse: (payload) => ({
      type: types.RECEIVE_CHALLENGE_RESPONSE,
      payload,
    }),
  }),
  selectors: {
    isTabLoading: new Selector(({ getStoreState }) =>
      createSelector(
        [getStoreState, (state) => getPageTabLabels(state)(PAGE_NAMES.PAYMENTS_CART)],
        (storeState, pageLabels) => (pathname) => {
          let url = pathname.split('/').pop();
          url = url === 'payments' ? '/add-ons' : `/${url}`;
          const tabs = Object.entries(pageLabels.tabs);
          const tab = tabs.find(([, data]) => url === data.url);
          if (tab && tab.length) {
            const [tabName] = tab;
            const data = get(storeState, tabName);

            if (data) {
              return Object.keys(data).some(
                (section) => get(data, `${section}.loading`, false) || !get(data, `${section}.loaded`, true)
              );
            }
            return true;
          }
          return false;
        }
      )
    ),
    isLoadingOnboardCC: new Selector((selectors) => {
      const { getStoreState, isLoadingPage, isLoadingTabs, getTabContent } = selectors;
      return createSelector(
        [
          getStoreState,
          isLoadingPage,
          isLoadingTabs,
          (state) => getTabContent(state)(ONBOARD_CREDIT_CARD),
          (state) => get(state, 'payments.onboardCreditCard.loaded'),
        ],
        (storeState, loadingPageStatus, loadingTabsStatus, { loaded }, isPreviouslyLoaded) => {
          if (isPreviouslyLoaded) {
            return false;
          }

          const isLoadingGifDetails = get(storeState, 'onboardCreditCard.gifDetails.loading');
          return !loaded || loadingPageStatus || loadingTabsStatus || isLoadingGifDetails;
        }
      );
    }),
    isAddingCreditCard: (state) => get(state, 'payments.onboardCreditCard.addingCard', false),
    getOnboardCCValidationFlag: (state) => get(state, 'payments.onboardCreditCard.onboardCCValidationFlag', false),
    getOnboardCCSaveFailed: (state) => get(state, 'payments.onboardCreditCard.failedToSaveOnboardCCFlag', false),
    getNextLocation: (state) => get(state, 'payments.onboardCreditCard.nextLocation', null),
    getOnboarDining: (state) => get(state, 'onboard.diningBeverage.content.sections[1].cards', null),
    getRegionalPaymentFields: new Selector(() =>
      createSelector([getMvjProperties, getMvjStrings], ({ bookingCountries }, { labels }) =>
        memoize((countryCode) => {
          const formCountry = bookingCountries?.find((country) => country.code === countryCode);
          const { countryConstants } = formCountry || {};

          return {
            title: `${countryCode}FIELDS`,
            fields: {
              state: labels?.forms[countryConstants?.stateProvinceLabelKey] || 'State',
              zip: labels?.forms[countryConstants?.zipPostalCodeLabelKey] || 'Zip Code',
            },
          };
        })
      )
    ),
    getPaymentAmountForOption: new Selector(() =>
      createSelector([getBookingDetails, getProtectionAmount, (state) => state], (bookingDetails, protectionAmount) =>
        memoize((option) => {
          const optionToAmountKey = {
            deposit: 'depositDue',
            fullPayment: 'totalDue',
            minimumDue: 'minimumDue',
            totalDue: 'totalDue',
            grossPrice: 'grossPrice',
          };
          let amount;
          if (option === 'deposit') {
            let depositAmount = get(bookingDetails, `payments.${optionToAmountKey.deposit}`, '');
            const grossAmount = get(bookingDetails, `payments.${optionToAmountKey.grossPrice}`, '');
            depositAmount = depositAmount === grossAmount ? depositAmount : depositAmount + protectionAmount;
            amount = Math.min(depositAmount, get(bookingDetails, 'payments.totalDue'));
          } else if (option === 'minimumDue' && bookingDetails.bookingStatus === BOOKING_STATUSES.CONFIRMED) {
            amount = 0;
          } else {
            amount = Math.min(
              get(bookingDetails, `payments.${optionToAmountKey[option]}`, ''),
              get(bookingDetails, 'payments.totalDue')
            );
          }
          return amount;
        })
      )
    ),
    getPaymentCheckoutBackButton: new Selector(({ getTabs }) =>
      createSelector(getTabs, (getTabAttributes) =>
        memoize((path) => {
          const tabs = getTabAttributes();
          if (!tabs || !Array.isArray(tabs) || isEmpty(tabs) || !path) {
            return undefined;
          }

          const { title = '', url = '/' } = tabs.find((tab) => path.includes(tab.url)) || {};
          return {
            label: title,
            previousPagePath: `${APP_PATHS.PAYMENTS}${url}`,
          };
        })
      )
    ),
    getSelectedPaymentAmountOption: (state) => formValueSelector(FORMS.PAYMENT_CHECKOUT)(state, 'paymentAmountOption'),
    getAllCalendarItems: (state) => get(state, 'calendar.calendarItems.itinerary', []),
    getTokenEx: (state) => get(state, 'payments.tokenEx', {}),
    getTokenExConfiguration: new Selector(({ getTokenEx }) =>
      createSelector(getTokenEx, (config) => memoize((paymentMethod) => (config ? config[paymentMethod] : null)))
    ),
    getTokenExMetaData: new Selector(({ getTokenEx }) =>
      createSelector([getTokenEx, getErrors], ({ meta } = {}, errors) => {
        const errorMessages = {};
        const errorKeys = {
          [ACH]: 'BankAccountNumberInvalid',
          [CREDIT_CARD]: 'CardNumberRequired',
          cardTypeInvalid: 'SelectCardTypeInvalid',
        };
        Object.entries(errorKeys).forEach(([key, errorCode]) => {
          errorMessages[key] = errors[errorCode];
        });

        const defaultMetadata = {
          active: false,
          error: true,
          errorMessages,
          pristine: true,
          touched: false,
        };

        if (!meta) {
          return defaultMetadata;
        }

        const { active, touched, validate = {}, possibleCardType } = meta;
        const { cardType, firstSix, isValid, validator } = validate;

        return {
          ...defaultMetadata,
          active,
          cardType,
          error: !isValid,
          firstSix,
          pristine: !validator || validator === 'required',
          touched,
          possibleCardType,
        };
      })
    ),
    getIsTokenExTouched: (state) => get(state, 'payments.tokenEx.meta.touched', false),
    getIsTokenExValid: (state) => get(state, 'payments.tokenEx.meta.validate.isValid', false),
    getIsTokenExLoaded: (state) => get(state, 'payments.tokenEx.tokenExLoaded', false),
    getIsTokenExRefreshed: (state) => get(state, 'payments.tokenEx.refreshed', false),
    getPaymentMethods: (state) => get(state, 'payments.paymentMethods.paymentOptions', []),
    getOnboardCCGuests: (state) => get(state, `payments.${FORMS.ONBOARD_CREDIT_CARD}.gifDetails.guests`, []),
    getSelectedGuest: (state) =>
      parseInt(formValueSelector(FORMS.ONBOARD_CREDIT_CARD)(state, 'nameOnCard'), 10) || null,
    getShareCCStatus: (state) => get(state, `payments.${FORMS.ONBOARD_CREDIT_CARD}.gifDetails.shareCC`, null),
    isPaymentMethodsLoaded: (state) => get(state, 'payments.paymentMethods.loaded'),
    getPaymentMethodChangeType: (state) => get(state, 'payments.paymentMethods.changeType'),
    getRemovingItemId: (state) => get(state, 'payments.addons.content.removing', []),
    getIsPaymentProcessing: (state) =>
      get(state, 'payments.addons.content.paymentProcessing', false) ||
      get(state, 'payments.cruisePayments.content.paymentProcessing', false),
    getPaymentProcessingTimer: (state) => get(state, 'payments.paymentProcessTimer', TIMER_STATUS.INIT),
    getCheckoutError: (state) => get(state, 'payments.checkoutError', {}),
    getCardTypeHasError: (state) => get(state, 'payments.cardTypeHasError', false),
    getComfortCheckInPassenger: (state) => get(state, 'documents.comfortCheckIn.passengerNumber', null),
    getDefaultSavedAccount: new Selector(({ getPaymentMethodOptions, isPaymentMethodsLoaded }) =>
      createSelector([getPaymentMethodOptions, isPaymentMethodsLoaded], (getOptions, paymentOptionsLoaded) =>
        memoize(
          (selectedAccountType, tabName) => {
            const { options = [] } = getOptions(tabName)[selectedAccountType] || {};
            if (!paymentOptionsLoaded || options.length > 1) {
              return null;
            }
            return ADD_NEW_ACCOUNT;
          },
          (...args) => JSON.stringify(args)
        )
      )
    ),
    getDepositDueInformation: new Selector(({ getFormattedCmsLabels, getPaymentAmountForOption }) =>
      createSelector(
        [getBookingDetails, getCountryCodeFromCurrency, getFormattedCmsLabels, getPaymentAmountForOption, getIsUKAUNZ],
        (
          { payments: { depositDueDate, totalAmountPaid }, bookingStatus },
          countryCode,
          cmsLabels,
          getPaymentAmount,
          isUKAUNZ
        ) => {
          const cmsLabelLength = Object.keys(cmsLabels).length;
          const totalDepositDue = getPaymentAmount('deposit');
          if (cmsLabelLength > 0) {
            return {
              depositDue:
                totalDepositDue <= 0 ||
                totalDepositDue <= totalAmountPaid ||
                bookingStatus === BOOKING_STATUSES.CONFIRMED
                  ? ''
                  : formatMoney(totalDepositDue, 2, countryCode),
              headerText: replaceCMSTokenWithValue(cmsLabels.depositDueBy, [
                {
                  key: 'DEPOSIT_DUE_DATE',
                  value: moment(depositDueDate).format(isUKAUNZ ? REGIONAL_LONG_DATES.EU : REGIONAL_LONG_DATES.NA),
                },
              ]),
            };
          }
          return {};
        }
      )
    ),
    getPassengerVouchersCreditsContent: new Selector(({ getTabContentSections }) =>
      createSelector(
        [
          getPassengers,
          getCountryCodeFromCurrency,
          (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS),
          (state) => getTabContentSections(state)(PAYMENT_METHODS),
        ],
        (passengers, countryCode, { labels: { shipboardCredits, vouchersCredits, voucher } }, sections = []) => {
          let helperText;
          if (sections?.[1]?.messages) {
            helperText = getCmsLabel(sections[1].messages, 'vouchersAndCreditsSubhead', 'text');
          }
          return {
            passengers: passengers.map(({ shipboardCredit, otherVoucherAvailable, ...passenger }) => {
              return {
                ...passenger,
                shipboardCredit: formatMoney(shipboardCredit, 2, countryCode),
                otherVoucherAvailable: formatMoney(otherVoucherAvailable, 2, countryCode),
                fullName: getPassengerAbbrevName(passenger),
              };
            }),
            labels: {
              shipboardCredits,
              vouchersCredits,
              voucher,
              helperText,
            },
          };
        }
      )
    ),
    getAvailableCreditCardTypes: new Selector(() =>
      createSelector(
        [getCountryCodeFromCurrency, (state) => getProperty(state)('creditCardTypes')],
        (country, creditCardTypes = []) => {
          const countryCode = country === 'UK' ? 'GB' : country;
          return (creditCardTypes || [])
            .filter(({ countries }) => countries?.includes(countryCode))
            .map(({ value }) => value);
        }
      )
    ),
    getIsCreditCardAvailable: new Selector(({ getAvailableCreditCardTypes }) =>
      createSelector([getAvailableCreditCardTypes], (creditCardTypes) =>
        memoize((cardType) => {
          if (cardType?.length > 2) {
            const mappedCard = Object.values(CREDIT_CARD_TYPE).find(({ tokenExCode }) => tokenExCode === cardType);
            if (mappedCard) {
              return creditCardTypes.includes(mappedCard.code);
            }
          }
          return creditCardTypes.includes(cardType);
        })
      )
    ),
    getPaymentMethodOptions: new Selector((selectors) => {
      const { getPaymentMethods, getTabContentLabels, getTabContentForm } = selectors;
      return createSelector(
        [
          getPaymentMethods,
          (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS),
          (state) => getProperty(state)('creditCardTypes'),
          getTabContentLabels,
          getTabContentForm,
        ],
        (paymentMethods, { labels }, creditCardTypes = [], defaultAccountOptions, getForm) =>
          memoize((tabName) => {
            const form = getForm(tabName, 'Payment Method') || [];
            const { fields = [] } = form.find(({ title }) => title === 'SELECT ACCOUNT') || {};

            const helperLabels = fields.reduce((acc, { htmlName, label }) => {
              if (htmlName) {
                acc[htmlName] = label;
              }
              return acc;
            }, {});

            const options = defaultAccountOptions(tabName);
            const { achSavingsLabel, achCheckingLabel } = labels;
            const defaultAch = {
              value: ADD_NEW_ACCOUNT,
              label: (options.find((o) => o.paymentType === ACH) || {}).title || '',
            };
            const defaultCC = {
              value: ADD_NEW_ACCOUNT,
              label: (options.find((o) => o.paymentType === CREDIT_CARD) || {}).title || '',
            };
            return paymentMethods.reduce(
              (acc, { ccType, maskedAccountNumber, id, accountHolderName, isCreditCard, achAccountType }) => {
                let label = [accountHolderName];
                if (isCreditCard) {
                  const cardType = creditCardTypes.find((type) => type.value === ccType);
                  if (cardType) {
                    label.push(cardType.label);
                  }
                  label.push(maskedAccountNumber);
                } else {
                  const accountType = achAccountType === ACH_TYPES.SAVINGS ? achSavingsLabel : achCheckingLabel;
                  label = label.concat([accountType, maskedAccountNumber]);
                }
                const paymentMethod = {
                  value: id,
                  label: label.join(', '),
                };
                if (!isCreditCard) {
                  acc[ACH].options.unshift(paymentMethod);
                }
                return acc;
              },
              {
                [CREDIT_CARD]: { helperLabel: helperLabels.selectCreditCard, options: [defaultCC] },
                [ACH]: { helperLabel: helperLabels.selectAccount, options: [defaultAch] },
              }
            );
          })
      );
    }),
    getPaymentAmountOptions: new Selector((selectors) => {
      const { getPaymentAmountForOption, getTabContentLabels } = selectors;
      return createSelector(
        [
          getProtectionAmount,
          getPaymentAmountForOption,
          getCountryCodeFromCurrency,
          getTabContentLabels,
          getBookingDetails,
        ],
        (protectionAmount, getPaymentAmount, countryCode, defaultAccountOptions, bookingDetails) => {
          const { bookingStatus } = bookingDetails;
          const depositAmount = getPaymentAmount('deposit');
          const fullAmount = getPaymentAmount('fullPayment');
          const minDueAmount = getPaymentAmount('minimumDue');
          const totalDue = getPaymentAmount('totalDue');
          const options = defaultAccountOptions('checkoutBooking');
          const getLabel = (reference) => getCmsLabel(options, reference, 'title');

          if (!options || !options.length) {
            return null;
          }

          const depositLabel = getLabel('deposit');
          const depositAndProtectionLabel = getLabel('depositAndTravelProtectionLabel');
          const fullLabel = getLabel('fullPayment');
          const otherLabel = getLabel('otherMinimumPaymentDueLabel');

          const paymentAmountOptions = {};
          if (depositAmount || protectionAmount) {
            const label = protectionAmount ? depositAndProtectionLabel : depositLabel;
            const amount = formatMoney(Math.min(depositAmount, totalDue), 2, countryCode);
            if (bookingStatus !== BOOKING_STATUSES.CONFIRMED) {
              paymentAmountOptions.deposit = {
                id: 'depositAmount',
                label: `${label} ${amount}`,
                value: minDueAmount === 0 ? 'deposit' : 'minimumDue',
              };
            }
          }
          paymentAmountOptions.fullPayment = {
            id: 'fullPaymentAmount',
            label: `${fullLabel}${formatMoney(fullAmount, 2, countryCode)}`,
            value: 'fullPayment',
          };
          paymentAmountOptions.other = {
            id: 'otherAmount',
            label: `${otherLabel} ${formatMoney(minDueAmount, 2, countryCode)}`,
            value: 'other',
          };
          return paymentAmountOptions;
        }
      );
    }),

    getMinDueAmount: new Selector(({ getPaymentAmountForOption }) =>
      createSelector([getPaymentAmountForOption], (getPaymentAmount) => getPaymentAmount('minimumDue'))
    ),
    getSelectedCardType: (state) => formValueSelector(FORMS.PAYMENT_CHECKOUT)(state, 'account') || {},
    getCreditCardFields: new Selector((selectors) => {
      const {
        getTabContentForm,
        getTabContent,
        getAddressFields,
        getPaymentMethodChangeType,
        getSelectedCardType,
        getTabContentSections,
        getSelectedGuest,
        getOnboardCreditCardSection,
        getIsPsd2,
        getRegionalPaymentFields,
      } = selectors;
      return createSelector(
        [
          (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS),
          getTabContentForm,
          getTabContent,
          (state) => getProperty(state)('creditCardTypes'),
          getAddressFields,
          getCountryCodeFromCurrency,
          getPaymentMethodChangeType,
          getSelectedCardType,
          getPassengers,
          (state) => getTabContentSections(state)(ONBOARD_CREDIT_CARD),
          getSelectedGuest,
          getOnboardCreditCardSection,
          getIsPsd2,
          getRegionalPaymentFields,
        ],
        (
          { buttons },
          tabContentForm,
          content,
          creditCardTypes = [],
          addressFieldsSelector,
          defaultCountry,
          changeType,
          selectedCardType,
          passengers,
          onboardCCSection,
          selectedGuest,
          onboardCreditCardData,
          isPsd2,
          regionalPaymentFields
        ) =>
          memoize(
            ({ tabName, selectedPaymentMethodId, country }) => {
              const countryCode = country || defaultCountry;
              const addressFields = addressFieldsSelector(country);
              const { ccDetailsPaxs } = onboardCreditCardData;
              const formSections =
                tabName === CREDIT_CARD
                  ? content(tabName)
                  : tabContentForm(tabName, PAYMENT_FORMS.CREDIT_CARD_INFORMATION);
              const tabContent = content(tabName);
              const messages = tabContent?.sections;
              const cvvToolTip = getCmsLabel(messages, 'cvvToolTip', 'title');
              const htmlNames = {
                addressLine1: 'address1',
                addressLine2: 'address2',
                cardNumber: 'cardnumber',
                cardType: 'cardtype',
                add: 'add',
                update: 'update',
                cancel: 'cancel',
                city: 'city',
                country: 'billingcountry',
                expiryMonth: 'expmonth',
                expiryYear: 'expyear',
                name: 'nameoncard',
                nameOnCard: 'nameoncard',
                save: 'savebox',
                removeAccount: 'removeaccount',
                state: 'state',
                zip: 'zip',
                mastercard: 'mastercardNoSave',
                discovercard: 'discovercardNoSave',
              };
              if (isPsd2) {
                htmlNames.cvv = 'cvv';
              }

              const formSection = get(formSections, '[0]', {});
              let { fields = [], title } = formSection;
              let message;
              let reference;

              if (formSections) {
                const { sections } = formSections;
                switch (changeType) {
                  case 'add': {
                    reference = 'CreditCardAddedLabel';
                    break;
                  }
                  case 'edit': {
                    reference = 'CreditCardUpdatedLabel';
                    break;
                  }
                  default: {
                    break;
                  }
                }
                message = getCmsLabel(sections, reference, 'longText');
              }
              if (tabName === CREDIT_CARD) {
                title = formSections.title || '';
                fields = get(formSections, 'forms[0].fields', []);
                title = !selectedPaymentMethodId ? title : formSections.subtitle;
              }
              const otherPassenger =
                passengers.find((passenger) => selectedGuest !== null && selectedGuest !== passenger.passengerNumber) ||
                {};
              const fullName = getPassengerAbbrevName(otherPassenger);
              let shareOnboardCCLabel;
              let bannerAlert;
              let onboardCCDisclaimer;
              if (onboardCCSection?.[0]?.messages) {
                shareOnboardCCLabel =
                  onboardCCSection[0].messages.length > 0
                    ? getCmsLabel(onboardCCSection[0].messages, 'shareMyCreditCardWithLabel', 'text')
                    : onboardCCSection[0]?.title;
                bannerAlert = getCmsLabel(onboardCCSection[0].messages, 'addOnboardccLockedDownLabel', 'text');
                onboardCCDisclaimer = getCmsLabel(onboardCCSection[0].messages, 'onboardCCDisclaimer', 'longText');
              }

              const accountFields = {
                nameOnCard: {
                  options: passengers.reduce((acc, passenger) => {
                    if (ccDetailsPaxs && !ccDetailsPaxs[passenger.passengerNumber - 1]) {
                      acc.push({
                        label: getPassengerAbbrevName(passenger),
                        value: String(passenger.passengerNumber),
                      });
                    }
                    return acc;
                  }, []),
                },
                shareCard: replaceCMSTokenWithValue(shareOnboardCCLabel, [
                  {
                    key: 'OTHER_GUEST_FIRST_NAME',
                    value: !isEmpty(otherPassenger) && Object.keys(otherPassenger).length ? fullName : '',
                  },
                ]),
                bannerAlert,
                onboardCCDisclaimer,
                ...addressFields,
              };
              const creditCardFields = addFieldPlaceholders(htmlNames, fields, accountFields);

              const regionalFields = regionalPaymentFields(normalizeCountryCode(country, COUNTRIES.UNITED_KINGDOM));
              creditCardFields.state.placeholder = regionalFields.fields.state || FORM_FIELD_NAMES.STATE;
              creditCardFields.zip.placeholder = regionalFields.fields.zip || FORM_FIELD_NAMES.ZIP;

              const cardTypeOptions = [];
              creditCardTypes.forEach(({ countries, label, value }) => {
                if (countries.includes(countryCode)) {
                  cardTypeOptions.push({ label, value });
                }
              });

              let filteredCardType;
              if (window.location.pathname.split('/').slice(-1)[0] === 'credit-card') {
                filteredCardType = cardTypeOptions.filter((el) => el.label !== 'Mastercard' && el.label !== 'Discover');
              } else {
                filteredCardType = cardTypeOptions;
              }
              creditCardFields.cardType.options = filteredCardType;
              creditCardFields.cvvToolTip = cvvToolTip;
              const isMastercard = selectedCardType.cardType === 'MC';
              const isDiscovercard = selectedCardType.cardType === 'DI' || selectedCardType.cardType === 'DC';
              return {
                creditCardFields,
                title,
                message,
                changeType,
                isMastercard,
                isDiscovercard,
                backButton: buttons.back,
              };
            },
            (...args) => JSON.stringify(args)
          )
      );
    }),
    getCruisePayments: new Selector(({ getFormattedCmsLabels }) =>
      createSelector(
        [getBookingDetails, getCountryCodeFromCurrency, getFormattedCmsLabels, getIsUKAUNZ],
        (
          { payments: { outstandingBalanceDue, totalDue, balanceDueDate, totalAmountPaid, grossPrice, depositDue } },
          countryCode,
          cmsLabels,
          isUKAUNZ
        ) => {
          const paymentsData = [];
          const cmsLabelLength = Object.keys(cmsLabels).length;
          if (cmsLabelLength > 0) {
            paymentsData.push(
              getCruisePaymentObject({
                headerLabel: cmsLabels.totalAmount,
                headerValue: grossPrice,
                countryCode,
              })
            );
            paymentsData.push(
              getCruisePaymentObject({
                headerLabel: cmsLabels.totalPaymentsMade,
                headerValue: totalAmountPaid,
                countryCode,
              })
            );
            paymentsData.push(
              getCruisePaymentObject({
                headerLabel: replaceCMSTokenWithValue(cmsLabels.remainingDueBy, [
                  {
                    key: 'BALANCE_DUE_DATE',
                    value: moment(balanceDueDate).format(isUKAUNZ ? REGIONAL_LONG_DATES.EU : REGIONAL_LONG_DATES.NA),
                  },
                ]),
                headerValue: depositDue === grossPrice ? totalDue : outstandingBalanceDue,
                countryCode,
              })
            );
          }

          return paymentsData;
        }
      )
    ),
    getFormattedCmsLabels: new Selector(({ getTabContent }) =>
      createSelector([(state) => getTabContent(state)(CRUISE_PAYMENTS)], (tabContent) => {
        const cmsLabelObject = {};
        if (tabContent.sections && tabContent.sections.length > 0) {
          const { messages } = tabContent.sections[0];
          messages.forEach((message) => {
            if (!cmsLabelObject[message.reference]) {
              cmsLabelObject[message.reference] = message.text;
            }
          });
        }
        return cmsLabelObject;
      })
    ),
    getCruiseMakePaymentSection: new Selector(
      ({ getUkPaymentsMessage, getFormattedCmsLabels, getIsPaymentProcessing }) =>
        createSelector(
          [
            getIsCloseToDepartureDate,
            (state) => getPageTabLabels(state)(PAYMENTS_CART),
            (state) => getUkPaymentsMessage(state)(CRUISE_CHECKOUT),
            getMvjStrings,
            getUpdateUserData,
            getAllowScheduledPayment,
            getBalanceDueDate,
            getFormattedCmsLabels,
            getBookingDetails,
            getIsPaymentProcessing,
            (state) => getPaymentsAllEnabled(state),
          ],
          (
            isCloseToDeparture,
            {
              buttons: { makePayment, details, scheduleAPayment },
              tabs: { cruisePayments },
              labels: { or, paymentInProgress },
            },
            ukPaymentMessage,
            mvjStrings,
            updateUserInfo,
            allowScheduledPayment,
            balanceDueDate,
            cmsLabels,
            { bookingStatus, payments },
            paymentIsProcessing,
            isPaymentsAllEnabled
          ) => {
            let alert = ukPaymentMessage || '';
            let alertClass = '';
            const today = moment();
            const balanceAlreadyDue = today.isSameOrAfter(moment(balanceDueDate));
            if (isCloseToDeparture) {
              alert = cruisePayments?.labels?.paymentDisabled;
            }
            if (![BOOKING_STATUSES.CONFIRMED, BOOKING_STATUSES.FINAL].includes(bookingStatus) && !balanceAlreadyDue) {
              alert = cmsLabels?.schedulePaymentBlocked || '';
            }
            if (updateUserInfo?.updateUserType === EVO_USER_TYPES[USER_TYPES.CSA]) {
              alert = get(mvjStrings.errors, 'restrictPaymentBookingError[0].message', '');
            }
            if (payments?.outstandingBalanceDue <= 0) {
              alert =
                cmsLabels?.paymentsCartBookingPaidInFull ||
                'Your booking is paid in full – no further booking payments are required';
              alertClass = 'payment-success';
            }
            const cruiseMakePaymentSection = {
              button: {
                text: makePayment,
                tip: paymentIsProcessing ? paymentInProgress : '',
              },
              balanceItems: [],
              lineItems: [],
              labels: { details, or },
              alert,
              alertClass,
            };
            if (allowScheduledPayment !== SCHEDULED_PAYMENT_STATE.HIDDEN) {
              const scheduledPaymentButton = {
                text: scheduleAPayment,
                attributes: {
                  disabled:
                    !isPaymentsAllEnabled ||
                    allowScheduledPayment === SCHEDULED_PAYMENT_STATE.DISABLED ||
                    paymentIsProcessing,
                },
              };
              if (balanceAlreadyDue) {
                scheduledPaymentButton.text = '';
              }
              cruiseMakePaymentSection.secondaryButton = scheduledPaymentButton;
              cruiseMakePaymentSection.scheduledPaymentDisabled =
                allowScheduledPayment === SCHEDULED_PAYMENT_STATE.DISABLED || paymentIsProcessing;
            }
            return cruiseMakePaymentSection;
          }
        )
    ),
    getJourneyCheckoutTotalAmount: new Selector(({ getTabContent }) =>
      createSelector(
        (state) => getTabContent(state)(ADDONS),
        ({ items: journeyContentItems = [] }) =>
          journeyContentItems.reduce((acc, item) => {
            if (!(item.isSoldOut || item.notOffered)) {
              return acc + getJourneyPassengersItemTotal(item);
            }
            return acc;
          }, 0)
      )
    ),
    getCartFailureCount: (state) => state?.payments?.addons?.failureCount || 0,
    getJourneyCheckoutSection: new Selector(
      ({
        getJourneyCheckoutTotalAmount,
        getTabContent,
        getRemovingItemId,
        getJourneyAddonsSection,
        getIsPaymentProcessing,
      }) =>
        createSelector(
          [
            (state) => getPageTabLabels(state)(PAYMENTS_CART, ADDONS),
            getJourneyAddonsSection,
            (state) => getTabContent(state)(ADDONS),
            getJourneyCheckoutTotalAmount,
            getCountryCodeFromCurrency,
            getRemovingItemId,
            getFeatureRestricted,
            getIsPaymentProcessing,
            (state) => getPaymentsAllEnabled(state),
            (state) => getPaymentsCheckoutEnabled(state),
          ],
          (
            { buttons: { checkout }, labels: { cartTotal, checkoutNow } },
            { items: journeyContentItems = [] },
            { sections, statusCode },
            total,
            countryCode,
            removingId = [],
            featureRestricted,
            paymentIsProcessing,
            isPaymentsAllEnabled,
            isPaymentsCheckoutEnabled
          ) => {
            const messages = get(sections[0], 'messages', []);
            const lineItems = journeyContentItems.reduce((acc, item) => {
              if (!(item.isSoldOut || item.notOffered)) {
                const value = getJourneyPassengersItemTotal(item);
                acc.push({ label: item.title, value });
              }
              return acc;
            }, []);
            const itemLabel = journeyContentItems.length > 1 ? CART_ITEMS.ITEMS : CART_ITEMS.ITEM;
            const nothingInCart = getCmsLabel(messages, 'paymentsCartNothingInCartCalendarShorex', 'text');
            const emptyCart = getCmsLabel(messages, 'paymentsCartEmptyCartLabel', 'text');
            const cartFailure = getCmsLabelLink(messages, 'unableToProcessCart');
            const unableToLoadCart = getCmsLabelLink(messages, 'unableToLoadCart');
            const getTip = () => {
              if (paymentIsProcessing) {
                return 'Payment in Process';
              }
              if (total) {
                return checkoutNow;
              }
              return '';
            };
            return {
              button: {
                text: checkout,
                tip: getTip(),
                attributes: {
                  onButtonClick: total ? goTo(`${APP_PATHS.CART}/checkout`) : null,
                  disabled:
                    !isPaymentsAllEnabled ||
                    !isPaymentsCheckoutEnabled ||
                    !(total > 0) ||
                    !!removingId.length ||
                    paymentIsProcessing ||
                    featureRestricted,
                  loading: paymentIsProcessing,
                },
              },
              lineItems: total
                ? getCruisePaymentObject({
                    lineItems,
                    countryCode,
                  }).lineItems
                : [],
              balanceItems: total
                ? getCruisePaymentObject({
                    lineItems: [
                      {
                        label: `${cartTotal} (${lineItems.length} ${itemLabel})`,
                        value: total,
                      },
                    ],
                    countryCode,
                  }).lineItems
                : [],
              nothingInCart,
              emptyCart,
              cartFailure,
              unableToLoadCart,
              cartError: statusCode === '500',
            };
          }
        )
    ),
    getPriceHasChangedText: new Selector(({ getTabContent }) =>
      createSelector([(state) => getTabContent(state)(ADDONS)], ({ sections }) => {
        const messages = get(sections[0], 'messages', []);
        const priceHasChangedText = getCmsLabel(messages, 'priceHasChanged', 'text');
        return {
          priceHasChangedText,
        };
      })
    ),

    getJourneyAddonsSection: new Selector(({ getTabContent, getRemovingItemId }) =>
      createSelector(
        [
          getBookingDetails,
          getLabels,
          (state) => getTabContent(state)(ADDONS),
          getCountryCodeFromCurrency,
          getFeatureRestricted,
          getRemovingItemId,
        ],
        (
          { passengers, itinerary } = {},
          { buttons = {} } = {},
          { items: journeyContentItems = [], sections },
          countryCode,
          featureRestricted,
          removingId
        ) => {
          const messages = get(sections[0], 'messages', []);
          const journeyAddonsValues = {
            items: journeyContentItems,
            passengers,
            removeText: buttons.remove,
            lockedDownMessage: getCmsLabel(messages, 'cartLockedDownLabel', 'text'),
            viewOnly: featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY,
          };
          return getJourneyAddonsArray({
            countryCode,
            itinerary,
            removingId,
            ...journeyAddonsValues,
          });
        }
      )
    ),
    getAllCalendarItemsAddons: new Selector(({ getRemovingItemId, getAllCalendarItems, getOnboarDining }) =>
      createSelector(
        [
          getBookingDetails,
          getLabels,
          (state) => getAllCalendarItems(state),
          (state) => getOnboarDining(state),
          getCountryCodeFromCurrency,
          getRemovingItemId,
        ],
        ({ passengers, itinerary } = {}, { buttons = {} } = {}, items, onboarDining, countryCode, removingId) => {
          const itemsMapped = items.map((item) => item.items).flat();

          const itemsMappedPax = itemsMapped.map((item) => {
            const itemServicCodeTrimmed = item?.serviceCode?.slice(item.serviceCode.length - 3);
            const onboardItem = onboarDining?.find((element) => element.reference === itemServicCodeTrimmed);
            const thumbnail = onboardItem?.images;
            const forPassenger = [];
            if (item.forPassenger1) {
              forPassenger.push({ id: 1, inCart: true });
            } else {
              forPassenger.push({ id: 1, inCart: false });
            }
            if (item.forPassenger2) {
              forPassenger.push({ id: 2, inCart: true });
            } else {
              forPassenger.push({ id: 2, inCart: false });
            }
            return {
              ...item,
              title: item.description,
              forPassenger,
              thumbnail,
            };
          });
          const journeyAddonsValues = {
            items: itemsMappedPax,
            passengers,
            removeText: buttons.remove,
          };
          return getJourneyAddonsArray({
            countryCode,
            itinerary,
            removingId,
            ...journeyAddonsValues,
          });
        }
      )
    ),
    getGratuityModalData: new Selector(({ getJourneyAddonsSection }) =>
      createSelector(
        [getJourneyAddonsSection, getModalData, getDiningBeverageModalCards, getBeforeYouGoModalCards],
        (journeyAddonsSection, { id: modalId }, ...modalDataObjects) => {
          const modalData = modalDataObjects.find((data) => data.id === modalId) || {};
          const { items } = journeyAddonsSection || [];
          const { itemId } = items.find((item) => item.serviceCode === modalData.reference) || {};

          return {
            ...modalData,
            itemId,
          };
        }
      )
    ),
    getSoldOutModalData: new Selector(({ getJourneyAddonsSection }) =>
      createSelector(
        [getJourneyAddonsSection, getLabels],
        (
          journeyAddonsSection,
          { buttons: { continueToCart } = {}, modals: { cartSoldOut: { title, subtitle } = {} } = {} }
        ) => {
          const soldOutExcursions = journeyAddonsSection.items.reduce((acc, item) => {
            if (item.isSoldOut) {
              acc.push(removeItemButtons(item));
            }
            if (item.notOffered) {
              acc.push(removeItemButtons(item));
            }
            return acc;
          }, []);
          return {
            title,
            subtitle,
            items: soldOutExcursions || [],
            button: {
              text: continueToCart,
            },
          };
        }
      )
    ),
    getSelectedPaymentMethod: (state) =>
      formValueSelector(FORMS.PAYMENT_CHECKOUT)(state, 'paymentMethod') ||
      formValueSelector(FORMS.AIR_PAYMENT_CHECKOUT)(state, 'paymentMethod'),
    getPaymentCheckoutInitialValues: new Selector(
      ({
        getJourneyCheckoutTotalAmount,
        getPaymentAmountForOption,
        getSelectedPaymentMethod,
        getDefaultSavedAccount,
        getCartCheckoutSummary,
      }) =>
        createSelector(
          [
            getCountryCodeFromCurrency,
            getPaymentAmountForOption,
            getJourneyCheckoutTotalAmount,
            getSelectedPaymentMethod,
            getDefaultSavedAccount,
            getBookingDetailsContent,
            getPassengers,
            getCartCheckoutSummary,
            getBookingStatus,
          ],
          (
            countryCode,
            getPaymentAmount,
            journeyCheckoutTotalAmount,
            selectedPaymentMethod,
            getDefaultAccount,
            bookingDetails,
            passengers,
            checkoutSummary,
            bookingStatus
          ) =>
            memoize((type) => {
              const tabName = getTabNameFromCheckoutType(type);
              const depositDueAmount = getPaymentAmount('deposit');
              const totalDue = getPaymentAmount('totalDue');
              const { noPaymentDescription } = checkoutSummary;
              const amount =
                bookingStatus === BOOKING_STATUSES.CONFIRMED ? totalDue : Math.min(depositDueAmount, totalDue);
              const initialValues = {
                savedAccount: getDefaultAccount(selectedPaymentMethod, tabName),
                paymentMethod: ACH,
                account: {
                  country: FIND_COUNTRY_MAPPING[countryCode] || countryCode,
                  accountType: 'C',
                },
                noPaymentRequired: type === ADDONS_CHECKOUT ? noPaymentDescription : '',
              };
              if (
                countryCode === COUNTRIES.UNITED_KINGDOM ||
                countryCode === COUNTRIES.AUSTRALIA ||
                bookingDetails.daysToGo <= ACH_UNAVAILABLE_DAYS
              ) {
                initialValues.paymentMethod = CREDIT_CARD;
              }
              if (type === ADDONS_CHECKOUT) {
                initialValues.paymentAmount = journeyCheckoutTotalAmount;
              } else if (amount) {
                initialValues.paymentAmount = amount;
                initialValues.paymentAmountOption =
                  bookingStatus === BOOKING_STATUSES.CONFIRMED ? 'fullPayment' : 'minimumDue';
              } else {
                initialValues.paymentAmount = getPaymentAmount('fullPayment');
                initialValues.paymentAmountOption = 'fullPayment';
              }

              if (type === AIR_CHECKOUT && bookingDetails.daysToGo <= ACH_UNAVAILABLE_DAYS) {
                if (![COUNTRIES.UNITED_KINGDOM, COUNTRIES.AUSTRALIA].includes(countryCode)) {
                  initialValues.paymentMethod = CREDIT_CARD;
                }
              }

              return initialValues;
            })
        )
    ),
    getPaymentMethodsData: new Selector(({ getPaymentMethods }) =>
      createSelector(
        [
          getPaymentMethods,
          (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS),
          getCountryCodeFromCurrency,
          getIsViewOnly,
          (state) => getPaymentsAllEnabled(state),
        ],
        (
          paymentMethods,
          {
            buttons: { addAccount, edit, remove },
            labels: { achCheckingLabel, achSavingsLabel, addAchDraftDiscount, bankDraft },
          },
          countryCode,
          isViewOnly,
          isPaymentsAllEnabled
        ) => {
          const paymentMethodsSection = [];
          const buttons = [
            {
              text: remove,
              action: PAYMENT_ACTION_TYPE.REMOVE,
            },
          ];
          const paymentMethodsValues = {
            title: bankDraft,
            subtitle: addAchDraftDiscount,
            button: {
              text: addAccount,
              attributes: {
                onButtonClick: goTo(`${APP_PATHS.PAYMENT_METHODS}/ach`),
                disabled: !isPaymentsAllEnabled || isViewOnly,
              },
            },
          };

          if (![COUNTRIES.AUSTRALIA, COUNTRIES.UNITED_KINGDOM].includes(countryCode)) {
            let mappedSavedBankAccounts = [];
            mappedSavedBankAccounts = paymentMethods
              .filter(({ isCreditCard }) => !isCreditCard)
              .map((ach) => ({
                ...ach,
                accountName: ach.achAccountType === ACH_TYPES.SAVINGS ? achSavingsLabel : achCheckingLabel,
              }));

            paymentMethodsSection.push({
              paymentMethodsValues,
              items: mappedSavedBankAccounts,
              actionButtons: [{ text: edit, action: PAYMENT_ACTION_TYPE.ACH_EDIT }, ...buttons],
            });
          }
          return paymentMethodsSection;
        }
      )
    ),
    getOnboardCreditCardData: new Selector(() =>
      createSelector(
        [(state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS), (state) => getPaymentsAllEnabled(state)],
        ({ buttons: { addCreditCard, remove } }, isPaymentsAllEnabled) => {
          const paymentMethodsSection = [];
          const buttons = [
            {
              text: remove,
              action: PAYMENT_ACTION_TYPE.REMOVE,
            },
          ];

          const paymentMethodsValues = {
            button: {
              text: addCreditCard,
              attributes: {
                onButtonClick: '',
                disabled: !isPaymentsAllEnabled,
              },
            },
          };
          paymentMethodsSection.push({
            paymentMethodsValues,
            actionButtons: [...buttons],
          });
          return {
            paymentMethodsSection,
          };
        }
      )
    ),
    getOnboardCreditCardInitialValues: new Selector(({ getCreditCardFields }) =>
      createSelector(
        [getCountryCodeFromCurrency, getLoggedInUser, (state) => getCreditCardFields(state)],
        (defaultCountry, { passengerNumber }, getFields) =>
          memoize(
            (country = defaultCountry) => {
              const fields = getFields({ tabName: TAB_NAMES.ONBOARD_CREDIT_CARD, country });
              let guestNumber = passengerNumber;
              const { options = [] } = get(fields, 'creditCardFields.nameOnCard', {});
              if (!options.find((o) => o.value === passengerNumber)) {
                guestNumber = get(options, '[0].value', undefined);
              }
              return {
                country: FIND_COUNTRY_MAPPING[country] || country,
                nameOnCard: `${guestNumber}`,
              };
            },
            (...args) => JSON.stringify(args)
          )
      )
    ),
    getRemovePaymentMethodModal: new Selector(({ getPaymentMethods, getTabContentSections }) =>
      createSelector(
        [
          getModalData,
          getPaymentMethods,
          (state) => getTabContentSections(state)(PAYMENT_METHODS),
          getSubmitting,
          (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS),
        ],
        (
          { id },
          paymentMethods,
          sections,
          submitting,
          { buttons: { yesRemoveAccount, noRemoveAccount, yesRemoveCard, noRemoveCard } }
        ) => {
          if (!id) {
            return {
              title: null,
            };
          }

          // check if id belongs to credit cards or ACHs
          const isCC = paymentMethods.some((p) => p.isCreditCard && p.id === id);
          const index = Number(paymentMethods.some((p) => p.isCreditCard && p.id === id));
          const modal = get(sections, `[0].cards[${index}].modal`, {});

          let message = '';
          let buttonConfirmText = '';
          let buttonCancelText = '';
          let failMessage = '';
          if (Array.isArray(modal.sections)) {
            if (isCC) {
              message = get(modal, 'subtitle', '');
              buttonConfirmText = yesRemoveCard;
              buttonCancelText = noRemoveCard;
              failMessage = get(
                modal.sections.find((s) => s.reference === 'removeCreditCardError'),
                'longText',
                ''
              );
            } else {
              message = get(modal, 'title', '');
              buttonConfirmText = yesRemoveAccount;
              buttonCancelText = noRemoveAccount;
              failMessage = get(
                modal.sections.find((s) => s.reference === 'removeACHAccountError'),
                'longText',
                ''
              );
            }
          }

          return {
            entityId: id,
            message,
            failMessage,
            buttons: [
              { id: 'cancel', text: buttonCancelText },
              { id: 'confirm', text: buttonConfirmText },
            ],
            submitting,
          };
        }
      )
    ),

    getRemoveOnboardCCModal: new Selector(({ getTabContentSections }) =>
      createSelector(
        [
          getModalData,
          (state) => getTabContentSections(state)(ONBOARD_CREDIT_CARD),
          getSubmitting,
          (state) => getPageTabLabels(state)(PAYMENTS_CART, ONBOARD_CREDIT_CARD),
        ],
        ({ id }, sections, submitting, { buttons: { yesRemoveCard, noRemoveCard } }) => {
          if (!id) {
            return {
              title: null,
            };
          }

          const modal = get(sections, '[1].cards[0].modal', {});
          let message = '';
          let buttonConfirmText = '';
          let buttonCancelText = '';
          let failMessage = '';
          if (Array.isArray(modal.sections)) {
            message = get(modal, 'subtitle', '');
            buttonConfirmText = yesRemoveCard;
            buttonCancelText = noRemoveCard;
            failMessage = get(
              modal.sections.find((s) => s.reference === 'removeCreditCardError'),
              'longText',
              ''
            );
          }

          return {
            entityId: Number(id),
            message,
            failMessage,
            buttons: [
              { id: 'cancel', text: buttonCancelText },
              { id: 'confirm', text: buttonConfirmText },
            ],
            submitting,
          };
        }
      )
    ),

    getManagePaymentMethodInitialValues: new Selector(({ getPaymentMethodsData }) =>
      createSelector(
        [getCountryCodeFromCurrency, getPaymentMethodsData, getPassenger],
        (countryCode, paymentMethodsData, getPassengerByNumber) =>
          memoize((paymentMethodId) => {
            const selectedPaymentMethodId = Number(paymentMethodId);
            let selectedPaymentMethod = {};
            let optionsType = null;
            let mappedPaymentOption = {};
            if (selectedPaymentMethodId && paymentMethodsData) {
              optionsType = paymentMethodsData.find(({ items }) =>
                items.find(({ id }) => id === selectedPaymentMethodId)
              );
              if (optionsType) {
                selectedPaymentMethod = optionsType.items.find(({ id }) => id === selectedPaymentMethodId);
              }

              const {
                id,
                expirationDate,
                accountHolderName,
                addressLine1,
                addressLine2,
                city,
                state,
                zip,
                ccType,
                token,
                maskedAccountNumber,
                achAccountType,
                isCreditCard,
                forPassengerNumber,
                routingNumber,
              } = selectedPaymentMethod;

              let expiryMonth = '';
              let expiryYear = '';
              if (typeof expirationDate === 'string' && expirationDate.length === 4) {
                expiryMonth = expirationDate.slice(0, 2);
                expiryYear = expirationDate.slice(2);
              }

              mappedPaymentOption = {
                id,
                city,
                zip,
                state,
                addressLine1,
                addressLine2,
                token,
              };
              if (isCreditCard) {
                mappedPaymentOption = {
                  ...mappedPaymentOption,
                  name: accountHolderName,
                  expiryMonth,
                  expiryYear,
                  cardType: ccType,
                  maskedAccountNumber,
                };
              } else {
                const { firstName, middle, lastName } = getPassengerByNumber(forPassengerNumber) || {};
                mappedPaymentOption = {
                  ...mappedPaymentOption,
                  nameOnAccount: `${forPassengerNumber}`,
                  bankRouting: routingNumber,
                  forPassengerNumber,
                  firstName,
                  middle,
                  lastName,
                  accountType: achAccountType,
                  maskedAccountNumber,
                };
              }
            }

            return {
              ...mappedPaymentOption,
              country: countryCode,
            };
          })
      )
    ),
    getStatesByCountry: new Selector(() =>
      createSelector([getCountryCodeFromCurrency, getCountryStates], (defaultCountry, countryStates) =>
        memoize((country = defaultCountry) => {
          const states = country ? countryStates(country) : [];
          return mapStatesToOptions(states);
        })
      )
    ),
    getAddressFields: new Selector(({ getStatesByCountry }) =>
      createSelector(
        [getCountryCodeFromCurrency, getStatesByCountry, (state) => getCountriesPopularFirst(state)('twoLetterCode')],
        (defaultCountry, getStateOptions, countryOptions) =>
          memoize((country = defaultCountry) => {
            const stateOptions = getStateOptions(country);
            const altCountryCode = FIND_COUNTRY_MAPPING[country] || country;
            const states = stateOptions || [];
            return {
              state: {
                options: states,
              },
              country: {
                options: countryOptions,
              },
              zip: postalCodeMask(altCountryCode),
            };
          })
      )
    ),
    getAccountECheckFields: new Selector((selectors) => {
      const { getAddressFields, getTabContent, getTabContentForm, getPaymentMethodChangeType } = selectors;
      return createSelector(
        [
          (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS),
          getPassengers,
          getTabContentForm,
          getTabContent,
          getAddressFields,
          (state) => getProperty(state)('accountType'),
          getCountryCodeFromCurrency,
          getPaymentMethodChangeType,
        ],
        ({ buttons }, passengers, tabContentForm, content, addressFields, accountTypes, countryCode, changeType) =>
          memoize(
            (tabName, selectedPaymentMethodId) => {
              const formSections =
                tabName === ACH ? content(tabName) : tabContentForm(tabName, 'Bank Account Information');

              const htmlNames = {
                nameOnAccount: 'nameonaccount',
                accountType: 'accounttype',
                bankAccount: 'accountnumber',
                addressLine1: 'address1',
                addressLine2: 'address2',
                add: 'add',
                update: 'update',
                cancel: 'cancel',
                city: 'city',
                save: 'savebox',
                country: 'country',
                // TODO: have CMS send 'state' and 'zip' for all countries
                state: countryCode === COUNTRIES.CANADA ? 'province' : 'state',
                zip: countryCode === COUNTRIES.CANADA ? 'postalcode' : 'zip',
              };
              if (countryCode === COUNTRIES.UNITED_STATES) {
                htmlNames.bankRouting = 'routingnumber';
              }
              if (countryCode === COUNTRIES.CANADA) {
                htmlNames.bankRouting = 'instituiontransitnumber';
              }

              if (countryCode === COUNTRIES.UNITED_KINGDOM) {
                countryCode = COUNTRIES.UNITED_KINGDOM_GB;
              }

              const formSection = get(formSections, '[0]', {});
              let { fields = [], title } = formSection;

              let message;
              let reference;
              let disclaimerMessage;

              if (formSections) {
                const { sections } = formSections;
                switch (changeType) {
                  case PAYMENT_METHOD_CHANGE_TYPE.ADD: {
                    reference = 'ACHAccountAddedLabel';
                    break;
                  }
                  case PAYMENT_METHOD_CHANGE_TYPE.EDIT: {
                    reference = 'ACHAccountUpdatedLabel';
                    break;
                  }
                  default: {
                    break;
                  }
                }
                message = getCmsLabel(sections, reference, 'longText');
                disclaimerMessage = getCmsLabel(sections, 'saveACHDisclaimer', 'longText');
              }
              if (tabName === ACH) {
                title = formSections.title || '';
                fields = get(formSections, 'forms[0].fields', []);
                title = !selectedPaymentMethodId ? title : formSections.subtitle;
              }

              const fieldValues = {};

              Object.entries(htmlNames).forEach(([key, fieldName]) => {
                const { label, tooltip } = getFormFieldValue(fields, fieldName) || {};
                fieldValues[key] = { label, tooltip };
              });

              let image = get(getFormFieldValue(fields, 'accountnumber'), 'imageset[0]');
              if (Array.isArray(image)) {
                image[0].type = TWO_BY_ONE;
                image = getCardThumbnailObject(image, false);
              }

              const accountFields = {
                accountType: {
                  options: accountTypes,
                },
                bankAccount: {
                  images: image,
                  description: fieldValues.bankAccount.tooltip,
                  fieldAttributes: {
                    name: 'bankAccount',
                  },
                },
                nameOnAccount: {
                  options: passengers.map((passenger) => ({
                    label: getPassengerAbbrevName(passenger),
                    value: String(passenger.passengerNumber),
                  })),
                },
                ...addressFields(countryCode),
              };

              if (countryCode === COUNTRIES.UNITED_STATES) {
                accountFields.bankRouting = {
                  fieldAttributes: {
                    name: 'bankRouting',
                    maxLength: 9,
                    normalize: numbersOnly,
                  },
                };
              }

              if (countryCode === COUNTRIES.CANADA) {
                accountFields.bankRouting = {
                  fieldAttributes: {
                    name: 'bankRouting',
                    mask: institutionTransitMask,
                  },
                  description: fieldValues.bankRouting.tooltip,
                };
              }

              const accountECheckFields = addFieldPlaceholders(htmlNames, fields, accountFields);

              if (countryCode === COUNTRIES.UNITED_STATES) {
                accountECheckFields.zip.placeholder = FORM_FIELD_NAMES.ZIP;
              }

              return {
                accountECheckFields,
                title,
                message,
                disclaimerMessage,
                changeType,
                backButton: buttons.back,
              };
            },
            (...args) => JSON.stringify(args)
          )
      );
    }),
    getPaymentMethodsSection: new Selector(({ getPaymentMethodsData }) =>
      createSelector(
        [getPaymentMethodsData, (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS)],
        (paymentMethods, { labels: { expired } }) => {
          const mappedPaymentMethods = paymentMethods.map(({ paymentMethodsValues, items, actionButtons }) => {
            const mappedItems = items.map(
              ({ id, accountName, accountHolderName, maskedAccountNumber, expirationDate }) => {
                const creditCardExpiration = isCreditCardExpired(expirationDate) ? expired : '';
                const nameParts = accountHolderName.split(' ');
                let name = accountHolderName;
                if (nameParts.length === 3) {
                  const [firstName, middle, lastName] = nameParts;
                  name = getPassengerAbbrevName({ firstName, middle, lastName });
                }
                return {
                  id,
                  accountName,
                  accountHolderName: name,
                  maskedAccountNumber,
                  actionButtons,
                  isExpired: creditCardExpiration,
                };
              }
            );
            return {
              paymentMethodsValues,
              items: mappedItems,
            };
          });
          return mappedPaymentMethods;
        }
      )
    ),

    getOnboardCreditCardSection: new Selector(
      ({ getOnboardCreditCardData, getOnboardCCGuests, getTabContentSections }) =>
        createSelector(
          [
            getPassengers,
            getOnboardCreditCardData,
            (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS),
            getOnboardCCGuests,
            (state) => getTabContentSections(state)(ONBOARD_CREDIT_CARD),
            getFeatureRestricted,
            getDaysToGo,
          ],
          (
            passengers,
            onboardCreditCard,
            { buttons: { saveCard, cancel } },
            onboardGuests,
            onboardCCSection,
            featureRestricted,
            daysToGo
          ) => {
            const { paymentMethodsSection } = onboardCreditCard;
            const ccDetailsPaxs = onboardGuests.map((pax) => pax.ccDetails);
            const mappedPaymentMethods = paymentMethodsSection.map(({ paymentMethodsValues, actionButtons }) => {
              const mappedItems = ccDetailsPaxs.reduce((acc, savedCard, idx) => {
                if (savedCard && savedCard.sequence) {
                  const { sequence, ccType, cardNumber } = savedCard;
                  const { cardType } = CREDIT_CARD_TYPE[ccType] || {};
                  acc.push({
                    id: sequence,
                    accountName: cardType,
                    accountHolderName: getPassengerAbbrevName(passengers[idx]),
                    maskedAccountNumber: `****${cardNumber.substring(12)}`,
                    actionButtons,
                  });
                }
                return acc;
              }, []);
              return {
                paymentMethodsValues,
                items: mappedItems,
              };
            });

            let lockDownMessage = '';
            if (daysToGo < DAYS_TO_GO.ONBOARD_CREDIT_CARD) {
              lockDownMessage = getCmsLabel(onboardCCSection?.[0]?.messages, 'addOnboardccLockedDownLabel', 'text');
            }
            return {
              mappedPaymentMethods,
              saveCardLabel: saveCard,
              cancelLabel: cancel,
              ccDetailsPaxs,
              hasSavedCCPax1: ccDetailsPaxs && !!ccDetailsPaxs[0],
              hasSavedCCPax2: ccDetailsPaxs && !!ccDetailsPaxs[1],
              isSinglePax: onboardGuests.length < 2,
              lockedDown:
                daysToGo < DAYS_TO_GO.ONBOARD_CREDIT_CARD || featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY,
              lockDownMessage,
            };
          }
        )
    ),

    getCruisePaymentCheckoutPaymentAmount: (state) =>
      Number(formValueSelector(FORMS.PAYMENT_CHECKOUT)(state, 'paymentAmount')),
    getCruisePaymentCheckoutPayToday: new Selector(({ getCruisePaymentCheckoutPaymentAmount }) =>
      createSelector(
        [getCruisePaymentCheckoutPaymentAmount],
        (cruisePaymentCheckoutPaymentAmount) =>
          cruisePaymentCheckoutPaymentAmount + calculateACHSavings(cruisePaymentCheckoutPaymentAmount)
      )
    ),
    getCruisePaymentCheckoutRemainingBalance: new Selector(
      ({ getCruisePaymentCheckoutPaymentAmount, getPaymentAmountForOption }) =>
        createSelector(
          getCruisePaymentCheckoutPaymentAmount,
          (state) => getPaymentAmountForOption(state)('fullPayment'),
          (cruisePaymentCheckoutPaymentAmount, fullPayment) => fullPayment - cruisePaymentCheckoutPaymentAmount
        )
    ),
    getCruisePaymentCheckoutSummary: new Selector(
      ({
        getCruisePaymentCheckoutPaymentAmount,
        getCruisePaymentCheckoutPayToday,
        getCruisePaymentCheckoutRemainingBalance,
        getSelectedPaymentMethod,
        getTabContentSections
      }) =>
        createSelector(
          [
            (state) => getPageTabLabels(state)(PAYMENTS_CART, CRUISE_PAYMENTS),
            (state) => getTabContentSections(state)('checkoutBooking'),
            getCruisePaymentCheckoutPaymentAmount,
            getCruisePaymentCheckoutPayToday,
            getCruisePaymentCheckoutRemainingBalance,
            getSelectedPaymentMethod,
            getCountryCodeFromCurrency,
            getBookingDetailsContent,
            getMvjStrings,
          ],
          (
            {
              buttons: { completePayment },
              labels: { achSavings, payToday, remainingBalance, paymentAmountLabel, secureCheckout },
            },
            sections = [],
            cruisePaymentCheckoutPaymentAmount,
            cruisePaymentCheckoutPayToday,
            cruisePaymentCheckoutRemainingBalance,
            selectedPaymentMethodOption,
            countryCode,
            bookingDetails,
            mvjStrings
          ) => {
            let lineItems = [
              {
                label: paymentAmountLabel,
                value: cruisePaymentCheckoutPaymentAmount,
              },
            ];
            let balanceItems = [
              {
                label: remainingBalance,
                value: cruisePaymentCheckoutRemainingBalance,
                markAdditional: true,
              },
            ];
            if (selectedPaymentMethodOption === ACH && bookingDetails.daysToGo > ACH_UNAVAILABLE_DAYS) {
              lineItems = [
                ...lineItems,
                {
                  label: achSavings,
                  value: calculateACHSavings(cruisePaymentCheckoutPaymentAmount),
                  markDiscount: true,
                },
              ];
              balanceItems = [{ label: payToday, value: cruisePaymentCheckoutPayToday }, ...balanceItems];
            }
            const genericValidationMessage = get(mvjStrings.errors, 'genericValidationError[0].message', '');
            const paymentCancelledMessage = get(mvjStrings.errors, 'paymentCancelledError[0].message', '');
            const tokenExRefreshedMessage = get(mvjStrings.errors, 'tokenExRefreshedMessage[0].message', '');
            const restrictPaymentMessage = get(mvjStrings.errors, 'restrictPaymentCheckoutError[0].message', '');
            const futureUseDisclaimer = getCmsLabel(sections, 'achFutureUseDisclaimer', 'longText');
            const completePaymentDisclaimer = getCmsLabel(sections, 'completePaymentDisclaimer', 'longText');
            return {
              button: {
                text: completePayment,
              },

              lineItems: getCruisePaymentObject({
                lineItems,
                countryCode,
              }).lineItems,

              balanceItems: getCruisePaymentObject({
                lineItems: balanceItems,
                countryCode,
              }).lineItems,
              secureCheckout,
              genericValidationMessage,
              paymentCancelledMessage,
              tokenExRefreshedMessage,
              restrictPaymentMessage,
              futureUseDisclaimer,
              completePaymentDisclaimer
            };
          }
        )
    ),
    getCartTotalBreakdown: new Selector((selectors) => {
      const { getSelectedPaymentMethod, getTabContent } = selectors;
      return createSelector(
        [(state) => getTabContent(state)(ADDONS), getPassengers, getSelectedPaymentMethod, getDaysToGo],
        ({ items: journeyContentItems = [] }, passengers, selectedPaymentMethod, daysToGo) => {
          let total = 0;
          journeyContentItems.forEach((item) => {
            total += getJourneyPassengersItemTotal(item);
          });
          const { totalVoucher, totalSbc } = passengers.reduce(
            (acc, passenger) => {
              const { appliedSBCVoucherTotal, appliedOtherVoucherTotal } = passenger;
              acc.totalVoucher += appliedOtherVoucherTotal;
              acc.totalSbc += appliedSBCVoucherTotal;
              return acc;
            },
            { totalVoucher: 0, totalSbc: 0 }
          );

          const subtotal = total - totalVoucher - totalSbc;

          let savingsAmount = 0;
          let amountDue = subtotal;
          if (selectedPaymentMethod === ACH && daysToGo > ACH_UNAVAILABLE_DAYS) {
            savingsAmount = calculateACHSavings(subtotal);
            // savingsAmount is a negative value, so we add it to total
            amountDue = subtotal + savingsAmount;
          }

          return {
            amountDue,
            subtotal,
            totalVoucher,
            totalSbc,
            savingsAmount,
          };
        }
      );
    }),
    getCartCheckoutSummary: new Selector((selectors) => {
      const { getTabContent, getTabContentSections, getCartTotalBreakdown, getJourneyAddonsSection } = selectors;
      return createSelector(
        [
          getCountryCodeFromCurrency,
          getTabContentSections,
          getMvjStrings,
          getCartTotalBreakdown,
          getJourneyAddonsSection,
          (state) => getTabContent(state)(ADDONS),
          (state) => getPageTabLabels(state)(PAYMENTS_CART, CHECKOUT_CART),
        ],
        (
          countryCode,
          getSections,
          mvjStrings,
          { amountDue, totalVoucher, totalSbc, savingsAmount },
          { items: journeyContentItems },
          { sections: addonsSections, statusCode },
          { labels }
        ) => {
          const sections = getSections(getTabNameFromCheckoutType(ADDONS_CHECKOUT));
          const messages = addonsSections[0]?.messages || [];

          const cartFailure = getCmsLabelLink(messages, 'unableToProcessCart');
          const unableToLoadCart = getCmsLabelLink(messages, 'unableToLoadCart');

          let total = 0;
          let lineItems = journeyContentItems.reduce((acc, item) => {
            const value = getJourneyPassengersItemTotal(item);
            total += getJourneyPassengersItemTotal(item);
            acc.push({ label: item.title, value });
            return acc;
          }, []);
          const genericValidationMessage = get(mvjStrings.errors, 'genericValidationError[0].message', '');
          const paymentCancelledMessage = get(mvjStrings.errors, 'paymentCancelledError[0].message', '');
          const tokenExRefreshedMessage = get(mvjStrings.errors, 'tokenExRefreshedMessage[0].message', '');
          const restrictPaymentMessage = get(mvjStrings.errors, 'restrictPaymentCheckoutError[0].message', '');
          const futureUseDisclaimer = getCmsLabel(sections, 'achFutureUseDisclaimer', 'longText');
          const completePaymentDisclaimer = getCmsLabel(sections, 'completePaymentDisclaimer', 'longText');
          const balanceItems = [];

          if (totalVoucher) {
            lineItems.push({
              label: getSectionText(sections, 'voucherAppliedLabel'),
              value: -1 * totalVoucher,
            });
          }
          if (totalSbc) {
            lineItems.push({
              label: getSectionText(sections, 'sbcAppliedLabel'),
              value: -1 * totalSbc,
            });
          }
          if (savingsAmount) {
            lineItems = [
              ...lineItems,
              {
                label: getSectionText(sections, 'checkoutAchSavings'),
                value: savingsAmount,
              },
            ];
          }

          const noPaymentDescription = isFloatZero(amountDue)
            ? labels?.noPaymentRequiredLabel || 'No payment required.'
            : '';

          balanceItems.push({
            label: getSectionText(sections, 'cartTotalLabel'),
            value: amountDue,
          });

          return {
            button: {
              text: getSectionText(sections, 'checkoutCompletePayment'),
              errorTip: '',
            },
            noPaymentDescription,
            genericValidationMessage,
            paymentCancelledMessage,
            tokenExRefreshedMessage,
            restrictPaymentMessage,
            futureUseDisclaimer,
            completePaymentDisclaimer,
            lineItems: total
              ? getCruisePaymentObject({
                  lineItems,
                  countryCode,
                }).lineItems
              : [],
            balanceItems: total
              ? getCruisePaymentObject({
                  lineItems: balanceItems,
                  countryCode,
                }).lineItems
              : [],
            secureCheckout: getSectionText(sections, 'secureCheckoutLabel'),
            cartFailure,
            unableToLoadCart,
            cartError: statusCode === '500',
          };
        }
      );
    }),
    getFailedNotificationModalData: new Selector(({ getTabContent }) =>
      createSelector([getTabContent], (paymentsContent) =>
        memoize((paymentMethod, id) => {
          const reference = id ? 'updatedAccountFailed' : 'addAccountFailed';
          const messages = get(paymentsContent(paymentMethod), 'sections', []);
          const title = getCmsLabel(messages, reference, 'title');
          const description = getCmsLabel(messages, reference, 'longText');
          const buttonText = getCmsLabel(messages, reference, 'callToActionTitle');
          return {
            id: 'failedNotificationModal',
            title,
            message: description,
            buttonText,
            type: NOTIFICATION_TYPES.FAILURE,
          };
        })
      )
    ),
    getPaymentFailedNotificationModalData: new Selector(({ getTabContent, getCheckoutError }) =>
      createSelector(
        [getTabContent, getCheckoutError, (state) => getPageTabLabels(state)(PAYMENTS_CART)],
        (
          paymentsContent,
          { errorCode, errorDescription },
          { buttons: { close, viewCart, goBack, backToCart, backToPayments } }
        ) =>
          memoize((type) => {
            let reference = 'paymentsCartPaymentFailed';
            if (['408', '00408'].includes(errorCode)) {
              reference = 'paymentInProcessModalMessage';
            } else if (errorCode === '409' || errorCode === '06901') {
              reference = 'paymentFailEmptyCartMessage';
            }
            const contentKey = type === ADDONS_CHECKOUT ? 'checkoutCart' : 'checkoutBooking';
            const sectionOrLabel = ['408', '00408', '409', '06901'].includes(errorCode) ? 'labels' : 'sections';
            const messages = get(paymentsContent(contentKey), sectionOrLabel, []);
            const title = getCmsLabel(messages, reference, 'title');
            // TODO: Get a better solution for this
            const specificErrorCodes = ['1508', '1667', '1668', '1669', '1670', '1672', '2003'];
            const genericErrorDescription = getCmsLabel(messages, reference, 'longText');
            const description = specificErrorCodes.includes(errorCode) ? errorDescription : genericErrorDescription;

            let buttonText = goBack;
            if (errorCode === '1508') {
              buttonText = viewCart;
            }
            if (errorCode === '2003') {
              buttonText = close;
            }
            if (['408', '00408'].includes(errorCode)) {
              buttonText = type === ADDONS_CHECKOUT ? backToCart : backToPayments;
            }

            return {
              id: 'failedNotificationModal',
              title,
              message: description,
              buttonText,
              type: NOTIFICATION_TYPES.FAILURE,
            };
          })
      )
    ),
    getPaymentCheckoutLabels: (state) => get(state, 'payments.checkoutCart.content.sections', ''),
    getCartItemsWhenAdded: (state) => get(state, 'payments.holding.cartItemsWhenAdded.items', []),
    getCartItemsWhenUpdated: (state) => get(state, 'payments.addons.content.items', []),
    getCruisePaymentLabels: (state) => get(state, 'payments.checkoutBooking.content.sections', ''),
    getPaymentMethodFormData: new Selector(({ getPaymentCheckoutLabels, getCruisePaymentLabels }) =>
      createSelector(
        [
          (state) => getPageTabLabels(state)(PAYMENTS_CART, PAYMENT_METHODS),
          getCountryCodeFromCurrency,
          getBookingDetailsContent,
          getPaymentCheckoutLabels,
          getCruisePaymentLabels,
        ],
        (
          { labels: { bankDraft, draftDiscount, cardTypeLabel: cardType, debitCards, creditDebitCard } },
          country,
          bookingDetails,
          paymentCheckoutLabels,
          cruisePaymentLabels
        ) => {
          let achAvailable = true;
          let achNotAvailable = '';
          const cruiseSection = getCmsLabel(cruisePaymentLabels, 'achPaymentNotAvailableLabel', 'longText');
          const addonSection = getCmsLabel(paymentCheckoutLabels, 'achPaymentNotAvailableLabel', 'longText');

          if (bookingDetails.daysToGo <= ACH_UNAVAILABLE_DAYS) {
            achNotAvailable = cruiseSection || addonSection;
          }

          const options = [
            {
              value: ACH,
              label: '',
              id: 'paymentMethodACH',
              disabled: !!achNotAvailable,
            },
            {
              value: CREDIT_CARD,
              label: cardType,
              id: 'paymentMethodCredit',
            },
          ];

          if (country === COUNTRIES.UNITED_KINGDOM) {
            achAvailable = false;
            options.shift();
            options.shift();
            options.push({
              value: CREDIT_CARD,
              label: creditDebitCard,
              id: 'paymentMethodCredit',
            });
          }

          if (country === COUNTRIES.AUSTRALIA) {
            achAvailable = false;
            options.shift();
            options.push({
              value: DEBIT_CARD,
              label: debitCards,
              id: 'paymentMethodDebit',
            });
          }

          return {
            bankDraft,
            draftDiscount,
            options,
            achAvailable,
            achNotAvailable,
          };
        }
      )
    ),
    getCustomizableTabs: new Selector(({ getTabs }) =>
      createSelector(
        [getBookingDetails, getTabs, getTabUrl, getCountryCodeFromCurrency, getIsTaAccount, getHasOnboardCreditCard],
        (bookingDetails, paymentTabs, getTabUrlFunction, countryCode, isTaAccount, hasOnboardCC) => (
          location,
          match
        ) => {
          const tabs = paymentTabs(location.pathname, match.path);
          const evoUICode = get(bookingDetails, 'agent.Evo-UI-Code');
          const isDirect = evoUICode === BOOKING_TYPE.DIRECT;
          const removeTab = (tabName) => {
            const tabToRemove = getTabUrlFunction(PAYMENTS_CART, tabName);
            const index = tabs.findIndex((t) => t.url.includes(tabToRemove));
            if (index !== -1) {
              tabs.splice(index, 1);
            }
          };
          // TODO: use reference or ID to remove tabs
          if (!isDirect && !isTaAccount) {
            removeTab(CRUISE_PAYMENTS);
          }
          if (!hasOnboardCC) {
            removeTab(ONBOARD_CREDIT_CARD);
          }
          return { tabs, isDirect, hasOnboardCC, isTaAccount };
        }
      )
    ),
    getUkPaymentsMessage: new Selector(({ getTabContent }) =>
      createSelector(
        [(state) => getIsCurrentCountryPaymentBlocked(state), (state) => getTabContent(state)],
        (ukPaymentBlocked, getTabContentData) => (type) => {
          const tabName = type === ADDONS_CHECKOUT ? TAB_NAMES.ADDONS : TAB_NAMES.CRUISE_PAYMENTS;
          const tabData = getTabContentData(tabName);
          const cmsLabels = tabData?.sections?.[0]?.messages || [];
          let ukPaymentMessage = '';
          if (ukPaymentBlocked(type)) {
            const ref = type === ADDONS_CHECKOUT ? 'checkoutProhibitedUk' : 'ukBookingPaymentNotAllowed';
            const paymentsProhibitedUK = getCmsLabel(cmsLabels, ref, 'longText');
            const phone = getCmsLabel(cmsLabels, 'PHONE', 'longText');
            ukPaymentMessage = replaceCMSTokenWithValue(paymentsProhibitedUK, [{ key: 'PHONE', value: phone }]);
            return ukPaymentMessage;
          }
          return '';
        }
      )
    ),
    getIsPsd2: new Selector(() =>
      createSelector([getCountryCodeFromCurrency], (country) => PSD2_PAYMENT_COUNTRIES.includes(country))
    ),
    getPaymentChallenge: (state) => state?.payments?.paymentChallenge,
    getChallengeModalData: new Selector(({ getPaymentChallenge, getTabContent }) =>
      createSelector(
        [getPaymentChallenge, getTabContent, getModalIsActive, (state) => getPageTabLabels(state)(PAYMENTS_CART)],
        (challengeData, paymentsContent, isActive, { buttons: { cancel, close } }) =>
          memoize((type) => {
            if (!challengeData?.iframeUrl) {
              return null;
            }

            const { challengeReq, challengeUrl } = challengeData;

            const contentKey = type === ADDONS_CHECKOUT ? 'checkoutCart' : 'checkoutBooking';
            const messages = get(paymentsContent(contentKey), 'sections', []);
            const baseUrl = window.location.origin;
            const iFrameUrl = `${baseUrl}/myjourney/paymentChallengeRenderer.html?creq=${challengeReq}&challengeUrl=${encodeURIComponent(
              challengeUrl
            )}`;

            return {
              id: MODALS.PAYMENT_CHALLENGE,
              iFrameUrl,
              challengeReq: challengeData?.challengeReq,
              labels: {
                cancel,
                close,
                redirectMessage: getCmsLabel(messages, 'challengeRedirectMessage', 'title'),
              },
              isModalOpen: isActive,
              challengeResponse: challengeData?.challengeResponse || {},
            };
          })
      )
    ),
    getChallengeTimeoutModalData: new Selector(({ getTabContent }) =>
      createSelector([getTabContent, getModalIsActive], (paymentsContent, isActive) =>
        memoize((type) => {
          const contentKey = type === ADDONS_CHECKOUT ? 'checkoutCart' : 'checkoutBooking';
          const messages = get(paymentsContent(contentKey), 'sections', []);
          return {
            title: getCmsLabel(messages, 'challengeTimeoutTitle', 'title'),
            message: getCmsLabel(
              messages,
              `challengeTimeoutBodyCopy${type === ADDONS_CHECKOUT ? 'Cart' : 'Booking'}`,
              'title'
            ),
            button: {
              buttonText: getCmsLabel(
                messages,
                `challengeTimeoutCTA${type === ADDONS_CHECKOUT ? 'Cart' : 'Booking'}`,
                'title'
              ),
            },
            isModalOpen: isActive,
          };
        })
      )
    ),
    getAllowScheduledPayment: new Selector(() =>
      createSelector(
        [
          getBalanceDue,
          getDaysToGo,
          getCountryCodeFromCurrency,
          (state) => getFlagValue(state)(MVJ_FLAG_VARIABLES.SCHEDULED_PAYMENT_COUNTRIES),
          getUserType,
          getBookingDetails,
        ],
        (balanceDue, daysToGo, countryCode, allowedCountries, userType, bookingDetails) => {
          const evoUICode = get(bookingDetails, 'agent.Evo-UI-Code');
          const isDirect = evoUICode === BOOKING_TYPE.DIRECT;
          const countryAllowed = allowedCountries?.includes(countryCode);
          if (!countryAllowed || !isDirect || balanceDue <= 0) {
            return SCHEDULED_PAYMENT_STATE.HIDDEN;
          }
          if (userType === USER_TYPES.CSA) {
            return SCHEDULED_PAYMENT_STATE.DISABLED;
          }
          if (balanceDue <= 0 || daysToGo < 30) {
            return SCHEDULED_PAYMENT_STATE.DISABLED;
          }
          return SCHEDULED_PAYMENT_STATE.ALLOWED;
        }
      )
    ),
    getBookingHasScheduledPaymentPending: new Selector(() =>
      createSelector(
        [getBookingDetails],
        ({ achBanksOnFile = [] }) => !!achBanksOnFile.find(({ selectedFinalPayment }) => selectedFinalPayment === 'Y')
      )
    ),
    getSchedulePaymentSection: new Selector(({ getFormattedCmsLabels }) =>
      createSelector(
        [
          getAllowScheduledPayment,
          (state) => getPageTabLabels(state)(PAYMENTS_CART, CRUISE_PAYMENTS),
          getFormattedCmsLabels,
          getBookingDetails,
          getBalanceDue,
          getBalanceDueDate,
          getCountryCodeFromCurrency,
        ],
        (
          schedulePaymentAllowed,
          { labels: { scheduleAch, finalPayment, changeAch } },
          cmsLabels,
          { achBanksOnFile = [] },
          balanceDue,
          balanceDueDate,
          countryCode
        ) => {
          if (schedulePaymentAllowed === SCHEDULED_PAYMENT_STATE.HIDDEN || balanceDue === 0) {
            return null;
          }

          const paymentScheduled = achBanksOnFile.find(({ selectedFinalPayment }) => selectedFinalPayment === 'Y');
          const payments = [];
          if (paymentScheduled) {
            const totalDue = balanceDue + calculateACHSavings(balanceDue);
            payments.push({
              label: finalPayment,
              amount: formatMoney(totalDue, 2, countryCode),
              body: replaceCMSTokenWithValue(cmsLabels.paymentScheduled, [
                { key: 'PHONE', value: cmsLabels.shedulePaymentPhone },
              ]),
              date: moment(balanceDueDate).format(REGIONAL_LONG_DATES.NA),
            });
          }

          const today = moment();
          if (today.isSameOrAfter(moment(balanceDueDate))) {
            return null;
          }

          return {
            title: scheduleAch,
            noPaymentScheduled: {
              heading: cmsLabels.noScheduledPayments,
              body: cmsLabels.noScheduledPaymentBody,
            },
            payments,
            changeAch: schedulePaymentAllowed === SCHEDULED_PAYMENT_STATE.DISABLED ? '' : changeAch,
            disableChange: schedulePaymentAllowed === SCHEDULED_PAYMENT_STATE.DISABLED,
          };
        }
      )
    ),
    getConfirmationModalData: new Selector(({ getTabContentSections }) =>
      createSelector(
        [
          (state) => getTabContentSections(state)(CRUISE_PAYMENTS),
          (state) => getPageTabLabels(state)(PAYMENTS_CART, CRUISE_PAYMENTS),
        ],
        (sections, { buttons: { cancel }, labels: { areYouSure, ok } }) => {
          const messages = sections?.[0]?.messages || [];

          return {
            confirmText: ok,
            cancelText: cancel,
            title: areYouSure,
            message: getCmsLabel(messages, 'addNewAccountWarning', 'text'),
          };
        }
      )
    ),
  },
});

const {
  creators: {
    receiveContent,
    receiveTabContent,
    receiveTokenExConfiguration,
    receivePaymentMethods,
    receiveOnboardCC,
    setOnboardCCValidationFlag,
    setPaymentMethodChangeType,
    pullFromHoldingArea,
    updateAddCardFlag,
    updateNextLocation,
    receivePaymentChallenge,
    receiveChallengeResponse,
  },
  selectors: {
    getOnboardCCGuests,
    getNextLocation,
    getIsPsd2,
    getPaymentChallenge,
    getTabContent,
    getCartTotalBreakdown,
  },
} = paymentsStore;

const { getManagePaymentMethodInitialValues } = paymentsStore.selectors;

export const gotoNextLocationIfNeeded = () => (dispatch, getState) => {
  const nextLocation = getNextLocation(getState());
  if (nextLocation) {
    navigateTo(nextLocation);
    dispatch(updateNextLocation(null));
  }
};

export const fetchPaymentsPageContent = () => (dispatch, getState) => {
  const url = buildUrl('/pages/paymentsCart', ['voyage.type'], getBookingDetails(getState()));
  dispatch(
    getData({
      url,
      store: paymentsStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export const fetchTabContent = (
  tab,
  refreshData = true,
  updateImmediately = true,
  fromHolding,
  refreshAfterTimeout
) => (dispatch, getState) => {
  const tabUrl = getPageTabUrl(PAYMENTS_CART, tab);
  const tabName = getTabReference(tabUrl);

  if (fromHolding) {
    return dispatch(pullFromHoldingArea(`${tab}.content`));
  }
  const bookingDetails = getBookingDetails(getState());
  const { comboBookings, passengers, voyage } = bookingDetails;

  let params = { shipId: get(bookingDetails, 'ship.shipCode') };

  if (tabName === ADDONS) {
    const formatDate = (dateTime) => dateTime && dateTime.split('T')[0];
    const comboVoyageIds = comboBookings.map(({ voyageId }) => voyageId).join('|');
    const formattedComboBookings = comboBookings
      .reduce((acc, combo) => {
        acc.push(`${combo.invoice}:${combo.shipCode}`);
        return acc;
      }, [])
      .join('|');

    params = {
      ...params,
      voyageIds: comboVoyageIds || get(voyage, 'id', ''),
      startDate: formatDate(voyage?.startDate),
      endDate: formatDate(voyage?.endDate),
      comboBookings: formattedComboBookings,
      isSingleBooking: passengers.length === 1,
      isTimeoutRefresh: refreshAfterTimeout,
    };
  }

  const url = buildUrl(
    `/paymentsCart/${tabName}`,
    ['office', 'currency', 'bookingNumber', 'voyage.type'],
    bookingDetails,
    params
  );
  let callId = '';
  const config = {};

  if (PMT_REC_COUNT) {
    callId = `PaymentsStore.fetchTabContent.${tabName}`;
    const trackedCalls = getTrackedCalls(getState());
    const count = trackedCalls?.[callId] || 0;
    config.tracestate = `mvjGroup=MVJ_${(tab || '').toUpperCase()};mvjreqcount=${count}`;
  }

  return dispatch(
    getData({
      url,
      store: paymentsStore,
      node: `${tab}.content`,
      creator: receiveTabContent,
      tab,
      refreshData,
      updateImmediately,
      config,
    })
  ).then((res) => {
    if (callId) {
      dispatch(trackCallCount(callId));
    }
    return Promise.resolve(res);
  });
};

export const fetchPaymentsMethodContent = (method) => (dispatch, getState) => {
  const url = buildUrl(`/paymentsMethod/${method}`, ['office', 'currency'], getBookingDetails(getState()));
  dispatch(
    getData({
      url,
      store: paymentsStore,
      node: `${method}.content`,
      creator: receiveTabContent,
      tab: method,
      refreshData: true,
    })
  );
};

export const fetchPaymentMethods = () => (dispatch, getState) => {
  const url = buildUrl('/wallets', ['office', 'currency', 'voyage.id', 'bookingNumber'], getBookingDetails(getState()));

  return dispatch(
    getData({
      url,
      store: paymentsStore,
      node: PAYMENT_METHODS,
      creator: receivePaymentMethods,
      refreshData: true,
    })
  );
};

export const fetchOnboardCC = () => (dispatch, getState) => {
  const state = getState();

  const bookingDetails = getBookingDetails(state);
  const { lastName } = get(bookingDetails, 'passengers[0]', {});

  const url = buildUrl(
    '/gif',
    ['office', 'currency', 'voyage.id', 'bookingNumber', 'ship.stateroomNumber', 'lastName'],
    {
      ...bookingDetails,
      lastName: btoa(lastName),
    },
    {
      sessionId: getSessionId(state),
    }
  );

  return dispatch(
    getData({
      url,
      node: `${ONBOARD_CREDIT_CARD}.gifDetails`,
      store: paymentsStore,
      creator: receiveOnboardCC,
    })
  );
};

export const fetchTokenExConfiguration = (paymentMethod, forceRefresh = undefined) => (dispatch, getState) => {
  const { currency } = getBookingDetails(getState());
  const countryCode = decodeCountryCodeFromCurrency(currency);

  let url = buildUrl('/tokenExConfig', ['countryCode', 'paymentMethod'], {
    countryCode,
    paymentMethod,
  });

  if (ENVIRONMENT_CODE === 'local') {
    url += `?originUrl=${btoa(window.location.origin)}`;
  }

  const storeNode = `tokenEx.${paymentMethod}`;
  return dispatch(
    getData({
      url,
      store: paymentsStore,
      node: storeNode,
      creator: receiveTokenExConfiguration.bind(null, paymentMethod),
      refreshData: forceRefresh,
    })
  );
};

export const submitManagePaymentsMethod = (payload) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);

  const updateObject = {
    values: payload,
  };

  const nameOnAccount = get(payload, 'creditCardDetails.nameOnAccount');
  if (nameOnAccount) {
    const transformedName = nameOnAccount.toUpperCase();
    updateObject.values.creditCardDetails.nameOnAccount = transformedName;
  }

  const { id, preventRedirect } = payload;
  const method = id ? 'put' : 'post';

  updateObject.url = buildUrl(
    `/wallets/${method}`,
    ['office', 'currency', 'voyage.id', 'bookingNumber'],
    bookingDetails
  );

  if (id) {
    updateObject.url = buildUrl(updateObject.url, ['id'], payload);
  }
  const request = id ? putData : postData;

  return dispatch(request(updateObject)).then(({ isSuccessful, data }) => {
    if (isSuccessful && data && data.status !== false) {
      const { ADD, EDIT } = PAYMENT_METHOD_CHANGE_TYPE;
      dispatch(setPaymentMethodChangeType(id ? EDIT : ADD));
      dispatch(fetchPaymentMethods()).then(() => {
        if (!id && !preventRedirect) {
          dispatch(setPaymentMethodChangeType(id ? EDIT : ADD));
          if (data.paymentOptionType.toLowerCase() === ACH) {
            history.push(`${APP_PATHS.PAYMENT_METHODS}/ach/${data.paymentOptionID}`);
          }
        }
      });
      return null;
    }
    return 'Payment failed';
  });
};

export const submitOnboardCreditCard = (values, selectedGuest, isSharingCC) => (dispatch, getState) => {
  const state = getState();
  const updateUserInfo = getUpdateUserData(state);
  const bookingDetails = getBookingDetails(state);
  const { lastName, firstName } = bookingDetails.passengers[selectedGuest - 1];
  const { address, creditCardDetails } = values;
  const queryParams = { validateAddress: false };
  const url = buildUrl(
    '/gif/put',
    ['office', 'currency', 'voyage.id', 'bookingNumber', 'ship.stateroomNumber', 'lastName', 'passengerId'],
    {
      ...bookingDetails,
      lastName: btoa(lastName),
      passengerId: selectedGuest,
    },
    queryParams
  );
  const lastFourDigits = creditCardDetails.token.substring(12);
  const ccDetails = {
    zip: address.zip,
    lastName,
    country: address.country,
    cvv: 0,
    expires: creditCardDetails.expirationDate,
    address: address.addressLineOne,
    city: address.city,
    type: creditCardDetails.ccType,
    token: creditCardDetails.token,
    firstName,
    sequence: '',
    creditCardNumber: `************${lastFourDigits}`,
    forCabinUse: isSharingCC,
    debitCard: false,
    forFinalPayment: false,
    totalCharges: 0,
    state: address.state,
  };
  const passengers = getOnboardCCGuests(state).map((passenger) => {
    if (isSharingCC) {
      return {
        ...passenger,
        ccDetails,
      };
    }
    if (passenger.passengerID === selectedGuest && !isSharingCC) {
      return {
        ...passenger,
        ccDetails,
      };
    }
    return passenger;
  });

  return new Promise((resolve, reject) => {
    dispatch(
      putData({
        url,
        values: {
          updateUserInfo,
          passengers,
        },
      })
    ).then((response) => {
      if (response.isSuccessful) {
        dispatch(receiveOnboardCC(response.data.gif));
        dispatch(updateAddCardFlag(false));
        dispatch(reset(FORMS.ONBOARD_CREDIT_CARD));
        return resolve(response);
      } else if (!response?.isSuccessful) {
        dispatch(setOnboardCCValidationFlag(true));
      }
      return reject();
    });
  });
};

export const removeOnboardCreditCard = () => (dispatch, getState) => {
  dispatch(setSubmitFlag(true));
  const state = getState();
  const updateUserInfo = getUpdateUserData(state);
  const bookingDetails = getBookingDetails(state);
  const { id } = getModalData(state);
  const { lastName } = get(bookingDetails, 'passengers[0]', {});
  const queryParams = { validateAddress: false };
  const url = buildUrl(
    '/gif/put',
    ['office', 'currency', 'voyage.id', 'bookingNumber', 'ship.stateroomNumber', 'lastName', 'passengerId'],
    {
      ...bookingDetails,
      lastName: btoa(lastName),
      passengerId: id,
    },
    queryParams
  );

  const passengers = getOnboardCCGuests(state).map((passenger) => {
    if (passenger.passengerID === parseInt(id, 10)) {
      return {
        ...passenger,
        ccDetails: {},
      };
    }
    return passenger;
  });

  return new Promise((resolve, reject) => {
    dispatch(
      putData({
        url,
        values: {
          updateUserInfo,
          passengers,
        },
      })
    ).then((response) => {
      if (response.isSuccessful) {
        dispatch(clearModal());
        dispatch(setSubmitFlag(false));
        dispatch(receiveOnboardCC(response.data.gif));
        return resolve(response);
      }
      return reject();
    });
    dispatch(setSubmitFlag(false));
  });
};

export const mapPaymentDetailsToPayload = (
  {
    id,
    addressLine1,
    addressLine2,
    city,
    zip,
    country,
    state = '',
    paymentMethodType,
    name,
    cardType,
    expiryMonth,
    expiryYear,
    nameOnAccount,
    accountType,
    bankRouting,
    firstName,
    middle,
    lastName,
    save,
    cvv = '',
    challengeData,
    bookingCountry,
  },
  token,
  schedulePayment
) => {
  const isPsd2 = PSD2_PAYMENT_COUNTRIES.includes(bookingCountry);
  const payload = {
    // TODO: separate ID from payload; it's used to determine POST vs PATCH
    // not required in request body.
    id,
    save,
    address: {
      addressLineOne: addressLine1,
      addressLineTwo: addressLine2,
      city,
      zip,
      country,
      state,
    },
  };
  if (!nameOnAccount && paymentMethodType !== CREDIT_CARD) {
    return {};
  }
  if (paymentMethodType === CREDIT_CARD) {
    payload.creditCardDetails = {
      nameOnAccount: name,
      ccType: cardType,
      expirationDate: expiryMonth + expiryYear,
      token,
    };
    if (isPsd2 && cvv) {
      payload.creditCardDetails.cvv = cvv;
      if (challengeData?.challengeResponse?.loaded) {
        const {
          salesCCID,
          threeDSVersion,
          paymentTransactionId,
          challengeResponse: { cres },
        } = challengeData;
        payload.creditCardDetails.salesCCID = salesCCID;
        payload.creditCardDetails.threeDSVersion = threeDSVersion;
        payload.creditCardDetails.challengeRes = cres;
        payload.creditCardDetails.paymentTransactionId = paymentTransactionId;
      }
      if (challengeData?.paRes) {
        const { salesCCID, threeDSVersion, paRes, paymentTransactionId, challengeSessionToken } = challengeData;
        payload.creditCardDetails.salesCCID = salesCCID;
        payload.creditCardDetails.threeDSVersion = threeDSVersion;
        payload.creditCardDetails.challengeSessionToken = challengeSessionToken;
        payload.creditCardDetails.challengeRes = paRes;
        payload.creditCardDetails.paymentTransactionId = paymentTransactionId;
      }
    }
  } else {
    payload.achDetails = {
      [`forPassenger${nameOnAccount}`]: true,
      firstName,
      middle,
      lastName,
      acctType: accountType,
      routingNumber: bankRouting,
      token,
      useACHFinalPayment: schedulePayment,
    };
  }
  return payload;
};

export const getPaymentMethod = (values, type) => {
  if (type === SCHEDULE_PAYMENT) {
    return ACH;
  }
  const { paymentMethod } = values;
  if (paymentMethod === DEBIT_CARD) {
    return CREDIT_CARD;
  }
  return paymentMethod;
};

export const clearCartAfterTimeout = () => async (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const { bookingNumber } = bookingDetails;
  const { items: cartItems } = getTabContent(state)(ADDONS);
  await dispatch(fetchBookings(bookingNumber, true, false, false)).then(async ({ booking: newBookingDetails }) => {
    const { passengers } = newBookingDetails;
    const allItemsPurchased = cartItems.every((item) => {
      const { serviceCode, voyageId, forPassenger, extensionType } = item;
      return forPassenger.every(({ id, inCart }) => {
        if (inCart) {
          // check if it is on new booking
          if (extensionType === 'S') {
            // check for shoreExcursions
            const paxExcursions = passengers?.find((pax) => pax.passengerNumber === id)?.excursions || [];
            return paxExcursions?.find(
              (bookedExcursion) => bookedExcursion.serviceCode === serviceCode && bookedExcursion.voyageId === voyageId
            );
          } else {
            // check for addons
            const paxAddons = passengers?.find((pax) => pax.passengerNumber === id)?.addons || [];
            return paxAddons?.find(
              (bookedExcursion) => bookedExcursion.serviceCode === serviceCode && bookedExcursion.voyageId === voyageId
            );
          }
        }
        // ignore if item was not in cart
        return true;
      });
    });

    // if allItemsPurchased is true, payment succeeded post timeout.
    if (allItemsPurchased) {
      // delete cart
      const addonIds = cartItems.map((item) => item.itemID);
      await dispatch(removeJourneyAddons(addonIds));
    }
  });
};

export const mapAndSend = (values, token, type, resolve, rejectPromise, setSchedulePayment) => (dispatch, getState) => {
  const state = getState();
  const passengers = getPassengers(state);
  const challengeData = getPaymentChallenge(state);
  const { paymentAmount, noPaymentRequired } = values;
  const paymentMethod = getPaymentMethod(values, type);
  const payload = {
    email: passengers[0].email,
  };

  const cartBreakdown = getCartTotalBreakdown(getState());
  const paymentAmountByType = {
    [ADDONS_CHECKOUT]: cartBreakdown.subtotal,
    [CRUISE_CHECKOUT]: paymentAmount,
    [SCHEDULE_PAYMENT]: 0,
  };

  const { cardType } = values || {};
  const accountValues = values?.account ? values.account : values;
  if (!noPaymentRequired) {
    payload.payment = {
      paymentMethod,
      totalCharges: paymentAmountByType[type],
      ...mapPaymentDetailsToPayload(
        {
          ...accountValues,
          cardType,
          paymentMethodType: paymentMethod,
          challengeData,
          bookingCountry: values?.bookingCountry,
        },
        token,
        type === SCHEDULE_PAYMENT || setSchedulePayment
      ),
    };
  }
  return dispatch(submitPayment(payload, type))
    .then((errorMessage) => {
      if (errorMessage) {
        rejectPromise(errorMessage);
      } else {
        resolve(true);
      }
    })
    .catch((error) => {
      rejectPromise(error);
      throw error;
    });
};

export const submitPayment = (payload, checkoutType) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const cartId = getCartId(state);
  const sessionId = getIdToken(state);
  const updateUserInfo = getUpdateUserData(state);
  const userDataEmail = getEmail(state);
  const isPsd2 = getIsPsd2(state);
  const paymentChallenge = getPaymentChallenge(state);
  const paymentPayload = payload.payment
    ? {
        sessionId,
        ...payload.payment,
        address: { ...payload.payment?.address, state: payload.payment?.address?.state || '' },
      }
    : undefined;
  const updateObject = {
    values: {
      updateUserInfo: {
        ...updateUserInfo,
        email: userDataEmail,
      },
      email: userDataEmail,
      payment: paymentPayload,
    },
  };

  if (checkoutType === ADDONS_CHECKOUT) {
    updateObject.url = buildUrl('/payment/checkoutCart', ['office', 'currency', 'bookingNumber', 'cartId'], {
      ...bookingDetails,
      cartId,
    });
    updateObject.values = {
      ...updateObject.values,
      ...getUpdateBookingData(state),
      shipId: bookingDetails.ship.shipCode,
    };
    delete updateObject.values.voyageId;
  } else {
    updateObject.url = buildUrl(
      '/payment/booking',
      ['office', 'currency', 'bookingNumber', 'voyage.id', 'ship.stateroomNumber'],
      bookingDetails
    );
  }

  // Since UK does not currently allow scheduledPayments, If that is updated we will need to verify the CVV field passing through correctly based on booking office
  if (isPsd2) {
    let postChallengeRedirectURL = `${API_HOST}/payment/challenge/${bookingDetails?.bookingNumber}`;
    if (ENVIRONMENT_CODE === 'local') {
      postChallengeRedirectURL += `?originUrl=${encodeURIComponent(window.location.origin)}`;
    }
    updateObject.values.browserDetails = getPaymentBrowserDetails({ postChallengeRedirectURL });
  }

  let callId = '';
  if (PMT_REC_COUNT) {
    callId = `PaymentsStore.checkout_${checkoutType}`;
    const trackedCalls = getTrackedCalls(getState());
    const count = trackedCalls?.[callId] || 0;
    if (!updateObject.config) {
      updateObject.config = {};
    }
    updateObject.config.tracestate = `mvjgroup=MVJ_${(checkoutType || '').toUpperCase()};requestCount=${count};`;
  }

  return dispatch(postData(updateObject)).then(async (res) => {
    if (PMT_REC_COUNT && callId) {
      dispatch(trackCallCount(callId));
    }
    let paymentResponse = {};
    if (checkoutType === ADDONS_CHECKOUT) {
      // Map challenge data from response
      let paymentData = {};
      if (res?.data && Array.isArray(res.data)) {
        paymentData = res?.data?.find((obj) => obj?.challengeReq);
      }
      paymentResponse = paymentData;
    } else {
      const { postPaymentResponse } = res.data;
      paymentResponse = postPaymentResponse;
    }

    if (paymentResponse?.challengeUrl) {
      if (!paymentChallenge?.challengeUrl) {
        const challengeVersion = paymentResponse?.threeDSVersion;
        if (challengeVersion?.startsWith('1.') && paymentResponse?.paRes) {
          await dispatch(receivePaymentChallenge(paymentResponse));
          // re-submit payment request with paRes
          const { salesCCID, threeDSVersion, paRes, paymentTransactionId, challengeSessionToken } = paymentResponse;
          payload.payment.creditCardDetails.salesCCID = salesCCID;
          payload.payment.creditCardDetails.threeDSVersion = threeDSVersion;
          payload.payment.creditCardDetails.challengeSessionToken = challengeSessionToken;
          payload.payment.creditCardDetails.challengeRes = paRes;
          payload.payment.creditCardDetails.paymentTransactionId = paymentTransactionId;

          return dispatch(submitPayment(payload, checkoutType));
        } else if (!paymentResponse?.paRes) {
          dispatch(receivePaymentChallenge(paymentResponse));
          dispatch(setViewAndShowModal(MODALS.PAYMENT_CHALLENGE, res.data.paymentResponse));
          return null;
        }
      }
    }

    const handleSuccess = async () => {
      dispatch(receivePaymentChallenge({}));
      await dispatch(reloadBookings()).then((bookingData) => {
        const pathname = window.location.pathname;
        initializeBookingData(bookingData?.booking, pathname);
      });
      await dispatch(fetchNotifications(true));
      if (checkoutType === ADDONS_CHECKOUT) {
        dispatch(handleSuccessfulCartUpdate());
      }
      if (get(updateObject, 'values.payment.save')) {
        await dispatch(
          submitManagePaymentsMethod({
            ...updateObject.values.payment,
            preventRedirect: true,
          })
        );
      }

      if ([ADDONS_CHECKOUT, CRUISE_CHECKOUT].includes(checkoutType)) {
        history.push(
          `${APP_PATHS.PAYMENTS}/${
            checkoutType === ADDONS_CHECKOUT ? 'add-ons' : 'cruise-payments'
          }/payment-confirmation`
        );
      }

      if (checkoutType === SCHEDULE_PAYMENT) {
        dispatch(setViewAndShowModal('notification-modal'));
      }

      return null;
    };

    if (res.isSuccessful) {
      return handleSuccess();
    }
    const evoErrors = getEvolutionErrors(state);
    const mappedError = getEvoErrorMessage(res.data, evoErrors);

    // Verify if payment succeeded after timeout was returned
    const wasTimeout = getWasTimeout(res);

    const { bookingNumber, payments } = bookingDetails;
    if (wasTimeout) {
      // handle cart checkout flow
      // get current cartItems
      if (checkoutType === ADDONS_CHECKOUT) {
        await dispatch(clearCartAfterTimeout());
      }

      // handle cruise payment case
      const { totalCharges } = payload?.payment;
      const { totalAmountPaid } = payments;
      return dispatch(fetchBookings(bookingNumber, true, false, false)).then(({ booking: newBookingDetails }) => {
        const {
          payments: { totalAmountPaid: currentAmountPaid },
        } = newBookingDetails;
        if (totalAmountPaid + totalCharges === currentAmountPaid) {
          dispatch(fetchBookings(bookingNumber, true, true, true));
          return handleSuccess();
        } else {
          dispatch(fetchBookings(bookingNumber, true, true, true));
          return mappedError.errorCode ? mappedError : 'Payment failed';
        }
      });
    }
    // special handling for EVO 1672 error in cruise payment flow
    if ([1672, '1672'].includes(mappedError?.errorCode) && checkoutType === CRUISE_CHECKOUT) {
      const { totalCharges } = payload?.payment;
      const { totalAmountPaid } = payments;
      return dispatch(fetchBookings(bookingNumber, true, false, false)).then(({ booking: newBookingDetails }) => {
        const {
          payments: { totalAmountPaid: currentAmountPaid },
        } = newBookingDetails;
        if (totalAmountPaid + totalCharges === currentAmountPaid) {
          dispatch(fetchBookings(bookingNumber, true, true, true));
          return handleSuccess();
        } else {
          dispatch(fetchBookings(bookingNumber, true, true, true));
          return mappedError.errorCode ? mappedError : 'Payment failed';
        }
      });
    }

    return mappedError.errorCode ? mappedError : 'Payment failed';
  });
};

export const removePaymentOption = (optionId) => (dispatch, getState) => {
  dispatch(setSubmitFlag(true));
  const bookingDetails = getBookingDetails(getState());

  const deleteObject = {
    url: buildUrl('/wallets/delete', ['office', 'currency', 'voyage.id', 'bookingNumber'], bookingDetails),
  };

  deleteObject.url = `${deleteObject.url}/${optionId}`;

  return dispatch(deleteData(deleteObject)).then(async ({ isSuccessful }) => {
    if (isSuccessful) {
      const response = await dispatch(fetchPaymentMethods());
      dispatch(clearModal());
      dispatch(setSubmitFlag(false));
      return response;
    }
    dispatch(setSubmitFlag(false));
    return NOTIFICATION_TYPES.FAILURE;
  });
};

export const removeJourneyAddons = (addonIds, refreshCart = true) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const cartId = getCartId(state);

  let deleteDispatchMe = null;

  // Delete a single ShorEx for only 1 pax?
  // This is required so the calendar is updated correctly - Ex. if a ShoreEx was already purchased for 1 pax,
  // 2nd pax is then added to cart, and then the cart item is removed.
  if (!Array.isArray(addonIds) || (Array.isArray(addonIds) && addonIds.length === 1)) {
    const addOnId = Array.isArray(addonIds) ? addonIds[0] : addonIds;
    const { getTabContent } = paymentsStore.selectors;
    const { items: journeyContentItems = [] } = getTabContent(state)(ADDONS);
    const foundItem = journeyContentItems.find((i) => i.itemID === addOnId);
    if (foundItem && foundItem.extensionType === EXTENSION_TYPES.SHOREX) {
      const isForBothPax = foundItem.forPassenger.every((pax) => pax.inCart === true);
      if (!isForBothPax) {
        const fromDate = moment(foundItem.startTime).format('YYYY-MM-DD');
        deleteDispatchMe = deleteSinglePaxShoreExFromCart(foundItem.itemID, foundItem.inventoryCode, fromDate);
      }
    }
  }

  // Default to use the cart delete API.
  if (deleteDispatchMe === null) {
    const deleteObject = {
      url: buildUrl('/cart', ['office', 'currency', 'itemsLiteral', 'cartId', 'calendarId'], {
        ...bookingDetails,
        itemsLiteral: 'items',
        cartId,
      }),
      data: addonIds,
    };

    deleteDispatchMe = deleteData(deleteObject);
  }

  return new Promise((resolve, reject) => {
    dispatch(deleteDispatchMe).then(({ isSuccessful }) => {
      if (isSuccessful) {
        if (refreshCart) {
          dispatch(fetchCart(true));
        }
        return resolve({ isSuccessful });
      }
      return reject(NOTIFICATION_TYPES.FAILURE);
    });
  });
};
export const getCartItems = () => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);

  const url = buildUrl('/cart', ['office', 'currency', 'bookingNumber', 'voyage.id'], {
    ...bookingDetails,
  });
  return dispatch(
    getData({
      url,
      store: paymentsStore,
      node: 'cartItemsWhenAdded',
    })
  );
};

export const updateUserData = (formName, passengerId, fieldNamePrefix) => (dispatch, getState) => {
  const passenger = getPassenger(getState())(passengerId);
  const { firstName, middle, lastName } = passenger;
  const fields = ['firstName', 'middle', 'lastName'];

  dispatch(change(formName, 'name', `${firstName} ${middle ? `${middle} ` : ''}${lastName}`));

  fields.forEach((key) => {
    let name = key;
    if (fieldNamePrefix) {
      name = `${fieldNamePrefix}.${name}`;
    }
    return dispatch(change(formName, name, passenger[key]));
  });
};

export const updatePaymentMethodForm = (savedAccount, formName, fieldNamePrefix, paymentMethod, initialValues) => (
  dispatch,
  getState
) => {
  const booking = getState();
  if (savedAccount !== ADD_NEW_ACCOUNT) {
    const option = getManagePaymentMethodInitialValues(booking)(savedAccount);
    if (paymentMethod === ACH) {
      const passenger = getPassenger(booking)(option.forPassengerNumber);
      dispatch(change(formName, `${fieldNamePrefix}.firstName`, `${passenger.firstName}`));
      dispatch(change(formName, `${fieldNamePrefix}.lastName`, `${passenger.lastName}`));
    }

    Object.keys(option).forEach((key) => {
      let name = key;
      if (fieldNamePrefix) {
        name = `${fieldNamePrefix}.${name}`;
      }
      dispatch(change(formName, name, option[key]));
    });
  } else {
    dispatch(change(formName, 'account', initialValues.account));
  }
};

export const isOnboardCCValidThroughCruise = (state) => {
  const { voyage } = getBookingDetails(state);

  const month = formValueSelector(FORMS.ONBOARD_CREDIT_CARD)(state, 'expiryMonth') || '';
  const year = formValueSelector(FORMS.ONBOARD_CREDIT_CARD)(state, 'expiryYear') || '';

  if (!(month && year)) {
    return false;
  }

  const expiryDate = moment(month + year, 'MMYY');
  return !expiryDate.isSameOrAfter(moment(voyage.disembarkDate), 'month');
};

export const fetchPaymentChallengeResponse = () => (dispatch, getState) => {
  const state = getState();
  const { bookingNumber } = getBookingDetails(state);
  const url = buildUrl('/payment/challenge/get', ['bookingNumber'], { bookingNumber });
  return dispatch(
    getData({
      url,
      store: paymentsStore,
      node: 'paymentChallenge',
      creator: receiveChallengeResponse,
    })
  );
};

export const postChallengeFailed = () => (dispatch, getState) => {
  const state = getState();
  const { salesCCID } = getPaymentChallenge(state);
  const { office, currency, bookingNumber } = getBookingDetails(state);
  const paymentAmount = formValueSelector(FORMS.PAYMENT_CHECKOUT)(state, 'paymentAmount');

  const body = {
    bookingNumber,
    salesCCID,
    amount: paymentAmount,
  };

  const url = buildUrl('/payment/challenge/failed', ['office', 'currency'], { office, currency });

  return dispatch(
    postData({
      url,
      values: body,
    })
  ).then((res) => {
    dispatch(receivePaymentChallenge({}));
    return Promise.resolve(res);
  });
};

export default paymentsStore;

import { connect } from 'react-redux';
import userStore from '../../common/UserStore';
import InformationModal from './InformationModal';

const { getCurrency } = userStore.selectors;

const mapStateToProps = (state) => ({
  currency: getCurrency(state),
});

export default connect(mapStateToProps)(InformationModal);

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import moment from 'moment';
import {
  ADOBE_DTM_KEY,
  BOOKING_STATUSES,
  CURRENCY_COUNTRY,
  DAYS_TO_SPA_RESERVATION,
  ENVIRONMENT_CODE,
  GIFT_CODES,
  SERVICE_CODES,
  UTM_PAGE_TYPES,
  UTM_PAGE_TYPE_DEFAULT,
  UTM_SITE_CODE,
} from './Constants';
import history from './history';
import { sessionStorageService } from './Utils';

const initializeTagData = (tagData) => {
  fireAdobeEvent({
    ...tagData,
    ...getCommonAttributes(),
    event: 'page_data_initialized',
    event_name: 'page_data_initialized',
    acdl_event: tagData.acdl_event,
  });
};

const initializeNonceData = (initialData) => {
  const { cspNonce, ...tagData } = initialData || {};
  fireAdobeEvent({
    ...tagData,
    csp_nonce: cspNonce,
    ...getCommonAttributes(),
    event: 'nonce_data_initialized',
    event_name: 'nonce_data_initialized',
    acdl_event: tagData.acdl_event,
  });
};

const getCommonAttributes = () => {
  return {
    environment_code: ENVIRONMENT_CODE,
    site_code: UTM_SITE_CODE,
    origin_country_code: sessionStorageService('getItem', 'originCountry'),
  };
};

const getPageTypeFromPath = (pathname, fallback) => {
  let match = fallback;
  Object.keys(UTM_PAGE_TYPES).forEach((pageType) => {
    const basePath = `/${pathname.split('/')[1]}`;
    const matchingPaths = UTM_PAGE_TYPES[pageType];
    if (matchingPaths.includes(pathname) || matchingPaths.includes(basePath)) {
      match = pageType;
    }
  });

  return match;
};

// Function `codify` must match marketing site
const codify = (value) => {
  if (typeof value === 'undefined') {
    return '';
  }
  if (value == null) {
    return '';
  }
  return value
    .replace(/[^a-zA-Z0-9À-ž-\d\s]/gi, '')
    .replace(/[^a-zA-Z0-9À-ž-\d]/gi, '_')
    .replace('__', '_')
    .toLowerCase();
};

const sha1 = async (message) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

const hashSession = ({ bookingId, userType }) => {
  return sha1(JSON.stringify({ bookingId, userType }));
};

const cleanData = (data) =>
  Object.keys(data)
    .sort()
    .reduce((acc, key) => {
      if (!!data[key] || [0, '0'].includes(data[key])) {
        return { ...acc, [key]: data[key] };
      }
      return acc;
    }, {});

const fireAdobeEvent = (tagData) => {
  const adobeEvent = cleanData({
    ...tagData,
    event: tagData.event || tagData.event_name,
  });
  if (!Array.isArray(window.adobeDataLayer)) {
    window.adobeDataLayer = [];
  }
  window.adobeDataLayer.push(adobeEvent);
};

const fireViewEvent = (inputTagData) => {
  const tagData = cleanData(inputTagData);
  fireAdobeEvent(tagData);
};

const fireLinkEvent = (inputTagData) => {
  const tagData = cleanData(inputTagData);
  fireAdobeEvent(tagData);
};

const insertAdobeDtmScript = (nonce) => {
  try {
    let adobeDtmScriptElement = document.getElementById('adobeDtmScript');
    if (!adobeDtmScriptElement && window.sessionStorage) {
      const host = ADOBE_DTM_KEY || 'launch-2f0c08439ba2';
      const adobeDtmSrc = ['https://assets.adobedtm.com/2fea3a6f04fc/6d1171907c18/', host, '.min.js'].join('');
      adobeDtmScriptElement = document.createElement('script');
      adobeDtmScriptElement.id = 'adobeDtmScript';
      adobeDtmScriptElement.src = adobeDtmSrc;
      if (nonce) {
        adobeDtmScriptElement.setAttribute('nonce', nonce);
      }
      document.head.appendChild(adobeDtmScriptElement);
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex);
  }
};

const triggerPageView = (path = '/', pageTypeFallback = UTM_PAGE_TYPE_DEFAULT, additionalAttributes) => {
  const attributes = additionalAttributes && JSON.parse(JSON.stringify(additionalAttributes).replace(/:null/gi, ':""'));
  const bookingId = attributes?.booking_id || '';
  const pageType = getPageTypeFromPath(path, pageTypeFallback);
  const pageId = codify(window.location.pathname.replace('/myjourney', ''));
  const pageName = `${window.document.title} ${bookingId}`;
  const eventData = {
    acdl_event: 'view',
    page_id: pageId,
    page_name: pageName || '',
    page: path || '',
    page_type: pageType || pageTypeFallback,
    ...attributes,
    event: attributes?.event || 'spa_page_view',
    event_name: attributes?.event_name || 'spa_page_view',
  };
  fireViewEvent(eventData);
};

/* eslint-disable camelcase */
const triggerLinkEvent = ({ event_name, ...additionalAttributes }) => {
  const attributes = additionalAttributes && JSON.parse(JSON.stringify(additionalAttributes).replace(/:null/gi, ':""'));
  const eventData = {
    acdl_event: 'link',
    event_name,
    ...getCommonAttributes(),
    ...attributes,
  };
  fireLinkEvent(eventData);
};
/* eslint-enable camelcase */

const updateAdobeTargetSettings = (nonce) => {
  window.targetGlobalSettings = window.targetGlobalSettings || {};
  window.targetGlobalSettings.cspScriptNonce = nonce;
};

const reactPlugin = new ReactPlugin(history);

const initializeBookingData = (data, pathname) => {
  const stateRoomName = data?.ship?.selectedStateroom?.parentCategory?.displayName?.replace(/\s+/g, '_')?.toLowerCase();
  const bookingStatusLabels = {
    [BOOKING_STATUSES.CONFIRMED]: 'deposit',
    [BOOKING_STATUSES.OFFER]: 'courtesyhold',
    [BOOKING_STATUSES.FINAL]: 'fullpayment',
  };
  const bookingStatus = bookingStatusLabels[data?.bookingStatus] || 'cancelled';
  const extractAirData = (air, hasAirPlus) => {
    const { pre, post } = air;

    // eslint-disable-next-line no-confusing-arrow
    const formatSegment = (segment) =>
      segment.length > 0
        ? {
            gateway: segment[0]?.departureAirportCode,
            gateway_name: segment[0]?.departureAirport,
            air_cabin_class: segment[0]?.classOfService,
            air_cabin_class_display: segment[0]?.airClassName,
            price: segment[0]?.extraFee,
          }
        : {};

    const departureSegment = formatSegment(pre);
    const returnSegment = formatSegment(post);

    return Object.keys(departureSegment).length === 0 && Object.keys(returnSegment).length === 0
      ? {}
      : {
          departure: departureSegment,
          return: returnSegment,
          has_air_plus: hasAirPlus !== 'None',
        };
  };

  const extractExtensionsData = (extensions) => {
    return extensions.reduce((acc, extension) => {
      if (extension?.type === 'pre') {
        acc.pre = {
          code: extension?.invoiceCode,
          name: extension?.description,
          price: extension?.price,
          number_of_nights: extension?.numberOfNights,
        };
      } else if (extension?.type === 'post') {
        acc.post = {
          code: extension?.invoiceCode,
          name: extension?.description,
          price: extension?.price,
          number_of_nights: extension?.numberOfNights,
        };
      }
      return acc;
    }, {});
  };

  const hasSsbp =
    data?.passengers?.some((passenger) =>
      passenger?.addOns?.some((addOn) => addOn?.serviceCode === SERVICE_CODES.SSBP)
    ) || (data?.promotionalAmenities || []).some((amenity) => amenity?.giftCode === GIFT_CODES.SSBP_AMENITY_GIFT_CODE);

  const hasPpg =
    data?.passengers?.some((passenger) =>
      passenger?.addOns?.some((addOn) => addOn?.serviceCode === SERVICE_CODES.PPG)
    ) ||
    (data?.promotionalAmenities || []).some((amenity) =>
      (GIFT_CODES.PPG_AMENITY_GIFT_CODES || []).includes(amenity?.giftCode)
    );

  const passengersData = data?.passengers?.map((passenger) => {
    const air = extractAirData(passenger?.air, passenger?.hasAirPlus) || {};
    const extensions = extractExtensionsData(passenger?.extensions || []);

    return {
      pax_num: passenger?.passengerNumber,
      has_past_pax_id: data?.pastPassenger,
      extensions,
      air,
      options: {
        has_travel_protection_plan: passenger?.hasInsurance,
        price: passenger?.insuranceAmount,
      },
    };
  });

  const stateroomDisplayName = data?.ship?.selectedStateroom?.title.replace(/<\/?p>/g, '');

  const formatDateRemoveTime = (dateTime) => dateTime && dateTime.split('T')[0];
  const formatDateOnlyTime = (dateTime) => dateTime && dateTime.split('T')[1];
  const spaOpenDate = moment(data?.diningOpenDateTz, 'YYYY-MM-DD HH:mm:ss');
  if (moment(data?.voyage?.embarkDate).diff(spaOpenDate, 'days') === 0) {
    spaOpenDate.subtract(DAYS_TO_SPA_RESERVATION, 'days');
  }
  spaOpenDate.todayIsBeforeSpaOpenDate = moment().isBefore(spaOpenDate);
  const formattedDates = {
    cruiseEmbarkDate: formatDateRemoveTime(data?.voyage?.embarkDate),
    cruiseDisembarkDate: formatDateRemoveTime(data?.voyage?.disembarkDate),
    cruiseStartDate: formatDateRemoveTime(data?.voyage?.startDate),
    cruiseEndDate: formatDateRemoveTime(data?.voyage?.endDate),
    depositDueDate: formatDateRemoveTime(data?.payments?.depositDueDate),
    depositDueTime: formatDateOnlyTime(data?.payments?.depositDueDate),
    balanceDueDate: formatDateRemoveTime(data?.payments?.balanceDueDate),
    excursionOpenDate: formatDateRemoveTime(data?.excursionsOpenDateTz),
    spaOpenDate: spaOpenDate.format('YYYY-MM-DD'),
    diningOpenDate: formatDateRemoveTime(data?.diningOpenDateTz),
  };

  const getGifCompletionStatus = (passengers) => {
    if (!Array.isArray(passengers)) {
      return {};
    }

    return passengers.reduce((result, passenger, index) => {
      result[`pax${index + 1}`] = passenger?.GIFCompleted || false;
      return result;
    }, {});
  };

  triggerPageView(pathname, '', {
    booking_data_initialized: true,
    event: data?.event,
    event_name: data?.event_name,
    booking_id: data?.bookingNumber,
    booking_status: bookingStatus,
    number_of_guests: data?.passengers?.length,
    cruise_embark_date: formattedDates.cruiseEmbarkDate,
    cruise_disembark_date: formattedDates.cruiseDisembarkDate,
    cruise_start_date: formattedDates.cruiseStartDate,
    cruise_end_date: formattedDates.cruiseEndDate,
    cruise_display_name: data?.cruise?.name,
    cruise_direction: data?.voyage?.direction,
    cruise_type: data?.brand?.toLowerCase(),
    deposit_due_date: formattedDates.depositDueDate,
    deposit_due_date_time: data?.payments?.depositDueDate,
    deposit_due_time: formattedDates.depositDueTime,
    domain_country_code: (CURRENCY_COUNTRY[data?.currency] || '')?.toLowerCase(),
    full_payment_due_date: formattedDates.balanceDueDate,
    stateroom_name: stateRoomName,
    stateroom_category: data?.ship?.stateroomCategory,
    currency: data?.currency,
    booking_type: data?.directBooking ? 'direct' : 'agent',
    office_code: data?.office,
    stateroom_display_name: stateroomDisplayName,
    passengers: passengersData,
    has_silver_spirits_beverage_package: hasSsbp,
    has_prepaid_gratuities: hasPpg,
    excursion_open_date: formattedDates.excursionOpenDate,
    spa_open_date: formattedDates.spaOpenDate,
    dining_open_date: formattedDates.diningOpenDate,
    gross_total_price: data?.payments?.grossPrice,
    gif_completed: getGifCompletionStatus(data?.passengers),
  });
};

export {
  codify,
  getCommonAttributes,
  hashSession,
  initializeBookingData,
  initializeNonceData,
  initializeTagData,
  insertAdobeDtmScript,
  reactPlugin,
  triggerLinkEvent,
  triggerPageView,
  updateAdobeTargetSettings,
};

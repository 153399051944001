import { connect } from 'react-redux';
import { compose } from 'redux';

import { TAB_NAMES } from '../../../../common/Constants';
import { withContent } from '../../../../components';
import documentStore, { fetchTabContent } from '../../../documents/DocumentsStore';

import BoardingPass from './BoardingPass';

const { getBoardingPassData } = documentStore.selectors;

const tabName = TAB_NAMES.COMFORT_CHECK_IN;

const mapStateToProps = (state, { passengerNumber }) => {
  return {
    ...getBoardingPassData(state, passengerNumber),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(tabName)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(BoardingPass);

import InputText from '@viking-eng/input-text';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import { FieldWrapper } from '../..';
import { ACH, CREDIT_CARD } from '../../../common/Constants';
import { focus } from '../../../pages/payments/TokenEx';
import PropTypes from '../../../PropTypes';

import { logAdvisoryCode } from '../../../common/Utils';
import './TokenEx.scss';

const TokenEx = ({
  description,
  disabled,
  forEdit,
  images,
  invalidCardError,
  maskedAccountNumber,
  meta: { active, error, errorMessages, pristine, touched },
  paymentMethod,
  placeholder,
  tokenExConfig,
  bookingNumber,
}) => {
  const renderMaskedBankAccount = forEdit && paymentMethod === ACH && pristine && !active && error;
  const renderError = (!active && error && touched && !renderMaskedBankAccount) || invalidCardError;
  let errorMessage = error && !active ? errorMessages[paymentMethod] : '';
  errorMessage = invalidCardError || errorMessage;

  useEffect(() => {
    setTimeout(() => {
      const iframeDiv = document.getElementById('tokenExIframeDiv');
      if (!iframeDiv?.children?.length) {
        logAdvisoryCode({
          advisoryCode: '51194',
          url: window.location.href,
          message: `TokenEx Configuration error: ${tokenExConfig}`,
          logData: { bookingNumber },
        });
      }
    }, 3000);
  }, [tokenExConfig, bookingNumber]);

  return (
    <div className="tokenex-wrapper">
      <FieldWrapper description={description} images={images}>
        <div className={classNames('iframe-wrapper', { 'iframe-wrapper-disabled': disabled })}>
          <div
            className={classNames('iframe', { 'credit-card': paymentMethod === CREDIT_CARD })}
            id="tokenExIframeDiv"
          />
          {renderMaskedBankAccount && (
            <InputText
              key="maskedBankAccount"
              input={{
                id: 'maskedBankAccount',
                name: 'masked-bank-account',
                value: maskedAccountNumber,
                onChange: () => {}, // suppress react warning
                onFocus: () => {
                  focus();
                },
              }}
              type="text"
              placeholder={placeholder}
            />
          )}
          <div className="placeholder-wrapper">
            <div className={classNames('tokenex-placeholder', { error: renderError })}>{placeholder}</div>
          </div>
        </div>
        {renderError && <div className="validation-error-text">{errorMessage}</div>}
      </FieldWrapper>
    </div>
  );
};

TokenEx.propTypes = {
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(PropTypes.images)),
  placeholder: PropTypes.string,
  maskedAccountNumber: PropTypes.string,
  paymentMethod: PropTypes.paymentMethod,
  forEdit: PropTypes.bool,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.bool,
    errorMessages: PropTypes.objectOf(PropTypes.string),
    pristine: PropTypes.bool,
    touched: PropTypes.bool,
  }).isRequired,
  invalidCardError: PropTypes.string,
  disabled: PropTypes.bool,
};

TokenEx.defaultProps = {
  description: null,
  images: [],
  placeholder: null,
  maskedAccountNumber: '',
  paymentMethod: null,
  forEdit: false,
  invalidCardError: '',
  disabled: false,
};

export default TokenEx;

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withContent } from '../..';
import userStore from '../../../common/UserStore';
import paymentsStore from '../../../pages/payments/PaymentsStore';
import renderTokenExIframe from '../../../pages/payments/TokenEx';
import TokenEx from './TokenEx';

const { getTokenExMetaData, getTokenEx } = paymentsStore.selectors;

const { getBookingDetails } = userStore.selectors;

const mapStateToProps = (state) => {
  const { bookingNumber } = getBookingDetails(state);
  return {
    meta: getTokenExMetaData(state),
    tokenExConfig: getTokenEx(state),
    bookingNumber,
  };
};

const mapDispatchToProps = (dispatch, { paymentMethod, handleBankAccountChange }) => ({
  fetchContent: () => {
    return dispatch(renderTokenExIframe(paymentMethod, handleBankAccountChange, true));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(TokenEx);

import { Selector } from 'extensible-duck';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import min from 'lodash/min';
import set from 'lodash/set';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { change, getFormValues } from 'redux-form';
import { clearWaiter, getWaiterResponse } from 'redux-waiter';
import { getWaiter } from 'redux-waiter/lib/selectors';
import { createSelector } from 'reselect';
import commonStore from '../../common';
import { getData, getTraceparent, getWaiterData, postWaiterData } from '../../common/Api';
import {
  AIR_ADD_COLLECT,
  AIR_CABIN_CLASS,
  AIR_FLOW_NAMES,
  AIR_PATHS,
  AIR_PAYMENT_CHECKOUT_CODES,
  BOOKING_PACKAGE_TYPE,
  CANCELED_INTERVAL_ID,
  CHECKOUT_PROGRESS_STEPS,
  CHECKOUT_STEP_PROGRESS,
  COUNTRIES,
  DEVIATION_TYPES,
  FAQ_CATEGORIES,
  FILTER_BOX_DIFF,
  FLIGHT_INFO_SCARCITY,
  FLIGHT_INFO_STATUS,
  FORMS,
  FROM_PAGE,
  MODALS,
  MODAL_BUTTONS,
  MULTIPLE_AIRLINES,
  NOTIFICATION_TYPES,
  PAX_IDS_STRING,
  POLLING_TIMES,
  RESERVATION_NUMBERS,
  RESPONSE_STATUS,
  STANDARD_FLIGHT_CLASS_MAP,
  SUCCESS_STATUS_CODES,
  TAB_NAMES,
  UPDATESEATS_PROGRESS_STEPS,
  UPDATESEATS_STEP_PROGRESS,
  WAITER_NAMESPACES,
} from '../../common/Constants';
import { ERROR_CODES } from '../../common/forms/Validations';
import history from '../../common/history';
import modalStore, { clearSpecificModal, setViewAndShowModal } from '../../common/ModalStore';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import userStore, { fetchBookings } from '../../common/UserStore';
import {
  buildUrl,
  convertStringToStartCase,
  decodeCountryCodeFromCurrency,
  formatMoney,
  formatMoneyABE,
  getDiff,
  getPageHasBeenRefreshed,
  getPassengerAbbrevName,
  goTo,
  isolateCorrectAirSegments,
  replaceCMSTokenWithValue,
  roundDown,
  roundUp,
  scrollToElementIfNeeded,
} from '../../common/Utils';
import documentsStore from '../documents/DocumentsStore';
import { sortFAQByOrder } from '../help/HelpStore';

const {
  creators: { receiveGifData },
  selectors: { getGifPassengers },
} = documentsStore;
const { UNITED_KINGDOM, AUSTRALIA } = COUNTRIES;
const {
  GET_AIRPORTS,
  GET_AIR_SCHEDULES,
  GET_REBOOKING,
  GET_SEARCH_FLIGHTS,
  GET_SEARCH_REQS,
  POST_CHECKOUT,
  POST_FLIGHT_DETAILS,
  POST_SEARCH_FLIGHTS,
  POST_MAKE_PAYMENT,
  REBOOKING_STATUS,
  SEATMAP,
  VALIDATE_CHECKOUT,
  POST_SAVE_SEATS,
  GET_SAVE_SEATS,
  GET_AIR_PENDING_STATUS,
} = WAITER_NAMESPACES;
const {
  REPRICED_FLIGHTS_MODAL,
  INVALID_FLIGHTS_MODAL,
  FAILED_BOOKING_MODAL,
  AIR_SEARCH_SESSION_EXPIRED,
  AIR_SEARCH_SESSION_WARNING,
  AIR_LOADING,
} = MODALS;
const { UPGRADE_CLASS, CHANGE_SEATS } = AIR_FLOW_NAMES;
const { repriced, invalid } = AIR_PAYMENT_CHECKOUT_CODES;
const {
  FLIGHT,
  DEVIATION,
  UPGRADE,
  GATEWAY_CHANGE,
  TRAVEL_PROTECTION,
  TRAVEL_PROTECTION_TOTAL,
  AIR_COST,
  TOTAL,
  PAID_SEATS,
} = AIR_ADD_COLLECT;
const SUPPORTED_CLASS_CODES = ['Q', 'P', 'D'];
const DEFAULT_AVAILABLE_FLIGHT_COUNT = 6;

const {
  selectors: { getLabels, getEmbarkationDate, getDisEmbarkationDate, getAirClassMap, getMvjStrings },
} = commonStore;

const {
  selectors: {
    getBookingDetails,
    getCountryCodeFromCurrency,
    getLoggedInUser,
    getPassengers,
    getUsername,
    getEmail,
    getVoyageType,
    getBalanceDue,
    getBalanceDueDate,
    isAllGifCompleted,
    getProgramId,
    getUpdateUserData,
  },
} = userStore;
const {
  creators: { clearModal },
} = modalStore;

const emptyFlightSchedule = () => ({ pre: [], post: [] });

const getModalData = (modal = {}, type) => {
  if (type === 'repriced') {
    const subtitle = replaceCMSTokenWithValue(modal.subtitle, [{ key: 'COST', value: formatMoneyABE(modal.price) }]);
    const { submitting } = modal;
    return {
      buttons: modal.buttons,
      message: subtitle,
      title: get(modal, 'title'),
      callToActionGroup: null,
      price: modal.price,
      invalidateOffer: true,
      submitting,
    };
  }

  if (type === 'paymentFailed') {
    return {
      buttons: [
        {
          id: MODAL_BUTTONS.CANCEL,
          text: get(modal, 'callToActionTitle', 'CLOSE'),
        },
      ],
      message: get(modal, 'subtitle', 'There was an issue booking your flights. Please try again'),
      title: get(modal, 'title', 'Payment Failed'),
      icon: 'exclamation-mark-filled',
      callToActionGroup: null,
      invalidateOffer: false,
    };
  }

  if (type === 'preSelectedChosen') {
    return {
      buttons: [
        {
          id: MODAL_BUTTONS.CANCEL,
          text: get(modal, 'viewCurrentFlight', 'VIEW CURRENT FLIGHT'),
        },
        {
          id: MODAL_BUTTONS.CONFIRM,
          text: get(modal, 'continueShopping', 'CONTINUE SHOPPING'),
        },
      ],
      title: get(modal, 'title', ''),
      message: get(modal, 'subtitle', ''),
      callToActionGroup: null,
      invalidateOffer: false,
    };
  }

  if (type === 'airConnectionWarning') {
    return {
      buttons: [
        {
          id: MODAL_BUTTONS.CANCEL,
          text: get(modal, 'review', 'REVIEW FLIGHT DETAILS'),
        },
        {
          id: MODAL_BUTTONS.CONFIRM,
          text: get(modal, 'continue', 'CONTINUE'),
        },
      ],
      title: get(modal, 'title', ''),
      message: get(modal, 'subtitle', ''),
      callToActionGroup: null,
      invalidateOffer: false,
    };
  }

  if (type === 'updateSeatsFailed') {
    return {
      buttons: [
        {
          id: MODAL_BUTTONS.CANCEL,
          text: get(modal, 'callToActionTitle', 'CLOSE'),
        },
      ],
      message: get(modal, 'subtitle', ''),
      title: get(modal, 'title', ''),
      icon: 'exclamation-mark-filled',
      callToActionGroup: null,
      invalidateOffer: false,
    };
  }

  if (type === 'noSeatSelected') {
    return {
      buttons: [
        {
          id: MODAL_BUTTONS.CANCEL,
          text: get(modal, 'callToActionTitle', 'BACK TO SELECTION'),
        },
      ],
      message: get(modal, 'subtitle', ''),
      title: get(modal, 'title', ''),
      callToActionGroup: null,
      invalidateOffer: false,
    };
  }

  if (type === 'exitSeatSelected') {
    return {
      buttons: [
        {
          id: MODAL_BUTTONS.CONFIRM,
          text: get(modal, 'yesIAccept', 'Yes, I Accept'),
        },
        {
          id: MODAL_BUTTONS.CANCEL,
          text: get(modal, 'noThankYou', 'No. Thank you'),
        },
      ],
      message: get(modal, 'longText', ''),
      title: get(modal, 'title', ''),
      callToActionGroup: null,
      invalidateOffer: false,
    };
  }

  if (type === MODALS.TOKEN_ERROR) {
    return {
      buttons: modal.buttons,
      message: '',
      title: get(modal, 'title', 'We are unable to complete this flow at the moment. Please try again later.'),
    };
  }

  if (type === MODALS.SEATS_PENDING) {
    const seatsStillPendingMessage = replaceCMSTokenWithValue(modal.subtitle, [
      { key: 'AIRPHONE', value: modal.airphone },
    ]);

    return {
      buttons: modal.buttons,
      title: get(modal, 'title', 'BOOKING SEATS'),
      message: seatsStillPendingMessage,
    };
  }
  if (type === MODALS.NO_PNR) {
    const noPNRFoundMessage = replaceCMSTokenWithValue(modal.title, [{ key: 'AIRPHONE', value: modal.airphone }]);

    return {
      buttons: modal.buttons,
      HTMLTitle: noPNRFoundMessage,
    };
  }

  if (type === MODALS.AIR_SEARCH_SESSION_EXPIRED) {
    return {
      buttons: [
        {
          id: MODAL_BUTTONS.CONFIRM,
          text: get(modal, 'ButtonText', 'NEW SEARCH'),
        },
      ],
      message: get(modal, 'title', ''),
      forceAction: true,
      id: MODALS.AIR_SEARCH_SESSION_EXPIRED,
    };
  }

  return {
    buttons: [
      {
        id: MODAL_BUTTONS.CONFIRM,
        text: get(modal, 'callToActionTitle', 'CONTINUE'),
      },
    ],
    message: get(modal, 'subtitle', null),
    title: get(modal, 'title', 'Flight No Longer Available'),
    callToActionGroup: null,
    invalidateOffer: true,
  };
};

const paxCanUpgrade = (pax, maxClass = AIR_CABIN_CLASS.BUSINESS.text) => {
  const paxAir = get(pax, 'air', { pre: [], post: [] });
  const preAirClasses = paxAir.pre?.map((seg) => seg.airClassName) || [];
  const postAirClasses = paxAir.post?.map((seg) => seg.airClassName) || [];
  return ![...preAirClasses, ...postAirClasses].includes(maxClass);
};

export const ERROR_CODE_MAPPING = {
  [ERROR_CODES.INVALID]: 'GuestRequired',
  [ERROR_CODES.XHR_FAILED]: 'AddToCartFailed',
};

const airStore = createPageTabsDuck('newAir').extend({
  types: [
    'UPDATE_ADD_COLLECT',
    'RESET_ADD_COLLECT',
    'RECEIVE_AIRPORT_AUTOCOMPLETE_RESULTS',
    'RECEIVE_CHOSEN_FLIGHT_DETAILS',
    'RECEIVE_SEARCH_RESULT_DETAILS',
    'RECEIVE_BOOKING_CHECKOUT',
    'RECEIVE_AIR_CMS',
    'SET_IS_REPRICED',
    'SET_FLOW',
    'SET_SEARCH_COMPLETE',
    'SET_SEARCH_GET_PENDING',
    'SET_SEARCH_INTERVAL_ID',
    'SET_AVAILABLE_FLIGHTS',
    'SET_ORIGINAL_AVAILABLE_FLIGHTS',
    'SET_FILTERS',
    'SET_AVAILABLE_FLIGHT_COUNT',
    'SET_FLIGHT_SELECTION',
    'SET_CHOSEN_FLIGHTS',
    'SET_TOKENIZED_VALUES',
    'SET_VIEWERSHIP_STATUSES',
    'SET_SELECTED_PASSENGER_INDEX',
    'SET_BOOKING_ALERTS',
    'SET_SEATMAP_PAGE_NUMBER',
    'SET_CHECKOUT_PROCESSING',
    'SET_RESET_PASSENGER_TURN',
    'SET_ASSIGNED_SEATS',
    'SET_CHOSEN_SEATS',
    'SET_REQUESTED_SEATS',
    'SET_BOOKED_SEATS',
    'SET_IS_AIR_PENDING_IN_PROGRESS',
    'SET_SEARCH_AGAIN',
    'INVALIDATE_OFFER',
    'SET_HIDE_LOAD_MORE',
    'SET_REFERENCE_NUMBER',
    'SET_ALL_SEATMAPS_UNAVAILABLE',
    'SET_EXPECTED_PAGE',
    'SET_EXIT_SEAT_ACCEPTED',
    'SET_SEATMAP_DONE',
    'SET_CHECKOUT_PROGRESS_DISPLAY',
    'SET_SEAT_TIMEOUT_CALL_COUNT',
    'SET_PREVIEW_SEATS_FOR_PAX_INDEX',
    'SET_PRE_SEAT_1',
    'SET_UPDATE_SEATS_INTERVAL_ID',
    'SET_IS_AIR_VIEW_ONLY',
    'SET_IS_ACH',
    'SET_CHECKOUT_VOUCHER_VALUE',
    'SET_PREVIOUSLY_REFRESHED',
  ],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_SEAT_TIMEOUT_CALL_COUNT:
        return {
          ...state,
          seatTimeOutCallCount: action.payload,
        };
      case types.SET_PREVIEW_SEATS_FOR_PAX_INDEX:
        return {
          ...state,
          previewSeatsForPaxIndex: action.payload,
        };
      case types.SET_PRE_SEAT_1:
        return {
          ...state,
          preSeat1: action.payload,
        };
      case types.SET_SEATMAP_DONE:
        return {
          ...state,
          seatMapDone: action.payload,
        };
      case types.SET_FILTERS:
        return {
          ...state,
          filters: action.payload,
        };
      case types.SET_IS_AIR_PENDING_IN_PROGRESS:
        return {
          ...state,
          isAirPendingInProgress: action.payload,
        };
      case types.SET_IS_AIR_VIEW_ONLY:
        return {
          ...state,
          isAirViewOnly: action.payload,
        };
      case types.SET_IS_ACH:
        return {
          ...state,
          isACH: action.payload,
        };
      case types.SET_CHECKOUT_VOUCHER_VALUE:
        return {
          ...state,
          checkoutVoucherValue: action.payload,
        };
      case types.SET_CHECKOUT_PROCESSING:
        return {
          ...state,
          isCheckoutProcessing: action.payload,
        };
      case types.SET_SEATMAP_PAGE_NUMBER:
        return {
          ...state,
          seatmapPageNumber: action.payload,
        };
      case types.SET_RESET_PASSENGER_TURN:
        return {
          ...state,
          resetPassengerTrun: action.payload,
        };
      case types.SET_PREVIOUSLY_REFRESHED:
        return {
          ...state,
          previouslyRefreshed: action.payload,
        };
      case types.SET_IS_REPRICED:
        return {
          ...state,
          isRepriced: action.payload,
        };
      case types.UPDATE_ADD_COLLECT: {
        return {
          ...state,
          addCollect: {
            ...state.addCollect,
            [action.payload.name]: action.payload.value,
          },
        };
      }
      case types.RESET_ADD_COLLECT:
        return {
          ...state,
          addCollect: {
            flight: [],
            deviation: [],
            gatewayChange: [],
            upgrade: [],
            travelProtection: [],
            travelProtectionTotal: [],
          },
        };
      case types.SET_AVAILABLE_FLIGHTS:
        return {
          ...state,
          availableFlights: Array.isArray(action.payload) ? action.payload : [],
        };
      case types.SET_ORIGINAL_AVAILABLE_FLIGHTS:
        return {
          ...state,
          originalAvailableFlights: Array.isArray(action.payload) ? action.payload : [],
        };
      case types.SET_AVAILABLE_FLIGHT_COUNT:
        return {
          ...state,
          availableFlightCount: action.payload,
        };
      case types.SET_FLIGHT_SELECTION:
        return {
          ...state,
          flightSelection: action.payload,
        };
      case types.SET_SEARCH_GET_PENDING:
        return {
          ...state,
          searchGetPending: action.payload,
        };
      case types.SET_SEARCH_COMPLETE:
        return {
          ...state,
          searchComplete: action.payload,
        };
      case types.SET_SEARCH_INTERVAL_ID:
        return {
          ...state,
          searchIntervalId: action.payload,
        };
      case types.SET_UPDATE_SEATS_INTERVAL_ID:
        return {
          ...state,
          updateSeatsIntervalId: action.payload,
        };
      case types.SET_FLOW:
        return {
          ...state,
          airBookingFlow: action.payload,
        };
      case types.RECEIVE_AIRPORT_AUTOCOMPLETE_RESULTS:
        return {
          ...state,
          airportAutocompleteResults: {
            ...state.airportAutocompleteResults,
            [action.payload.name]: {
              ...((state.airportAutocompleteResults && state.airportAutocompleteResults[action.payload.name]) || {}),
              ...action.payload.data,
              airports: action.payload.data.airports.map((airport) => {
                if (!airport) {
                  return airport;
                }
                return {
                  ...airport,
                  airportName: airport.airportName.toUpperCase(),
                };
              }),
            },
          },
        };
      case types.RECEIVE_SEARCH_RESULT_DETAILS: {
        const { flightDetails, id, preSelected } = action.payload;
        const flightDetailResults = flightDetails && flightDetails[0] ? flightDetails[0].results : null;
        const availableFlights = get(state, 'availableFlights', []).map((flight, index) => {
          const mapFlightLeg = (arr) =>
            arr.map((seg) => {
              const targetSeg =
                flightDetailResults &&
                flightDetailResults.find((detailSeg) => parseInt(detailSeg.segmentNumber, 10) === seg.segmentNumber);
              if (targetSeg) {
                return {
                  ...seg,
                  flightDetails: targetSeg,
                };
              }
              return {
                ...seg,
                flightDetails: [],
              };
            });

          if (id === index) {
            if (preSelected) {
              return {
                ...flight,
                current: {
                  pre: mapFlightLeg(flight.current.pre),
                  post: mapFlightLeg(flight.current.post),
                },
              };
            }
            return {
              ...flight,
              pre: mapFlightLeg(flight.pre),
              post: mapFlightLeg(flight.post),
            };
          }
          return flight;
        });
        return { ...state, availableFlights };
      }
      case types.RECEIVE_AIR_CMS: {
        const cms = action.payload.reduce((acc, item) => {
          if (item?.reference) {
            return {
              ...acc,
              [item.reference]: item,
            };
          }
          if (item?.card?.reference) {
            return {
              ...acc,
              [item.card.reference]: item.card,
            };
          }
          return acc;
        }, {});

        return {
          ...state,
          cms,
        };
      }
      case types.RECEIVE_CHOSEN_FLIGHT_DETAILS: {
        const flightDetailsRaw = action.payload;
        const passengers = state.chosenFlights.passengers.map((pax) => {
          const matchFlightDetails = (seg) => {
            // TODO: Match flight details by segmentNumber when it is available
            // from the pre-selected itinerary segments
            const getFlightDetails = (flightDetailsArray) => {
              if (flightDetailsArray.length > 1) {
                return pax.passengerNumber === 1 ? flightDetailsArray[0].results : flightDetailsArray[1].results;
              }
              return flightDetailsArray[0].results;
            };
            const flightDetails = getFlightDetails(flightDetailsRaw);
            const targetSeg = flightDetails.find((detailSeg) => {
              return parseInt(detailSeg.segmentNumber, 10) === seg.segmentNumber;
            });
            if (targetSeg) {
              return {
                ...seg,
                flightDetails: targetSeg,
              };
            }
            return seg;
          };

          const flightSchedule = {
            ...pax.flightSchedule,
            pre: get(pax, 'flightSchedule.pre', []).map(matchFlightDetails),
            post: get(pax, 'flightSchedule.post', []).map(matchFlightDetails),
          };

          return {
            ...pax,
            flightSchedule,
          };
        });
        return {
          ...state,
          chosenFlights: { ...state.chosenFlights, passengers },
        };
      }
      case types.RECEIVE_BOOKING_CHECKOUT:
        return {
          ...state,
          bookingCheckoutResponse: action.payload,
        };
      case types.SET_CHOSEN_FLIGHTS:
        return {
          ...state,
          chosenFlights: action.payload,
        };
      case types.SET_TOKENIZED_VALUES:
        return {
          ...state,
          tokenizedValues: action.payload,
        };
      case types.SET_VIEWERSHIP_STATUSES:
        return {
          ...state,
          viewershipStatuses: action.payload,
        };
      case types.SET_SELECTED_PASSENGER_INDEX:
        return {
          ...state,
          selectedPassengerIndex: action.payload,
        };
      case types.SET_ASSIGNED_SEATS:
        return {
          ...state,
          preAssignedSeats: action.payload,
        };
      case types.SET_EXIT_SEAT_ACCEPTED:
        return {
          ...state,
          exitSeatAccepted: action.payload,
        };
      case types.SET_CHOSEN_SEATS:
        return {
          ...state,
          chosenSeats: action.payload,
        };
      case types.SET_REQUESTED_SEATS:
        return {
          ...state,
          requestedSeats: action.payload,
        };
      case types.SET_BOOKED_SEATS:
        return {
          ...state,
          bookedSeats: action.payload,
        };
      case types.SET_BOOKING_ALERTS:
        return {
          ...state,
          bookingAlerts: action.payload,
        };
      case types.SET_SEARCH_AGAIN:
        return {
          ...state,
          isSearchingAgain: action.payload,
        };
      case types.INVALIDATE_OFFER: {
        const invalidatedOffers = state.invalidatedOffers ? [].concat(state.invalidatedOffers) : [];
        if (action.payload) {
          invalidatedOffers.push(action.payload);
        }
        return {
          ...state,
          invalidatedOffers,
        };
      }
      case types.SET_HIDE_LOAD_MORE:
        return {
          ...state,
          isHidingLoadMore: action.payload,
        };
      case types.SET_ALL_SEATMAPS_UNAVAILABLE:
        return {
          ...state,
          allSeatMapsUnavailable: action.payload,
        };
      case types.SET_REFERENCE_NUMBER: {
        const { passengerIndex, pnrLocator } = action.payload;
        const newAirBooking = state.airBooking;
        if (pnrLocator && newAirBooking?.content?.airSchedules?.passengers?.length > passengerIndex) {
          const p = newAirBooking.content.airSchedules.passengers[passengerIndex];
          p.referenceNumber = pnrLocator;
          /* eslint-disable no-param-reassign */
          p.flightSchedule.pre.forEach((s) => {
            s.referenceNumber = pnrLocator;
          });
          p.flightSchedule.post.forEach((s) => {
            s.referenceNumber = pnrLocator;
          });
          /* eslint-enable no-param-reassign */
        }
        return {
          ...state,
          airBooking: newAirBooking,
        };
      }
      case types.SET_EXPECTED_PAGE:
        return {
          ...state,
          expectedPage: action.payload,
        };
      case types.SET_CHECKOUT_PROGRESS_DISPLAY:
        return {
          ...state,
          checkoutProgressDisplay: action.payload,
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    setSeatTimeOutCallCount: (payload) => ({
      type: types.SET_SEAT_TIMEOUT_CALL_COUNT,
      payload,
    }),
    setPreviewSeatSForPaxIndex: (payload) => ({
      type: types.SET_PREVIEW_SEATS_FOR_PAX_INDEX,
      payload,
    }),
    setPreSeat1: (payload) => ({
      type: types.SET_PRE_SEAT_1,
      payload,
    }),
    setSeatMapDone: (payload) => ({
      type: types.SET_SEATMAP_DONE,
      payload,
    }),
    setSeatmapPageNumber: (payload) => ({
      type: types.SET_SEATMAP_PAGE_NUMBER,
      payload,
    }),
    setExitSeatAccepted: (payload) => ({
      type: types.SET_EXIT_SEAT_ACCEPTED,
      payload,
    }),
    setResetPassengerTurn: (payload) => ({
      type: types.SET_RESET_PASSENGER_TURN,
      payload,
    }),
    setPreviouslyRefreshed: (payload) => ({
      type: types.SET_PREVIOUSLY_REFRESHED,
      payload,
    }),
    updateAddCollect: (name, addCollectLineItem) => ({
      type: types.UPDATE_ADD_COLLECT,
      payload: {
        name,
        // eslint-disable-next-line
        value: addCollectLineItem.map((lineItemValue) =>
          typeof lineItemValue === 'number' ? { value: lineItemValue } : lineItemValue
        ),
      },
    }),
    setIsRepriced: (payload) => ({
      type: types.SET_IS_REPRICED,
      payload,
    }),
    setCheckoutProcessing: (payload) => ({
      type: types.SET_CHECKOUT_PROCESSING,
      payload,
    }),
    setFlow: (payload) => ({
      type: types.SET_FLOW,
      payload,
    }),
    setFilters: (payload) => ({
      type: types.SET_FILTERS,
      payload,
    }),
    setIsAirPendingInProgress: (payload) => ({
      type: types.SET_IS_AIR_PENDING_IN_PROGRESS,
      payload,
    }),
    resetAddCollect: (payload) => ({
      type: types.RESET_ADD_COLLECT,
      payload,
    }),
    setAvailableFlights: (payload) => ({
      type: types.SET_AVAILABLE_FLIGHTS,
      payload,
    }),
    setOriginalAvailableFlights: (payload) => ({
      type: types.SET_ORIGINAL_AVAILABLE_FLIGHTS,
      payload,
    }),
    setAvailableFlightCount: (payload) => ({
      type: types.SET_AVAILABLE_FLIGHT_COUNT,
      payload,
    }),
    setFlightSelection: (payload) => ({
      type: types.SET_FLIGHT_SELECTION,
      payload,
    }),
    setSearchGetPending: (payload) => ({
      type: types.SET_SEARCH_GET_PENDING,
      payload,
    }),
    setSearchComplete: (payload) => ({
      type: types.SET_SEARCH_COMPLETE,
      payload,
    }),
    setSearchIntervalId: (payload) => ({
      type: types.SET_SEARCH_INTERVAL_ID,
      payload,
    }),
    setIsAirViewOnly: (payload) => ({
      type: types.SET_IS_AIR_VIEW_ONLY,
      payload,
    }),
    setIsACH: (payload) => ({
      type: types.SET_IS_ACH,
      payload,
    }),
    setCheckoutVoucherValue: (payload) => ({
      type: types.SET_CHECKOUT_VOUCHER_VALUE,
      payload,
    }),
    receiveAirCms: (payload) => ({
      type: types.RECEIVE_AIR_CMS,
      payload,
    }),
    receiveChosenFlightDetails: (payload) => ({
      type: types.RECEIVE_CHOSEN_FLIGHT_DETAILS,
      payload,
    }),
    receiveSearchResultDetails: (payload) => ({
      type: types.RECEIVE_SEARCH_RESULT_DETAILS,
      payload,
    }),
    receiveBookingCheckout: (isSuccessful, payload) => ({
      type: types.RECEIVE_BOOKING_CHECKOUT,
      payload,
    }),
    receiveAirportAutocompleteResults: (data, name) => ({
      type: types.RECEIVE_AIRPORT_AUTOCOMPLETE_RESULTS,
      payload: {
        data,
        name,
      },
    }),
    setChosenFlights: (payload) => ({
      type: types.SET_CHOSEN_FLIGHTS,
      payload,
    }),
    setTokenizedValues: (payload) => ({
      type: types.SET_TOKENIZED_VALUES,
      payload,
    }),
    setViewershipStatuses: (payload) => ({
      type: types.SET_VIEWERSHIP_STATUSES,
      payload,
    }),
    setSelectedPassengerIndex: (payload) => ({
      type: types.SET_SELECTED_PASSENGER_INDEX,
      payload,
    }),
    setAssignedSeats: (payload) => ({
      type: types.SET_ASSIGNED_SEATS,
      payload,
    }),
    setChosenSeats: (payload) => ({
      type: types.SET_CHOSEN_SEATS,
      payload,
    }),
    setRequestedSeats: (payload) => ({
      type: types.SET_REQUESTED_SEATS,
      payload,
    }),
    setBookedSeats: (payload) => ({
      type: types.SET_BOOKED_SEATS,
      payload,
    }),
    setBookingAlerts: (payload) => ({
      type: types.SET_BOOKING_ALERTS,
      payload,
    }),
    setSearchingAgain: (payload) => ({
      type: types.SET_SEARCH_AGAIN,
      payload,
    }),
    invalidateOffer: (payload) => ({ type: types.INVALIDATE_OFFER, payload }),
    setHidingLoadMore: (payload) => ({
      type: types.SET_HIDE_LOAD_MORE,
      payload,
    }),
    setReferenceNumber: (passengerIndex, newPnrLocator) => ({
      type: types.SET_REFERENCE_NUMBER,
      payload: { passengerIndex, pnrLocator: newPnrLocator },
    }),
    setAllSeatMapsUnavailable: (payload) => ({
      type: types.SET_ALL_SEATMAPS_UNAVAILABLE,
      payload,
    }),
    setExpectedPage: (payload) => ({
      type: types.SET_EXPECTED_PAGE,
      payload,
    }),
    setCheckoutProgressDisplay: (payload) => ({
      type: types.SET_CHECKOUT_PROGRESS_DISPLAY,
      payload,
    }),
  }),
  selectors: {
    getVoucherValue: new Selector(({ getChosenFlightsPassengers }) =>
      createSelector(
        [(state) => getWaiterResponse(state, VALIDATE_CHECKOUT), getChosenFlightsPassengers],
        (validateCheckout, chosenFlightsPassengers) =>
          chosenFlightsPassengers.reduce((acc, pax) => {
            const { otherVoucherAvailable, passengerNumber } = pax;
            const targetInvoicePax = get(validateCheckout, ['data', 'invoicePricing', 'passengers'], []).find(
              (invoicePax) => Number.parseInt(invoicePax.passengerNumber, 10) === passengerNumber
            );
            // Only calculate voucher if we're getting something from invoice pricing
            if (targetInvoicePax) {
              const updatedVoucherAvailable = targetInvoicePax.otherVoucherAvailable - otherVoucherAvailable;
              return acc + updatedVoucherAvailable;
            }
            return acc <= 0 ? acc : acc * -1;
          }, 0)
      )
    ),
    getOfferAddCollect: new Selector(({ getChosenFlights }) =>
      createSelector([getChosenFlights], (chosenFlights) =>
        get(chosenFlights, ['passengers', 0, 'flightSchedule', 'offer', 'addCollectFee'], {})
      )
    ),
    getOfferAddCollectToken: new Selector(({ getChosenFlights }) =>
      createSelector([getChosenFlights], (chosenFlights) =>
        get(chosenFlights, ['passengers', 0, 'flightSchedule', 'offer', 'addCollectTokenized'], {})
      )
    ),
    getAirCms: ({ newAir }) => newAir.cms || [],
    getAirEmbarkationDate: (state) => {
      const bookingEmbarkationDate = getEmbarkationDate(state);
      const passengers = get(state, 'user.bookingDetails.passengers', []);
      const preAirDetails = passengers.map((pax) => pax.air.pre);
      const arrivalDates = preAirDetails?.map((pax) => pax?.map((seg) => seg.arrivalDate));
      const latestArrivalDate = new Date(Math.max(...arrivalDates.map((date) => new Date(date))));
      if (moment(latestArrivalDate).isAfter(bookingEmbarkationDate)) {
        return latestArrivalDate.toISOString();
      }
      return bookingEmbarkationDate;
    },
    getAirDisembarkationDate: (state) => {
      const bookingDisembarkationDate = getDisEmbarkationDate(state);
      const passengers = get(state, 'user.bookingDetails.passengers', []);
      const postAirDetails = passengers.map((pax) => pax.air.post);
      const departureDates = postAirDetails?.map((pax) => pax?.map((seg) => seg.departureDate));
      const earliestDepartureDate = new Date(Math.min(...departureDates.map((date) => new Date(date))));
      if (moment(earliestDepartureDate).isBefore(bookingDisembarkationDate)) {
        return earliestDepartureDate.toISOString();
      }
      return bookingDisembarkationDate;
    },
    getFilterLimits: (state) => {
      const filterLimits = get(getWaiterResponse(state, GET_SEARCH_FLIGHTS), ['data', 'filter'], {});

      return Object.keys(filterLimits).reduce((acc, filter) => {
        const getFilterMinMax = (diff, comparison, minimum, maximum, multiplier) => {
          if (diff > comparison) {
            return {
              min: roundDown(minimum, multiplier),
              max: roundUp(maximum, multiplier),
            };
          }
          return { min: minimum, max: maximum };
        };

        const filterMin = filterLimits[filter].min;
        const filterMax = filterLimits[filter].max;
        const diff = getDiff(filterMin, filterMax);

        if (filter === 'price') {
          acc.price = getFilterMinMax(diff, FILTER_BOX_DIFF.PRICE, filterMin, filterMax, 100);
        }

        if (filter === 'numberOfStops') {
          acc.maxStops = filterLimits[filter].max;
          acc.minStops = filterLimits[filter].min;
        }

        if (
          [
            'outgoingDepartureTime',
            'outgoingStopoverDepartureTime',
            'returnDepartureTime',
            'returnStopoverDepartureTime',
            'connectingTime',
          ].includes(filter)
        ) {
          const minAndMaxTimes = getFilterMinMax(diff, FILTER_BOX_DIFF.CONNECTION_TIME, filterMin, filterMax, 1);
          acc[filter] = [minAndMaxTimes.min, minAndMaxTimes.max];
        }

        if (filter === 'airlines') {
          acc.airlines = filterLimits[filter].reduce(
            (airlineAcc, airlineName) => ({
              ...airlineAcc,
              [airlineName]: true,
            }),
            {}
          );
          acc.toggle = true;
        }

        if (['outgoingGateways', 'returnGateways'].includes(filter)) {
          acc[filter] = filterLimits[filter][0] || null;
          acc[`${filter}Stopover`] = filterLimits[filter][1] ? filterLimits[filter][1] : null;
          acc.totalGateways = filterLimits.outgoingGateways.length + filterLimits.returnGateways.length || 0;
        }

        if (filter === 'hasAdditionalOffers') {
          acc.hasAdditionalOffers = filterLimits[filter];
        }
        return acc;
      }, {});
    },
    getFilters: new Selector(({ getFilterLimits }) =>
      createSelector(
        [(state) => state.newAir, getFilterLimits],
        (newAir, filterLimits) => newAir.filters || filterLimits
      )
    ),
    getCheckoutProcessing: ({ newAir }) => newAir.isCheckoutProcessing || false,
    getBookedSeats: ({ newAir }) => newAir.bookedSeats || [],
    getIsACH: ({ newAir }) => newAir.isACH,
    getCheckoutVoucherValue: ({ newAir }) => newAir.checkoutVoucherValue,
    getResetPassengerTurn: ({ newAir }) => get(newAir, 'resetPassengerTrun', false),
    getSeatMapDone: ({ newAir }) => get(newAir, 'seatMapDone', false),
    getSeatTimeOutCallCount: ({ newAir }) => get(newAir, 'seatTimeOutCallCount', 0),
    getPreviewSeatsForPaxIndex: ({ newAir }) => get(newAir, 'previewSeatsForPaxIndex', 0),
    getAirPaymentMethod: ({ form }) => get(form, 'airPaymentCheckout.values.paymentMethod', ''),
    getSeatmapPageNumber: ({ newAir }) => newAir.seatmapPageNumber || 0,
    isLastSeatmapPage: new Selector(({ getSeatmapPageNumber, getChosenFlights }) =>
      createSelector([getSeatmapPageNumber, getChosenFlights], (seatmapPageNumber, chosenFlights) => {
        const flightSchedules = get(chosenFlights, ['passengers', 0, 'flightSchedule'], emptyFlightSchedule());
        const allFlightsRaw = flightSchedules.pre.concat(flightSchedules.post);
        const allFlights = allFlightsRaw.filter((item) => item.type !== 'label');
        return seatmapPageNumber >= allFlights.length - 1;
      })
    ),
    getSearchPageIsReady: (state) =>
      !!get(getWaiterResponse(state, GET_SEARCH_REQS), 'data') ||
      !!get(getWaiterResponse(state, `${UPGRADE_CLASS}-${GET_SEARCH_REQS}`), 'data'),
    getEffectiveGateways: (state) =>
      get(getWaiterResponse(state, GET_SEARCH_REQS), ['data', 'effectiveGateways', 'CruiseList', 0, 'AirCities']) ||
      get(
        getWaiterResponse(
          state,
          `${UPGRADE_CLASS}-${GET_SEARCH_REQS}`,
          ['data', 'effectiveGateways', 'CruiseList', 0, 'AirCities'],
          []
        )
      ),
    getFlightSearchDateRange: (state) =>
      get(getWaiterResponse(state, GET_SEARCH_REQS), 'data.travelDates') ||
      get(getWaiterResponse(state, `${UPGRADE_CLASS}-${GET_SEARCH_REQS}`), 'data.travelDates', {}),
    getSearchGetPending: ({ newAir }) => get(newAir, 'searchGetPending', false),
    getCurrency: ({ user }) => get(user, 'bookingDetails.currency', ''),
    getSearchIntervalId: ({ newAir }) => get(newAir, 'searchIntervalId', false),
    getAirportAutocompleteResults: ({ newAir }) => get(newAir, 'airportAutocompleteResults', {}),
    getAirlineList: (state) => {
      const airlinesFromSearchReqs = get(getWaiterResponse(state, GET_SEARCH_REQS), 'data.airPreferences.airlines', []);
      const optionsMap = airlinesFromSearchReqs
        .map((airline) => ({
          value: airline.code,
          label: airline.name,
        }))
        .sort((a, b) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      return [
        {
          label: 'No Preference',
          value: -1,
        },
        ...optionsMap,
      ];
    },
    getSearchComplete: ({ newAir }) => get(newAir, 'searchComplete', false),
    getAirBookingFlow: ({ newAir }) => get(newAir, 'airBookingFlow', null),
    getPreSelectedChosenModalData: new Selector(({ getAirCms }) =>
      createSelector([getAirCms], (cmsContent) => {
        const modalData = {
          title: cmsContent.abeCurrentFlightSelectedTitle.title,
          subtitle: cmsContent.abeCurrentFlightSelected.subtitle,
          viewCurrentFlight: cmsContent.abeViewCurrentFlight.title,
          continueShopping: cmsContent.abeContinueShopping.title,
        };
        return getModalData(modalData, 'preSelectedChosen');
      })
    ),
    getConnectionWarningModalData: new Selector(({ getAirCms }) =>
      createSelector([getAirCms, getLabels], (cmsContent, labels) => {
        const modalData = {
          title: cmsContent?.abeConnectionWarning?.title,
          subtitle: cmsContent?.abeConnectionWarning?.subtitle,
          review: cmsContent?.abeReviewFlightDetails?.title.toUpperCase(),
          continue: get(labels, 'pages.newAir.labels.buttons.continue'),
        };
        return getModalData(modalData, 'airConnectionWarning');
      })
    ),
    getTokenErrorModalData: new Selector(({ getAirCms }) =>
      createSelector([getAirCms, getLabels], (cmsContent, labels) => {
        const tokenErrorMessaging = cmsContent.abeTokenErrorModal;
        const tokenErrorModalData = getModalData(
          {
            ...tokenErrorMessaging,
            buttons: [
              {
                id: MODAL_BUTTONS.CONFIRM,
                text: get(labels, 'buttons.viewItinerary', 'VIEW ITINERARY'),
              },
            ],
          },
          MODALS.TOKEN_ERROR
        );
        return tokenErrorModalData;
      })
    ),
    getNoPNRFoundModalData: new Selector(({ getAirCms }) =>
      createSelector([getAirCms, getLabels], (cmsContent, labels) => {
        const noPNRFoundMessaging = cmsContent.noPNRModalMessage;
        const airphone = get(cmsContent.AIRPHONE, 'longText', RESERVATION_NUMBERS.US);

        const noPNRFoundModalData = getModalData(
          {
            ...noPNRFoundMessaging,
            airphone,
            buttons: [
              {
                id: MODAL_BUTTONS.CONFIRM,
                text: get(labels, 'buttons.viewItinerary', 'VIEW ITINERARY'),
              },
            ],
          },
          MODALS.NO_PNR
        );
        return noPNRFoundModalData;
      })
    ),
    getSeatsPendingModalData: new Selector(({ getAirCms, checkForPaidSeats }) =>
      createSelector([getAirCms, getLabels, checkForPaidSeats], (cmsContent, labels, hasPaidSeats) => {
        const seatsStillPendingMessage = hasPaidSeats
          ? cmsContent.mvaSeatsStillPendingMessagePaid
          : cmsContent.mvaSeatsStillPendingMessageFree;
        const airphone = get(cmsContent.AIRPHONE, 'longText', RESERVATION_NUMBERS.US);

        const seatsStillPendingModalData = getModalData(
          {
            ...seatsStillPendingMessage,
            airphone,
            buttons: [
              {
                id: MODAL_BUTTONS.CANCEL,
                text: get(labels, 'buttons.returnToHome', 'RETURN TO HOME'),
              },
            ],
          },
          MODALS.SEATS_PENDING
        );
        return seatsStillPendingModalData;
      })
    ),
    getCheckoutModalData: new Selector(
      ({ getAirCms, getChosenFlightsPassengers, getTravelProtectionMessage, getAirBookingFlow }) =>
        createSelector(
          [
            getAirCms,
            (state) => getWaiterResponse(state, POST_CHECKOUT) || getWaiterResponse(state, VALIDATE_CHECKOUT),
            getLabels,
            getChosenFlightsPassengers,
            getTravelProtectionMessage,
            (state) => getAirBookingFlow(state),
          ],
          (cmsContent, checkoutResponse, labels, passengers, travelProtectionMessage, currAirFlow) => {
            const notAvailableModal = cmsContent.abeFlightsNotAvailable;
            const notAvailableModalData = getModalData(notAvailableModal);

            const failedPaymentModal = cmsContent.abeBookingFailed;
            const updateSeatsFailedModal = cmsContent.abeSeatsFailed;
            const noSeatSelectedModal = cmsContent.abeCompleteSeatSelection;
            const exitSeatSelectedModalRaw = cmsContent.abeEmergencyExitSeatRequirements;
            const failedPaymentModalData = getModalData(failedPaymentModal, 'paymentFailed');
            const failedUpdateSeatsModalData = getModalData(updateSeatsFailedModal, 'updateSeatsFailed');
            const noSeatSelectedModalData = getModalData(noSeatSelectedModal, 'noSeatSelected');

            const { yesIAccept, noThankYou, abeSessionTimeout, abeStartNewSearchButton } = cmsContent;
            const exitSeatSelectedModalDetails = {
              ...exitSeatSelectedModalRaw,
              yesIAccept: yesIAccept.title,
              noThankYou: noThankYou.title,
            };
            const exitSeatSelectedModalData = getModalData(exitSeatSelectedModalDetails, 'exitSeatSelected');
            const airSearchExpiredModalDetails = {
              title: abeSessionTimeout.title,
              ButtonText: abeStartNewSearchButton.title,
            };
            const airSearchExpiredModalData = getModalData(
              airSearchExpiredModalDetails,
              MODALS.AIR_SEARCH_SESSION_EXPIRED
            );
            const repricedModal = cmsContent.abePriceChangedModal;
            const numOfRebookedPassengers = passengers.length;
            const offerStatus = get(checkoutResponse, 'data.validateOffer.status');
            const repriceAddCollect = get(checkoutResponse, 'data.validateOffer.priceIncrease', 0);
            const repriceAddCollectTotal =
              numOfRebookedPassengers > 0 ? repriceAddCollect * numOfRebookedPassengers : repriceAddCollect;

            const repricedModalData = getModalData(
              {
                ...repricedModal,
                price: repriceAddCollectTotal,
                buttons: [
                  {
                    id: MODAL_BUTTONS.CANCEL,
                    text:
                      currAirFlow !== CHANGE_SEATS
                        ? get(labels, 'pages.newAir.labels.buttons.newSearch')
                        : get(labels, 'pages.newAir.labels.buttons.mvaSelectNewSeatsCTA', 'SELECT NEW SEATS'),
                  },
                  {
                    id: MODAL_BUTTONS.CONFIRM,
                    text: get(labels, 'pages.newAir.labels.buttons.continue'),
                  },
                ],
              },
              'repriced'
            );

            const isRepriced = offerStatus === 'REPRICED';
            const copy = isRepriced ? repricedModalData.message : travelProtectionMessage;
            const message = isRepriced ? travelProtectionMessage : '';
            const updatedRepricedModalData = {
              ...repricedModalData,
              copy,
              message,
            };

            return {
              repricedModal: updatedRepricedModalData,
              notAvailableModal: notAvailableModalData,
              failedPaymentModal: failedPaymentModalData,
              failedUpdateSeatsModal: failedUpdateSeatsModalData,
              noSeatSelectedModal: noSeatSelectedModalData,
              exitSeatSelectedModal: exitSeatSelectedModalData,
              airSearchExpiredModal: airSearchExpiredModalData,
            };
          }
        )
    ),
    getCustomizableTabs: new Selector(({ getTabs }) =>
      createSelector(
        [getBookingDetails, getTabs, getCountryCodeFromCurrency],
        ({ packageType }, airTabs, countryCode) => (location, match) => {
          const tabs = airTabs(location.pathname, match.path);
          // check for package type to hide air preferences for cruise only bookings
          const isCruiseOnly = packageType === BOOKING_PACKAGE_TYPE.CRUISE_ONLY;
          let tabToRemove;
          if (isCruiseOnly || [AUSTRALIA, UNITED_KINGDOM].includes(countryCode)) {
            // find index to remove by tab's name
            // TODO: use reference or ID to remove tabs
            tabToRemove = TAB_NAMES.TRAVELER_INFORMATION_TITLE;
          } else {
            tabToRemove = TAB_NAMES.TRANSFERS_TITLE;
          }

          const index = tabs.findIndex((t) => t.title.includes(tabToRemove));

          if (index !== -1) {
            tabs.splice(index, 1);
          }
          return { tabs, isCruiseOnly };
        }
      )
    ),
    getReferenceNumber: new Selector(({ getSelectedPassengerIndex }) =>
      createSelector([(state) => state, getSelectedPassengerIndex], ({ newAir }, selectedPassengerIndex) =>
        get(newAir, `airBooking.content.airSchedules.passengers[${selectedPassengerIndex}].referenceNumber`, '')
      )
    ),
    getChosenFlights: ({ newAir }) => get(newAir, 'chosenFlights', []),
    getTokenizedValues: ({ newAir }) => get(newAir, 'tokenizedValues', []),
    getViewershipStatuses: ({ newAir }) => get(newAir, 'viewershipStatuses', []),
    // eslint-disable-next-line max-len
    getFlightDetails: new Selector(({ getSelectedPassengerIndex, getItinerariesDiffer }) =>
      createSelector(
        [(state) => state, getSelectedPassengerIndex, getItinerariesDiffer],
        ({ newAir }, selectedPassengerIndex, itinerariesDiffer) => {
          const flightSchedule = get(
            newAir,
            `airBooking.content.airSchedules.passengers[${selectedPassengerIndex}]flightSchedule`,
            []
          );

          const secondPassengerIndex = selectedPassengerIndex === 0 ? 1 : 0;
          let flightSchedule2 = get(
            newAir,
            `airBooking.content.airSchedules.passengers[${secondPassengerIndex}]flightSchedule`,
            []
          );

          const passengers = get(newAir, 'airBooking.content.airSchedules.passengers', []);
          if (passengers.length < 2 || itinerariesDiffer) {
            flightSchedule2 = [];
          }

          // eslint-disable-next-line max-len
          const secondPassengerSeatsPre =
            flightSchedule2 && flightSchedule2.pre ? flightSchedule2.pre.map((seg) => seg.seat) : [];
          const secondPassengerSeatsPost =
            flightSchedule2 && flightSchedule2.post ? flightSchedule2.post.map((seg) => seg.seat) : [];

          const getComboSeats = (arr, secondPaxArr) =>
            arr.map((seg, i) => {
              let separator = '';
              if (seg.seat && secondPaxArr[i]) {
                separator = ', ';
              }
              const comboSeats = seg.seat + separator + get(secondPaxArr, [i], '');
              return { ...seg, seat: comboSeats };
            });

          const departureLegs =
            flightSchedule && flightSchedule.pre ? getComboSeats(flightSchedule.pre, secondPassengerSeatsPre) : [];
          const arrivalLegs =
            flightSchedule && flightSchedule.post ? getComboSeats(flightSchedule.post, secondPassengerSeatsPost) : [];
          return { departureLegs, arrivalLegs };
        }
      )
    ),
    getAirportNames: new Selector(({ getAirBookingFlow }) =>
      createSelector([(state) => state, getAirBookingFlow], (state, currentFlow) => {
        const allGateways =
          currentFlow !== UPGRADE_CLASS
            ? get(getWaiterResponse(state, GET_SEARCH_REQS), 'data.gateways', [])
            : get(getWaiterResponse(state, `${UPGRADE_CLASS}-${GET_SEARCH_REQS}`), 'data.gateways', []);
        const allGatewaysArr = [
          ...get(allGateways, 'preDeviationGateways', []),
          ...get(allGateways, 'postDeviationGateways', []),
          ...get(allGateways, 'preStopoverGateways', []),
          ...get(allGateways, 'postStopoverGateways', []),
        ];
        return allGatewaysArr.filter((gateway, index) => allGatewaysArr.indexOf(gateway) === index) || [];
      })
    ),
    getEndpointGateways: new Selector(({ getAirBookingFlow }) =>
      createSelector([(state) => state, getAirBookingFlow], (state, currentFlow) => {
        const allGateways =
          currentFlow !== UPGRADE_CLASS
            ? get(getWaiterResponse(state, GET_SEARCH_REQS), 'data.endpointGateways.airports', [])
            : get(
                getWaiterResponse(state, `${UPGRADE_CLASS}-${GET_SEARCH_REQS}`),
                'data.endpointGateways.airports',
                []
              );
        return allGateways || [];
      })
    ),

    getAirModifiedBy: (state) => {
      const modifyingUser = getUpdateUserData(state);
      return `${get(modifyingUser, 'firstName', '')} ${get(modifyingUser, 'lastName', '')}`.trim();
    },
    getSelectedPassengerBookingAir: new Selector(({ getSelectedPassengerIndex }) =>
      createSelector([(state) => state, getSelectedPassengerIndex], ({ user }, selectedPassengerIndex) =>
        get(user, `bookingDetails.passengers[${selectedPassengerIndex}].air`, {
          pre: [],
          post: [],
        })
      )
    ),
    getViewedAllAvailableFlights: ({ newAir }) =>
      get(newAir, 'availableFlightCount', DEFAULT_AVAILABLE_FLIGHT_COUNT) >= get(newAir, 'availableFlights', []).length,
    getAvailableFlightCount: ({ newAir }) => get(newAir, 'availableFlightCount', DEFAULT_AVAILABLE_FLIGHT_COUNT),
    getTotalAvailableFlightCount: ({ newAir }) => get(newAir, 'availableFlights', []).length,
    getOriginalAvailableFlights: ({ newAir }) => get(newAir, 'originalAvailableFlights', []),
    getOriginalTotalAvailableFlightCount: ({ newAir }) => get(newAir, 'originalAvailableFlights', []).length,
    /* eslint-disable-next-line max-len */
    getAvailableFlights: new Selector(
      ({
        getAvailableFlightCount,
        getFlightSelectionKey,
        getInvalidatedOffers,
        getAirBookingFlow,
        getAllowDeltaComfortPlus,
        getCurrency,
      }) =>
        createSelector(
          [
            (state) => getAirBookingFlow(state),
            (state) => getAllowDeltaComfortPlus(state),
            (state) => getWaiterResponse(state, GET_SEARCH_REQS),
            (state) => getWaiterResponse(state, `${UPGRADE_CLASS}-${GET_SEARCH_REQS}`),
            getAvailableFlightCount,
            ({ newAir }) => get(newAir, 'availableFlights', []),
            getFlightSelectionKey,
            getAirClassMap,
            getInvalidatedOffers,
            (state) => getCurrency(state),
          ],
          (
            currAirFlow,
            allowDeltaComfortPlus,
            searchReqResponse,
            upgradeClassResponse,
            flightCount,
            availableFlights,
            flightSelection,
            flightClassMap,
            invalidatedOffers,
            currency
          ) => {
            const getSearchResponseByFlow = currAirFlow === UPGRADE_CLASS ? upgradeClassResponse : searchReqResponse;
            const availableAirClassCodes = get(getSearchResponseByFlow, 'data.offerCodes.availableAirClasses', []);
            const filteredCodes = availableAirClassCodes.filter((code) => SUPPORTED_CLASS_CODES.indexOf(code) !== -1);
            const availableFlightClasses = [];
            const entries = Object.entries(flightClassMap);
            for (let i = 0; i < entries.length; i += 1) {
              if (filteredCodes.indexOf(entries[i][0]) !== -1) {
                availableFlightClasses.push(convertStringToStartCase(entries[i][1]));
              }
              if (entries[i][0] === AIR_CABIN_CLASS.ECONOMY.evoCode && allowDeltaComfortPlus) {
                availableFlightClasses.push(convertStringToStartCase(AIR_CABIN_CLASS.DELTA_COMFORT_PLUS.code));
              }
            }

            const flightInfoUnchosen = availableFlights
              .map((data, index) => {
                const offers = data.offers.reduce((acc, offer) => {
                  acc[offer.cabinClass] = offer;
                  return acc;
                }, {});
                const flightClasses = availableFlightClasses.length
                  ? availableFlightClasses
                      .map((flightClass, i) => {
                        const getFlightClass = (cabClass) => cabClass.toUpperCase().split(' ').join('_');
                        const offer = offers[getFlightClass(flightClass)];
                        const cabinClass = get(offer, ['cabinClass'], '');
                        if (!cabinClass.length) {
                          return null;
                        }
                        const formatDeltaComfortCabinClass = (deltaComfortCabinClass) =>
                          deltaComfortCabinClass.replace(
                            AIR_CABIN_CLASS.DELTA_COMFORT_PLUS.textUnformat,
                            AIR_CABIN_CLASS.DELTA_COMFORT_PLUS.text
                          );
                        const segmentCabinClasses = get(offer, ['cabinClasses'], []);
                        const addCollect = get(offer, ['addCollectFee', 'totalFeeRounded'], 0);
                        const offerId = get(offer, ['offerId'], null);
                        const seatRemaining = get(offer, ['seatRemaining'], '');
                        const current = get(offer, ['current'], false);
                        let selectedForYou = '';

                        let price = `${addCollect > 0 ? '+' : ''}${formatMoneyABE(addCollect, 2)}`;
                        let disabled = false;
                        if (current === true) {
                          selectedForYou = FLIGHT_INFO_STATUS.YOUR_CURRENT_FLIGHT;
                        } else if (seatRemaining === 0) {
                          price = FLIGHT_INFO_STATUS.SOLD_OUT;
                          disabled = true;
                        } else if (offerId === null || (invalidatedOffers && invalidatedOffers.includes(offerId))) {
                          price = FLIGHT_INFO_STATUS.UNAVAILABLE;
                          disabled = true;
                        }

                        const subtitle =
                          disabled === false && seatRemaining > 0 && seatRemaining < 7
                            ? FLIGHT_INFO_SCARCITY.replace('{NUMBER_SEATS_REMAINING}', seatRemaining)
                            : '';
                        return {
                          id: i + 1,
                          type: formatDeltaComfortCabinClass(convertStringToStartCase(cabinClass)),
                          selectedForYou,
                          subtitle,
                          disabled,
                          price,
                          input: {
                            value: `${index},${cabinClass.toLowerCase()}`,
                          },
                          segmentCabinClasses,
                          addCollect,
                          currency,
                          seatRemaining,
                        };
                      })
                      .filter((flightClass) => flightClass !== null)
                  : [];

                const getFlightClassIndex = (parsed) => {
                  let chosenClassIndex = 0;
                  if (!flightClasses || !flightClasses.length) {
                    return 0;
                  }
                  if (parsed.length === 2) {
                    const airClassCode = parsed[parsed.length - 1];
                    const airClass = (
                      Object.values(AIR_CABIN_CLASS).find((c) => c.code === airClassCode) || AIR_CABIN_CLASS.ECONOMY
                    ).text.toLowerCase();
                    chosenClassIndex = flightClasses.findIndex(({ type }) => type.toLowerCase() === airClass);
                  }
                  return chosenClassIndex > -1 &&
                    flightClasses.length > chosenClassIndex &&
                    !flightClasses[chosenClassIndex].disabled
                    ? chosenClassIndex
                    : 0;
                };
                const parsed = flightSelection.split(',');
                let flightClassIndex = flightClasses.findIndex((flightClass) => !flightClass.disabled) || 0;
                let selectedFlightClass = flightClasses[flightClassIndex];
                if (parseInt(parsed[0], 10) === index) {
                  flightClassIndex = getFlightClassIndex(parsed, flightClasses);
                  selectedFlightClass = flightClasses ? flightClasses[flightClassIndex] : {};
                }
                const departureCabinClasses = get(selectedFlightClass, 'segmentCabinClasses.pre', []);
                const arrivalCabinClasses = get(selectedFlightClass, 'segmentCabinClasses.post', []);

                let departureLegsWithLabelsRaw;
                let arrivalLegsWithLabelsRaw;

                if (selectedFlightClass && selectedFlightClass.selectedForYou !== '') {
                  departureLegsWithLabelsRaw = get(data, 'current.pre', []);
                  arrivalLegsWithLabelsRaw = get(data, 'current.post', []);
                } else {
                  departureLegsWithLabelsRaw = get(data, 'pre', []);
                  arrivalLegsWithLabelsRaw = get(data, 'post', []);
                }

                const departureSegments = departureLegsWithLabelsRaw.filter((item) => item.type !== 'label');
                const arrivalSegments = arrivalLegsWithLabelsRaw.filter((item) => item.type !== 'label');
                const formatSegments = (segments, segmentCabinClasses) => {
                  const legs = [];
                  for (let i = 0; i < segments.length; i += 1) {
                    const cabinClassObject = segmentCabinClasses.find(
                      (cabinClass) => cabinClass.segmentNumber === segments[i].segmentNumber
                    );
                    const cabinClass = cabinClassObject === undefined ? '' : cabinClassObject.cabinClass;
                    legs.push({
                      segment: { ...segments[i], cabinClass },
                      flightDetails: get(segments[i], 'flightDetails', []),
                    });
                  }
                  return legs;
                };
                const departureLegs = formatSegments(departureSegments, departureCabinClasses);
                const arrivalLegs = formatSegments(arrivalSegments, arrivalCabinClasses);
                const departureLegsWithLabels = formatSegments(departureLegsWithLabelsRaw, departureCabinClasses);
                const arrivalLegsWithLabels = formatSegments(arrivalLegsWithLabelsRaw, arrivalCabinClasses);

                const separateSegments = (segments) => {
                  let stopOverSegmentNumber;
                  const mappedSegments = segments.map((segment) => {
                    if (segment.isStopover) {
                      stopOverSegmentNumber = segment.segmentNumber;
                      return {
                        ...segment,
                        flightOne: true,
                      };
                    }
                    if (stopOverSegmentNumber && segment.segmentNumber > stopOverSegmentNumber) {
                      return {
                        ...segment,
                        flightTwo: true,
                      };
                    }
                    return {
                      ...segment,
                      flightOne: true,
                    };
                  });
                  return mappedSegments;
                };

                const flightInfoAggregation = (segments = [], fc = []) => {
                  if (segments.length === 0) {
                    return [];
                  }
                  const lastIndex = segments.length - 1;
                  const { shareInfoCarrier, segmentNumber, airlineName, departureDate } = segments[0];
                  const { arrivalDate } = segments[lastIndex];
                  const overNight = sumBy(segments, 'overNight');
                  let duration = 0;

                  for (let i = 0; i < segments.length; i += 1) {
                    duration += segments[i].duration + segments[i].layoverDuration;
                  }
                  /* eslint-disable-next-line max-len */
                  const airlineNames = new Set(segments.map((seg) => seg.airlineName));
                  const segsWithOperatedBy = segments.filter((seg) => seg.shareInfoCarrier);

                  const checkForDiffOperatedBy = (operatedBySegs, allSegments) => {
                    const multiSegmentsWithOperatedBy = operatedBySegs.length > 1;
                    const hasSegmentWithoutOperatedBy = operatedBySegs.length < allSegments.length;
                    const operatedByNames = new Set(operatedBySegs.map((seg) => seg.shareInfoCarrier));

                    if (multiSegmentsWithOperatedBy) {
                      if (hasSegmentWithoutOperatedBy) {
                        return true;
                      }
                      return operatedByNames.size > 1;
                    }
                    return operatedByNames.size > 0;
                  };
                  const hasDiffOperatedBy = checkForDiffOperatedBy(segsWithOperatedBy, segments);

                  const multiAirline = segments.length > 1 && (airlineNames.size > 1 || hasDiffOperatedBy);
                  return [
                    {
                      threeFlightClasses: !!(fc && fc.length === 3),
                      segment: {
                        airlineName: multiAirline ? MULTIPLE_AIRLINES.displayName : airlineName,
                        segmentNumber,
                        stops: segments.length - 1,
                        duration,
                        arrivalCity: segments[lastIndex].arrivalCity,
                        arrivalCityAirport: segments[lastIndex].arrivalCityAirport,
                        arrivalCityCode: segments[lastIndex].arrivalCityCode,
                        arrivalDate,
                        arrivalTime: segments[lastIndex].arrivalTime,
                        departureCity: segments[0].departureCity,
                        departureCityAirport: segments[0].departureCityAirport,
                        departureCityCode: segments[0].departureCityCode,
                        departureDate,
                        departureTime: segments[0].departureTime,
                        overNight,
                        layoverDurationWarning: segments.some((seg) => seg.layoverDurationWarning),
                      },
                      operatedBy: multiAirline || shareInfoCarrier === airlineName ? '' : shareInfoCarrier,
                      logoUrl: multiAirline ? MULTIPLE_AIRLINES.logoURL : segments[0].airlineLogoURL,
                    },
                  ];
                };

                const getFlightsSummary = (segments, summaryFilter) =>
                  // eslint-disable-next-line
                  separateSegments(segments).filter((segment) =>
                    summaryFilter === 'flightOne' ? segment.flightOne : segment.flightTwo
                  );

                const departureFlightOne = flightInfoAggregation(
                  getFlightsSummary(departureSegments, 'flightOne'),
                  flightClasses
                );
                const departureFlightTwo = flightInfoAggregation(
                  getFlightsSummary(departureSegments, 'flightTwo'),
                  flightClasses
                );
                const departureLegsAggregate = departureFlightOne.concat(departureFlightTwo);

                const arrivalFlightOne = flightInfoAggregation(
                  getFlightsSummary(arrivalSegments, 'flightOne'),
                  flightClasses
                );
                const arrivalFlightTwo = flightInfoAggregation(
                  getFlightsSummary(arrivalSegments, 'flightTwo'),
                  flightClasses
                );
                const arrivalLegsAggregate = arrivalFlightOne.concat(arrivalFlightTwo);
                return {
                  id: index,
                  flightClasses,
                  departureLegs,
                  arrivalLegs,
                  departureLegsWithLabels,
                  arrivalLegsWithLabels,
                  offers,
                  departureLegsAggregate: departureLegsAggregate.sort(
                    (itemA, itemB) => itemA.segment.segmentNumber - itemB.segment.segmentNumber
                  ),
                  arrivalLegsAggregate: arrivalLegsAggregate.sort(
                    (itemA, itemB) => itemA.segment.segmentNumber - itemB.segment.segmentNumber
                  ),
                };
              })
              .slice(0, flightCount);

            return [flightInfoUnchosen, availableFlightClasses];
          }
        )
    ),
    getFlightSelectionKey: ({ newAir }) => get(newAir, 'flightSelection', ''),
    getFlightSelectionCabinClass: new Selector(({ getFlightSelectionKey }) =>
      createSelector([getFlightSelectionKey], (flightSelectionKey) => flightSelectionKey.split(',')[1])
    ),
    getSelectedForYouCabinClass: ({ newAir }) => {
      let firstFlightOffers = get(newAir, 'originalAvailableFlights[0].offers', []);
      if (firstFlightOffers.length < 1) {
        firstFlightOffers = get(newAir, 'availableFlights[0].offers', []);
      }
      const currentOffer = firstFlightOffers.length > 0 && firstFlightOffers.filter((offer) => offer.current);
      return currentOffer && currentOffer.length > 0 ? currentOffer[0].cabinClass : '';
    },
    getIsAirPending: ({ newAir }) => get(newAir, 'airBooking.content.airSchedules.airPending', ''),
    getWasPreviouslyRefreshed: ({ newAir }) => get(newAir, 'previouslyRefreshed', false),
    getIsAirViewOnly: ({ newAir }) => get(newAir, 'isAirViewOnly', false),
    getIsFlightDetailsPending: ({ waiter }) => get(waiter, 'postFlightDetails.isPending', true),
    getFlightSelectionValue: ({ newAir }) => {
      const availableFlights = get(newAir, 'availableFlights', []);
      if (availableFlights.length > 0) {
        const flightSelection = get(newAir, 'flightSelection', '').split(',');
        if (flightSelection.length === 2) {
          const { offers } = availableFlights[flightSelection[0]];
          const offerIndex = offers.findIndex((item) => item.cabinClass.toLowerCase() === flightSelection[1]);
          const selectedOffer = offers[offerIndex];
          const selectedFlightInfo = availableFlights[flightSelection[0]];
          const preSelected = flightSelection[0] === '0' && selectedOffer.current === true;

          const pre = preSelected ? selectedFlightInfo.current.pre : selectedFlightInfo.pre;
          const post = preSelected ? selectedFlightInfo.current.post : selectedFlightInfo.post;

          return {
            itinerary: { pre, post },
            offer: offers[offerIndex],
            airClass: flightSelection[1],
          };
        }
      }
      return {};
    },
    getBookingCheckoutResponse: ({ newAir }) => get(newAir, 'bookingCheckoutResponse', {}),
    getPreAssignedSeats: ({ newAir }) => get(newAir, 'preAssignedSeats', {}),
    getPreAssignedSeatsSplitPnr: ({ newAir }) => get(newAir, 'preSeat1', []),
    getExitSeatAccepted: ({ newAir }) => get(newAir, 'exitSeatAccepted', {}),
    getChosenSeats: ({ newAir }) => get(newAir, 'chosenSeats', {}),
    checkForPaidSeats: new Selector(({ getAddCollect }) =>
      createSelector([getAddCollect], (addCollect) => {
        const paidSeats = get(addCollect, 'paidSeats', []);
        const pax1PaidSeatValues = paidSeats[0] ? Object.values(paidSeats[0]) : [];
        const pax2PaidSeatValues = paidSeats[1] ? Object.values(paidSeats[1]) : [];
        const pax1HasPaidSeats = pax1PaidSeatValues.some((value) => value !== null && value !== 0);
        const pax2HasPaidSeats = pax2PaidSeatValues.some((value) => value !== null && value !== 0);
        return pax1HasPaidSeats || pax2HasPaidSeats;
      })
    ),
    getHasOutboundDeviation: ({ waiter }) =>
      get(waiter, 'getSearchFlights.response.data.filter.hasOriginDeviation', DEVIATION_TYPES.NONE) !==
      DEVIATION_TYPES.NONE,
    getHasReturnDeviation: ({ waiter }) =>
      get(waiter, 'getSearchFlights.response.data.filter.hasReturnDeviation', DEVIATION_TYPES.NONE) !==
      DEVIATION_TYPES.NONE,
    getAllowDeltaComfortPlus: ({ waiter }) =>
      get(waiter, 'getSearchFlights.response.data.allowDeltaComfortPlus', false),
    getItinerariesDiffer: ({ newAir }) => {
      const passengers = get(newAir, 'airBooking.content.airSchedules.passengers', []);
      if (passengers.length < 2) {
        return false;
      }
      const passengerOneReferenceNumber = passengers[0].referenceNumber;
      const passengerTwoReferenceNumber = passengers[1].referenceNumber;
      return passengerOneReferenceNumber !== passengerTwoReferenceNumber;
    },
    // eslint-disable-next-line max-len
    getSelectedPassengerIndex: new Selector(() =>
      createSelector([(state) => state, getLoggedInUser], ({ newAir }, loggedInUser) => {
        const passengers = get(newAir, 'airBooking.content.airSchedules.passengers', []);
        const loggedInPassengerNumber = get(loggedInUser, 'passengerNumber', 1);
        let foundIndex = passengers.findIndex((passenger) => passenger.passengerNumber === loggedInPassengerNumber);
        if (foundIndex === -1) {
          foundIndex = 0;
        }
        const selectedPassengerIndex = get(newAir, 'selectedPassengerIndex', foundIndex);
        if (selectedPassengerIndex < passengers.length) {
          return selectedPassengerIndex;
        }
        return foundIndex;
      })
    ),
    getAirPassengers: ({ newAir }) => get(newAir, 'airBooking.content.airSchedules.passengers', []),
    getIsRepriced: ({ newAir }) => get(newAir, 'isRepriced', false),
    getInvoicePricingPassengers: (state) =>
      get(getWaiterResponse(state, VALIDATE_CHECKOUT), ['data', 'invoicePricing', 'passengers']),
    getAddCollectTotal: new Selector(({ getChosenFlightsPassengers, getAirBookingFlow }) =>
      createSelector(
        [
          getChosenFlightsPassengers,
          getAirBookingFlow,
          ({ newAir }) => newAir.addCollect[TRAVEL_PROTECTION_TOTAL],
          ({ newAir }) => newAir.addCollect[PAID_SEATS],
          ({ newAir }) => newAir.addCollect[TOTAL],
        ],
        (chosenFlightsPass, currFlow, travelProtectionLineItems, paidSeatsLineItems, ...lineItems) => {
          const pax1PaidSeatValues =
            paidSeatsLineItems && paidSeatsLineItems[0] ? Object.values(paidSeatsLineItems[0]) : [];
          const pax2PaidSeatValues =
            paidSeatsLineItems && paidSeatsLineItems[1] ? Object.values(paidSeatsLineItems[1]) : [];
          const allPaidSeatValues = pax1PaidSeatValues.concat(pax2PaidSeatValues);
          const paidSeatSum =
            allPaidSeatValues.length > 0
              ? allPaidSeatValues.filter((v) => typeof v === 'number').reduce((acc, item) => acc + item, 0)
              : 0;
          if (currFlow === CHANGE_SEATS && !travelProtectionLineItems) {
            return paidSeatSum;
          }
          const selectedPassengerTravelProtectionSum = travelProtectionLineItems
            .filter((pass) =>
              chosenFlightsPass.find(
                (chosenPass) => Number(chosenPass.passengerNumber) === Number(pass.passengerNumber)
              )
            )
            .reduce((acc, item) => acc + item.value, 0);
          if (currFlow === CHANGE_SEATS && lineItems.length !== 0) {
            return selectedPassengerTravelProtectionSum + paidSeatSum;
          }
          const restLineItemSum = lineItems.flat().reduce((acc, lineItem) => acc + lineItem?.value, 0);
          return selectedPassengerTravelProtectionSum + restLineItemSum + paidSeatSum;
        }
      )
    ),
    getAddCollect: ({ newAir }) => get(newAir, 'addCollect', {}),
    getAirSchedules: ({ newAir }) => get(newAir, 'airBooking.content.airSchedules', {}),
    getBookingAlerts: ({ newAir }) => get(newAir, 'bookingAlerts', []),
    getAllSeatMapsUnavailable: ({ newAir }) => get(newAir, 'allSeatMapsUnavailable', false),
    getIsAirPendingInProgress: ({ newAir }) => get(newAir, 'isAirPendingInProgress', ''),
    getAvailableFlightClasses: new Selector(() =>
      createSelector(
        [
          (state) =>
            getWaiterResponse(state, GET_SEARCH_REQS) ||
            getWaiterResponse(state, `${UPGRADE_CLASS}-${GET_SEARCH_REQS}`),
          getAirClassMap,
        ],
        (searchReqs, flightClassMap) => {
          const availableAirClassCodes = get(searchReqs, 'data.offerCodes.availableAirClasses', []);
          const filteredCodes = availableAirClassCodes.filter((code) => SUPPORTED_CLASS_CODES.indexOf(code) !== -1);
          const availableAirClassValues = [];
          const entries = Object.entries(flightClassMap);
          for (let i = 0; i < entries.length; i += 1) {
            if (filteredCodes.indexOf(entries[i][0]) !== -1) {
              availableAirClassValues.push(convertStringToStartCase(entries[i][1]));
            }
          }
          return availableAirClassValues;
        }
      )
    ),
    getChosenFlightsPassengers: new Selector(({ getChosenFlights }) =>
      createSelector(
        [(state) => state, getChosenFlights, getPassengers],
        // eslint-disable-next-line max-len
        (state, chosenFlights, passengers) =>
          (passengers || []).filter(
            (pax) =>
              !!(chosenFlights.passengers || []).find((flightPax) => pax.passengerNumber === flightPax.passengerNumber)
          )
      )
    ),
    getValidateOfferStatus: ({ waiter }) => get(waiter, 'postValidateCheckout.response.data.validateOffer.status', ''),
    // eslint-disable-next-line max-len
    getTravelProtectionMessage: new Selector(({ getAddCollect, getChosenFlightsPassengers, getAirCms }) =>
      createSelector(
        [(state) => state, getAddCollect, getChosenFlightsPassengers, getAirCms],
        (state, addCollect, passengers, cms) => {
          let travelProtectionCms = '';
          const travelProtectionLineItems = get(addCollect, 'travelProtection', []);
          const chosenPassengersWithTravelProtection = travelProtectionLineItems
            .map((lineItem) => {
              if (!lineItem.value) {
                return null;
              }

              const matchingPassenger = passengers.find(
                (p) => Number(p.passengerNumber) === Number(lineItem.passengerNumber)
              );
              return matchingPassenger
                ? {
                    ...lineItem,
                    name: getPassengerAbbrevName(matchingPassenger),
                  }
                : null;
            })
            .filter((p) => p);

          if (chosenPassengersWithTravelProtection.length > 0) {
            travelProtectionCms =
              chosenPassengersWithTravelProtection[0].value > 0
                ? get(cms, ['abeTravelProtectionChange', 'title'])
                : get(cms, ['abeTravelProtectionDecrease', 'title']);
          }

          const travelProtectionMessage = replaceCMSTokenWithValue(travelProtectionCms, [
            {
              key: 'COST',
              // eslint-disable-next-line max-len
              value: formatMoneyABE(
                chosenPassengersWithTravelProtection.reduce((acc, pass) => acc + pass.value, 0),
                getCountryCodeFromCurrency(state)
              ),
            },
          ]);
          return travelProtectionMessage;
        }
      )
    ),
    getTermsAndConditionsURL: (state) => {
      const termsAndConditions = get(state, 'common.content.footer.items', []).find(
        (x) => x.name === 'terms_conditions'
      );

      return termsAndConditions.contentUrl;
    },
    getAirFaq: (state) => {
      const cmsContent = get(state, 'newAir.cms', []);
      const airphone = get(cmsContent.AIRPHONE, 'longText', RESERVATION_NUMBERS.US);
      const airFaq = get(state, 'help.content.sections', []).find((x) => x.title === FAQ_CATEGORIES.AIR);
      const questions = sortFAQByOrder(airFaq?.questions || [], true).map((item, ind) => ({
        question: item.title,
        answer: replaceCMSTokenWithValue(item.longText, [{ key: 'airPhone', value: airphone }], '{{', '}}'),
        id: ind,
      }));
      return questions;
    },
    getMVJSectionCards: ({ newAir }) => get(newAir, 'airBooking.content.sections[0].cards', []),
    getIsSearchingAgain: ({ newAir }) => get(newAir, 'isSearchingAgain', false),
    getInvalidatedOffers: ({ newAir }) => get(newAir, 'invalidatedOffers', null),
    getIsHidingLoadMore: ({ newAir }) => get(newAir, 'isHidingLoadMore', false),
    getCurrentFlightTotalSeatsAvailable: new Selector(({ getAvailableFlights }) =>
      createSelector([getAvailableFlights], (availableFlights) => {
        let totalSeatsAvailable = 0;
        let isCurrentFlight = [];
        const flights = availableFlights[0].slice(0, 1);
        if (flights.length === 1) {
          isCurrentFlight = Object.values(flights[0].offers)
            .map((offer) => offer.current)
            .filter((c) => c);
        }
        if (flights.length === 1 && isCurrentFlight.length > 0) {
          totalSeatsAvailable = Object.values(flights[0].offers)
            // eslint-disable-next-line
            .map((offer) =>
              !offer.current &&
              offer.cabinClass !== AIR_CABIN_CLASS.DELTA_COMFORT_PLUS.code.toUpperCase() &&
              offer.seatRemaining
                ? offer.seatRemaining
                : 0
            )
            .reduce((seats, total) => total + seats);
        }
        return totalSeatsAvailable;
      })
    ),
    getAirData: ({ newAir }) => newAir || {},
    getExpectedPage: ({ newAir }) => get(newAir, 'expectedPage', ''),
    getCheckoutProgressDisplay: ({ newAir }) => get(newAir, 'checkoutProgressDisplay', 0),
    getAirPassengersSkipPassengerSelection: new Selector(({ getAirBookingFlow }) =>
      createSelector([getAirBookingFlow, getPassengers], (currAirFlow, passengers) => {
        let airPassengers = (passengers || []).filter(
          (pax) => get(pax, 'air.pre', []).length > 0 || get(pax, 'air.post', []).length > 0
        );
        if (currAirFlow === UPGRADE_CLASS && airPassengers.length > 1) {
          airPassengers = airPassengers.filter((pax) => paxCanUpgrade(pax));
        }
        return airPassengers;
      })
    ),
  },
});

// Fetches all data that is required for search page to function
export const fetchSearchPageReqs = (currentFlow) => (dispatch, getState) => {
  const state = getState();
  const {
    selectors: { getChosenFlightsPassengers },
    creators: { setSearchGetPending },
  } = airStore;
  const { voyage, currency, office, bookingNumber, rateCode, bookedDate } = getBookingDetails(state);

  const country = decodeCountryCodeFromCurrency(currency) || office;
  const pax = getChosenFlightsPassengers(state);
  const fromDate = voyage.embarkDate.split('T')[0];
  const toDate = voyage.disembarkDate.split('T')[0];
  let { originAirClass, returnAirClass } = get(pax, [0], {
    originAirClass: 'Q',
    returnAirClass: 'Q',
  });

  if (originAirClass === ' ') {
    originAirClass = returnAirClass;
  } else if (returnAirClass === ' ') {
    returnAirClass = originAirClass;
  }

  if (getWaiter(state, SEATMAP).isPending) {
    dispatch(clearWaiter(SEATMAP));
  }

  dispatch(setSearchGetPending(false));
  return dispatch(
    getWaiterData({
      name: currentFlow === UPGRADE_CLASS ? `${UPGRADE_CLASS}-${GET_SEARCH_REQS}` : GET_SEARCH_REQS,
      url: `/abe/air/${office}/${currency}/${voyage.id}/${bookingNumber}/search`,
      config: {
        params: {
          bookedDate,
          fromDate,
          toDate,
          offerCode: rateCode,
          originAirClass,
          returnAirClass,
          country,
        },
      },
    })
  );
};

export const getSearchEffectiveGateways = (gatewaysList, preItinerary, postItinerary, availableClasses) => {
  let formattedSegments;
  const segments = [...preItinerary, ...postItinerary];
  if (segments && segments.length > 0) {
    if (preItinerary.length === 0) {
      formattedSegments = [segments[segments.length - 1].arrivalCityCode];
    } else if (postItinerary.length === 0) {
      formattedSegments = [segments[0].departureCityCode];
    } else {
      formattedSegments = [segments[0].departureCityCode];
      if (!formattedSegments.includes(segments[segments.length - 1].arrivalCityCode)) {
        formattedSegments.push(segments[segments.length - 1].arrivalCityCode);
      }
    }
  }
  return formattedSegments.reduce((acc, seg) => {
    availableClasses.forEach((serviceClass) => {
      const gatewayMatch = gatewaysList.find(({ AirCity, AirClass }) => AirCity === seg && AirClass === serviceClass);

      if (gatewayMatch) {
        acc.push(gatewayMatch);
      }
    });

    return acc;
  }, []);
};

export const getSearchStopoverDeviationGateways = (gateways, itinerary) => {
  const { pre, post } = itinerary;

  const preStopoverDeviation = pre.reduce((acc, segment, index) => {
    // Deviation
    if (index === pre.length - 1) {
      const deviation = gateways?.preDeviationGateways?.find((gateway) => gateway.code === segment.arrivalCityCode);
      if (deviation) {
        deviation.isPre = true;
        acc.push(deviation);
      }
    }

    // Stopover
    if (pre.length > 1 && index === 0) {
      const stopover = gateways?.preStopoverGateways?.find((gateway) => gateway.code === segment.arrivalCityCode);
      if (stopover) {
        stopover.isStopover = true;
        acc.push(stopover);
      }
    }

    return acc;
  }, []);

  const postStopoverDeviation = post.reduce((acc, segment, index) => {
    // Deviation
    if (index === 0) {
      const deviation = gateways?.postDeviationGateways?.find((gateway) => gateway.code === segment.departureCityCode);
      if (deviation) {
        deviation.isPost = true;
        acc.push(deviation);
      }
    }

    // Stopover
    if (post.length > 1) {
      const stopover = gateways?.postStopoverGateways?.find((gateway) => gateway.code === segment.arrivalCityCode);
      if (stopover) {
        stopover.isStopover = true;
        acc.push(stopover);
      }
    }

    return acc;
  }, []);

  return [...preStopoverDeviation, ...postStopoverDeviation];
};

const getFormattedSearchCriteria = () => (dispatch, getState) => {
  const { preFlightSelector, postFlightSelector } = getFormValues(FORMS.AIR_SEARCH)(getState());

  const formatLeg = (arr, direction) => {
    const noParenRegEx = /[A-Z]{3}/;
    return arr.map((flight) => {
      return {
        departureCityCode: flight.from.match(noParenRegEx)[0] || '',
        arrivalCityCode: flight.to.match(noParenRegEx)[0] || '',
        departureDate: direction !== 'pre' ? moment(flight.date).format('YYYY-MM-DD') : null,
        arrivalDate: direction === 'pre' ? moment(flight.date).format('YYYY-MM-DD') : null,
      };
    });
  };
  const preMap = preFlightSelector && preFlightSelector.length === 0 ? [] : formatLeg(preFlightSelector, 'pre');
  const postMap = postFlightSelector && postFlightSelector.length === 0 ? [] : formatLeg(postFlightSelector, 'post');

  return { pre: preMap, post: postMap };
};

const getCostObj = () => async (dispatch, getState) => {
  const {
    selectors: { getChosenFlightsPassengers, getChosenFlights },
  } = airStore;
  const state = getState();

  const bookingPassengers = getChosenFlightsPassengers(state);

  const searchReqs =
    getWaiterResponse(state, `${UPGRADE_CLASS}-${GET_SEARCH_REQS}`) || getWaiterResponse(state, GET_SEARCH_REQS);

  const chosenFlights = getChosenFlights(state);
  const { tokenizedValues } = get(chosenFlights, ['passengers', 0]);
  const addOns = get(bookingPassengers, [0, 'addOns'], []);
  const { pre, post } = dispatch(getFormattedSearchCriteria());

  const availableAirClassCodes = get(searchReqs, 'data.offerCodes.availableAirClasses', []);
  const searchReqsGatewaysList = get(searchReqs, ['data', 'effectiveGateways', 'CruiseList', 0, 'AirCities']);

  const effectiveGateways = getSearchEffectiveGateways(searchReqsGatewaysList, pre, post, availableAirClassCodes);

  const stopoverDeviationGateways = getSearchStopoverDeviationGateways(get(searchReqs, 'data.gateways', {}), {
    pre,
    post,
  });

  const { dateOnlyDeviationFeeTokenized } = get(searchReqs, 'data.gateways', {});

  const combinedTokenizedValues = {
    tokenizedValues,
    dateOnlyDeviationFeeTokenized,
  };

  return {
    combinedTokenizedValues,
    effectiveGateways,
    stopoverDeviationGateways,
    addOns: addOns.filter((addOn) => addOn.serviceCode === 'AIRDEV'),
    travelDates: get(searchReqs, ['data', 'travelDates'], {}),
  };
};

export const searchFlights = (excludeFlights) => async (dispatch, getState) => {
  dispatch(setViewAndShowModal(AIR_LOADING));

  const state = getState();
  const {
    selectors: { getAirBookingFlow, getChosenFlightsPassengers, getReferenceNumber, getSearchIntervalId },
    creators: {
      setFilters,
      resetAddCollect,
      setAvailableFlights,
      setOriginalAvailableFlights,
      setSearchComplete,
      setSearchGetPending,
      setSearchIntervalId,
      setSearchingAgain,
      setFlightSelection,
      setAvailableFlightCount,
    },
  } = airStore;

  const { ONE_MIN_TIME_OUT, REPEAT_INTERVAL } = POLLING_TIMES;

  // Clear out current results to begin
  dispatch(setAvailableFlights([]));
  dispatch(resetAddCollect());
  dispatch(setFlightSelection(''));
  dispatch(setAvailableFlightCount(DEFAULT_AVAILABLE_FLIGHT_COUNT));
  dispatch(setSearchComplete(false));
  dispatch(clearWaiter(POST_CHECKOUT));
  dispatch(clearWaiter(VALIDATE_CHECKOUT));

  const currAirFlow = getAirBookingFlow(getState());
  dispatch(setSearchGetPending(true));

  const booking = getBookingDetails(state);
  const username = getUsername(state);
  const referenceNumber = getReferenceNumber(state);
  const { brand, office, currency, bookingNumber, ship, voyage, marketCode } = booking;
  const voyageType = getVoyageType(state);
  const { shipCode, shipName } = ship;
  const { embarkDate, disembarkDate, embarkPort, disembarkPort, id } = voyage;

  const { pre, post } = dispatch(getFormattedSearchCriteria());

  const bookingPassengers = getChosenFlightsPassengers(state);
  const numPax = bookingPassengers.length;

  if (currAirFlow === UPGRADE_CLASS) {
    await dispatch(fetchSearchPageReqs(UPGRADE_CLASS));
  }

  let flightClassMap = getAirClassMap(state);
  if (Object.keys(flightClassMap).length < 1) {
    flightClassMap = STANDARD_FLIGHT_CLASS_MAP;
  }

  const cost = await dispatch(getCostObj());
  const pax = getChosenFlightsPassengers(state);
  if (cost.travelDates.length > 1 && pax.length === 1) {
    cost.travelDates = cost.travelDates.filter((p) => p.passengerNumber === pax[0].passengerNumber);
  }
  dispatch(setSearchIntervalId(null));
  const traceparent = getTraceparent();
  const startTime = Math.floor(new Date().getTime());
  let requestCount = 0;
  const postUrl = '/abe/availableflights';
  return dispatch(
    postWaiterData({
      name: POST_SEARCH_FLIGHTS,
      url: postUrl,
      values: {
        cost,
        filter: {
          userId: username,
          guestNumber: numPax,
          pnrLocator: referenceNumber,
          cruiseBooking: {
            bookingId: bookingNumber,
            pos: office,
            company: brand,
            programId: getProgramId(state),
            region: marketCode,
            cruiseDetails: {
              cruiseId: id,
              cruiseType: voyageType,
              arrivalDate: moment(disembarkDate).format('YYYY-MM-DD'),
              arrivalPort: disembarkPort,
              departureDate: moment(embarkDate).format('YYYY-MM-DD'),
              departurePort: embarkPort,
              shipId: shipCode,
              shipName,
            },
          },
          pre,
          post,
          // No longer used, required fields
          preferences: {
            airlines: [],
          },
        },
        office,
        currency,
        bookingId: bookingNumber,
      },
      config: {
        traceparent,
        tracestate: 'mvagroup=MVA_SEARCH;',
      },
    })
  ).then(({ data }) => {
    if (data.errorCode === '404') {
      return dispatch(setViewAndShowModal(MODALS.TOKEN_ERROR));
    }
    const searchStartTime = data.startTime || startTime;
    let intervalCount = 0;
    let intervalId;
    dispatch(setSearchIntervalId(null));
    const pollAvailableFlights = (url) => {
      intervalCount += REPEAT_INTERVAL;
      requestCount += 1;
      dispatch(
        getWaiterData({
          url,
          name: GET_SEARCH_FLIGHTS,
          config: {
            params: { excludeFlights },
            traceparent,
            tracestate: `mvagroup=MVA_SEARCH;mvastart=${searchStartTime};mvareqcount=${requestCount};`,
          },
        })
      ).then((res) => {
        const finishProcessing = () => {
          if (intervalId) {
            clearInterval(intervalId);
          }
          dispatch(setSearchGetPending(false));
          dispatch(clearSpecificModal(AIR_LOADING));
          dispatch(setSearchingAgain(false));
          window.scrollTo(0, 0);
        };
        const currentSearch = getSearchIntervalId(getState());
        if (currentSearch && currentSearch !== intervalId) {
          finishProcessing();
          return null;
        }
        if (res.data.errorCode === '500' || intervalCount >= ONE_MIN_TIME_OUT) {
          finishProcessing();
          return dispatch(setSearchComplete(true));
        }
        if (res.data.errorCode === '404') {
          finishProcessing();
          if (res?.data?.internalCode === RESPONSE_STATUS.FAILURE_NO_PNR) {
            return dispatch(setViewAndShowModal(MODALS.NO_PNR));
          }
          return dispatch(setViewAndShowModal(MODALS.TOKEN_ERROR));
        }
        if (res && res.status !== 206) {
          finishProcessing();
          if (res.status === 200) {
            const { data: resData } = res;
            const flights = Object.values(resData.flights);
            dispatch(setAvailableFlights(flights));
            dispatch(setOriginalAvailableFlights(flights));
            dispatch(setFilters());
            dispatch(setSearchComplete(true));
          }
          return res;
        }
        return [];
      });
    };
    if (get(data, 'requestId.id', '')) {
      const availableFlightsUrl = `/abe/availableflights/${data.requestId.id}?excludeFlights=${
        currAirFlow === UPGRADE_CLASS
      }`;
      pollAvailableFlights(availableFlightsUrl);
      intervalId = setInterval(pollAvailableFlights, REPEAT_INTERVAL, availableFlightsUrl);
      dispatch(setSearchIntervalId(intervalId));
    } else {
      dispatch(setSearchGetPending(false));
      dispatch(clearSpecificModal(AIR_LOADING));
      dispatch(setSearchingAgain(false));
      dispatch(setSearchComplete(true));
      return dispatch(setViewAndShowModal(MODALS.TOKEN_ERROR));
    }
    return data;
  });
};

export const fetchAirports = ({ code, name, legDirection, segDirection, index, arrLen }) => (dispatch, getState) => {
  const { receiveAirportAutocompleteResults } = airStore.creators;

  const booking = getBookingDetails(getState());
  const { currency, voyage, office, rateCode } = booking;
  let country = decodeCountryCodeFromCurrency(currency) || office;

  let type = null;
  if (arrLen > 1) {
    if ((index !== 0 && segDirection === 'from') || (segDirection === 'to' && index + 1 !== arrLen)) {
      type = 'stopover';
    }
  }

  if (
    (legDirection === 'pre' && segDirection === 'to' && index + 1 === arrLen) ||
    (legDirection === 'post' && segDirection === 'from' && index === 0)
  ) {
    type = 'deviation';
  }

  if (
    (legDirection === 'pre' && segDirection !== 'from') ||
    (legDirection === 'post' && segDirection === 'from') ||
    (legDirection === 'post' && segDirection === 'to' && index + 1 !== arrLen)
  ) {
    country = 'ALL';
  }

  const params = {
    searchText: code,
    country,
    offerId: rateCode,
    office,
    currency,
    voyageId: voyage.id,
    type,
  };

  const url = buildUrl('/abe/airports/autocomplete', [], {}, params);
  return dispatch(
    getWaiterData({
      name: GET_AIRPORTS,
      url,
    })
  ).then((res) => {
    const noResults = { airports: [''] };

    // Scroll to the suggestion list rendered by react-autosuggest
    scrollToElementIfNeeded(document.getElementById(`react-autowhatever-${name}`));

    // Handle various messages by adding a blank item to suggestions list
    if (!res || res.status === 404 || res.status === 400) {
      return dispatch(receiveAirportAutocompleteResults(noResults, name));
    }
    const { data } = res;

    // Filter airports with effective gateways if field is first in pre or last in post
    if (
      (index === 0 && legDirection === 'pre' && segDirection === 'from') ||
      (index + 1 === arrLen && legDirection === 'post' && segDirection === 'to')
    ) {
      const { getEffectiveGateways } = airStore.selectors;
      const effectiveGateways = getEffectiveGateways(getState());
      const filteredAirports = get(data, 'airports').filter(
        (airport) => !!effectiveGateways.find((gateway) => airport.airportCode === gateway.AirCity)
      );
      return filteredAirports.length > 0
        ? dispatch(receiveAirportAutocompleteResults({ ...data, airports: filteredAirports }, name))
        : dispatch(receiveAirportAutocompleteResults(noResults, name));
    }
    return dispatch(receiveAirportAutocompleteResults(data, name));
  });
};

export const fetchAirPageContent = () => (dispatch, getState) => {
  const { receiveContent } = airStore.creators;
  const url = buildUrl('/pages/airBookingEngine', ['voyage.type'], getBookingDetails(getState()));
  dispatch(
    getData({
      url,
      store: airStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export const fetchTabContent = (tab) => (dispatch, getState) => {
  const { getAuthData, isGifOverride } = userStore.selectors;
  const {
    receiveTabContent,
    receiveAirCms,
    setChosenFlights,
    setViewershipStatuses,
    setTokenizedValues,
    setCheckoutProcessing,
  } = airStore.creators;
  const { getAirEmbarkationDate, getAirDisembarkationDate } = airStore.selectors;

  dispatch(setCheckoutProcessing(false));

  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const { office, currency, voyage, packageType } = bookingDetails;
  const embarkationDateWithTime = getAirEmbarkationDate(state);
  const embarkationDate = `${embarkationDateWithTime.substring(0, embarkationDateWithTime.indexOf('T'))}T23:59:59`;
  const disembarkationDateWithTime = getAirDisembarkationDate(state);
  const disembarkationDate = `${disembarkationDateWithTime.substring(
    0,
    disembarkationDateWithTime.indexOf('T')
  )}T00:00:00`;
  const voyageType = getVoyageType(state);
  const depositDue = getBalanceDue(state);
  const depositDueDate = getBalanceDueDate(state);
  const GIFCompleted = isAllGifCompleted(state);
  const bookingStatus = get(bookingDetails, 'bookingStatus', []);
  const modifyingUser = getUpdateUserData(state);
  const loggedInUser = getAuthData(state);
  const userID = loggedInUser?.username || loggedInUser?.account?.username || '';
  const sessionData = {
    userID,
    ...modifyingUser,
    isGifOverride: isGifOverride(state),
  };

  const isCruiseOnly = packageType === BOOKING_PACKAGE_TYPE.CRUISE_ONLY;

  const passengers = get(bookingDetails, 'passengers', []).map(({ air, passengerNumber, addOnsTokenized }) => {
    const pre = get(air, 'pre.segments', air.pre) || [];
    const post = get(air, 'post.segments', air.post) || [];
    const airFlight = { pre, post };
    return {
      air: airFlight,
      passengerNumber,
      addOnsTokenized,
    };
  });
  const programId = getProgramId(getState());

  const url = buildUrl(`/abe/air/flights/${office}/${currency}`, ['bookingNumber'], bookingDetails, {
    embarkationDate,
    disembarkationDate,
    new: true,
  });
  return dispatch(
    postWaiterData({
      name: GET_AIR_SCHEDULES,
      url,
      values: {
        voyageId: voyage && voyage.id,
        passengers,
        voyageType,
        depositDue,
        depositDueDate,
        GIFCompleted,
        bookingStatus,
        programId,
        sessionData,
        isCruiseOnly,
      },
    })
  ).then(({ data }) => {
    const passengerTokenValues = data.airSchedules?.passengers.map((pax) => pax.tokenizedValues);
    if (tab === TAB_NAMES.AIR_SEARCH) {
      dispatch(setTokenizedValues(passengerTokenValues));
    } else {
      // Use receiveTabContent action to put airSchedules into store
      dispatch(receiveTabContent(data, tab));
      dispatch(receiveAirCms(get(data, ['sections', 0, 'items'], []).concat(get(data, ['sections', 0, 'cards'], []))));
      dispatch(setChosenFlights(data.airSchedules));
      dispatch(setTokenizedValues(passengerTokenValues));
      dispatch(setViewershipStatuses(data.statusCodes));
    }
    return null;
  });
};

// Using change instead of clearFields because of redux-form 7.x.x bug
// https://github.com/redux-form/redux-form/issues/4101
export const clearAirport = (name) => (dispatch) => dispatch(change(FORMS.AIR_SEARCH, name, null));

export const loadMoreFlightSearchResults = () => (dispatch, getState) => {
  const mvjStrings = getMvjStrings(getState());
  const batchSize = get(mvjStrings, 'labels.pages.newAir.constants.loadMoreFlightsBatchSize', 10);
  const { setAvailableFlightCount } = airStore.creators;
  const { getAvailableFlightCount } = airStore.selectors;
  const nextSliceAmount = getAvailableFlightCount(getState()) + batchSize;
  return dispatch(setAvailableFlightCount(nextSliceAmount));
};

export const resetSearch = (refetchSearch = false) => async (dispatch, getState) => {
  const {
    setSearchComplete,
    setAvailableFlights,
    setOriginalAvailableFlights,
    setFilters,
    resetAddCollect,
    setSearchGetPending,
    setSearchIntervalId,
    setSeatTimeOutCallCount,
    setSeatmapPageNumber,
  } = airStore.creators;
  const { getSearchIntervalId, getAirBookingFlow } = airStore.selectors;
  const cancelSearch = getSearchIntervalId(getState());
  if (cancelSearch) {
    clearInterval(cancelSearch);
    dispatch(setSearchIntervalId(CANCELED_INTERVAL_ID));
  }
  dispatch(clearSpecificModal(AIR_LOADING));
  dispatch(setSearchGetPending(false));
  dispatch(clearWaiter(GET_SEARCH_FLIGHTS));
  dispatch(clearWaiter(POST_CHECKOUT));
  dispatch(clearWaiter(VALIDATE_CHECKOUT));
  dispatch(setAvailableFlights());
  dispatch(setOriginalAvailableFlights());
  dispatch(setFilters());
  dispatch(setSearchComplete(false));
  dispatch(resetAddCollect());
  dispatch(setSeatTimeOutCallCount(0));
  dispatch(setSeatmapPageNumber(0));
  if (history.location.pathname === AIR_PATHS.SEARCH && refetchSearch) {
    await dispatch(fetchSearchPageReqs(getAirBookingFlow(getState())));
  }
};

const invalidateCurrentOffer = () => (dispatch, getState) => {
  const { getChosenFlights } = airStore.selectors;
  const { invalidateOffer, setFlightSelection } = airStore.creators;
  const offerId = get(getChosenFlights(getState()), ['passengers', 0, 'flightSchedule', 'offer', 'offerId'], '');
  if (offerId) {
    dispatch(setFlightSelection(''));
    dispatch(invalidateOffer(offerId));
  }
};

export const navigateTo = (path, replace) => (dispatch) => {
  const { setExpectedPage } = airStore.creators;
  dispatch(setExpectedPage({ from: history.location.pathname, to: path }));
  if (replace) {
    history.replace(path);
  } else {
    goTo(path)();
  }
};

export const navigateBack = () => (dispatch) => {
  const { setExpectedPage } = airStore.creators;
  dispatch(setExpectedPage('*'));
  history.goBack();
};

export const isNotExpectedPage = (state, currentPage, allowBack) => {
  const { getExpectedPage } = airStore.selectors;
  const expectedPage = getExpectedPage(state);
  if (!expectedPage) {
    return true;
  }

  if (allowBack && [expectedPage.to, expectedPage.from, '*'].includes(currentPage)) {
    return false;
  }

  if (
    expectedPage.to !== currentPage ||
    (expectedPage.from !== currentPage && ![currentPage, '*'].includes(expectedPage.to))
  ) {
    return true;
  }
  return false;
};

export const searchAgain = () => async (dispatch, getState) => {
  const { setSearchingAgain } = airStore.creators;
  const { getAirBookingFlow } = airStore.selectors;
  const currAirFlow = getAirBookingFlow(getState());
  dispatch(clearModal());
  dispatch(resetSearch(false));
  dispatch(navigateTo(AIR_PATHS.SEARCH, true));
  await dispatch(fetchSearchPageReqs(currAirFlow));
  dispatch(setSearchingAgain(true));
  dispatch(setViewAndShowModal(AIR_LOADING));
  dispatch(searchFlights());
};

export const returnToSearchResults = (removeOffer) => async (dispatch) => {
  dispatch(clearModal());
  if (removeOffer) {
    dispatch(invalidateCurrentOffer());
  }
  dispatch(navigateTo(AIR_PATHS.SEARCH, true));
};

export const returnToSeatMapsFromModal = () => async (dispatch) => {
  const { setSeatmapPageNumber } = airStore.creators;
  dispatch(clearModal());
  dispatch(setSeatmapPageNumber(0));
  dispatch(navigateTo(AIR_PATHS.SEATS, true));
};

export const setTimer = (ignoreEmptySearch) => (dispatch) => {
  dispatch({ type: 'AIR_BOOKING_TIMER_SET', payload: ignoreEmptySearch });
};

export const cancelTimer = () => (dispatch) => {
  dispatch({ type: 'AIR_BOOKING_TIMER_CANCEL' });
};

export const warnUserOfExpiredSession = () => (dispatch) => {
  dispatch(setViewAndShowModal(AIR_SEARCH_SESSION_WARNING, {}, true));
};

export const expireSearchSession = () => (dispatch) => {
  dispatch(setViewAndShowModal(AIR_SEARCH_SESSION_EXPIRED, {}, true));
  dispatch(cancelTimer());
};

export const expireSearchSessionConfirm = () => (dispatch) => {
  dispatch(searchAgain(false));
};

export const setAirBookingFlow = (airBookingFlow) => (dispatch) => {
  const { setFlow } = airStore.creators;
  dispatch(setFlow(airBookingFlow));
};

export const getSelectedSeats = (flights) => {
  const paxFlights = get(flights, ['flightSchedule'], {});
  const preFlights = get(paxFlights, ['pre'], []);
  const postFlights = get(paxFlights, ['post'], []);
  const allFlights = preFlights.concat(postFlights);
  const flightsWithoutLabels = allFlights.length > 0 ? allFlights.filter((item) => item.type !== 'label') : [];
  const paxAssignedSeats =
    flightsWithoutLabels.length > 0
      ? flightsWithoutLabels.map((flight) => ({
          seat: get(flight, 'seat', ''),
          price: get(flight, 'seatCost', 0),
          seatWaiver: get(flight, 'seatWaiver', false),
        }))
      : [];
  return paxAssignedSeats;
};
export const setPreAssignedSeats = (flightsArr) => (dispatch) => {
  const { setAssignedSeats } = airStore.creators;
  const assignedSeatsObj = {
    pax1AssignedSeats: getSelectedSeats(flightsArr[0]),
    pax2AssignedSeats: getSelectedSeats(flightsArr[1]),
  };
  dispatch(setAssignedSeats(assignedSeatsObj));
};
export const setFinalChosenSeats = (flightsArr) => (dispatch) => {
  const { setChosenSeats } = airStore.creators;
  const chosenSeatsObj = {
    pax1AssignedSeats: getSelectedSeats(flightsArr[0]),
    pax2AssignedSeats: getSelectedSeats(flightsArr[1]),
  };
  dispatch(setChosenSeats(chosenSeatsObj));
};
export const mapCabinClassFromOffer = (itinerary = [], offer = {}, direction = 'pre') =>
  itinerary.map((seg) => {
    const { cabinClass } =
      offer.cabinClasses[direction].find((offerSeg) => offerSeg.segmentNumber === seg.segmentNumber) || {};
    return { ...seg, cabinClass };
  });

export const commitFlightSelection = () => (dispatch, getState) => {
  const { setChosenFlights, updateAddCollect } = airStore.creators;
  const { getFlightSelectionValue, getChosenFlights } = airStore.selectors;

  const selectedFlight = getFlightSelectionValue(getState());
  const offer = get(selectedFlight, 'offer', {});

  const pre = mapCabinClassFromOffer(get(selectedFlight, 'itinerary.pre', []), offer, 'pre');
  const post = mapCabinClassFromOffer(get(selectedFlight, 'itinerary.post', []), offer, 'post');

  const chosenFlights = getChosenFlights(getState());
  const { passengers } = chosenFlights;

  const paxWithSelection = passengers.map((pax) => ({
    ...pax,
    flightSchedule: {
      ...pax.flightSchedule,
      pre,
      post,
      offer,
    },
  }));

  const addCollect = get(selectedFlight, 'offer.addCollectFee', {});
  dispatch(
    updateAddCollect(
      FLIGHT,
      passengers.map(() => addCollect.addCollect)
    )
  );
  dispatch(
    updateAddCollect(
      DEVIATION,
      passengers.map(() => addCollect.deviationFee)
    )
  );
  dispatch(
    updateAddCollect(
      UPGRADE,
      passengers.map(() => addCollect.upgradeFee)
    )
  );
  dispatch(
    updateAddCollect(
      GATEWAY_CHANGE,
      passengers.map(() => addCollect.gatewayChangeFee)
    )
  );
  dispatch(
    updateAddCollect(
      TOTAL,
      passengers.map(() => addCollect.totalFee)
    )
  );
  dispatch(setChosenFlights({ ...chosenFlights, passengers: paxWithSelection }));
};

export const postFlightDetails = (flightSchedules) => (dispatch) => {
  // Map only the flight info that the endpoint requires
  const mapSegments = (segments) =>
    segments.map(
      ({
        departureTime,
        cabinClass,
        shareInfoFlightNumber,
        shareInfoCarrierCode,
        arrivalCityCode,
        departureCityCode,
        arrivalTime,
        airlineCode,
        departureDate,
        arrivalDate,
        flightNumber,
      }) => ({
        departureTime,
        shareInfoCarrierCode,
        cabinClass,
        shareInfoCarrierFlightNumber: shareInfoFlightNumber,
        arrivalCityCode,
        departureCityCode,
        arrivalTime,
        airlineCode,
        departureDate,
        arrivalDate,
        flightNumber,
      })
    );

  const formattedFlightSchedules = flightSchedules.map((schedule) => ({
    ...schedule,
    segments: mapSegments(schedule.segments),
  }));

  return dispatch(
    postWaiterData({
      name: POST_FLIGHT_DETAILS,
      url: '/abe/air/flightdetails',
      values: { request: formattedFlightSchedules },
    })
  );
};

export const onSelectedPassengerChanged = (index) => (dispatch, getState) => {
  const { setSelectedPassengerIndex, setChosenFlights, receiveChosenFlightDetails } = airStore.creators;
  const { getAirSchedules } = airStore.selectors;
  const airSchedules = cloneDeep(getAirSchedules(getState()));
  const passengers = getPassengers(getState());
  const newSelectedPassenger = passengers[index];
  airSchedules.passengers = get(airSchedules, 'passengers', []).filter(
    (pax) => pax.passengerNumber === newSelectedPassenger.passengerNumber
  );

  const segments = [
    ...get(airSchedules, ['passengers', 0, 'flightSchedule', 'pre'], []),
    ...get(airSchedules, ['passengers', 0, 'flightSchedule', 'post'], []),
  ];

  dispatch(setChosenFlights(airSchedules));
  dispatch(setSelectedPassengerIndex(index));
  dispatch(postFlightDetails(segments)).then((res) => {
    if (res.status === 200) {
      dispatch(receiveChosenFlightDetails(get(res, 'data', [])));
    }
  });
};

export const getSegmentNumber = (seatmapPage) => (_, getState) => {
  const { getChosenFlights } = airStore.selectors;
  const flightSchedules = get(getChosenFlights(getState()), ['passengers', 0, 'flightSchedule'], {});
  const allSegmentsRaw = [...get(flightSchedules, 'pre', []), ...get(flightSchedules, 'post', [])];
  const allSegments = allSegmentsRaw.filter((item) => item.type !== 'label');
  return allSegments && allSegments[seatmapPage] && allSegments[seatmapPage].segmentNumber;
};

const setCheckoutStepProgress = (startTimeMillisec, totalTimeSec, startPercentage, endPercentage) => (dispatch) => {
  const { setCheckoutProgressDisplay } = airStore.creators;
  const progress = (Date.now() - startTimeMillisec) / (1000 * totalTimeSec);
  const display = Math.min(startPercentage + progress * (endPercentage - startPercentage), endPercentage);

  dispatch(setCheckoutProgressDisplay(display));
  return display;
};

const progressJumpTo = (total, minPct, maxPct, currentInterval) => (dispatch) => {
  if (currentInterval) {
    clearInterval(currentInterval);
  }
  const progressStartTime = Date.now();
  const ret = setInterval(() => {
    dispatch(setCheckoutStepProgress(progressStartTime, total, minPct, maxPct));
  }, 500);
  dispatch(setCheckoutStepProgress(progressStartTime, total, minPct, maxPct));
  return ret;
};

const setCheckoutProgressComplete = () => (dispatch) => {
  const { setCheckoutProgressDisplay } = airStore.creators;
  dispatch(setCheckoutProgressDisplay(100));
};

export const getAirPendingStatus = (checkoutTraceparent, checkoutStartTime, mvaGroup) => (dispatch, getState) => {
  const traceparent = checkoutTraceparent || getTraceparent();
  const bookingDetails = getBookingDetails(getState());
  const { setIsAirPendingInProgress } = airStore.creators;
  const { office, currency, bookingNumber } = bookingDetails;
  const { REPEAT_INTERVAL, TWO_MIN_TIME_OUT } = POLLING_TIMES;

  dispatch(setIsAirPendingInProgress(true));

  return new Promise((resolve) => {
    let intervalCount = 0;
    let requestCount = 0;
    let intervalId;

    const pollAirPending = async (url) => {
      intervalCount += REPEAT_INTERVAL;
      requestCount += 1;
      const airPendingStatus = await dispatch(
        getWaiterData({
          url,
          name: GET_AIR_PENDING_STATUS,
          config: {
            traceparent,
            tracestate: `mvagroup=${
              mvaGroup || 'MVA_CHECKOUT'
            };mvastart=${checkoutStartTime};mvareqcount=${requestCount};`,
          },
        })
      );

      if (intervalCount >= TWO_MIN_TIME_OUT) {
        if (intervalId) {
          clearInterval(intervalId);
        }
        dispatch(setIsAirPendingInProgress(false));
        return resolve(airPendingStatus);
      }
      if (airPendingStatus && airPendingStatus.status === 200 && !airPendingStatus.data.airPending) {
        if (intervalId) {
          clearInterval(intervalId);
        }
        dispatch(setIsAirPendingInProgress(false));
        return resolve(airPendingStatus);
      }

      return airPendingStatus;
    };
    const airPendingUrl = `/abe/air/flights/${office}/${currency}/${bookingNumber}/airpending`;
    pollAirPending(airPendingUrl);
    intervalId = setInterval(pollAirPending, REPEAT_INTERVAL, airPendingUrl);
  });
};

export const updateSeatMapData = (segmentId, availableOfferIds, flightIndex, paxIndex) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const {
    getChosenFlightsPassengers,
    getChosenFlights,
    getFlightSelectionCabinClass,
    getFlightSelectionKey,
    getAirBookingFlow,
    getReferenceNumber,
  } = airStore.selectors;
  const { setAllSeatMapsUnavailable, setExitSeatAccepted } = airStore.creators;
  const passengers = getChosenFlightsPassengers(state);
  const flightSelectionOfferClass = getFlightSelectionCabinClass(state);
  const flightSelectionKeyRaw = getFlightSelectionKey(state).split(',');
  const flightSelectionKey = parseInt(flightSelectionKeyRaw[0], 10);
  const offersArray = availableOfferIds ? Object.values(availableOfferIds) : [];
  const chosenFlight = getChosenFlights(state);
  const chosenOffer = chosenFlight.passengers[0].flightSchedule.offer;
  const currFlow = getAirBookingFlow(state);
  const referenceNumber = getReferenceNumber(state);

  const getSeatMapPassengersDetails = (isCurrentFlight) => {
    return passengers.map((passenger, index) => {
      const ffArray = get(passenger, 'airPreferences.frequentFlyers', []);
      const filteredFFNs = ffArray.filter(
        (val, idx, arr) => arr.findIndex((t) => t.airlineCode === val.airlineCode) === idx
      );
      const ffArrayMapped = filteredFFNs.map((ff) => ({
        sequence: ff.sequence,
        airline: ff.airlineCode,
        ffNumber: ff.frequentFlyerNumber,
      }));

      let seat;
      if (isCurrentFlight) {
        const chosenFlights = get(chosenFlight, `passengers[${index}].flightSchedule`, '');
        const allFlightsRaw = chosenFlights ? chosenFlights.pre.concat(chosenFlights.post) : '';
        const currentSegment = allFlightsRaw && allFlightsRaw.find((flight) => flight.segmentNumber === segmentId);
        seat = currentSegment ? currentSegment.seat : '';
      }

      return {
        id: get(passenger, 'airPassengerId', ''),
        firstName: get(passenger, 'firstName', ''),
        lastName: get(passenger, 'lastName', ''),
        ffNumbers: ffArrayMapped,
        seat,
      };
    });
  };

  let pnrLocator = '';
  let postSeatMapDef;
  if (offersArray && offersArray.length > 0 && currFlow !== CHANGE_SEATS) {
    const selectedOffer = offersArray.filter(
      (offer) => offer.cabinClass.toLowerCase() === flightSelectionOfferClass
    )[0];
    const flightOffer = offersArray.filter((offer) => offer.offerId && offer.offerId.length > 0)[0];
    const offer =
      flightIndex === flightSelectionKey && selectedOffer && selectedOffer.offerId ? selectedOffer : flightOffer;
    const { offerId } = offer;
    postSeatMapDef = {
      offerId,
      passengers: getSeatMapPassengersDetails(offer && offer.current),
    };
  } else if (chosenOffer && currFlow !== CHANGE_SEATS) {
    const chosenOfferId = chosenOffer.offerId;
    postSeatMapDef = {
      offerId: chosenOfferId,
      passengers: getSeatMapPassengersDetails(chosenOffer && chosenOffer.current),
    };
  } else {
    if (referenceNumber && !paxIndex) {
      pnrLocator = referenceNumber;
    } else if (chosenFlight.passengers && !paxIndex) {
      pnrLocator = chosenFlight.passengers[0].referenceNumber;
    } else if (chosenFlight.passengers && paxIndex) {
      pnrLocator = chosenFlight.passengers[paxIndex].referenceNumber;
    }
    postSeatMapDef = {
      pnrLocator,
      passengers: getSeatMapPassengersDetails(true),
    };
  }

  if (getWaiter(state, SEATMAP).isPending) {
    dispatch(clearWaiter(SEATMAP));
  }
  dispatch(setExitSeatAccepted(false));
  const url = buildUrl('/abe/seatmap', ['segmentId'], { segmentId });
  const updateSeatMapResponse = await dispatch(
    postWaiterData({
      name: SEATMAP,
      url,
      values: postSeatMapDef,
    })
  );

  const seatMapCabins = get(updateSeatMapResponse, 'data.cabins', []);
  const oneOrMoreSeatsAvailable = seatMapCabins.length && seatMapCabins.some((c) => c.availableSeats > 0);
  const seatMapNotAvailable =
    updateSeatMapResponse.status === 400 ||
    updateSeatMapResponse.status === 404 ||
    updateSeatMapResponse.status === 500 ||
    updateSeatMapResponse.internalCode === 'NOT_FOUND' ||
    !oneOrMoreSeatsAvailable;
  if (!seatMapNotAvailable) {
    dispatch(setAllSeatMapsUnavailable(false));
  }
  if (
    currFlow === CHANGE_SEATS &&
    seatMapNotAvailable &&
    updateSeatMapResponse?.data?.internalCode === RESPONSE_STATUS.FAILURE_NO_PNR
  ) {
    dispatch(setViewAndShowModal(MODALS.NO_PNR));
  }
  return updateSeatMapResponse;
};

const getCheckoutPassengers = (passengers, gifs, chosenFlights, flightSelectionOfferClass, tokensFromState) => {
  return passengers.map((pax, idx) => {
    const targetPassenger =
      chosenFlights.passengers.find((airPax) => airPax.passengerNumber === pax.passengerNumber) || {};
    const { pre, post } = get(targetPassenger, 'flightSchedule', emptyFlightSchedule());
    const preWithoutLabels = pre.filter((item) => item.type !== 'label');
    const postWithoutLabels = post.filter((item) => item.type !== 'label');

    delete preWithoutLabels.flightDetails;
    delete postWithoutLabels.flightDetails;

    const tokenizedValues = tokensFromState[idx];

    // eslint-disable-next-line max-len
    const targetGifPassenger = gifs.find((gifPax) => gifPax.passengerID === pax.passengerNumber) || {};
    const { passportNumber, nationality, passportIssuer, passportExpiresDate } = targetGifPassenger;

    const { middle, ...paxProps } = pax; // Transform `middle` -> `middleName`
    return {
      ...paxProps,
      middleName: middle,
      flightSchedule: { pre: preWithoutLabels, post: postWithoutLabels },
      passportNumber,
      nationality,
      passportIssuer,
      passportExpiresDate,
      newReturnAirClass: flightSelectionOfferClass,
      newOriginAirClass: flightSelectionOfferClass,
      tokenizedValues,
    };
  });
};

const seatMapPaxDetails = (passengers, chosenFlightsPassengers) => {
  const passengerSeats = passengers.map((passenger) => {
    const paxChosenFlight = chosenFlightsPassengers.filter((pax) => pax.passengerNumber === passenger.passengerNumber);
    const preFlights = get(paxChosenFlight[0], 'flightSchedule.pre', []);
    const postFlights = get(paxChosenFlight[0], 'flightSchedule.post', []);
    const allFlightsRaw = preFlights.concat(postFlights);

    const allFlights = allFlightsRaw.filter((item) => item.type !== 'label');
    const seatsObjRaw = allFlights.map((flight) => ({
      segmentNumber: flight.segmentNumber,
      seatNumber: flight.seat,
      price: flight.seatPrice,
      seatWaiver: flight.seatWaiver,
    }));
    const ffArray = get(passenger, 'airPreferences.frequentFlyers', []);
    const ffArrayMapped = ffArray.map((ff) => ({
      sequence: ff.sequence,
      airline: ff.airlineCode,
      ffNumber: ff.frequentFlyerNumber,
    }));
    return {
      id: get(passenger, 'airPassengerId', ''),
      frequentFlyers: ffArrayMapped,
      seats: seatsObjRaw,
      firstName: get(passenger, 'firstName', ''),
      lastName: get(passenger, 'lastName', ''),
      passengerNumber: get(passenger, 'passengerNumber', ''),
    };
  });
  return passengerSeats;
};

export const updateSeats = (paymentDetails = {}) => async (dispatch, getState) => {
  const state = getState();
  const {
    getChosenFlightsPassengers,
    getChosenFlights,
    getChosenSeats,
    getPreAssignedSeats,
    getAllSeatMapsUnavailable,
    checkForPaidSeats,
    getFlightSelectionCabinClass,
    getAddCollectTotal,
    getBookingAlerts,
    getTokenizedValues,
    getAirBookingFlow,
    getAddCollect,
  } = airStore.selectors;
  const {
    setCheckoutProcessing,
    setIsAirPendingInProgress,
    updateAddCollect,
    setBookingAlerts,
    setRequestedSeats,
    setBookedSeats,
    setSeatmapPageNumber,
    setIsACH,
    setPreviouslyRefreshed,
  } = airStore.creators;
  if (getPageHasBeenRefreshed()) {
    dispatch(setPreviouslyRefreshed(true));
  }
  dispatch(setViewAndShowModal(AIR_LOADING));
  dispatch(setCheckoutProcessing(true));
  dispatch(setIsAirPendingInProgress(true));

  let progressIntervalId;
  let currentStep = 0;
  const progressToStep = (step) => {
    if (!currentStep || step > currentStep) {
      currentStep = step;
      const progressSteps = UPDATESEATS_PROGRESS_STEPS;
      if (step && step > 0 && step <= progressSteps.length) {
        const newStep = progressSteps[step - 1];
        progressIntervalId = dispatch(progressJumpTo(newStep.time, newStep.start, newStep.end, progressIntervalId));
      } else {
        progressIntervalId = dispatch(
          progressJumpTo(
            10,
            UPDATESEATS_STEP_PROGRESS.COMPLETE_PERCENTAGE - 1,
            UPDATESEATS_STEP_PROGRESS.COMPLETE_PERCENTAGE,
            progressIntervalId
          )
        );
      }
    }
  };
  progressToStep(1);
  const { TWO_MIN_TIME_OUT, REPEAT_INTERVAL } = POLLING_TIMES;
  const preAssignedSeats = getPreAssignedSeats(state);
  const chosenSeats = await getChosenSeats(state);
  const allSeatMapsUnavailable = getAllSeatMapsUnavailable(state);
  const booking = getBookingDetails(state);
  const {
    currency,
    office,
    ship,
    voyage,
    rateCode,
    packageType,
    passengers: bookingPassengers,
    bookingNumber,
  } = booking;
  const { stateroomNumber, stateroomCategoryCode: stateroomCategory } = ship;
  const passengers = getChosenFlightsPassengers(state);

  const chosenFlights = getChosenFlights(state);
  const chosenFlightsPassengers = get(chosenFlights, 'passengers', '');
  const seatMapPassengersDetails = seatMapPaxDetails(passengers, chosenFlightsPassengers);
  const pnrLocator = get(getChosenFlights(state), 'passengers[0].referenceNumber', '');
  const currAirFlow = getAirBookingFlow(state);

  const sameSeats = (paxChosenSeats, paxPreAssigned) => {
    return isEqual(
      paxPreAssigned.map((s) => s.seat),
      paxChosenSeats.map((s) => s.seatNumber)
    );
  };

  let seatsChanged = false;
  seatMapPassengersDetails.forEach((pax) => {
    if (pax && pax.id) {
      if (
        !sameSeats(
          pax.seats,
          pax.passengerNumber === 1 ? preAssignedSeats.pax1AssignedSeats : preAssignedSeats.pax2AssignedSeats
        )
      ) {
        seatsChanged = true;
      }
    }
  });

  const modifyingUser = getUpdateUserData(state);
  const modifiedBy = `${get(modifyingUser, 'firstName', '')} ${get(modifyingUser, 'lastName', '')}`.trim();

  const cleanUp = () => {
    if (progressIntervalId) {
      clearInterval(progressIntervalId);
    }
    dispatch(setCheckoutProgressComplete());
    dispatch(setIsAirPendingInProgress(false));
    dispatch(setCheckoutProcessing(false));
    dispatch(clearModal(AIR_LOADING));
    window.scrollTo(0, 0);
  };

  if (progressIntervalId) {
    clearInterval(progressIntervalId);
  }

  const postUrl = '/abe/air/updateseat';

  if (seatsChanged && !isEmpty(chosenSeats) && !allSeatMapsUnavailable) {
    const filteredSeats = [];
    const filterEmptySeats = (details) => {
      details.forEach((p) => {
        // eslint-disable-next-line no-param-reassign
        p.seats = p.seats.filter((seat) => seat.seatNumber.length > 0 && seat.seatWaiver !== undefined);
        if (p.seats.length > 0) {
          filteredSeats.push(p);
        }
      });
      setRequestedSeats(filteredSeats);
      return filteredSeats;
    };
    const postChangeSeatsDef = {
      bookingId: bookingNumber,
      pnrLocator,
      modifiedBy,
      passengers: filterEmptySeats(seatMapPassengersDetails),
      currAirFlow,
    };

    const hasPaidSeats = checkForPaidSeats(state);

    const tokenizedValues = getTokenizedValues(state);

    let updateSeatsPayload = { seatsPayload: postChangeSeatsDef };
    if (hasPaidSeats) {
      const checkoutPassengers = getCheckoutPassengers(
        bookingPassengers,
        getGifPassengers(state),
        chosenFlights,
        (getFlightSelectionCabinClass(state) || '').toUpperCase(),
        tokenizedValues
      );
      const email = getEmail(state);

      const invoicePricingPayload = {
        stateroomNumber,
        stateroomCategory,
        numberOfPassengers: bookingPassengers.length,
        voyage,
        pnrLocator,
        bookingNumber,
        office,
        currency,
        packageType,
        rateCode,
        email,
        modifiedBy,
        checkoutPassengers,
      };

      updateSeatsPayload = {
        seatsPayload: postChangeSeatsDef,
        invoicePricing: invoicePricingPayload,
      };
    }

    const traceparent = getTraceparent();
    const startTime = Math.floor(new Date().getTime());
    let requestCount = 0;
    const updateSeatsResponse = await dispatch(
      postWaiterData({
        name: POST_SAVE_SEATS,
        url: postUrl,
        values: updateSeatsPayload,
        config: {
          traceparent,
          tracestate: 'mvagroup=MVA_SEATS;',
        },
      })
    );

    progressToStep(2);

    const updateSeatsResponseError = get(updateSeatsResponse, 'data.errorCode', null);
    if (updateSeatsResponse && !updateSeatsResponseError) {
      const { data } = updateSeatsResponse;
      const updateSeatPayload = data.updateSeat;
      if (updateSeatPayload && updateSeatPayload.isAirPending) {
        return new Promise((resolve) => {
          const searchStartTime = data.startTime || startTime;
          let intervalCount = 0;
          let updateIntervalId;
          const pollUpdateSeats = (url) => {
            intervalCount += REPEAT_INTERVAL;
            requestCount += 1;
            const isLast = intervalCount >= TWO_MIN_TIME_OUT;
            dispatch(
              getWaiterData({
                url,
                name: GET_SAVE_SEATS,
                config: {
                  traceparent,
                  tracestate: `mvagroup=MVA_SEATS;mvastart=${searchStartTime};mvareqcount=${requestCount};`,
                  params: {
                    bookingId: bookingNumber,
                    numberOfPassengers: bookingPassengers.length,
                    office,
                    currency,
                    voyageId: voyage.id,
                  },
                },
              })
            ).then(async (getResponse) => {
              const finishProcessing = () => {
                cleanUp();
                if (updateIntervalId) {
                  clearInterval(updateIntervalId);
                }
              };
              const errorCode = get(getResponse, 'data.errorCode', '');
              if (errorCode === '500' || errorCode === '404') {
                finishProcessing();
                dispatch(setViewAndShowModal(MODALS.FAILED_BOOKING_MODAL));
                return getResponse;
              }
              if (isLast && errorCode === RESPONSE_STATUS.SUCCESS_PARTIAL_CONTENT_206.toString()) {
                finishProcessing();
                dispatch(setViewAndShowModal(MODALS.SEATS_PENDING));
                return getResponse;
              }
              if (getResponse && getResponse.status === 200 && errorCode !== '206') {
                const rebookingStatusURL = '/abe/air/postSuccessfulRebooking';
                const checkoutStats = get(data, 'checkoutStats', {});

                dispatch(
                  postWaiterData({
                    url: rebookingStatusURL,
                    name: REBOOKING_STATUS,
                    values: checkoutStats,
                    config: {
                      traceparent,
                      tracestate: 'mvagroup=MVA_SEATS;',
                    },
                  })
                );
                if (updateIntervalId) {
                  clearInterval(updateIntervalId);
                }
                progressToStep(3);

                const isAirPending = await dispatch(getAirPendingStatus(traceparent, startTime, 'MVA_SEATS'));
                let paymentResult;
                const bookingAlerts = getBookingAlerts(state);

                if (isAirPending && isAirPending.data && !isAirPending.data.airPending) {
                  let total;
                  dispatch(setSeatmapPageNumber(0));
                  const bookedSeatsPayload = get(getResponse, 'data.response.updateStatus', []);
                  const bookedSeats = bookedSeatsPayload.map((pax) => pax.seats);
                  const allSeatErrors = bookedSeatsPayload.every((pax) =>
                    pax.seats.every((seat) => seat.status !== 'SUCCESS')
                  );

                  if (
                    chosenFlightsPassengers.length !== bookedSeats.length &&
                    bookedSeatsPayload[0]?.id === PAX_IDS_STRING.PAX_1
                  ) {
                    bookedSeats.push([]);
                  } else if (chosenFlightsPassengers.length !== bookedSeats.length) {
                    bookedSeats.unshift([]);
                  }
                  dispatch(setBookedSeats(bookedSeats));

                  if (getResponse.data.invoicePricing) {
                    const paymentPassengers = getResponse.data.invoicePricing.pricing.passengers;

                    const currentAddCollectState = getAddCollect(state);
                    const currentTravelProtection = get(currentAddCollectState, 'travelProtectionTotal', []);
                    const confirmTravelProtectionDiff = (currentPaxTravelProtectionDiff, newTravelProtectionDiff) => {
                      if (currentPaxTravelProtectionDiff) {
                        const updatedDiff = newTravelProtectionDiff - currentPaxTravelProtectionDiff.value;

                        if (updatedDiff) {
                          const tppChangeAlert = {
                            code: NOTIFICATION_TYPES.TPP_CHANGE,
                          };
                          dispatch(
                            setBookingAlerts(bookingAlerts ? bookingAlerts.concat(tppChangeAlert) : tppChangeAlert)
                          );
                        }
                        return updatedDiff;
                      }
                      return newTravelProtectionDiff;
                    };

                    const passengerTravelProtectionAmounts =
                      paymentPassengers &&
                      paymentPassengers.map(({ passengerNumber, travelProtectionDiff }) => ({
                        passengerNumber,
                        value: travelProtectionDiff,
                      }));

                    const updatedTPPDiffs = passengerTravelProtectionAmounts.map((pax, idx) => {
                      let updatedTPP = pax.value;
                      if (currentTravelProtection.length > 0) {
                        updatedTPP = confirmTravelProtectionDiff(currentTravelProtection[idx], pax.value);
                      }
                      return {
                        passengerNumber: pax.passengerNumber,
                        value: updatedTPP,
                      };
                    });

                    const travelProtectionTotal =
                      paymentPassengers && paymentPassengers.reduce((acc, pax) => acc + pax.travelProtectionDiff, 0);

                    dispatch(updateAddCollect(TRAVEL_PROTECTION, updatedTPPDiffs));
                    dispatch(updateAddCollect(TRAVEL_PROTECTION_TOTAL, passengerTravelProtectionAmounts));

                    const paidSeatsAddCollect =
                      paymentPassengers && paymentPassengers.map((pax) => pax.bookedPaidSeatsPrice);

                    dispatch(updateAddCollect(PAID_SEATS, paidSeatsAddCollect));

                    const seatTotal =
                      paymentPassengers && paymentPassengers.reduce((acc, pax) => acc + pax.bookedPaidSeatsPrice, 0);
                    total = travelProtectionTotal + seatTotal;
                  } else if (allSeatErrors) {
                    dispatch(updateAddCollect(PAID_SEATS, [null, null]));
                    dispatch(updateAddCollect(TRAVEL_PROTECTION, []));
                    dispatch(updateAddCollect(TRAVEL_PROTECTION_TOTAL, []));
                  } else {
                    total = getAddCollectTotal(state);
                  }
                  progressToStep(4);
                  if (paymentDetails.paymentMethod && !allSeatErrors && total > 0) {
                    dispatch(setIsACH(paymentDetails.paymentMethod === 'ach'));
                    paymentResult = await dispatch(makePayment(paymentDetails, traceparent, startTime, total));
                    const paymentError = get(paymentResult, 'data.errorCode', '').toString();
                    const isPaymentFailure = parseInt(paymentError, 10) === RESPONSE_STATUS.FAILURE_ERROR_CODE;
                    const isPaymentTimeout = parseInt(paymentError, 10) === RESPONSE_STATUS.FAILURE_TIMEOUT_408;
                    if (paymentResult.status !== 200 || isPaymentFailure || isPaymentTimeout) {
                      dispatch(setCheckoutProcessing(false));
                      const paymentFailureAlert = {
                        code: NOTIFICATION_TYPES.PAYMENT_FAILURE,
                      };
                      dispatch(
                        setBookingAlerts(
                          bookingAlerts ? bookingAlerts.concat(paymentFailureAlert) : paymentFailureAlert
                        )
                      );
                    }
                  }
                } else {
                  const updatingFlightsAlert = {
                    code: NOTIFICATION_TYPES.UPDATE_FLIGHT_IN_PROGRESS,
                  };
                  paymentResult = NOTIFICATION_TYPES.UPDATE_FLIGHT_IN_PROGRESS;
                  dispatch(
                    setBookingAlerts(bookingAlerts ? bookingAlerts.concat(updatingFlightsAlert) : updatingFlightsAlert)
                  );
                }
                finishProcessing();
                return resolve(getResponse);
              }
              return [];
            });
          };
          const getUrl = `${postUrl}/${updateSeatPayload.id}`;
          pollUpdateSeats(getUrl);
          updateIntervalId = setInterval(pollUpdateSeats, REPEAT_INTERVAL, getUrl);
        });
      }
    } else if (updateSeatsResponseError) {
      cleanUp();
      dispatch(setViewAndShowModal(MODALS.FAILED_BOOKING_MODAL));
      return updateSeatsResponse;
    }
    cleanUp();
    dispatch(setViewAndShowModal(MODALS.FAILED_BOOKING_MODAL));
    return { data: { errorCode: '500' } };
  }
  cleanUp();
  return null;
};

// eslint-disable-next-line max-len
export const updatePassengerSeat = (segmentId, seatID, seatPrice, seatWaiver, passengerIndex) => (
  dispatch,
  getState
) => {
  const { getChosenFlights, getAddCollect, getChosenSeats } = airStore.selectors;
  const { setChosenFlights, updateAddCollect } = airStore.creators;
  const chosenFlights = cloneDeep(getChosenFlights(getState()));
  const chosenSeats = cloneDeep(getChosenSeats(getState()));
  const pax1CurrSeats = get(chosenSeats, 'pax1AssignedSeats', []).map((seatObj) => seatObj.seat);
  const pax2CurrSeats = get(chosenSeats, 'pax2AssignedSeats', []).map((seatObj) => seatObj.seat);

  const currentAddCollectState = getAddCollect(getState());
  const currentPaidSeats = get(currentAddCollectState, 'paidSeats', []);
  const currentPaxPaidSeats = currentPaidSeats[passengerIndex];

  let trueSeatPrice = seatPrice;

  if (
    (passengerIndex === 0 && pax1CurrSeats.includes(seatID)) ||
    (passengerIndex === 1 && pax2CurrSeats.includes(seatID)) ||
    !seatID
  ) {
    if (currentPaxPaidSeats && currentPaxPaidSeats[segmentId] === seatPrice) {
      trueSeatPrice = null;
    }
  }

  const updatePaidSeatsArray = () => {
    if (currentPaxPaidSeats) {
      currentPaxPaidSeats[segmentId] = trueSeatPrice;
    }
    return currentPaxPaidSeats || { [segmentId]: trueSeatPrice };
  };
  const updatedCurrentPaxSeatsArray = updatePaidSeatsArray();
  if (passengerIndex === 0) {
    dispatch(updateAddCollect(PAID_SEATS, [updatedCurrentPaxSeatsArray, currentPaidSeats[1]]));
  } else if (passengerIndex === 1) {
    dispatch(updateAddCollect(PAID_SEATS, [currentPaidSeats[0], updatedCurrentPaxSeatsArray]));
  }

  const preFlights = cloneDeep(get(chosenFlights, `passengers[${passengerIndex}].flightSchedule.pre`, []));
  const postFlights = cloneDeep(get(chosenFlights, `passengers[${passengerIndex}].flightSchedule.post`, []));
  // eslint-disable-next-line max-len
  const segmentIndexinPreFlights = preFlights.findIndex((segment) => segment.segmentNumber === segmentId);
  // eslint-disable-next-line max-len
  const segmentIndexinPostFlights = postFlights.findIndex((segment) => segment.segmentNumber === segmentId);
  const passengerSeat = seatID;
  if (segmentIndexinPreFlights >= 0 && segmentIndexinPostFlights < 0) {
    set(preFlights, [segmentIndexinPreFlights, 'seat'], passengerSeat);
    set(preFlights, [segmentIndexinPreFlights, 'seatPrice'], seatPrice);
    set(preFlights, [segmentIndexinPreFlights, 'seatWaiver'], seatWaiver);
    set(chosenFlights, ['passengers', passengerIndex, 'flightSchedule', 'pre'], preFlights);
  }
  if (segmentIndexinPreFlights < 0 && segmentIndexinPostFlights >= 0) {
    set(postFlights, [segmentIndexinPostFlights, 'seat'], passengerSeat);
    set(postFlights, [segmentIndexinPostFlights, 'seatPrice'], seatPrice);
    set(postFlights, [segmentIndexinPostFlights, 'seatWaiver'], seatWaiver);
    set(chosenFlights, ['passengers', passengerIndex, 'flightSchedule', 'post'], postFlights);
  }
  dispatch(setChosenFlights(chosenFlights));
};

export const checkout = (validationOnly, checkoutTraceparent, progressToStep) => async (dispatch, getState) => {
  if (!validationOnly) {
    progressToStep(1);
  }
  const traceparent = checkoutTraceparent || getTraceparent();
  const state = getState();
  const {
    creators: {
      setCheckoutProcessing,
      setBookingAlerts,
      setBookedSeats,
      updateAddCollect,
      setReferenceNumber,
      setIsAirViewOnly,
      setIsRepriced,
    },
    selectors: {
      getOfferAddCollect,
      getOfferAddCollectToken,
      getChosenFlights,
      getFlightSelectionValue,
      getReferenceNumber,
      getAddCollect,
      getFlightSelectionCabinClass,
      getSelectedPassengerIndex,
      getAirModifiedBy,
      getAirBookingFlow,
      getTokenizedValues,
    },
  } = airStore;

  const { REPEAT_INTERVAL } = POLLING_TIMES;

  const currAirFlow = getAirBookingFlow(state);
  const changeSeatsFlow = currAirFlow === CHANGE_SEATS;
  const booking = getBookingDetails(state);
  const { currency, office, ship, voyage, rateCode, packageType, passengers, bookingNumber } = booking;
  const { stateroomNumber, stateroomCategoryCode: stateroomCategory } = ship;
  const email = getEmail(state);
  const pnrLocator = getReferenceNumber(state);

  const modifiedBy = getAirModifiedBy(state);

  const tokenizedValues = getTokenizedValues(state);

  const checkoutPassengers = getCheckoutPassengers(
    passengers,
    getGifPassengers(state),
    getChosenFlights(state),
    (getFlightSelectionCabinClass(state) || '').toUpperCase(),
    tokenizedValues
  );
  const chosenFlights = getChosenFlights(state);
  const chosenFlightsPassengers = get(chosenFlights, 'passengers', '');

  const seats = seatMapPaxDetails(passengers, chosenFlightsPassengers);

  const data = {
    stateroomNumber,
    stateroomCategory,
    numberOfPassengers: passengers.length,
    voyage,
    pnrLocator,
    bookingId: bookingNumber,
    office,
    currency,
    packageType,
    rateCode,
    email,
    modifiedBy,
    passengers: checkoutPassengers,
    changeSeatsFlow,
    currAirFlow,
    seats,
  };

  if (!changeSeatsFlow) {
    const offerId = get(getFlightSelectionValue(state), 'offer.offerId', '');
    const addCollect = getOfferAddCollect(state);
    const addCollectTokenized = getOfferAddCollectToken(state);
    const cost = await dispatch(getCostObj());

    data.offerId = offerId;
    data.addCollect = addCollect;
    data.addCollectTokenized = addCollectTokenized;
    data.cost = cost;
  }

  const previousPostCheckout = getWaiterData(state, POST_CHECKOUT);

  if (previousPostCheckout && validationOnly) {
    dispatch(clearWaiter(POST_CHECKOUT));
  }
  let requestCount = 0;
  const tracestate = validationOnly ? '' : 'mvagroup=MVA_CHECKOUT;';
  const checkoutResponse = await dispatch(
    postWaiterData({
      name: validationOnly ? VALIDATE_CHECKOUT : POST_CHECKOUT,
      url: '/abe/air/checkout',
      values: data,
      config: {
        params: {
          review: validationOnly,
        },
        traceparent,
        tracestate,
      },
    })
  );

  if (!SUCCESS_STATUS_CODES.includes(checkoutResponse.status) || checkoutResponse.data.errorMessage) {
    dispatch(setCheckoutProcessing(false));
    dispatch(setViewAndShowModal(FAILED_BOOKING_MODAL));
    if (get(checkoutResponse, 'data.errorDescription', '').includes(RESPONSE_STATUS.FAILURE_LOCKED)) {
      return Promise.resolve({ errorModal: true, isLocked: true });
    }
    return Promise.resolve({ errorModal: true });
  }

  const invoicePricingErrorDescription = get(checkoutResponse, 'data.invoicePricing.errorDescription', '');

  // check for booking locked by other user advisory code in invoicePricing response
  if (invoicePricingErrorDescription.includes(RESPONSE_STATUS.FAILURE_LOCKED)) {
    dispatch(setIsAirViewOnly(true));
    dispatch(setCheckoutProcessing(false));
    dispatch(setViewAndShowModal(MODALS.VIEW_ONLY));
    return Promise.resolve({ errorModal: true, isLocked: true });
  }

  const displayAirCost = get(checkoutResponse, 'data.validateOffer.displayAirCost');
  if (displayAirCost !== undefined) {
    dispatch(
      updateAddCollect(
        AIR_COST,
        passengers.map(() => displayAirCost)
      )
    );
  }

  if (get(checkoutResponse, 'data.gifs', []).length > 0) {
    dispatch(receiveGifData(checkoutResponse.data.gifs));
  }

  if (get(checkoutResponse, 'data.validateOffer.status') === invalid) {
    dispatch(setCheckoutProcessing(false));
    dispatch(setViewAndShowModal(INVALID_FLIGHTS_MODAL));
    return Promise.resolve({ errorModal: true });
  }

  const currentAddCollectState = getAddCollect(state);
  const currentTravelProtection = get(currentAddCollectState, 'travelProtectionTotal', []);
  const confirmTravelProtectionDiff = (currentPaxTravelProtectionDiff, newTravelProtectionDiff) => {
    if (currentPaxTravelProtectionDiff) {
      return newTravelProtectionDiff - currentPaxTravelProtectionDiff.value;
    }
    return newTravelProtectionDiff;
  };

  const passengerTravelProtectionAmounts = get(checkoutResponse, 'data.invoicePricing.passengers', []).map(
    ({ passengerNumber, travelProtectionDiff }) => ({
      passengerNumber,
      value: travelProtectionDiff,
    })
  );

  const updatedTPPDiffs = passengerTravelProtectionAmounts.map((pax, idx) => {
    let updatedTPP = pax.value;
    if (currentTravelProtection.length > 0) {
      updatedTPP = confirmTravelProtectionDiff(currentTravelProtection[idx], pax.value);
    }
    return {
      passengerNumber: pax.passengerNumber,
      value: updatedTPP,
    };
  });

  dispatch(updateAddCollect(TRAVEL_PROTECTION, updatedTPPDiffs));
  dispatch(updateAddCollect(TRAVEL_PROTECTION_TOTAL, passengerTravelProtectionAmounts));

  const travelProtectionPax1 = updatedTPPDiffs.length > 0 ? updatedTPPDiffs[0].value : 0;
  const travelProtectionPax2 = updatedTPPDiffs.length > 1 ? updatedTPPDiffs[1].value : 0;

  const isRepriced = get(checkoutResponse, 'data.validateOffer.status') === repriced;

  if (isRepriced || travelProtectionPax1 !== 0 || travelProtectionPax2 !== 0) {
    dispatch(setCheckoutProcessing(false));
    dispatch(setViewAndShowModal(REPRICED_FLIGHTS_MODAL));
    dispatch(setIsRepriced(isRepriced));
    return Promise.resolve({ errorModal: true });
  }

  const checkoutTime = get(checkoutResponse, 'data.startTime', Date.now());

  if (checkoutResponse.status !== 200) {
    dispatch(setCheckoutProcessing(false));
    return { ...checkoutResponse, startTime: checkoutTime };
  }

  const id = get(checkoutResponse, 'data.rebooking');

  if (id && !validationOnly) {
    progressToStep(2);
    let intervalId;
    return new Promise((resolve) => {
      const pollRebooking = async (url) => {
        requestCount += 1;
        const rebookingGet = await dispatch(
          getWaiterData({
            url,
            name: GET_REBOOKING,
            config: {
              traceparent,
              tracestate: `mvastart=${checkoutTime};mvareqcount=${requestCount};${tracestate}`,
            },
          })
        );
        if (rebookingGet && rebookingGet.status !== 206) {
          if (intervalId) {
            clearInterval(intervalId);
          }
          if (rebookingGet.status === 200) {
            const newPNR = (get(rebookingGet, 'data.pnr.pnrLocator', '') || '').trim().toUpperCase();
            if (newPNR && newPNR !== pnrLocator.trim().toUpperCase()) {
              const passengerIndex = getSelectedPassengerIndex(state);
              dispatch(setReferenceNumber(passengerIndex, newPNR));
            }

            const bookedSeatsPayload = get(rebookingGet, 'data.updateStatus', []);
            const bookedSeats = bookedSeatsPayload.map((pax) => pax.seats);
            dispatch(setBookedSeats(bookedSeats));

            const rebookingStatusURL = '/abe/air/postSuccessfulRebooking';
            const checkoutStats = get(checkoutResponse, 'data.checkoutStats', {});

            dispatch(
              postWaiterData({
                url: rebookingStatusURL,
                name: REBOOKING_STATUS,
                values: checkoutStats,
                config: {
                  traceparent,
                  tracestate: `mvastart=${checkoutTime};mvareqcount=${requestCount};${tracestate}`,
                },
              })
            );

            const invoicePricingPax = get(rebookingGet, 'data.invoicePricing.passengers', []);
            if (invoicePricingPax.length !== 0) {
              const passengerTravelProtectionAmounts = invoicePricingPax.map(
                ({ passengerNumber, travelProtectionDiff }) => ({
                  passengerNumber,
                  value: travelProtectionDiff,
                })
              );
              dispatch(updateAddCollect(TRAVEL_PROTECTION_TOTAL, passengerTravelProtectionAmounts));

              const paidSeatsTotal = get(rebookingGet, 'data.paidSeatsTotal', []);
              dispatch(updateAddCollect(PAID_SEATS, paidSeatsTotal));
            }
          }
          if (get(rebookingGet, 'data.pnr.warnings', []).length > 0) {
            dispatch(setBookingAlerts(uniqBy(get(rebookingGet, 'data.pnr.warnings', []), 'code')));
          }

          return resolve({ ...rebookingGet, startTime: checkoutTime });
        }
        return { ...rebookingGet, startTime: checkoutTime };
      };
      const rebookUrl = `/abe/air/rebooking/${bookingNumber}/${id}`;
      pollRebooking(rebookUrl);
      intervalId = setInterval(pollRebooking, REPEAT_INTERVAL, rebookUrl);
    });
  }

  return { ...checkoutResponse, startTime: checkoutTime };
};

const makePayment = (payment = {}, checkoutTraceparent, checkoutStartTime, total = 0) => async (dispatch, getState) => {
  const traceparent = checkoutTraceparent || getTraceparent();
  const state = getState();
  const updateUserInfo = getUpdateUserData(state);
  const {
    selectors: { getAddCollectTotal, getVoucherValue, getReferenceNumber },
  } = airStore;

  const booking = getBookingDetails(state);
  const pnrLocator = getReferenceNumber(state);
  const addCollectTotal = getAddCollectTotal(state);
  const voucherValue = getVoucherValue(state);
  let totalCharges = total;
  if (totalCharges === 0) {
    totalCharges = addCollectTotal;
    if (voucherValue) {
      totalCharges = addCollectTotal + voucherValue;
    }
  }
  const paymentValue = {
    payment: { ...payment, totalCharges: totalCharges.toFixed(2) },
    pnrLocator,
    updateUserInfo,
  };
  const url = buildUrl(
    '/abe/air/payment',
    ['office', 'currency', 'bookingNumber', 'voyage.id', 'ship.stateroomNumber'],
    booking
  );

  return dispatch(
    postWaiterData({
      name: POST_MAKE_PAYMENT,
      url,
      values: paymentValue,
      config: {
        traceparent,
        tracestate: `mvagroup=MVA_CHECKOUT;mvastart=${checkoutStartTime};`,
      },
    })
  );
};

export const airCheckout = (payment) => async (dispatch, getState) => {
  const {
    setCheckoutProcessing,
    setBookingAlerts,
    setIsAirViewOnly,
    setIsACH,
    setSeatmapPageNumber,
    setCheckoutVoucherValue,
    setPreviouslyRefreshed,
  } = airStore.creators;
  const { getBookingAlerts, getVoucherValue } = airStore.selectors;
  const checkoutTraceparent = getTraceparent();
  if (getPageHasBeenRefreshed()) {
    dispatch(setPreviouslyRefreshed(true));
  }
  dispatch(setViewAndShowModal(AIR_LOADING));
  dispatch(setBookingAlerts(''));
  dispatch(setCheckoutProcessing(true));
  dispatch(cancelTimer());
  let progressIntervalId;
  let currentStep;
  const progressToStep = (step) => {
    if (!currentStep || step > currentStep) {
      currentStep = step;
      const progressSteps = CHECKOUT_PROGRESS_STEPS;
      if (step && step > 0 && step <= progressSteps.length) {
        const newStep = progressSteps[step - 1];
        progressIntervalId = dispatch(progressJumpTo(newStep.time, newStep.start, newStep.end, progressIntervalId));
      } else {
        progressIntervalId = dispatch(
          progressJumpTo(
            10,
            CHECKOUT_STEP_PROGRESS.COMPLETE_PERCENTAGE - 1,
            CHECKOUT_STEP_PROGRESS.COMPLETE_PERCENTAGE,
            progressIntervalId
          )
        );
      }
    }
  };
  const cleanUp = () => {
    if (progressIntervalId) {
      clearInterval(progressIntervalId);
    }
  };
  const checkoutCall = await dispatch(checkout(undefined, checkoutTraceparent, progressToStep));
  if (checkoutCall.errorModal) {
    dispatch(setCheckoutProcessing(false));
    if (checkoutCall.isLocked) {
      dispatch(setIsAirViewOnly(true));
      dispatch(clearModal(AIR_LOADING));
      dispatch(setViewAndShowModal(MODALS.VIEW_ONLY));
      return checkoutCall;
    }
    cleanUp();
    return null;
  }

  if (!SUCCESS_STATUS_CODES.includes(checkoutCall.status)) {
    dispatch(setCheckoutProcessing(false));
    dispatch(clearSpecificModal(AIR_LOADING));
    dispatch(setViewAndShowModal(FAILED_BOOKING_MODAL));
    cleanUp();
    return checkoutCall;
  }

  const checkoutStartTime = get(checkoutCall, 'startTime', Math.floor(new Date().getTime()));
  let paymentResult;
  const bookingAlerts = getBookingAlerts(getState()) || [];
  const updatingFlightsAlert = [set({}, ['code'], NOTIFICATION_TYPES.UPDATE_FLIGHT_IN_PROGRESS)];

  progressToStep(3);
  const isAirPending = await dispatch(getAirPendingStatus(checkoutTraceparent, checkoutStartTime, 'MVA_CHECKOUT'));

  if (isAirPending && isAirPending.data && !isAirPending.data.airPending) {
    dispatch(setSeatmapPageNumber(0));
    if (payment) {
      dispatch(setIsACH(payment.paymentMethod === 'ach'));
    }
    paymentResult = await dispatch(makePayment(payment, checkoutTraceparent, checkoutStartTime));
    const paymentError = get(paymentResult, 'data.errorCode', '').toString();
    const isPaymentFailure = parseInt(paymentError, 10) === RESPONSE_STATUS.FAILURE_ERROR_CODE;
    const isPaymentTimeout = parseInt(paymentError, 10) === RESPONSE_STATUS.FAILURE_TIMEOUT_408;
    if (paymentResult.status !== 200 || isPaymentFailure || isPaymentTimeout) {
      dispatch(setCheckoutProcessing(false));
      const paymentFailureAlert = [set({}, ['code'], NOTIFICATION_TYPES.PAYMENT_FAILURE)];
      dispatch(setBookingAlerts(bookingAlerts ? bookingAlerts.concat(paymentFailureAlert) : paymentFailureAlert));
    }
  } else {
    paymentResult = NOTIFICATION_TYPES.UPDATE_FLIGHT_IN_PROGRESS;
    dispatch(setBookingAlerts(bookingAlerts ? bookingAlerts.concat(updatingFlightsAlert) : updatingFlightsAlert));
  }
  progressToStep(4);
  dispatch(setCheckoutVoucherValue(getVoucherValue(getState())));
  await dispatch(fetchBookings(undefined, true, true));
  cleanUp();
  dispatch(setCheckoutProgressComplete());
  dispatch(setCheckoutProcessing(false));
  dispatch(clearSpecificModal(AIR_LOADING));
  return paymentResult;
};

export const getFilterMinMax = (filterLimits) => {
  const filterNotNeeded = (v1, v2) => v1 === v2;
  const hideDepartureFilter = (v1, v2, threshold) => {
    return filterNotNeeded(v1, v2) || v2 - v1 < threshold;
  };
  const getTimeMinMax = (limit) => ({
    min: limit && limit[0],
    max: limit && limit[1],
    visible: limit && !hideDepartureFilter(limit[0], limit[1], FILTER_BOX_DIFF.DEPARTURE_TIME),
  });

  return {
    outgoing: getTimeMinMax(filterLimits.outgoingDepartureTime),
    outgoingStopover: getTimeMinMax(filterLimits.outgoingStopoverDepartureTime),
    return: getTimeMinMax(filterLimits.returnDepartureTime),
    returnStopover: getTimeMinMax(filterLimits.returnStopoverDepartureTime),
    connectionTime: {
      min: filterLimits.connectingTime && filterLimits.connectingTime[0],
      max: filterLimits.connectingTime && filterLimits.connectingTime[1],
      visible:
        filterLimits.connectingTime && !filterNotNeeded(filterLimits.connectingTime[0], filterLimits.connectingTime[1]),
    },
    numberOfStops: {
      min: filterLimits.minStops || 0,
      max: filterLimits.maxStops || 0,
      visible: !filterNotNeeded(filterLimits.minStops, filterLimits.maxStops),
    },
    price: {
      ...filterLimits.price,
      visible: filterLimits.price && !filterNotNeeded(filterLimits.price.min, filterLimits.price.max),
    },
  };
};

export const filterSearchResults = (values) => (dispatch, getState) => {
  const state = getState();
  const {
    selectors: { getOriginalAvailableFlights },
    creators: { setFilters, setAvailableFlights, setFlightSelection },
  } = airStore;

  const originalResults = getOriginalAvailableFlights(state);

  const airlineList = Object.keys(values.airlines).reduce(
    (acc, airline) => (values.airlines[airline] ? [...acc, airline] : acc),
    []
  );

  const filterDepartureTimes = (segments, direction, isStopover, value) => {
    const getCorrectObjProperty = isStopover ? 'stopover' : direction.toLowerCase();
    const isolatedSegments = isolateCorrectAirSegments(segments, direction)[getCorrectObjProperty];
    const flightInfo = isolatedSegments.filter((segment) => segment.type === 'data');
    const departureTime = flightInfo.length > 0 && flightInfo[0].departureTimeAsNum;
    return departureTime >= value[0] && departureTime <= value[1];
  };

  const filteredFlights = originalResults
    // Price
    .filter((itinerary) => {
      const itineraryOffers = get(itinerary, ['offers'], []);
      const minPrice = min(itineraryOffers.map((offer) => get(offer, ['addCollectFee', 'totalFee'])));
      return minPrice <= values.price.max;
    })
    // Airlines
    .filter((itinerary) => {
      const allSegments = [...get(itinerary, 'pre', []), ...get(itinerary, 'post', [])];
      return allSegments.some((segment) => airlineList.includes(segment.airlineName));
    })
    // Connection time
    .filter(({ pre, post }) => {
      const allSegments = [...pre, ...post];
      const connectionTime1 = values?.connectingTime ? values?.connectingTime?.[0] : values?.connectionTime?.[0];
      const connectionTime2 = values?.connectingTime ? values?.connectingTime?.[1] : values?.connectionTime?.[1];

      const hasSegmentsThatFailFilterCriteria = allSegments.some(
        (segment) =>
          (segment.layoverDuration < connectionTime1 && segment.layoverDuration !== 0) ||
          segment.layoverDuration > connectionTime2
      );
      return !hasSegmentsThatFailFilterCriteria;
    })
    // Max stops
    .filter(({ stops = {} }) => {
      const { pre, post } = stops;
      const maxSegmentStops = pre > post ? pre : post;
      return maxSegmentStops <= values.maxStops;
    })
    // Outgoing Departure Time
    .filter(({ pre }) => {
      if (values.outgoingDepartureTime && !values.outgoingDepartureTime[0] !== null) {
        return filterDepartureTimes(pre, 'Outbound', false, values.outgoingDepartureTime);
      }
      return pre;
    })
    // Outgoing Stopover Departure Time
    .filter(({ pre }) => {
      if (values.outgoingStopoverDepartureTime && values.outgoingStopoverDepartureTime[0] !== null) {
        return filterDepartureTimes(pre, 'Outbound', true, values.outgoingStopoverDepartureTime);
      }
      return pre;
    })
    // Return Departure Time
    .filter(({ post }) => {
      if (values.returnDepartureTime && values.returnDepartureTime[0] !== null) {
        return filterDepartureTimes(post, 'Return', false, values.returnDepartureTime);
      }
      return post;
    })
    // Return Stopover Departure Time
    .filter(({ post }) => {
      if (values.returnStopoverDepartureTime && values.returnStopoverDepartureTime[0] !== null) {
        return filterDepartureTimes(post, 'Return', true, values.returnStopoverDepartureTime);
      }
      return post;
    });

  dispatch(setFilters(values));
  dispatch(setFlightSelection(''));
  return dispatch(setAvailableFlights(filteredFlights));
};
export const confirmReprice = (price) => async (dispatch, getState) => {
  const {
    selectors: { getChosenFlightsPassengers },
    creators: { updateAddCollect },
  } = airStore;
  const state = getState();
  const checkoutResponse = getWaiterResponse(state, POST_CHECKOUT) || getWaiterResponse(state, VALIDATE_CHECKOUT);
  const passengers = getChosenFlightsPassengers(state);
  const newTotal = get(checkoutResponse, 'data.validateOffer.addCollect.totalFee');
  const newAirCost = get(checkoutResponse, 'data.validateOffer.displayAirCost');
  const isRepricedFromZero = get(checkoutResponse, 'data.validateOffer.displayAirCost') === 0;
  const priceIncrease = get(checkoutResponse, 'data.validateOffer.priceIncrease');
  if (newTotal !== undefined) {
    dispatch(
      updateAddCollect(
        TOTAL,
        passengers.map(() => newTotal)
      )
    );
  }
  if (newAirCost !== undefined) {
    dispatch(
      updateAddCollect(
        AIR_COST,
        passengers.map(() => newAirCost)
      )
    );
  }
  if (isRepricedFromZero && priceIncrease) {
    dispatch(
      updateAddCollect(
        AIR_COST,
        passengers.map(() => priceIncrease)
      )
    );
    dispatch(
      updateAddCollect(
        TOTAL,
        passengers.map(() => priceIncrease)
      )
    );
  }
  dispatch(
    updateAddCollect(
      FLIGHT,
      passengers.map(() => price)
    )
  );
  dispatch(clearModal());
};

export const getCurrentCabinClass = (state) => {
  const paxFlights = get(state, 'newAir.airBooking.content.airSchedules.passengers[0].flightSchedule', {});
  const { pre, post } = paxFlights;
  const allFlights = pre.concat(post);
  const cabinClasses = [
    ...new Set(allFlights.filter((f) => f.type !== 'label' && !!f.cabinClass).map((f) => f.cabinClass.toLowerCase())),
  ];

  if (cabinClasses.includes(AIR_CABIN_CLASS.FIRST.code)) {
    return AIR_CABIN_CLASS.FIRST.code;
  }
  if (cabinClasses.includes(AIR_CABIN_CLASS.BUSINESS.code)) {
    return AIR_CABIN_CLASS.BUSINESS.code;
  }
  if (cabinClasses.includes(AIR_CABIN_CLASS.PREMIUM_ECONOMY.code)) {
    return AIR_CABIN_CLASS.PREMIUM_ECONOMY.code;
  }
  return cabinClasses[0] || '';
};

export const getCheckoutSummaryLineItems = (state, page) => {
  const {
    getAddCollect,
    getHasOutboundDeviation,
    getHasReturnDeviation,
    getAirBookingFlow,
    getChosenFlightsPassengers,
    getChosenFlights,
  } = airStore.selectors;

  const { TRAVEL_PROTECTION_TOTAL, GATEWAY_CHANGE, DEVIATION, UPGRADE, AIR_COST, PAID_SEATS } = AIR_ADD_COLLECT;

  const addCollect = getAddCollect(state);
  const hasDeviation = getHasOutboundDeviation(state) || getHasReturnDeviation(state);
  const currAirFlow = getAirBookingFlow(state);
  const countryCode = getCountryCodeFromCurrency(state);
  const passengers = getChosenFlightsPassengers(state);
  const chosenFlights = getChosenFlights(state);

  const paxLineItems = passengers.reduce((acc, passenger) => {
    const passengerFlight = chosenFlights.passengers.find(
      ({ passengerNumber }) => passengerNumber === passenger.passengerNumber
    );
    const cabinClass = get(passengerFlight, ['flightSchedule', 'offer', 'cabinClass']);
    const fullName = getPassengerAbbrevName(passenger);
    const { passengerNumber } = passenger;
    // eslint-disable-next-line
    const getAddCollectLineItem = (lineItem) =>
      addCollect && addCollect[lineItem] && addCollect[lineItem].length > 0 ? addCollect[lineItem][0].value : 0;
    const gateWayChangeAmount = getAddCollectLineItem(GATEWAY_CHANGE);
    const deviationAmount = getAddCollectLineItem(DEVIATION);
    const upgradeAmount = getAddCollectLineItem(UPGRADE);
    const flightAmount = getAddCollectLineItem(AIR_COST);
    const travelProtectionAmount = addCollect[TRAVEL_PROTECTION_TOTAL].find(
      (p) => Number(p.passengerNumber) === Number(passengerNumber)
    )
      ? addCollect[TRAVEL_PROTECTION_TOTAL].find((p) => Number(p.passengerNumber) === Number(passengerNumber)).value
      : 0;
    const getFormattedCabinClass = (cabinClass) => {
      let str = cabinClass;
      if (str === 'PREMIUM_ECONOMY') {
        str = cabinClass.replace('_', ' ');
      }
      return str && convertStringToStartCase(str);
    };

    const getPaxPaidSeatTotal = () => {
      const allPaidSeats = addCollect && addCollect[PAID_SEATS];
      let paxPaidSeats = [];
      if (allPaidSeats && passengers.length === 1 && allPaidSeats[0]) {
        paxPaidSeats = Object.values(allPaidSeats[0]);
      } else if (allPaidSeats && allPaidSeats[passengerNumber - 1]) {
        paxPaidSeats = Object.values(allPaidSeats[passengerNumber - 1]);
      }
      return paxPaidSeats.length > 0
        ? paxPaidSeats.filter((v) => typeof v === 'number').reduce((a, price) => a + price, 0)
        : 0;
    };

    const paidSeatsTotal = getPaxPaidSeatTotal();
    const subItems = [
      {
        label: 'Gateway Change',
        value: formatMoney(gateWayChangeAmount, 2, countryCode),
        key: 'gateway-change',
        rawValue: gateWayChangeAmount,
      },
      {
        label: 'Stopover/Deviation Fee',
        value: formatMoney(deviationAmount, 2, countryCode),
        key: 'deviation',
        rawValue: deviationAmount,
      },
      {
        label: getFormattedCabinClass(cabinClass),
        value: formatMoney(upgradeAmount, 2, countryCode),
        key: 'upgrade',
        rawValue: upgradeAmount,
      },
      {
        label: 'Air Cost',
        value: formatMoney(flightAmount, 2, countryCode),
        key: 'premium',
        rawValue: flightAmount,
      },
      {
        label: 'Travel Protection',
        value: formatMoney(travelProtectionAmount, 2, countryCode),
        key: 'travel-protection',
        rawValue: travelProtectionAmount,
      },
      {
        label: 'Paid Seats',
        value: formatMoney(paidSeatsTotal, 2, countryCode),
        key: 'paid-seats',
        rawValue: paidSeatsTotal,
      },
    ];

    const subItemsWithGroundTransportation = [
      ...subItems,
      {
        label: 'Ground Transfers',
        value: 'Included',
        key: 'transfer',
        rawValue: hasDeviation || currAirFlow === CHANGE_SEATS || page === FROM_PAGE.THANK_YOU ? 0 : -1,
      },
    ];

    const paxTotal = subItems.reduce((a, item) => a + item.rawValue, 0);
    if (paxTotal !== 0 || currAirFlow === CHANGE_SEATS || page === FROM_PAGE.THANK_YOU) {
      return [
        ...acc,
        {
          label: fullName,
          value: formatMoney(
            subItems.reduce((a, item) => a + item.rawValue, 0),
            2,
            countryCode
          ),
          key: fullName,
          subItems: subItemsWithGroundTransportation.filter((item) => item.rawValue !== 0),
          seatLineItems: [],
        },
      ];
    }
    return acc;
  }, []);

  return paxLineItems;
};

export const getCheckoutSummarySeatItems = (state) => {
  const { getChosenFlights, getBookedSeats, getAirBookingFlow } = airStore.selectors;

  const bookedSeats = getBookedSeats(state);
  const currAirFlow = getAirBookingFlow(state);
  const { passengers } = getChosenFlights(state);
  const paxAir = passengers.map((pax) => pax.flightSchedule);

  if (bookedSeats.length === 0) {
    return [];
  }

  const getNecessaryAirDetails = (paxAirInfo) =>
    paxAirInfo
      .filter((seg) => seg.type !== 'label')
      .map((seg) => {
        const { segmentNumber, departureCityCode, arrivalCityCode } = seg;
        return { segmentNumber, departureCityCode, arrivalCityCode };
      });

  const addSeatsToAir = (paxAirInfo, seatsInfo) => {
    paxAirInfo?.forEach((airSeg) => {
      seatsInfo?.forEach((seatSeg) => {
        if (seatSeg.segmentNumber === airSeg.segmentNumber) {
          airSeg.seat = seatSeg.seatNumber;
          airSeg.status = seatSeg.status;
          airSeg.seatPrice = seatSeg.price;
        }
      });
    });
    return paxAirInfo;
  };

  const getSeatValue = (seg) => {
    if (seg.status === 'ERROR') {
      return 'Unavailable';
    }

    if (seg.status === 'PENDING') {
      return `${seg.seat} Pending`;
    }

    return seg.seat;
  };

  const bookingRes = getWaiterResponse(state, GET_REBOOKING);
  const bookingResFlights = get(bookingRes, ['data', 'pnr', 'pre'], []).concat(
    get(bookingRes, ['data', 'pnr', 'post'], [])
  );

  const paxAirPre = getNecessaryAirDetails(paxAir[0].pre);
  const paxAirPost = getNecessaryAirDetails(paxAir[0].post);
  const fullPaxAir = currAirFlow === CHANGE_SEATS ? paxAirPre.concat(paxAirPost) : bookingResFlights;

  const paxAirWithSeats = addSeatsToAir(fullPaxAir, bookedSeats[0]);

  const getSeatSubItems = (paxAirAndSeats) =>
    paxAirAndSeats
      .map((seg) => {
        return {
          label: `${seg.departureCityCode} - ${seg.arrivalCityCode}`,
          value: getSeatValue(seg),
          key: `${seg.departureCityCode} - ${seg.arrivalCityCode}`,
          rawValue: seg.seat,
        };
      })
      .filter((seatObj) => seatObj.value);

  if (passengers.length > 1) {
    const pax2AirPre = getNecessaryAirDetails(paxAir[1].pre);
    const pax2AirPost = getNecessaryAirDetails(paxAir[1].post);
    const bookingResFlightsCopy = JSON.parse(JSON.stringify(bookingResFlights));
    const fullPax2Air = currAirFlow === CHANGE_SEATS ? pax2AirPre.concat(pax2AirPost) : bookingResFlightsCopy;

    const pax2AirWithSeats = addSeatsToAir(fullPax2Air, bookedSeats[1]);

    const pax1SeatSubItems = getSeatSubItems(paxAirWithSeats);
    const pax2SeatSubItems = getSeatSubItems(pax2AirWithSeats);

    return [pax1SeatSubItems, pax2SeatSubItems];
  }

  return getSeatSubItems(paxAirWithSeats);
};

export default airStore;

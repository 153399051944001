import Button from '@viking-eng/button';
import Header from '@viking-eng/header';
import Icon from '@viking-eng/icon';
import IconPanel from '@viking-eng/icon-panel';
import ResponsiveContent from '@viking-eng/responsive-content';
import React from 'react';
import { MODALS, START_GUIDE_BUTTONS, TA_DISABLED_MENU_ITEM, USER_TYPES } from '../../common/Constants';
import { AddBooking } from '../../modals';
import PropTypes from '../../PropTypes';
import './MvjHeader.scss';
const MvjHeader = ({
  addChangeBookingFunction,
  handleAddBookingOpen,
  handleTaRedirect,
  homeLink,
  icons,
  loggedInUserName: { firstName },
  logo,
  menuItems: { left, right, bookings },
  preHeaderLink,
  preHeaderLinkMobile,
  preHeaderText,
  setOpenStartGuide,
  startGuideOpen,
  userType,
}) => {
  const leftMenu = left?.map((item) => {
    const openStartGuideValue = item.title === START_GUIDE_BUTTONS.TITLE;
    return {
      ...item,
      onClick: () => {
        setOpenStartGuide(openStartGuideValue);
        if (item.title === START_GUIDE_BUTTONS.TITLE) {
          startGuideOpen();
        }
      },
    };
  });
  const headerData = {
    logo,
    logoURL: logo, // CMSv2
    navigationRoute: homeLink,
    items: leftMenu,
  };
  const mappedBookings = addChangeBookingFunction(bookings) || [];
  let remappedRightItems = right;

  if (Array.isArray(remappedRightItems) && remappedRightItems.length > 0) {
    remappedRightItems = right.map((btn) => {
      if (btn['data-target'] === TA_DISABLED_MENU_ITEM.ADD_BOOKING) {
        return {
          ...btn,
          onClick: () => handleAddBookingOpen(),
        };
      }
      return btn;
    });
  }
  const rightItems = mappedBookings.concat(remappedRightItems);
  return (
    <div className={`mvj-header icons-${icons.length} ${userType === USER_TYPES.TA ? 'travel-advisor' : ''}`}>
      {logo && (
        <Header
          preHeaderText={preHeaderText}
          preHeaderButton={{
            textMobile: `< ${preHeaderLinkMobile}`,
            text: preHeaderLink,
            onClick: () => handleTaRedirect(),
          }}
          header={headerData}
          additionalMenus={[
            {
              id: 'rightMenu',
              items: rightItems,
              tabIndex: '0',
            },
          ]}
        >
          <div className="right-menu d-flex">
            <Button
              attributes={{
                'data-toggle': 'collapse',
                'data-target': '#rightMenu',
                'aria-haspopup': 'true',
                'aria-expanded': 'false',
                'aria-label': 'Toggle navigation',
                tabIndex: '0',
              }}
              appearance="icon"
            >
              <ResponsiveContent
                media={[
                  {
                    tier: 'lg',
                    content: (
                      <>
                        <span className="label text-nowrap">
                          Welcome,&nbsp;
                          {firstName}
                        </span>
                        <Icon name="chevron-down" />
                      </>
                    ),
                  },
                  {
                    tier: 'xs',
                    content: <Icon name="account" />,
                  },
                ]}
              />
            </Button>
            <IconPanel icons={icons} truncateLimit={9} />
          </div>
        </Header>
      )}
      <AddBooking id={MODALS.ADD_BOOKING} />
    </div>
  );
};

const menuItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    isSmallText: PropTypes.string.isRequired,
    openInNewWindow: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
  })
);

MvjHeader.propTypes = {
  loggedInUserName: PropTypes.shape({
    firstName: PropTypes.string,
  }),
  homeLink: PropTypes.string,
  menuItems: PropTypes.shape({
    left: menuItemsType,
    right: menuItemsType,
    bookings: menuItemsType,
  }).isRequired,
  logo: PropTypes.string.isRequired,
  icons: PropTypes.iconPanelIcons,
  addChangeBookingFunction: PropTypes.func.isRequired,
  preHeaderText: PropTypes.string,
  preHeaderLink: PropTypes.string,
  preHeaderLinkMobile: PropTypes.string,
  handleAddBookingOpen: PropTypes.func.isRequired,
  handleTaRedirect: PropTypes.func.isRequired,
  userType: PropTypes.string,
};

MvjHeader.defaultProps = {
  preHeaderText: null,
  preHeaderLink: null,
  preHeaderLinkMobile: null,
  loggedInUserName: {
    firstName: null,
  },
  homeLink: '',
  icons: [],
  userType: USER_TYPES.CONSUMER,
};

export default MvjHeader;
